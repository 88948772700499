const pushEcommerceEvent = (eventName, ecommerceData, user_id = null, clear = true) => {
  if (!window.dataLayer) return;
  if (clear) {
    window.dataLayer.push({ ecommerce: null });
  }
  window.dataLayer.push({
    event: eventName,
    ecommerce: ecommerceData,
    user_id,
  });
};

export default pushEcommerceEvent;
