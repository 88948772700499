<template>
  <div class="profile-data-widget">
    <div
      v-show="loggedIn"
      class="wrapper"
      @click="setProfileModal(true)"
    >
      <circular-percentage :percentage="profileStatus" />
    </div>
    <portal to="modal">
      <b-modal
        :active="isProfileModal"
        custom-class="is-fullwidth"
        @update:active="value => setProfileModal(value)"
      >
        <user-profile-wrapper />
      </b-modal>
    </portal>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex';
import UserProfileWrapper from './user-profile-wrapper.vue';
import { AUTH_MODULE, LOGGED_IN } from '../../store/modules/auth/types';
import { GET_PROFILE_COMPLETION, USER_MODULE } from '../../store/modules/user/types';
import {
  NAVIGATION_MODULE,
  PROFILE_MODAL_VISIBLE,
  SET_PROFILE_MODAL,
} from '../../store/modules/navigation/types';

export default {
  name: 'ProfileDataWidget',
  components: { UserProfileWrapper },
  data() {
    return {
      diameter: 20,
      strokeWidth: 2,
    };
  },
  computed: {
    ...mapState(AUTH_MODULE, {
      loggedIn: LOGGED_IN,
    }),
    ...mapGetters(USER_MODULE, {
      profileStatus: GET_PROFILE_COMPLETION,
    }),
    ...mapState(NAVIGATION_MODULE, {
      isProfileModal: PROFILE_MODAL_VISIBLE,
    }),
    radius() {
      return this.diameter / 2;
    },
    circumference() {
      return this.diameter * Math.PI;
    },
  },
  methods: {
    ...mapMutations(NAVIGATION_MODULE, {
      setProfileModal: SET_PROFILE_MODAL,
    }),
  },
};
</script>

<style lang="scss" scoped>
</style>
