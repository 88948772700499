var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{style:(!_vm.isReviseRequote && 'padding-bottom: 10rem;')},[_vm._l((_vm.ELEMENTS),function(elementDefinition){return [(Array.isArray(elementDefinition) && (!_vm.leadHash || !_vm.REFERENCE_DATA_OBJECT_BY_ID))?_c('b-skeleton',{attrs:{"animated":_vm.animated}}):_vm._e(),(Array.isArray(elementDefinition) && _vm.leadHash && _vm.REFERENCE_DATA_OBJECT_BY_ID)?_c('div',{key:elementDefinition.entity,staticClass:"is-flex is-align-items-center is-justify-space-between has-multiple-inputs"},_vm._l((elementDefinition),function(subElementDefinition,index){return _c('DraftConfiguratorElement',{key:subElementDefinition.entity,staticClass:"is-flex-grow-1",class:{
          'mr-2': index < elementDefinition.length - 1,
          'ml-2': index > 0
        },attrs:{"def":subElementDefinition,"configurations":_vm.configurations,"locked-configurations":_vm.lockedConfigurations,"lead-hash":_vm.leadHash},on:{"update-config":function($event){return _vm.updateConfiguration(subElementDefinition.entity, $event)},"effect":_vm.handleEffect,"set-note":function($event){return _vm.handleNote(subElementDefinition.entity, $event)}}})}),1):_vm._e(),(!Array.isArray(elementDefinition) && (!_vm.REFERENCE_DATA || !_vm.leadHash))?_c('b-skeleton',{attrs:{"animated":_vm.animated}}):_vm._e(),(!Array.isArray(elementDefinition) && _vm.REFERENCE_DATA && _vm.leadHash)?_c('DraftConfiguratorElement',{key:elementDefinition.entity,attrs:{"def":elementDefinition,"configurations":_vm.configurations,"locked-configurations":_vm.lockedConfigurations,"lead-hash":_vm.leadHash},on:{"update-config":function($event){return _vm.updateConfiguration(elementDefinition.entity, $event)},"effect":_vm.handleEffect,"set-note":function($event){return _vm.handleNote(elementDefinition.entity, $event)}}}):_vm._e()]}),_c('ProductionRequirements',{attrs:{"parts":_vm.parts,"lead-hash":_vm.leadHash,"configurations":_vm.configurations,"locked-configurations":_vm.lockedConfigurations,"is-revise-requote":_vm.isReviseRequote},on:{"update-config":(function (ref) {
                var entitySlug = ref.entitySlug;
                var canApply = ref.canApply;

                return _vm.updateConfiguration(entitySlug, canApply);
})}}),_c('p',{staticClass:"is-size-7"},[_vm._v("\n    Extras\n  ")]),_c('PartFormDraftConfiguratorExtras',{attrs:{"parts":_vm.parts,"lead-hash":_vm.leadHash,"configurations":_vm.configurations,"locked-configurations":_vm.lockedConfigurations},on:{"effect":_vm.handleEffect,"update-config":(function (ref) {
                var entitySlug = ref.entitySlug;
                var canApply = ref.canApply;

                return _vm.updateConfiguration(entitySlug, canApply);
})}}),(_vm.showNotes)?_c('PartFormNoteComponent',{attrs:{"parts":_vm.parts,"lead-hash":_vm.leadHash,"configurations":_vm.configurations,"locked-configurations":_vm.lockedConfigurations},on:{"update-config":(function (ref) {
                var entitySlug = ref.entitySlug;
                var canApply = ref.canApply;

                return _vm.updateConfiguration(entitySlug, canApply);
})}}):_vm._e(),_c('div',[_c('GmButton',{staticClass:"p-0",attrs:{"is-link":"","type":"info","size":"small"},on:{"click":function($event){_vm.showNotes = !_vm.showNotes}}},[_vm._v("\n      "+_vm._s(_vm.notesMessage)+"\n    ")])],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }