<template>
  <BModal
    :active.sync="showRegistrationDetailsModal"
    @close="dismiss"
  >
    <div class="registration-details-modal-content">
      <div class="registration-details-modal-content__side is-hidden-touch">
        <h2 class="title is-2">
          Complete<br> your profile
        </h2>
        <p class="is-size-7/6">
          You're nearly there. We just need a few more details so we can
          match you with your perfect manufacturing partners.
        </p>
        <ul class="registration-details-modal-content__steps">
          <li
            v-for="step in steps"
            :key="step"
            class="registration-details-modal-content__step"
          >
            <BIcon
              icon="check-circle"
              class="mr-3"
            />
            <span class="is-size-7/6">
              {{ step }}
            </span>
          </li>
        </ul>
      </div>
      <div class="registration-details-modal-content__side">
        <h2 class="title is-5 mb-0">
          A bit more about you
        </h2>
        <RegistrationDetails
          :is-modal-edit="true"
          @close="showRegistrationDetailsModal = false"
        >
          <template #title>
            <p class="has-text-black">
              A little more about you
            </p>
          </template>
        </RegistrationDetails>
      </div>
    </div>
  </BModal>
</template>

<script>
import { mapState } from 'vuex';
import { USER_DATA, USER_MODULE } from '@/app-buyer/store/modules/user/types';
import RegistrationDetails from '@/app-buyer/components/registration/RegistrationDetails.vue';

export default {
  name: 'RegistrationDetailsModal',
  components: { RegistrationDetails },
  data() {
    return {
      showRegistrationDetailsModal: false,
      steps: ['Account created', 'Contact details', 'A bit more about you'],
    };
  },
  computed: {
    ...mapState(USER_MODULE, {
      USER_DATA,
    }),
  },
  watch: {
    [USER_DATA]: {
      handler(val) {
        if (
          val
          && this.$route.name !== 'register'
          && this.isMissingRegistrationDetails(val)
          && !this.wasRegisteredToday(val)
          && !this.$cookies.get(`postpone_seg_info.${val.user.id}`)
          && !this.$cookies.get('hide_buyer_registration_modal')
        ) {
          this.showRegistrationDetailsModal = true;
        }
      },
      immediate: true,
    },
  },
  methods: {
    isMissingRegistrationDetails(userData) {
      return !(
        userData.user.contact_number
        && userData.buyer_profile_fields?.some((f) => f.field === 'demand_specific_services')
        && userData.buyer_profile_fields?.some((f) => f.field === 'title')
        && userData.buyer_profile_fields?.some((f) => f.field === 'industry_segments')
      );
    },
    /**
     * The point of this is to reduce the likelihood of this modal appearing while the user
     * is still completing the same form on the RegistrationDetails component.
     *
     * @param userData
     * @returns {boolean}
     */
    wasRegisteredToday(userData) {
      return new Date(userData.profile.created_at).toDateString() === new Date().toDateString();
    },
    dismiss() {
      this.showRegistrationDetailsModal = false;
      this.$cookies.set(`postpone_seg_info.${this[USER_DATA].user.id}`, 1, '7d');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~bulma/sass/utilities/mixins';

.registration-details-modal-content {
  margin: auto;
  background-color: white;
  display: flex;
  border-radius: 5px;
  width: 1000px;
  max-width: 100%;

  &__side {
    padding: 2rem;

    @include desktop {
      flex: none;
      width: 50%;
    }

    &:first-child {
      background-image: url("~@/assets/images/extra-details-modal.jpg");
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      color: white;
    }
  }

  h2.title {
    text-transform: uppercase;

    &.is-2 {
      max-width: 400px;
      color: white;
    }
  }

  &__step {
    margin-bottom: .5rem;
    color: #5fd388;
    display: flex;
    align-items: center;
    position: relative;

    &::after {
      content: "";
      height: 1.1rem;
      width: 1px;
      position: absolute;
      bottom: .3rem;
      left: .725rem;
      -webkit-transform: translate(-50%, 100%);
      transform: translateY(100%);
      background-color: currentColor;
    }

    &:last-child {
      color: white;

      &::after {
        content: none;
      }
    }
  }

  &__steps {
    margin-top: 2rem;
  }
}
</style>
