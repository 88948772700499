<template>
  <div
    class="card is-flex-desktop is-size-7 is-block-touch"
  >
    <table
      class="table collapsed"
    >
      <thead>
        <tr>
          <th
            colspan="2"
          >
            {{ isProduction ? 'Production' : 'Initial' }} qty quote
          </th>
        </tr>
      </thead>
      <tbody>
        <template v-for="data in quoteData">
          <QuoteRow
            :key="`${data.title}-top-row`"
            :data="data"
            :is-penny="isPenny"
          />
        </template>
      </tbody>
      <tfoot>
        <tr>
          <hr>
        </tr>
        <QuoteRow
          :data="subtotalData"
          :is-penny="isPenny"
        />
      </tfoot>
    </table>
  </div>
</template>

<script>

import QuoteRow from '@/app-buyer/components/rfq-list/QuoteRow.vue';

export default {
  name: 'QuoteDetails',
  components: { QuoteRow },
  props: {
    rfq: {
      required: true,
      type: Object,
    },
    quote: {
      type: Object,
      default: () => null,
    },
    isProduction: {
      type: Boolean,
      default: () => false,
    },
    isPenny: Boolean,
  },
  computed: {
    quoteData() {
      const partPriceData = this.quote?.entries?.find((e) => e.type?.slug === 'part');
      const toolingPriceData = this.quote?.entries?.find((e) => e.type?.slug === 'tooling');
      const requestedManufacturingTime = this.rfq.configuration_object['manufacturing-time'].name;
      const deliveryData = this.quote?.delivery;
      const addonPriceData = this.quote?.entries
        ?.filter((e) => e.type?.slug === 'addon')
        ?.reduce((res, cur, index) => ({
          ...res,
          title: index ? 'Addons cost' : 'Addon cost',
          value: cur.unit_cost + res.value,
        }), {
          title: 'Addon cost',
          value: 0,
          applyFilter: true,
        });
      const quantity = this.isProduction
        ? this.rfq.quantity_production
        : this.rfq.quantity_initial;
      const result = [
        {
          title: this.isProduction ? 'Production quantity' : 'Initial quantity',
          value: quantity,
        },
        {
          title: 'Unit cost',
          value: this.isProduction ? this.quote?.unit_cost : partPriceData?.unit_cost,
          applyFilter: true,
        },
        {
          title: 'Tooling cost',
          value: toolingPriceData?.unit_cost,
          applyFilter: true,
        },
      ];

      if (addonPriceData) {
        result.push(addonPriceData);
      }

      result.push(
        {
          title: 'Requested Manufacturing Time',
          value: deliveryData?.requested || requestedManufacturingTime,
        },
      );
      result.push({
        title: 'Proposed Manufacturing Time',
        value: deliveryData ? `${deliveryData.range} business days` : null,
      });
      return result;
    },
    subtotalData() {
      const partPriceData = this.quote?.entries?.find((e) => e.type?.slug === 'part');
      return {
        title: 'Subtotal',
        value: partPriceData?.subtotal || this.quote?.subtotal,
        applyFilter: true,
        bold: true,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  td {
    border: none !important;
  }
}
.collapsed {
  border-collapse: collapse !important;
}
</style>
