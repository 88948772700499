<template>
  <ul
    v-if="project && project.members"
    class="is-flex is-flex-wrap-wrap"
  >
    <li
      v-for="member in members"
      :key="member.id"
      class="mx-1"
    >
      <MemberAvatar :user="member.user" />
    </li>
    <li v-if="members.length < project.members.length">
      <div class="member-remainder">
        +{{ project.members.length - members.length }}
      </div>
    </li>
  </ul>
</template>
<script>
import MemberAvatar from '@/app-buyer/components/teams/MemberAvatar.vue';

export default {
  name: 'ProjectMemberAvatars',
  components: { MemberAvatar },
  props: {
    project: {
      type: Object,
      default: null,
    },
    max: {
      type: Number,
      default: null,
    },
  },
  computed: {
    members() {
      if (this.max == null) return this.project.members;
      return this.project.members.slice(0, this.max);
    },
  },
};
</script>

<style lang="scss" scoped>
.member-remainder {
  width: 25px;
  height: 25px;
  border-radius: 25px;
  border: 1px solid variables.$g-light-1;
  line-height: 24px;
  text-align: center;
  font-size: 10px;
  font-weight: bold;
}
</style>
