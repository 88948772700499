/**
 * Property types
 */
export const CNC = 'cnc';
export const THREE_D_PRINTING = '3d-printing';
export const INJECTION_MOULDING = 'injection-moulding';
export const SHEET_METAL = 'sheet-metal';
export const STP = 'stp';
export const STEP = 'step';
export const IGES = 'iges';
export const IGS = 'igs';
export const PDF = 'pdf';
export const DXF = 'dxf';
export const DWG = 'dwg';
export const STL = 'stl';
export const HAS_HOLE_THREADING = 'has-hole-threading';
export const NO_HOLE_THREADING = 'no-hole-threading';
export const HAS_COUNTERSINK_HOLES = 'has-countersunk-holes';
export const NO_HOLE_COUNTERSINKING = 'no-hole-countersinking';
export const HAS_BENDING = 'has-bending';
export const NO_BENDING = 'no-bending';
export const STANDARD_PLUS_MINUS_0127MM = 'standard-plus-minus-0127mm';
export const PRECISION_LESS_THAN_0127MM = 'precision-less-than-0127mm';
export const RA04 = 'ra-04';
export const ADD_ON = 'add-on';
export const SCREWS = 'screws';
export const PART_MARKING = 'part-marking';
export const CERTIFICATE_STANDARD = 'certificate-standard';
export const INSPECTION = 'inspection';
export const STANDARD_INSPECTION = 'standard-inspection';
export const PRIMARY_FINISH = 'primary-finish';
export const PRIMARY_FINISH_POLISHED = 'primary-polished';
export const TOLERANCE = 'tolerance';
export const PPAP_PACKAGE = 'ppap-package';
export const FORMAL_INSPECTION = 'formal-inspection';
export const CUSTOM_INSPECTION = 'custom-inspection';
export const SLS = 'sls-selective-laser-sintering';

/**
 * Upload types
 */
export const DRAFT_RFQ_MODEL = 'draft-rfq-model';
export const RFQ_MODEL = 'rfq-model';
export const DRAFT_RFQ_SUPPORTING_FILE = 'draft-rfq-supporting-file';
export const RFQ_SUPPORTING_FILE = 'rfq-supporting-file';
export const REDACTED_RFQ_SUPPORTING_FILE = 'redacted-rfq-supporting-file';
export const DRAFT_RFQ_MODEL_PREVIEW = 'draft-rfq-model-preview';
export const RFQ_MODEL_PREVIEW = 'rfq-model-preview';
export const REDACTED_RFQ_MODEL_PREVIEW = 'redacted-rfq-model-preview';
export const ORDER_QUALITY_CONTROL_FILE = 'order-quality-control-file';
export const MESSAGE_ATTACHMENT = 'message-attachment';
export const LOGO = 'logo';
export const NDA_SIGNATURE = 'nda-signature';
export const VENDOR_CERTIFICATE = 'vendor-certificate';
export const JOB_BID_FILE = 'job-bid-file';
export const TEMP_FILE = 'temp-file';
export const REDACTED_RFQ_MODEL = 'redacted-rfq-model';
export const SYSTEM_FILE = 'system-file';

/**
 * Draft issue types
 */
export const WARNING = 'warning';
export const ERROR = 'error';
export const DANGER = 'danger';

export const partOrder = [STEP, STP, PDF, IGS, IGES, DWG, DXF, STL];
export const STRICTLY_MODEL_FILES = {
  [CNC]: [STEP, STP, IGS, IGES],
  [SHEET_METAL]: [],
  [THREE_D_PRINTING]: [STL],
  [INJECTION_MOULDING]: [STEP, STP, IGS, IGES],
};
