<template>
  <div class="btn-wrapper is-flex">
    <ul style="width: 180px;">
      <li
        v-for="(file, index) in supportingFiles"
        :key="file.id"
        :class="{'mb-1' : index < supportingFiles.length - 1}"
        class=" pr-1 is-width-100"
      >
        <div
          ref="supporting-file"
          class="button-group is-width-100"
        >
          <GmButton
            v-if="isDraggable"
            draggable
            size="small"
            rounded
            outlined
            type="light"
            class="pl-2 pr-1"
            @dragstart.stop="startDrag($event, index)"
            @dragend="handleDragEnd"
            @dragexit="handleDragEnd"
          >
            <BIcon
              icon="grip-vertical"
              size="is-small"
              style="color: black;"
            />
          </GmButton>
          <GmButton
            :title="file.client_original_name || file.name"
            size="small"
            rounded
            outlined
            type="light"
            class="upload-button hidden-ghost"
            @click="handlePDFClick(file)"
          >
            <span
              data-testid="supporting-file-name"
              style="color: black; overflow: hidden; text-overflow: ellipsis;"
            >
              {{ file.client_original_name || file.name }}
            </span>
            <span
              v-if="showHover"
              class="is-absolute is-flex is-align-items-center is-justify-content-center
              is-inset-0 is-block"
            >
              <BIcon
                icon="eye"
                style="color: white;"
              />
            </span>
          </GmButton>
          <GmButton
            size="small"
            rounded
            outlined
            type="light"
            class="px-1"
            style="background-color: #f3f3f3;"
            @click="handleRemoveSupportingFile({ file })"
          >
            <BIcon
              icon="times"
              size="is-small"
              style="color: black;"
            />
          </GmButton>
        </div>
      </li>
    </ul>
    <GmButton
      size="small"
      outlined
      type="light"
      style="color: black; padding: 0;"
      rounded
      @click="$emit('add-more-files')"
    >
      <BIcon
        type="dark"
        icon="plus"
        style="font-size: .8rem;"
      />
    </GmButton>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import {
  REMOVE_SUPPORTING_FILE, REVISE_REQUOTE_CONFIG, RFQ_MODULE, SET_REVISE_REQUOTE_CONFIG,
} from '@/app-buyer/store/modules/rfq/types';
import { PROJECT_MODULE, SET_PROJECT_SUPPORTING_FILE_MODAL } from '@/app-buyer/store/modules/projects/types';
import { viewPDF } from '@/app-buyer/components/project/mixins';
import accessDragData from '@/app-buyer/mixins/drag-drop-access';
import { DRAFT_RFQ_SUPPORTING_FILE } from '@/shared/consts/slugs';

export default {
  name: 'PartSupportingFiles',

  mixins: [viewPDF, accessDragData],

  props: {
    part: {
      type: Object,
      required: true,
    },

    supportingFiles: {
      type: Array,
      default: () => [],
    },

    isDraggable: {
      type: Boolean,
      default: true,
    },
    showHover: {
      type: Boolean,
      default: true,
    },
    isProjectPage: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    ...mapState(RFQ_MODULE, {
      REVISE_REQUOTE_CONFIG,
    }),
  },

  methods: {
    ...mapActions(RFQ_MODULE, {
      REMOVE_SUPPORTING_FILE,
    }),
    ...mapMutations(RFQ_MODULE, {
      SET_REVISE_REQUOTE_CONFIG,
    }),
    ...mapMutations(PROJECT_MODULE, {
      SET_PROJECT_SUPPORTING_FILE_MODAL,
    }),

    handleRemoveSupportingFile({ file }) {
      if (this.isProjectPage) this[REMOVE_SUPPORTING_FILE]({ part: this.part, file });
      else {
        const config = this[REVISE_REQUOTE_CONFIG].filter((c) => c.hash === this.part.hash);
        config[0].files.supportingFiles = config[0]?.files?.supportingFiles?.filter((sF) => sF.name !== file.name);

        if (!config[0]?.files?.supportingFiles?.length) {
          delete config[0].files.supportingFiles;
        }

        this[SET_REVISE_REQUOTE_CONFIG](config[0]);
      }
    },

    startDrag(event, index) {
      const file = this.supportingFiles[index];
      if (!file) return;
      const el = this.$refs['supporting-file'][index];
      const ghost = el.cloneNode(true);
      ghost.style.position = 'fixed';
      document.body.appendChild(ghost);
      event.dataTransfer.setDragImage(ghost, 0, 0);
      this.$nextTick(() => {
        this.handleDragStart(file);
      });
    },

    handleDragStart(file) {
      this._setDragData({
        file,
        ownerHash: this.part.hash,
      }, DRAFT_RFQ_SUPPORTING_FILE);
    },

    handleDragEnd() {
      this._removeDragData();
      this.dragoverFile = null;
    },

    handlePDFClick(file) {
      this[SET_PROJECT_SUPPORTING_FILE_MODAL](file);
      this.$emit('shift-select');
    },
  },
};
</script>
<style scoped lang="scss">
.btn-wrapper:last-of-type {
  padding-bottom: 1rem;
}
</style>
