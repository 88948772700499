<template>
  <div
    v-if="!promoHidden"
    class="promo-card is-flex is-align-items-center has-background-g-light-3 px-5"
  >
    <button
      class="delete"
      @click="hidePromo"
    />
    <figure
      class="image"
      style="width: 150px;"
    >
      <img
        :src="require('../../../assets/images/teamMembers.svg')"
        alt="payment modes"
      >
    </figure>
    <ul class="ml-4">
      <li
        v-for="promo in promoTexts"
        :key="promo"
        class="is-flex is-size-7/6 my-2"
      >
        <div
          class="mr-2"
          style="margin-top: 1px;"
        >
          <BIcon
            icon="check-circle"
            type="is-success"
            size="is-small"
          />
        </div>
        {{ promo }}
      </li>
    </ul>
  </div>
</template>

<script>
const localStoragePromoKey = 'gm_tm_promo_hidden';

export default {
  name: 'ProjectMemberInviteModalPromo',
  data() {
    return {
      promoTexts: [
        'Collaborate on parts within your team',
        'View all quotes for a project',
        'Track progress of project orders',
        'Assign payment & purchasing contacts',
      ],
      promoHidden: localStorage.getItem(localStoragePromoKey),
    };
  },
  methods: {
    hidePromo() {
      localStorage.setItem(localStoragePromoKey, Date.now());
      this.promoHidden = true;
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>

.promo-card {
  border-radius: variables.$radius;
  position: relative;
  border: 1px solid variables.$g-light-2;

  button.delete {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
  }
}
</style>
