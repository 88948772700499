<template>
  <div class="button-group">
    <GmButton
      size="small"
      rounded
      outlined
      class="upload-button"
      type="light"
      :loading="loading"
      :disabled="loading"
      @click="SET_VIEWED(part)"
    >
      <span style="color: black; overflow: hidden; text-overflow: ellipsis;">
        3D Configuration
      </span>
      <span
        class="is-absolute is-flex is-align-items-center is-justify-content-center
              is-inset-0 is-block"
      >
        <BIcon
          icon="eye"
          style="color: white;"
        />
      </span>
    </GmButton>
    <GmButton
      size="small"
      rounded
      outlined
      type="light"
      class="px-1"
      style="background-color: #f3f3f3; color: black;"
      :loading="loading"
      :disabled="loading"
      @click="$emit('remove')"
    >
      <BIcon
        icon="times"
        size="is-small"
        style="color: black;"
      />
    </GmButton>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import { RFQ_MODULE, SET_VIEWED } from '@/app-buyer/store/modules/rfq/types';

export default {
  name: 'PartSupportingConfiguration',
  props: {
    part: {
      type: Object,
      required: true,
    },
    loading: Boolean,
  },
  methods: {
    ...mapMutations(RFQ_MODULE, {
      SET_VIEWED,
    }),
  },
};
</script>
