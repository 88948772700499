<template>
  <div data-testid="user-address-component">
    <div class="ml-4">
      <slot name="shipping-address">
        <p class="is-size-6 has-text-weight-bold">
          1. Shipping address
        </p>
      </slot>
      <div class="pl-4 py-2">
        <user-address
          :data="deliveryAddress"
          address-type="delivery"
          @update="updateAddressEvent('delivery', $event)"
        />
      </div>
    </div>
    <hr>
    <div class="ml-4">
      <slot name="billing-address">
        <p class="is-size-6 has-text-weight-bold">
          2. Billing address
        </p>
      </slot>
      <div class="pl-4 py-2">
        <b-checkbox
          v-model="sameAddress"
          class="is-size-7/6"
          data-testid="same-billing-address-checkbox"
        >
          Same as shipping address
        </b-checkbox>
        <user-address
          :data="billingAddress"
          :hide-string="sameAddress"
          address-type="billing"
          @update="updateAddressEvent('billing', $event)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import UserAddress from '@/app-buyer/components/user-profile/v2/user-address.vue';
import { mapGetters, mapState } from 'vuex';
import {
  GET_BILLING_DATA,
  GET_DELIVERY_DATA,
  USER_DATA,
  USER_MODULE,
} from '@/app-buyer/store/modules/user/types';
import updateAddressMixin from '@/app-buyer/components/user-profile/v2/update-address-mixin';

export default {
  name: 'CartUserAddress',
  components: { UserAddress },
  mixins: [updateAddressMixin],
  data() {
    return {
      innerSameAddress: null,
    };
  },
  computed: {
    ...mapState(USER_MODULE, {
      userData: USER_DATA,
    }),
    ...mapGetters(USER_MODULE, {
      deliveryAddress: GET_DELIVERY_DATA,
      billingAddress: GET_BILLING_DATA,
    }),
    sameAddress: {
      get() {
        return this.innerSameAddress;
      },
      set(value) {
        this.innerSameAddress = value;
        if (value) {
          this.updateAddress('billing', this.deliveryAddress);
        }
      },
    },
  },
  watch: {
    userData: {
      handler() {
        const properties = [
          'street_address',
          'town',
          'post_code',
          'county',
          'country',
        ];
        if (this.deliveryAddress && this.billingAddress) {
          this.innerSameAddress = properties
            .every((e) => this.deliveryAddress[e] === this.billingAddress[e]);
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style scoped>

</style>
