const routeParameterHandler = {
  methods: {
    extractUrlParameters(customRoute, ...params) {
      const route = customRoute || this.$route;
      return params.reduce((res, curr) => {
        const stringInUrl = route.params[curr];
        if (stringInUrl) {
          const split = typeof stringInUrl === typeof '' ? stringInUrl.split(',') : [stringInUrl];
          return { ...res, [curr]: split.length === 1 ? split[0] : split };
        }
        return res;
      }, {});
    },
    async applyUrlParameters(customRoute, params) {
      const name = customRoute ? customRoute.name : this.$route.name;
      Object.keys(params).forEach((key) => {
        if (Array.isArray(params[key])) {
          // eslint-disable-next-line no-param-reassign
          params[key] = params[key].reduce((res, curr) => `${res}${curr},`, '');
        }
      });
      await this.$router.push({
        name,
        params,
      });
    },
  },
};

export default routeParameterHandler;
