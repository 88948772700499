import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faArchive,
  faBox,
  faBoxOpen,
  faCartPlus as faCartPlusLight,
  faCheck as faCheckLight,
  faClone as faCloneLight,
  faCog as faCogLight,
  faCommentDots as faCommentDotsLight,
  faCube as faCubeLight,
  faEdit as faEditLight,
  faEye as faEyeLight,
  faFilePdf as faFilePdfLight,
  faMinusCircle as faMinusCircleLight,
  faPlus as faPlusLight,
  faSync,
  faTrash as faTrashLight,
  faUserPlus as faUserPlusLight,
} from '@ManufacturingSource/pro-light-svg-icons';
import {
  faInbox,
  faAngleLeft,
  faAngleRight,
  faArrowDown,
  faArrowLeft,
  faArrowUp,
  faBackward,
  faBars,
  faBell,
  faBold,
  faCalendar,
  faCalendarDay,
  faCartPlus,
  faCheck,
  faCheckCircle,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faClipboardList,
  faClock,
  faClone,
  faCloudDownloadAlt,
  faCog,
  faCommentDots,
  faComments,
  faCrown,
  faCube,
  faDownload,
  faEdit,
  faEllipsisH,
  faEnvelope,
  faExchangeAlt,
  faExclamationCircle,
  faExclamationTriangle,
  faEye,
  faEyeSlash,
  faFileAlt,
  faFilePdf,
  faFolder,
  faFolderPlus,
  faGripVertical,
  faImage,
  faInfoCircle,
  faItalic,
  faListOl,
  faListUl,
  faLock,
  faMinus,
  faMinusCircle,
  faPaperclip,
  faPaperPlane,
  faPencilAlt,
  faPlayCircle,
  faPlus,
  faPlusCircle,
  faPoll,
  faQuestionCircle,
  faRedo,
  faSearch,
  faSearchPlus,
  faShoppingCart,
  faSort,
  faSortDown,
  faSortUp,
  faSpinner,
  faStar,
  faStarHalfAlt,
  faSyncAlt,
  faThList,
  faThumbsUp,
  faTimes,
  faTimesCircle,
  faTrash,
  faTrashAlt,
  faUndo,
  faUpload,
  faUserPlus,
  faUserSecret,
  faVideo,
  faCreditCard,
  faBolt,
} from '@fortawesome/free-solid-svg-icons';

library.add(
  faInbox,
  faAngleLeft,
  faAngleRight,
  faArchive,
  faArrowLeft,
  faArrowDown,
  faArrowUp,
  faBackward,
  faBars,
  faBell,
  faBold,
  faCalendar,
  faCalendarDay,
  faCartPlus,
  faCheck,
  faCheckCircle,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faClipboardList,
  faClock,
  faClone,
  faCloudDownloadAlt,
  faCog,
  faCommentDots,
  faComments,
  faCube,
  faDownload,
  faEdit,
  faEllipsisH,
  faExchangeAlt,
  faExclamationCircle,
  faExclamationTriangle,
  faEye,
  faEyeSlash,
  faFileAlt,
  faFilePdf,
  faFolder,
  faImage,
  faInfoCircle,
  faItalic,
  faListOl,
  faListUl,
  faLock,
  faMinus,
  faMinusCircle,
  faPaperclip,
  faPaperPlane,
  faPencilAlt,
  faPlayCircle,
  faPlus,
  faPlusCircle,
  faPoll,
  faRedo,
  faSearch,
  faSearchPlus,
  faShoppingCart,
  faSort,
  faSortDown,
  faSortUp,
  faSpinner,
  faSyncAlt,
  faThList,
  faTimes,
  faTimesCircle,
  faThumbsUp,
  faTrash,
  faStar,
  faStarHalfAlt,
  faSync,
  faUndo,
  faUpload,
  faUserSecret,
  faVideo,
  faUserPlus,
  faEnvelope,
  faCrown,
  faTrashAlt,
  faUserPlusLight,
  faFilePdfLight,
  faCubeLight,
  faFolderPlus,
  faQuestionCircle,
  faMinus,
  faGripVertical,
  faArchive,
  faBoxOpen,
  faBox,
  faEditLight,
  faPlusLight,
  faCheckLight,
  faEyeLight,
  faCommentDotsLight,
  faCogLight,
  faCloneLight,
  faCartPlusLight,
  faMinusCircleLight,
  faTrashLight,
  faCreditCard,
  faBolt,
);
