import { CHATS, CHAT_MESSAGES, SUBSCRIPTIONS } from './types';
import { CHANNELS } from '../types';

export default function getState() {
  return {
    [CHATS]: [],
    [CHAT_MESSAGES]: {},
    [CHANNELS]: {},
    [SUBSCRIPTIONS]: {},
  };
}
