<template>
  <aside>
    <div class="is-flex is-align-items-center mb-6">
      <icon-font
        icon="geomiq-up"
        class="mr-4"
        style="font-size: 30px; color: white; font-weight: 100;"
      />
      <icon-font
        icon="geomiq-text"
        style="font-size: 24px; color: white; font-weight: 100; margin-top: 3px;"
      />
    </div>
    <h1 class="is-size-2 has-text-white has-text-weight-bold is-uppercase mb-4">
      High quality mechanical parts on demand
    </h1>
    <ul class="mt-4">
      <li
        v-for="({ icon, label }) in listElements"
        :key="label"
        class="is-flex is-align-items-center has-text-white py-1"
      >
        <BIcon :icon="icon" />
        <p class="mt-1 ml-2">
          {{ label }}
        </p>
      </li>
    </ul>
  </aside>
</template>

<script>
export default {
  name: 'RegistrationAside',
  data() {
    return {
      listElements: [
        {
          label: 'Uploads secure & confidential',
          icon: 'lock',
        },
        {
          label: 'Best of 3 quotes',
          icon: 'clipboard-list',
        },
        {
          label: 'Quality inspection guarantee',
          icon: 'poll',
        },
        {
          label: 'Rapid 5 day delivery',
          icon: 'calendar-day',
        },
      ],
    };
  },
};
</script>
