<template>
  <b-field
    :class="{disabled}"
    :message="message"
    :style="fullwidth && 'width: 100%;'"
    :type="type"
    :expanded="fullwidth"
    class="file-upload"
    :data-testid="dataTestid"
  >
    <upload-input
      :key="refreshKey"
      v-model="files"
      :accept="accept"
      :disabled="disabled"
      :drag-drop="dragDrop"
      :multiple="multiple"
      :style="fullwidth && 'width: 100%'"
      :data-testid="dataTestid + '-hidden-input'"
      :expanded="fullwidth"
      v-bind="$attrs"
      :type="type"
      :label-style="labelStyle"
      @file-error="rejectedHandler"
    >
      <slot />
      <section
        v-if="!$slots.default"
        :style="fullwidth && 'width: 100%'"
      >
        <a
          :class="[buttonClass, {'is-loading': loading}]"
          class="upload-text-wrapper"
        >
          <slot name="icon-left" />
          <p :class="textClass">
            <strong :style="loading && 'color: transparent;'">{{ label }}</strong>
          </p>
          <slot name="icon-right" />
        </a>
      </section>
    </upload-input>
  </b-field>
</template>

<script>
import UploadInput from './upload-input.vue';
import notificationInjection from '../../../shared/components/notification/notification-injection-mixin';

export default {
  name: 'FileUpload',
  components: { UploadInput },
  mixins: [notificationInjection],
  props: {
    label: {
      type: String,
      default: () => 'Upload files',
    },
    accept: {
      type: String,
      default: () => '',
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    fullwidth: {
      type: Boolean,
      default: () => false,
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
    dragDrop: {
      type: Boolean,
      default: () => false,
    },
    appearance: {
      type: String,
      default: () => 'button',
      validator(value) {
        return ['button', 'link', 'button-emphasised', 'link-emphasised'].indexOf(value) !== -1;
      },
    },
    hideDash: {
      type: Boolean,
      default: () => false,
    },
    loading: {
      type: Boolean,
      default: () => false,
    },
    message: {
      type: String,
      default: () => '',
    },
    type: {
      type: String,
      default: () => '',
    },
    dataTestid: {
      type: String,
      default: () => '',
    },
    noRejectionMessage: {
      type: Boolean,
      default: () => false,
    },
    labelStyle: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      files: null,
      refreshKey: 0,
      allDroppedFiles: null,
    };
  },
  computed: {
    textClass() {
      return this.appearance.includes('emphasised') ? 'is-emphasised' : '';
    },
    buttonClass() {
      return this.appearance.includes('button') ? 'button is-light-blue is-expanded' : '';
    },
  },
  watch: {
    files: {
      handler(value) {
        if (value) {
          this.$emit('upload', value);
          this.files = null;
          this.refreshKey++;
        }
      },
      deep: true,
    },
  },
  methods: {
    rejectedHandler(rejected) {
      if (!this.noRejectionMessage) {
        let summary = '';
        for (let i = 0; i < rejected.length; i++) {
          summary += `<br />${rejected[i].name}`;
        }
        this._addNotification({
          message: `<b>The following files are not yet compatible: </b>${summary}`,
          type: 'warning',
          unique: true,
        });
      }
      this.$emit('rejected', rejected);
    },
  },
};
</script>

<style lang="scss" scoped>
  .upload-text-wrapper {
    display: flex;
    justify-content: center;

    p {
      color: var(--text-blue);
      margin: 0 0.2rem;
    }
  }

  .file-upload.disabled {
    filter: saturate(0);
    opacity: 0.8;
  }
</style>
