<template>
  <section>
    <RegistrationLayout>
      <template #title>
        Create account & get quotes now
      </template>
      <template #subtitle>
        Get a rapid quote and receive parts in as soon as 5 days
      </template>
      <ValidationObserver v-slot="{ passes }">
        <form
          data-testid="registration-form"
          @submit.prevent.stop
        >
          <BInputWithValidator
            v-model="email"
            custom-class="has-text-white is-size-7-touch has-text-weight-bold mt-3"
            :field-type="messages.email && messages.email[0] && 'is-danger'"
            :message="messages.email && messages.email[0]"
            label="Business Email"
            name="email"
            placeholder="Enter email..."
            rules="required"
            vid="email"
            trim
            @input="validateEmail"
          />
          <BMessage
            v-if="showResend"
            type="is-warning"
            class="pt-3"
          >
            An account already exists for this email address,
            to register please click the link in your email.<br>
            <a
              v-if="!resendSubmitted"
              @click="handleResend"
            >Resend email</a>
            <section v-else>
              Email Sent
            </section>
          </BMessage>
          <BInputWithValidator
            v-model="password"
            custom-class="has-text-white is-size-7-touch has-text-weight-bold mt-3"
            :field-type="messages.password && messages.password[0] && 'is-danger'"
            :message="messages.password && messages.password[0]"
            name="password"
            placeholder="Enter password..."
            rules="required"
            type="password"
            vid="password"
            password-reveal
          >
            <template #label>
              <div class="is-flex is-justify-content-space-between is-align-content-flex-end mt-3">
                <span class="has-text-white is-size-7-touch has-text-weight-bold">
                  Create password
                </span>
                <a
                  v-if="FORCED"
                  @click="FORCE_AUTH('login')"
                >
                  Already have an account?
                </a>
                <RouterLink
                  v-else
                  to="/login"
                  class="has-text-decoration-underline is-flex is-align-items-center is-size-7"
                  data-testid="login-link"
                >
                  Already have an account?
                </RouterLink>
              </div>
            </template>
          </BInputWithValidator>
          <BInputWithValidator
            v-model="name"
            custom-class="has-text-white is-size-7-touch has-text-weight-bold mt-3"
            :field-type="messages.name && messages.name[0] && 'is-danger'"
            :message="messages.name && messages.name[0]"
            label="Name"
            name="name"
            placeholder="Enter name..."
            rules="required"
            vid="name"
          />
          <BInputWithValidator
            v-model="contact_number"
            custom-class="has-text-white is-size-7-touch has-text-weight-bold mt-3"
            :field-type="messages.contact_number && messages.contact_number[0] && 'is-danger'"
            :message="messages.contact_number && messages.contact_number[0]"
            input-type="number"
            type="number"
            min-length="5"
            label="Phone Number"
            name="contact_number"
            placeholder="Enter phone number..."
            rules="required"
            vid="contact_number"
          />
          <BField
            custom-class="has-text-white mt-3"
            label="Your industry"
            data-testid="register-details-industry-wrapper"
          >
            <BSelect
              v-model="industry"
              name="industry"
              required
              expanded
              placeholder="Please select an industry segment"
              data-testid="register-details-industry"
            >
              <option
                v-for="(industrySegment, slug) in INDUSTRY_SEGMENTS"
                :key="slug"
                :value="slug"
              >
                {{ industrySegment }}
              </option>
            </BSelect>
          </BField>
          <div class="has-text-white mt-4 mb-1">
            <BInputWithValidator
              v-model="agreement"
              input-type="checkbox"
              name="terms"
              rules="required:true"
              type="is-v2-supporting-1"
              vid="terms"
              data-testid="terms"
            >
              <p class="has-text-white is-size-7">
                I agree to the <a
                  href="https://geomiq.com/user-terms-conditions/"
                  class="has-text-decoration-underline"
                  target="_blank"
                >Terms and conditions</a>&nbsp; and the
                <a
                  href="https://geomiq.com/privacy-policy/"
                  class="has-text-decoration-underline"
                  target="_blank"
                >Privacy policy</a>
              </p>
            </BInputWithValidator>
          </div>

          <BInputWithValidator
            v-model="subscribe"
            input-type="checkbox"
            name="subscribe"
            rules="required:false"
            type="is-v2-supporting-1"
            vid="subscribe"
            data-testid="subscribe"
          >
            <p class="is-flex is-align-items-center is-size-7 has-text-white">
              Receive updates about Product & Exclusive offers
            </p>
          </BInputWithValidator>

          <div class="is-flex mt-2 is-align-items-center">
            <BButton
              :disabled="submitted"
              :loading="submitted"
              data-testid="sign-up-button"
              has-shadow
              class="has-text-weight-bold is-size-7 px-6 mr-4"
              native-type="submit"
              type="is-v2-supporting-1"
              style="height: 3rem;"
              @click="passes(startRegister)"
            >
              Create Account
            </BButton>
            <a
              :href="partnerForm"
              class="has-text-decoration-underline is-size-7 py-2"
            >
              Sign up as a manufacturing partner
            </a>
          </div>
        </form>
      </ValidationObserver>
    </RegistrationLayout>
  </section>
</template>

<script>
import { ValidationObserver } from 'vee-validate';
import { mapActions, mapMutations, mapState } from 'vuex';
import { debounce } from 'lodash/function';
import getEnvironmentVariable from '@/shared/misc/env-variable';
import BInputWithValidator from '@/shared/components/inputs/BInputWithValidator.vue';
import {
  AUTH_MODULE, FORCE_AUTH, FORCED, REGISTER,
} from '@/app-buyer/store/modules/auth/types';
import Api from '@/app-buyer/api/api';
import ENDPOINTS from '@/app-buyer/api/endpoints';
import RegistrationLayout from '@/app-buyer/components/registration/RegistrationLayout.vue';
import INDUSTRY_SEGMENTS from './industry-segments.json';

export default {
  name: 'RegistrationForm',
  components: { RegistrationLayout, BInputWithValidator, ValidationObserver },
  data() {
    return {
      email: '',
      password: '',
      name: '',
      contact_number: '',
      agreement: false,
      subscribe: true,
      submitted: false,
      messages: {},
      showResend: false,
      resendSubmitted: false,
      industry: null,
      INDUSTRY_SEGMENTS,
    };
  },
  computed: {
    ...mapState(AUTH_MODULE, {
      FORCED,
    }),
    partnerForm() {
      return `${getEnvironmentVariable('VUE_APP_API_URL')}/partners/register`;
    },
  },
  methods: {
    ...mapActions(AUTH_MODULE, {
      register: REGISTER,
    }),
    ...mapMutations(AUTH_MODULE, {
      FORCE_AUTH,
    }),
    async startRegister() {
      let token = null;
      if (getEnvironmentVariable('VUE_APP_RECAPTCHA_KEY')) {
        token = await this.getRecaptchaToken();
      }
      this.submitted = true;
      const response = await this.register({
        email: this.email,
        password: this.password,
        name: this.name,
        contact_number: this.contact_number,
        industry: this.industry,
        subscribe: this.subscribe,
        user_type: 'buyer',
        agreement: this.agreement,
        captcha: token,
      });
      if (response.status < 300) {
        this.$nextTick(() => {
          this.$emit('progress', 'success');
        });

        // SEGMENT TRACKING TO REMOVE
        if (this.$gtm) {
          this.$gtm.trackEvent({
            event: 'user-action',
            user_id: response.data?.id,
            'user-action-type': 'sign-up',
            email: this.email,
          });
        }
        // SEGMENT TRACKING TO REMOVE
      } else {
        this.messages = response.data.errors;
      }
      this.submitted = false;
    },
    async getRecaptchaToken() {
      return new Promise((resolve) => {
        window.grecaptcha.ready(async () => {
          const token = await window.grecaptcha.execute(
            getEnvironmentVariable('VUE_APP_RECAPTCHA_KEY'),
          );
          resolve(token);
        });
      });
    },
    validateEmail: debounce(async function validateEmail() {
      let token = null;
      if (getEnvironmentVariable('VUE_APP_RECAPTCHA_KEY')) {
        token = await this.getRecaptchaToken();
      }
      const { data, status } = await Api.post(ENDPOINTS.VALIDATIONS.EMAIL, {
        email: this.email,
        captcha: token,
      }).catch((e) => e.response);
      if (status < 300) {
        this.messages = {
          ...this.messages,
          email: null,
        };
        this.showResend = false;
      } else if (data?.errors?.email[0] === 'An account already exists for this email address.') {
        this.messages = {
          ...this.messages,
          email: null,
        };
        this.showResend = true;
      } else {
        this.messages = {
          ...this.messages,
          email: data?.errors?.email[0] !== 'The email field is required.' ? data.errors.email : '',
        };
        console.log(this.messages);
        this.showResend = false;
      }
    }, 500),
    async handleResend() {
      await Api.post(ENDPOINTS.AUTH.REGISTER_BASIC_RESEND, {
        email: this.email,
      });

      this.resendSubmitted = true;
    },
  },
};
</script>

<style lang="scss" scoped>
// height of registration fields made shorter
::v-deep .input {
  height: 2.3em;
}

::v-deep span.icon.is-right {
  height: 2.3em;
}

::v-deep span select, span.select select{
  height: 2.3em;
}
</style>
