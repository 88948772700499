import {
  DANGER, DRAFT_RFQ_SUPPORTING_FILE, ERROR, PDF, RFQ_SUPPORTING_FILE,
} from '@/shared/consts/slugs';
import {
  REFERENCE_DATA_NOTE_REQUIRED, REFERENCE_DATA_OBJECT_BY_ID,
  REFERENCE_DATA_PDF_REQUIRED, REFERENCE_MODULE,
} from '@/app-buyer/store/modules/reference-data/types';
import PartSupporting from '@/app-buyer/components/project/PartSupporting.vue';
import PartFileUpload from '@/app-buyer/components/project/PartFileUpload.vue';
import { findDraftModelFile } from '@/app-buyer/components/project/helpers';
import { getParserErrorMessage } from '@/app-buyer/mixins/check-uploads';
import ProductionRequirementsButton
from '@/app-buyer/components/project/ProductionRequirementsButton.vue';
import getEnvironmentVariable from '@/shared/misc/env-variable';
import { RFQ_MODULE, REVISE_REQUOTE_CONFIG } from '@/app-buyer/store/modules/rfq/types';

export const hasBeen30Mins = (createdAt) => {
  let createdAtTimeStamp = createdAt;
  if (typeof createdAt === 'number') createdAtTimeStamp *= 1000;
  const thirtyMins = 60 * 60 * 500;
  return (new Date() - new Date(createdAtTimeStamp)) > thirtyMins;
};

const getModelFileIssues = (draft) => {
  const modelFile = findDraftModelFile(draft);
  if (draft.uploadPercent) {
    return {
      type: 'parsing',
      blockSubmit: true,
    };
  }
  if ((!modelFile && !draft.uploadPercent && !draft.updatingFile && !draft.awaitingDispatch) || modelFile?.parser_metadata?.failed_at) {
    return {
      ...getParserErrorMessage(modelFile.parser_metadata.status),
      type: ERROR,
      component: PartFileUpload,
    };
  }

  return false;
};

const getNoteIssues = (draft, requiring) => {
  if (!requiring?.length) return false;
  const customNotes = localStorage.getItem(`notes_${draft.hash}`);
  const hasNotes = !!draft?.notes || customNotes;
  if (!hasNotes && requiring.length) {
    return {
      messageTitle: 'Notes are required',
      message: `When selecting ${requiring.map((p) => `${p.string_value}`).join(', ')} you need to add notes to clarify what you require.`,
      type: ERROR,
    };
  }
  return false;
};

const getSupportingFileIssues = ({ draft, requiring }) => {
  if (!requiring?.length || draft.configuration_object.service.slug === '3d-printing') return false;
  const modelFile = findDraftModelFile(draft);
  if (!modelFile) return false;
  const supportingFiles = draft?.uploads?.filter(
    (u) => [RFQ_SUPPORTING_FILE, DRAFT_RFQ_SUPPORTING_FILE].includes(u?.type?.slug),
  );
  const inspection = draft?.configuration_object?.inspection && draft?.configuration_object?.inspection?.slug !== 'standard-inspection';
  const pdfSupportingFileExists = supportingFiles?.find((f) => f.extension.toLowerCase() === PDF);

  // if buyer selects any inspection for a part, they must upload a PDF format supporting file
  if (modelFile.extension.toLowerCase() !== PDF && inspection && !pdfSupportingFileExists) {
    return {
      messageTitle: 'PDF supporting file is required',
      message: `When selecting ${requiring.map((p) => `${p.string_value}`).join(' & ')} you need to add a PDF supporting file to clarify what you require.`,
      btnText: 'Add PDF supporting file',
      btnType: DANGER,
      type: ERROR,
      service: draft.configuration_object?.service?.name,
      showEditor: false,
      component: PartSupporting,
    };
  }
  if (modelFile.extension.toLowerCase() !== PDF && !supportingFiles?.length && requiring.length) {
    return {
      messageTitle: 'Supporting file is required',
      message: `When selecting ${requiring.map((p) => `${p.string_value}`).join(' & ')} you need to add a supporting file to clarify what you require.`,
      btnText: 'Add supporting file',
      btnType: DANGER,
      type: ERROR,
      service: draft.configuration_object?.service?.name,
      showEditor: false,
      component: PartSupporting,
    };
  }

  return false;
};

const getProductionRequirementIssues = (draft) => {
  if (draft.configuration_object.service.slug !== 'injection-moulding') {
    return false;
  }

  if (new Date(draft?.production_requirements?.t1_sample_deliver_by) < new Date()) {
    return {
      messageTitle: 'Update production requirements',
      message: 'Please update your preferred T1 sample delivery date.',
      btnText: 'Update T1 sample date',
      component: ProductionRequirementsButton,
      type: ERROR,
    };
  }

  const required = [
    't1_sample_deliver_by',
    'development_stage',
  ];
  if (required.every((k) => draft.production_requirements?.[k])) {
    return false;
  }

  return {
    messageTitle: 'Add production requirements',
    message: 'Please complete the `Production requirements` section in the configuration of this part.',
    btnText: 'Add production requirements',
    component: ProductionRequirementsButton,
    type: ERROR,
  };
};

const getParserIssue = (draft) => {
  const modelFile = findDraftModelFile(draft);
  if (modelFile?.extension.toLowerCase() === PDF) return false;
  if (modelFile?.parser_metadata?.completed_at) return false;
  if (modelFile?.parser_metadata?.failed_at) {
    if (modelFile.parser_metadata.status === 'multi-body' && getEnvironmentVariable('VUE_APP_ENV') === 'production') {
      // eslint-disable-next-line no-undef
      Intercom('trackEvent', 'multipart-file-upload');
    }

    return {
      ...getParserErrorMessage(modelFile.parser_metadata.status),
      type: ERROR,
      component: PartFileUpload,
    };
  }
  if (modelFile?.extension.toLowerCase() !== PDF
    && hasBeen30Mins(modelFile?.created_at)
    && !modelFile?.parser_metadata?.failed_at
    && !modelFile?.parser_metadata?.completed_at) {
    return {
      messageTitle: 'Analysis has failed',
      message: 'Please try uploading this file again or with a different file. Please contact support if the issue continues.',
      type: ERROR,
      component: PartFileUpload,
    };
  }
  return {
    isParsing: true,
    type: 'parsing',
  };
};

const getDraftIssues = (draft, rootGetters, rootState) => {
  const fullProperties = Object.values(draft.configuration).map((propertyId) => {
    if (!rootGetters[`${REFERENCE_MODULE}/${REFERENCE_DATA_OBJECT_BY_ID}`]) return {};
    return rootGetters[`${REFERENCE_MODULE}/${REFERENCE_DATA_OBJECT_BY_ID}`][propertyId];
  }).filter(Boolean);
  const propertiesRequiringPdf = rootGetters[`${REFERENCE_MODULE}/${REFERENCE_DATA_PDF_REQUIRED}`];
  const propertiesRequiringNotes = rootGetters[`${REFERENCE_MODULE}/${REFERENCE_DATA_NOTE_REQUIRED}`];

  const hasInspections = draft.configuration_object?.inspection && draft.configuration_object?.inspection?.slug !== 'standard-inspection';
  const hasBending = draft.configuration_object?.bending?.slug === 'has-bending';
  const hasThreading = draft.configuration_object?.['hole-threading']?.slug === 'has-hole-threading';
  const hasCountersinking = draft.configuration_object?.['hole-countersinking']?.slug === 'has-countersunk-holes';
  let requiresSupportingFile = false;

  // Has Revise & Requote has attached a new file?
  const reviseRequoteConfigFiles = rootState[RFQ_MODULE][REVISE_REQUOTE_CONFIG]?.find((c) => c.hash === draft.hash)?.files;

  if ((propertiesRequiringPdf || hasInspections || hasBending || hasThreading || hasCountersinking) && !reviseRequoteConfigFiles?.hasOwnProperty('supportingFiles')) {
    let requiring = [];
    if (propertiesRequiringPdf) requiring = fullProperties.filter((p) => propertiesRequiringPdf[p.slug]);
    if (hasInspections) requiring = [...requiring, draft.configuration_object.inspection];
    if (hasBending) requiring = [...requiring, draft.configuration_object?.bending];
    if (hasThreading) requiring = [...requiring, draft.configuration_object?.['hole-threading']];
    if (hasCountersinking) requiring = [...requiring, draft.configuration_object?.['hole-countersinking']];

    requiresSupportingFile = getSupportingFileIssues({ draft, requiring });
  }

  let requiresNotes = false;
  if (propertiesRequiringNotes) {
    requiresNotes = getNoteIssues(
      draft,
      fullProperties.filter((p) => propertiesRequiringNotes[p.slug]),
    );
  }

  const requiresModelFile = getModelFileIssues(draft);

  const requiresProdRequirements = getProductionRequirementIssues(draft);

  if (
    requiresSupportingFile
    || requiresNotes
    || requiresModelFile
    || requiresProdRequirements
  ) {
    return [
      requiresSupportingFile,
      requiresNotes,
      requiresModelFile,
      requiresProdRequirements,
    ].filter((error) => error);
  }

  return false;
};

export default getDraftIssues;
