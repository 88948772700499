<template>
  <section class="main-aside is-flex is-flex-direction-column is-relative">
    <section class="main-aside-body">
      <ul>
        <li
          v-for="item in items"
          :key="item.name"
          class="main-aside-element"
          :data-testid="`main-aside-element-${item.name}`"
          @mouseenter="handleHover(true, item.name)"
          @mouseleave="handleHover(false, item.name)"
        >
          <RouterLink
            v-show="!item.requiresAuth || LOGGED_IN"
            :to="item.to"
            :data-testid="`buyer-sidenav-${item.name}`"
          >
            <div
              class="is-flex is-flex-align-centered
            is-justify-center p-y-md is-flex-direction-column sidebar-item"
            >
              <div class="wrapper is-relative">
                <IconFont
                  :icon="item.icon"
                  style="font-size: 1.4rem;"
                />
                <span
                  v-if="item.hasCount && METADATA && METADATA[item.hasCount]"
                  class="count is-flex is-flex-align-centered is-justify-center is-absolute"
                >
                  {{ METADATA[item.hasCount] }}
                </span>
              </div>
              <p
                class="text-transform-capitalize"
                style="font-size: 0.9rem;"
              >
                {{ item.name }}
              </p>
            </div>
          </RouterLink>
          <div
            v-if="item.hoverComponent"
            :ref="`asideHoverEl_${item.name}`"
            class="main-aside-element-hover-child"
          >
            <component :is="item.hoverComponent" />
          </div>
        </li>
      </ul>
      <div class="is-flex-grow-1 is-hidden-touch" />
      <!--      <section-->
      <!--        class="is-flex is-flex-direction-column is-justify-flex-end side-nav-bottom"-->
      <!--      >-->
      <!--        <RouterLink-->
      <!--          v-show="LOGGED_IN"-->
      <!--          to="/messages"-->
      <!--        >-->
      <!--          <div-->
      <!--            class="is-flex is-flex-align-centered-->
      <!--            is-justify-center p-y-md is-flex-direction-column sidebar-item"-->
      <!--          >-->
      <!--            <div-->
      <!--              class="wrapper is-relative"-->
      <!--            >-->
      <!--              <IconFont-->
      <!--                icon="chat"-->
      <!--                style="font-size: 1.4rem;"-->
      <!--              />-->
      <!--              <span-->
      <!--                v-if="!!UNREAD_CHATS"-->
      <!--                class="count is-flex is-flex-align-centered is-justify-center is-absolute"-->
      <!--              >-->
      <!--                {{ UNREAD_CHATS }}-->
      <!--              </span>-->
      <!--            </div>-->
      <!--            <p-->
      <!--              class="text-transform-capitalize"-->
      <!--              style="font-size: 0.9rem;"-->
      <!--            >-->
      <!--              Messages-->
      <!--            </p>-->
      <!--          </div>-->
      <!--        </RouterLink>-->
      <!--      </section>-->
      <LoginRegistration />
    </section>
  </section>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { AUTH_MODULE, LOGGED_IN } from '../../store/modules/auth/types';
import { METADATA, USER_DATA, USER_MODULE } from '../../store/modules/user/types';
import LoginRegistration from '../login-registration/login-registration.vue';
import { ACTIVE_PROJECT, PROJECT_MODULE } from '../../store/modules/projects/types';
import { CHATS_MODULE, UNREAD_CHATS } from '../../store/modules/chats/types';
import ProjectSidebar from '@/app-buyer/components/sidebar/ProjectSidebar.vue';

export default {
  name: 'SideNavbar',

  components: { LoginRegistration },

  data() {
    return {
      chatReady: false,
      chatLoading: false,
    };
  },

  computed: {
    ...mapState(AUTH_MODULE, {
      LOGGED_IN,
    }),

    ...mapState(USER_MODULE, {
      METADATA,
      userData: USER_DATA,
    }),

    ...mapGetters(PROJECT_MODULE, {
      ACTIVE_PROJECT,
    }),

    ...mapGetters(CHATS_MODULE, {
      UNREAD_CHATS,
    }),

    items() {
      return [
        {
          name: 'projects',
          to: `/project${this[ACTIVE_PROJECT] ? `/${this[ACTIVE_PROJECT].hash}` : ''}`,
          requiresAuth: true,
          hasCount: 'projects_count',
          icon: 'folder',
          hoverComponent: ProjectSidebar,
        },
        {
          name: 'quotes',
          to: '/quotes',
          tag: 'router-link',
          hasCount: 'quotes_without_orders_count',
          requiresAuth: true,
          icon: 'quotes',
        },
        {
          name: 'orders',
          to: '/orders',
          tag: 'router-link',
          hasCount: 'active_orders_count',
          requiresAuth: true,
          icon: 'order',
        },
      ];
    },
  },

  created() {
    window.hsConversationsSettings = {
      loadImmediately: false,
    };
    if (window.HubSpotConversations) {
      this.chatReady = true;
      if (window.HubSpotConversations.widget) {
        window.HubSpotConversations.widget.remove();
      }
    } else {
      window.hsConversationsOnReady = [
        () => {
          this.chatReady = true;
        },
      ];
    }
  },

  methods: {

    openChat() {
      if (window.HubSpotConversations.widget) {
        this.chatLoading = true;
        const hubspotToken = this.userData?.user?.hubspot_id_token;
        if (hubspotToken) {
          window.hsConversationsSettings = {
            identificationEmail: this.userData.user.email,
            identificationToken: hubspotToken,
          };
        }
        window.HubSpotConversations.widget.load();
        window.HubSpotConversations.widget.open();
        setTimeout(() => {
          this.chatLoading = false;
        }, 1500);
      }
    },
    handleHover(hover, elName) {
      if (elName !== 'projects') return;
      if (hover) this.$refs[`asideHoverEl_${elName}`][0].classList.add('active');
      else this.$refs[`asideHoverEl_${elName}`][0].classList.remove('active');
    },
  },
};
</script>

<style lang="scss" scoped>

  .main-aside {
    background-color: variables.$v2-neutral-1;
    box-shadow: inset 0 0 0 1px #02182B;
    height: calc(100vh - var(--navbar-height));
    z-index: 25;

    @media all and (max-width: variables.$tablet) {
      flex-direction: row;
      height: auto;
    }

    &-body {
      display: flex;
      flex-direction: column;
      flex-grow: 1;

      @media all and (max-width: variables.$tablet) {
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding-top: 0;

        ul {
          display: flex;
        }
      }

      .sidebar-item {
        box-shadow: inset 0 0 0 1px #02182B;
        color: white;
        opacity: 0.8;

        @media all and (max-width: variables.$tablet) {
          padding: 0.5rem;
        }

        p.is-size-6 {
          text-transform: capitalize;
        }

        span.count {
          background-color: white;
          border-radius: 1rem;
          bottom: 0;
          color: variables.$v2-neutral-1;
          font-size: 0.8rem;
          height: 1.2rem;
          right: -1rem;
          min-width: 1.2rem;
          padding: 0 .2rem;
        }
      }

      .sidebar-item:hover {
        background-color: variables.$v2-neutral-1;
        box-shadow: inset 8px 0 0 -4px variables.$v2-supporting-7;
        color: variables.$v2-supporting-7;
        opacity: 1;

        span.count {
          background-color: variables.$v2-supporting-7;
          color: variables.$v2-neutral-1;
        }
      }

      .router-link-active .sidebar-item {
        background-color: variables.$v2-neutral-2;
        box-shadow: inset 8px 0 0 -4px white;
        color: white;
        opacity: 1;

        span.count {
          background-color: white;
          color: variables.$v2-neutral-2;
        }
      }
    }

    &-footer {
      flex-grow: 0;
    }
  }

  .side-nav-bottom {
    padding-bottom: 45px;

    @media all and (max-width: variables.$tablet) {
      flex-grow: 0;
      padding-bottom: 0;
    }
  }

  .main-aside-element-hover-child {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 1px;
    z-index: 1;
    max-width: 0;
    overflow: hidden;
    transform: translateX(100%);
    transition: max-width .5s ease-in-out;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    &.active {
      max-width: 1000px;
    }
  }
</style>
