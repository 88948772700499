import {
  GET_FIRST_SELECTED_PART,
  REVISE_REQUOTE_SELECTED_RFQS,
  RFQ_MODULE,
} from '../store/modules/rfq/types';

// If there is only one viewed rfq/draft-rfq this gives access to it, otherwise returns false

const accessFirstViewed = {
  computed: {
    _singleViewedModel() {
      return this.$store.getters[`${RFQ_MODULE}/${REVISE_REQUOTE_SELECTED_RFQS}`][0] || this.$store.getters[`${RFQ_MODULE}/${GET_FIRST_SELECTED_PART}`];
    },
  },
};

export default accessFirstViewed;
