<template>
  <aside class="is-flex is-flex-direction-column is-justify-content-space-between">
    <FeedbackOrderImage
      class="mb-4"
      :rfqs="invoice[rfqType]"
    />
    <BTable
      :mobile-cards="false"
      :data="summary"
    >
      <BTableColumn
        #default="{ row }"
        :td-attrs="tdAttrs"
        :th-attrs="tdAttrs"
      >
        <p class="has-text-weight-bold is-size-7">
          {{ row.label }}:
        </p>
      </BTableColumn>
      <BTableColumn
        #default="{ row }"
        :th-attrs="tdAttrs"
        :td-attrs="tdAttrs"
      >
        <p class="is-size-7">
          {{ row.value }}
        </p>
      </BTableColumn>
    </BTable>
  </aside>
</template>
<script>
import moment from 'moment';
import FeedbackOrderImage from '@/app-buyer/components/feedback-modal/FeedbackOrderImage.vue';

export default {
  name: 'FeedbackOrderSingleSummary',
  components: { FeedbackOrderImage },
  props: {
    invoice: {
      type: Object,
      required: true,
    },
  },
  computed: {
    summary() {
      const result = [
        {
          label: 'Invoice number',
          value: this.invoice.reference,
        },
        {
          label: 'Service line',
          value: [...new Set(this.cleanServiceLine()),
          ].join(', '),
        },
        {
          label: 'Project',
          value: [...new Set(this.cleanProject()),
          ].join(', '),
        },
        {
          label: 'Delivered on',
          value: moment(this.cleanDelivered()).format('DD/MM/YYYY'),
        },
      ];
      return result;
    },
    rfqType() {
      if (this.invoice?.last_5_rfqs?.length) return 'last_5_rfqs';
      return 'rfqs';
    },
  },
  methods: {
    tdAttrs() {
      return {
        style: {
          padding: 0,
          paddingRight: '1rem',
          border: 'none',
        },
      };
    },
    cleanServiceLine() {
      return this.invoice[this.rfqType]
        .map((e) => e.configuration_object?.service?.string_value)
        .filter((e) => e);
    },
    cleanProject() {
      return this.invoice[this.rfqType]
        .map((e) => e.project_name)
        .filter((e) => e);
    },
    cleanDelivered() {
      return this.invoice[this.rfqType][0].order?.delivery_date;
    },
  },
};
</script>
