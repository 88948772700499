<template>
  <div
    class="is-flex-grow-1 is-flex is-flex-direction-column fill-fix-height is-justify-space-between"
  >
    <PartTableWrapper v-if="hasDrafts" />
    <PartUpload :compact="!!hasDrafts" />
    <transition name="fade">
      <ProjectContentLoader v-show="LOGGED_IN && (!INITIAL_LOAD || loadingProject)" />
    </transition>
    <BModal
      :active="!!PROJECT_SUPPORTING_FILE_MODAL"
      :full-screen="false"
      class="project-pdf-modal"
      width="98%"
      :can-cancel="false"
    >
      <PDFView
        v-if="!!PROJECT_SUPPORTING_FILE_MODAL"
        :pdf="PROJECT_SUPPORTING_FILE_MODAL"
        :full-screen="true"
        :is-project-pdf="true"
        class="project-pdf"
        @close="handlePdfModalClose"
      />
    </BModal>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex';
import PartTableWrapper from '@/app-buyer/components/project/PartTableWrapper.vue';
import PartUpload from '@/app-buyer/components/project/PartUpload.vue';
import PDFView from '@/app-buyer/components/project/PDFView.vue';
import { DRAFT_RFQS, RFQ_MODULE } from '@/app-buyer/store/modules/rfq/types';
import ProjectContentLoader from '@/app-buyer/components/project/ProjectContentLoader.vue';
import {
  ACTIVE_PROJECT, PROJECT_MODULE, PROJECT_SUPPORTING_FILE_MODAL, SET_PROJECT_SUPPORTING_FILE_MODAL,
} from '@/app-buyer/store/modules/projects/types';
import { INITIAL_LOAD } from '@/app-buyer/store/modules/types';
import { AUTH_MODULE, LOGGED_IN } from '@/app-buyer/store/modules/auth/types';

export default {
  name: 'ProjectContent',
  components: {
    ProjectContentLoader, PartTableWrapper, PartUpload, PDFView,
  },
  computed: {
    ...mapState(AUTH_MODULE, {
      LOGGED_IN,
    }),

    ...mapState(RFQ_MODULE, {
      DRAFT_RFQS,
    }),

    ...mapState(PROJECT_MODULE, {
      INITIAL_LOAD,
      PROJECT_SUPPORTING_FILE_MODAL,
    }),

    ...mapGetters(PROJECT_MODULE, {
      ACTIVE_PROJECT,
    }),

    hasDrafts() {
      return this[DRAFT_RFQS]?.length;
    },

    loadingProject() {
      return this[ACTIVE_PROJECT]
        && this[ACTIVE_PROJECT]._loading
        && !this[ACTIVE_PROJECT]._detailed;
    },
  },
  destroyed() {
    this[SET_PROJECT_SUPPORTING_FILE_MODAL](null);
  },
  methods: {
    ...mapMutations(PROJECT_MODULE, {
      SET_PROJECT_SUPPORTING_FILE_MODAL,
    }),

    handlePdfModalClose() {
      this[SET_PROJECT_SUPPORTING_FILE_MODAL](null);
    },
  },
};
</script>

<style lang="scss" scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}

.project-pdf-modal {
  position: absolute;
}
</style>
