<template>
  <material-search-select
    v-bind="$attrs"
    :options="properties"
    :values="values"
    @input="$emit('input', $event)"
    @set-note="$emit('set-note', $event)"
  />
</template>
<script>
import { formInput } from '@/app-buyer/mixins/form-input';
import MaterialSearchSelect
  from '@/app-buyer/components/configurator/configurator-body/configurator-drafts/configurator-element/material-search-select.vue';

export default {
  name: 'ConfiguratorElementMaterial',
  components: { MaterialSearchSelect },
  mixins: [formInput],
};
</script>

<style
    lang="scss"
    scoped
>
.input-squisher {
  overflow: hidden;
  width: 100%;

  &.is-squished {
    margin-right: 1rem;
    width: 0;
  }
}
</style>
