import {
  SUBSCRIPTIONS, UNREAD_CHATS, CHATS,
} from './types';

export default {
  [UNREAD_CHATS]: (state) => Object.keys(state[SUBSCRIPTIONS])
  // eslint-disable-next-line eqeqeq
    .filter((e) => state[CHATS]?.find((chat) => chat.id == e
       && chat.updated_at > state[SUBSCRIPTIONS][e])).length,
};
