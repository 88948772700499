<template>
  <div class="quote-row-simple-wrapper is-flex is-flex-direction-column py-4">
    <div class="is-flex">
      <div class="left">
        <PartThumbnail
          :part="quote"
          :size="69"
        />
        <div class="ref-wrapper has-text-centered is-size-7 p-1">
          {{ quote.ref }}
        </div>
      </div>
      <div class="right pl-4">
        <div class="label-column has-text-weight-bold">
          <p>Part name</p>
          <p>Service</p>
          <p>Material</p>
          <p>Quantity</p>
          <p v-if="quote.quantity_production">
            Production quantity
          </p>
          <p>Manufacturing time</p>
        </div>
        <div class="body-column">
          <p>{{ quote.name }}</p>
          <p>{{ quote.service }}</p>
          <p>{{ quote.material }}</p>
          <p>{{ quote.quantity_initial }}</p>
          <p v-if="quote.quantity_production">
            {{ quote.quantity_production }}
          </p>
          <p>{{ quote.manufacturing_time }} days</p>
        </div>
      </div>
    </div>
    <div class="pt-4">
      <p class="has-text-weight-bold is-size-7">
        Geomiq notes:
      </p>
      <p class="is-size-7">
        {{ notes }}
      </p>
    </div>
  </div>
</template>

<script>
import PartThumbnail from '@/app-buyer/components/project/PartThumbnail.vue';

export default {
  components: {
    PartThumbnail,
  },
  props: {
    quote: {
      type: Object,
      required: true,
    },
  },
  computed: {
    notes() {
      return this.quote?.notes?.map((e) => e.content).join(' | ');
    },
  },
};
</script>

<style lang="scss" scoped>
.quote-row-simple-wrapper {
  width: 100%;
  border-top: solid 1px #dbdbdb;

  .ref-wrapper {
    width: 69px;
    border: 1px solid #dbdbdb;
    border-radius: 4px;
  }
  .right {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 1.4rem;
  }
}
</style>
