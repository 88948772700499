import actions from './actions';
import mutations from './mutations';
import {
  BATCH_RFQ_SUBMISSION_FAILED_EVENT,
  BATCH_RFQ_SUBMISSION_SUCCEEDED_EVENT,
  BATCH_RFQ_UPDATE_FAILED_EVENT,
  BATCH_RFQ_UPDATE_SUCCEEDED_EVENT,
  CART_PAYMENT_FAILED_EVENT,
  CART_PAYMENT_SUCCESSFUL_EVENT,
  CHAT_MESSAGE_CREATED,
  NOTIFICATION_TEST_EVENT,
  ORDERS_CREATION_FAILED_EVENT,
  ORDERS_CREATION_SUCCEEDED_EVENT,
  QUOTE_STATUS_CHANGED_EVENT,
} from './types';

export default {
  namespaced: true,
  state: {
    channel: null,
    channels: {
      'Chat.MessageCreatedEvent': [
        CHAT_MESSAGE_CREATED,
      ],
      NotificationTestEvent: [
        NOTIFICATION_TEST_EVENT,
      ],
      'Cart.PaymentSuccessfulEvent': [
        CART_PAYMENT_SUCCESSFUL_EVENT,
      ],
      'Cart.PaymentFailedEvent': [
        CART_PAYMENT_FAILED_EVENT,
      ],
      'Quote.QuoteStatusChangedEvent': [
        QUOTE_STATUS_CHANGED_EVENT,
      ],
      'Cart.OrdersCreationSucceededEvent': [
        ORDERS_CREATION_SUCCEEDED_EVENT,
      ],
      'Cart.OrdersCreationFailedEvent': [
        ORDERS_CREATION_FAILED_EVENT,
      ],
      BatchRfqSubmissionSucceededEvent: [
        BATCH_RFQ_SUBMISSION_SUCCEEDED_EVENT,
      ],
      BatchRfqSubmissionFailedEvent: [
        BATCH_RFQ_SUBMISSION_FAILED_EVENT,
      ],
      BatchRfqUpdateSucceededEvent: [
        BATCH_RFQ_UPDATE_SUCCEEDED_EVENT,
      ],
      BatchRfqUpdateFailedEvent: [
        BATCH_RFQ_UPDATE_FAILED_EVENT,
      ],
    },
  },
  actions,
  mutations,
};
