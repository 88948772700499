<template>
  <div>
    <table class="table">
      <tbody>
        <slot name="modelFile" />
        <slot name="supportingFile" />
        <tr
          v-for="data in dataMapped"
          :key="data.title"
        >
          <td class="has-text-weight-bold">
            {{ data.title }}
          </td>
          <td
            v-if="data.applyFilter"
          >
            {{ data.value | formatMoney }}
          </td>
          <td
            v-else
            v-html="data.value"
          />
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
export default {
  name: 'PartMappedData',
  props: {
    dataMapped: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
