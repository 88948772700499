<template>
  <div
    class="is-flex"
    style="min-height: 100%;"
  >
    <div
      :class="{
        'is-auth' : LOGGED_IN
      }"
      class="project-content-wrapper"
    >
      <ProjectContentHeader />
      <ProjectContent />
    </div>
    <div
      v-if="LOGGED_IN"
      class="part-form-wrapper has-background-g-light-3 is-hidden-touch is-flex pt-5"
    >
      <PartForm
        @request-quotes="requestQuotes($event)"
      />
    </div>
    <PartFormFooter
      v-if="LOGGED_IN"
      class="is-hidden-desktop"
      is-mobile
      @request-quotes="requestQuotes($event)"
    />
  </div>
</template>

<script>
import {
  mapActions, mapGetters, mapMutations, mapState,
} from 'vuex';
import URL_PARAMS from '@/app-buyer/consts/url-params';
import {
  ACTIVE_PROJECT, LISTEN_PROJECT_CHANNEL, PROJECT_MODULE, PROJECTS, SET_ACTIVE_PROJECT,
} from '@/app-buyer/store/modules/projects/types';
import PartForm from '@/app-buyer/components/project/PartForm.vue';
import ProjectContentHeader from '@/app-buyer/components/project/ProjectContentHeader.vue';
import ProjectContent from '@/app-buyer/components/project/ProjectContent.vue';
import {
  CREATE_RFQS, DRAFT_COUNT, DRAFT_DETAIL, DRAFT_RFQ_ISSUES, DRAFT_RFQS, GET_ALL_SELECTED_PARTS,
  RFQ_MODULE, SELECTED_PART_HASHES, SET_SELECTED_PART_HASHES, UPLOADED_DRAFTS,
} from '@/app-buyer/store/modules/rfq/types';
import { QUOTES_MODULE, SET_QUOTES } from '@/app-buyer/store/modules/quotes/types';
import PartFormFooter from '@/app-buyer/components/project/PartFormFooter.vue';
import deliveryCountryPrompt from '@/app-buyer/mixins/delivery-country';
import {
  AUTH_MODULE, FORCE_AUTH, LOGGED_IN, UPLOAD_BEFORE_AUTH,
} from '@/app-buyer/store/modules/auth/types';
import { GET, SET } from '@/app-buyer/store/modules/types';
import emailVerification from '@/app-buyer/mixins/email-verification';
import { partSelectMixin } from '@/app-buyer/components/project/mixins';
import { GET_USER_ID } from '@/app-buyer/store/modules/user/types';
import RfqSuccessModalContent from '@/app-buyer/components/project/RfqSuccessModalContent.vue';
import RfqPendingModalContent from '@/app-buyer/components/project/RfqPendingModalContent.vue';
import notificationInjection from '@/shared/components/notification/notification-injection-mixin';
import RfqUnsuccessfulModalContent
from '@/app-buyer/components/project/RfqUnsuccessfulModalContent.vue';

const getCustomNotes = (draft) => {
  const customNotes = localStorage.getItem(`notes_${draft.hash}`);
  const { notes } = draft;
  if (customNotes) {
    const customNotesObject = JSON.parse(customNotes);
    const notePairs = Object.entries(customNotesObject).map(([entity, note]) => `${entity}: ${note}`);
    return `${notes ? `${notes}, ` : ''}${notePairs.join(', ')}`;
  }
  return notes;
};

export default {
  name: 'Project',

  components: {
    PartFormFooter,
    ProjectContent,
    ProjectContentHeader,
    PartForm,
  },

  mixins: [deliveryCountryPrompt, emailVerification, partSelectMixin, notificationInjection],

  provide() {
    return {
      projectContext: this.projectContext,
      setHighlightedParts: this.setHighlightedParts,
    };
  },

  props: {
    [URL_PARAMS.PROJECT_HASH]: {
      type: [Number, String],
      default: null,
    },

    [URL_PARAMS.DRAFT_HASH]: {
      type: [Number, String],
      default: null,
    },
  },

  data() {
    return {
      projectContext: {
        highlightedParts: [],
        submitting: false,
      },
      buefyModal: null,
    };
  },

  computed: {
    ...mapState(AUTH_MODULE, {
      LOGGED_IN,
      UPLOAD_BEFORE_AUTH,
    }),

    ...mapState(PROJECT_MODULE, {
      PROJECTS,
    }),

    ...mapGetters(PROJECT_MODULE, {
      ACTIVE_PROJECT,
    }),

    ...mapState(RFQ_MODULE, {
      DRAFT_RFQS,
      UPLOADED_DRAFTS,
      DRAFT_COUNT,
      SELECTED_PART_HASHES,
    }),

    ...mapGetters(RFQ_MODULE, {
      DRAFT_RFQ_ISSUES,
      GET_ALL_SELECTED_PARTS,
    }),

    submittable() {
      return this[DRAFT_RFQS].filter((d) => {
        const issues = this[DRAFT_RFQ_ISSUES][d.hash];
        if (typeof issues === 'object' && issues != null) {
          return Object.values(issues).every((issue) => !issue?.blockSubmit);
        }
        return !issues;
      });
    },

    submittableSelected() {
      return this[GET_ALL_SELECTED_PARTS].filter((d) => {
        const issues = this[DRAFT_RFQ_ISSUES][d.hash];
        if (typeof issues === 'object' && issues != null) {
          return Object.values(issues).every((issue) => !issue?.blockSubmit);
        }
        return !issues;
      });
    },
  },

  watch: {
    [URL_PARAMS.PROJECT_HASH]: {
      async handler(hash) {
        const project = this[PROJECTS]?.find((p) => p.hash === hash);
        if (!project) return;
        await this[SET_ACTIVE_PROJECT]({ project, noRedirect: true });
        if (this[DRAFT_RFQS][0]?.hash && !this[SELECTED_PART_HASHES]?.length) this.selectParts(this[DRAFT_RFQS][0]?.hash);
      },
      immediate: true,
    },

    [URL_PARAMS.DRAFT_HASH]: {
      handler(hash) {
        this[SET_SELECTED_PART_HASHES](hash ? hash.split(',') : []);
      },
      immediate: true,
    },

    // This watcher is here to catch when a draft_rfq has been uploaded before
    // a user has been authenticated. This will trigger only after all
    // the draft_rfq(s) have been updated with the users id, then
    // it will call the project GET and set the active project.
    async [UPLOADED_DRAFTS](nv) {
      if (this[UPLOAD_BEFORE_AUTH] && this[DRAFT_COUNT] === nv && this[LOGGED_IN]) {
        await this[GET]({ clear: true });
        const project = this[ACTIVE_PROJECT] || this[PROJECTS][0];
        this[SET_ACTIVE_PROJECT]({ project, force: true });
        this[SET_SELECTED_PART_HASHES]([project.draft_rfqs[0]?.hash]);
      }
    },
  },

  created() {
    this.emailVerificationToast();
    this.extractUserlessDrafts();
  },

  methods: {
    ...mapActions(PROJECT_MODULE, {
      SET_ACTIVE_PROJECT,
      LISTEN_PROJECT_CHANNEL,
      GET,
    }),

    ...mapMutations(RFQ_MODULE, {
      SET_SELECTED_PART_HASHES,
      SET,
    }),

    ...mapActions(RFQ_MODULE, {
      CREATE_RFQS,
      DRAFT_DETAIL,
    }),

    ...mapMutations(QUOTES_MODULE, {
      SET_QUOTES,
    }),

    ...mapMutations(AUTH_MODULE, {
      FORCE_AUTH,
    }),

    setHighlightedParts(ids) {
      this.projectContext.highlightedParts = ids;
    },

    requestQuotes(selected = false) {
      if (!this.submittable.length) return;
      this.confirmDeliveryCountry(selected).then(this.finaliseRequests);
    },

    async sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },

    async finaliseRequests({ deliveryCountry, selected = false }) {
      this.projectContext.submitting = true;
      const drafts = selected ? [...this[GET_ALL_SELECTED_PARTS]] : [...this.submittable];
      const rfqsToRequest = drafts.map((draft) => ({
        ...draft,
        delivery_country: deliveryCountry,
        notes: getCustomNotes(draft),
      }));
      try {
        this.handleProgressModal({ propData: rfqsToRequest.length, status: 'pending' });
        const { data } = await this[CREATE_RFQS]({ draftOrRfq: rfqsToRequest });
        this[SET_QUOTES]([]);
        await this.sleep(1500);
        this.handleProgressModal({ status: 'successful' });

        // GTM event for successful rfq submit
        if (this.$gtm && data.length) {
          data.forEach(() => {
            this.$gtm.trackEvent({
              event: 'user-action',
              'user-action-type': 'submit-quote',
              user_id: this[GET_USER_ID],
            });
          });
        }
        // SEGMENT TRACKING TO REMOVE
      } catch (error) {
        this.handleProgressModal({ propData: error.data, status: 'unsuccessful' });
      } finally {
        this.submitting = false;
        this.projectContext.submitting = false;
      }
    },

    handleProgressModal({ propData, status }) {
      let returnedComponent = RfqPendingModalContent;
      if (status === 'successful') returnedComponent = RfqSuccessModalContent;
      if (status === 'unsuccessful') returnedComponent = RfqUnsuccessfulModalContent;

      if (this.buefyModal) this.buefyModal.close();

      this.buefyModal = this.$buefy.modal.open({
        parent: this,
        component: returnedComponent,
        props: {
          ...propData,
        },
        canCancel: status === 'successful' || status === 'unsuccessful',
        width: '700px',
        hasModalCard: true,
        trapFocus: true,
      });
    },

    async extractUserlessDrafts() {
      const hashIds = this.$route.query.drafts ? JSON.parse(this.$route.query.drafts) : [];
      const calls = hashIds.map((hash) => this[DRAFT_DETAIL]({ hash }));
      const responses = await Promise.all(calls);

      const drafts = responses.map((r) => {
        try {
          const { data: { data } } = r;
          return data;
        } catch {
          return null;
        }
      });

      if (!drafts.length) return;

      this.selectParts(drafts[0].hash);

      this[SET]({ draftRfqs: drafts, clear: true });

      this[LISTEN_PROJECT_CHANNEL](drafts[0]?.project_hash);

      if (!this[LOGGED_IN]) {
        const hasAccount = localStorage.getItem('gm_has_account');
        this[FORCE_AUTH](hasAccount ? 'login' : 'register');
      }
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
.part-form-wrapper {
  box-shadow: inset 15px 0 20px -20px rgba(0, 0, 0, 0.2);
  flex: none;
  padding: 40px;
  width: 30rem;

  @media all and (max-width: 1500px) {
    padding: 1rem;
    width: 22rem;
  }
}

.project-content-wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
}

.project-content-wrapper,
.part-form-wrapper {
  height: calc(100vh - var(--navbar-height));
  min-height: 0;
  overflow: auto;

  @media all and (max-width: 1024px) {
    height: unset;

    &.is-auth {
      padding-bottom: 12rem;
    }
  }
}
</style>
