<template>
  <div class="is-flex mb-5">
    <PartTableSearch class="mr-2 mb-0" />
    <PartTableHeaderUpload />
  </div>
</template>

<script>
import PartTableSearch from '@/app-buyer/components/project/PartTableSearch.vue';
import PartTableHeaderUpload from '@/app-buyer/components/project/PartTableHeaderUpload.vue';

export default {
  name: 'PartTableHeader',
  components: { PartTableHeaderUpload, PartTableSearch },
};
</script>
