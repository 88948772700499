<template>
  <section
    v-if="LOGGED_IN"
    :class="{collapsed}"
    class="sidebar-collapsable"
    data-testid="project-sidebar"
    @click="handleCollapsedState"
  >
    <div
      class="is-flex is-flex-direction-column is-justify-space-between"
      style="height: calc(100vh - var(--navbar-height))"
    >
      <section
        v-if="LOGGED_IN"
        class="is-flex-grow-1"
        style="min-height: 0;"
      >
        <div class="is-flex is-justify-content-flex-end  is-hidden-tablet">
          <button
            class="button-no-style"
            @click.stop="collapsed = !collapsed"
          >
            <IconFont
              :icon="'collapse-' + (collapsed ? 'right' : 'left')"
              style="font-size: 0.75rem; color: white; padding: 0.25rem;"
            />
          </button>
        </div>
        <header
          :style="collapsed && 'opacity: 0; pointer-events: none;'"
        >
          <div class="m-4">
            <ProjectSidebarCreate @click="promptForNewName" />
          </div>
          <div class="m-4">
            <BInput
              v-model="searchInput"
              class="project-search-input"
              data-testid="sidebar-project-search-input"
              icon="search"
              placeholder="Search projects..."
              size="is-small"
              style="height: 35px; border-radius: 5px;"
              @input="inputSearch"
            />
          </div>
        </header>
        <div
          class="is-relative is-flex-grow-1"
          style="min-height:0;overflow-y:auto;height:100%; padding-bottom: 10rem;"
        >
          <ul
            :style="collapsed && 'opacity: 0; pointer-events: none;'"
            class="sidebar__projects-list pt-5"
            data-testid="sidebar-project-list"
          >
            <ProjectSidebarBottom
              v-if="!PAGINATION_PAGES.includes(1) &&
                PROJECTS.length && PAGINATION_PAGES.length"
              :next-page="false"
              @load-previous-page="GET({ search: searchInput, clear: false, page: Number(Math.min(...PAGINATION_PAGES) - 1), toFront: true})"
            />
            <li
              v-for="project in filteredProjects"
              :key="project.hash"
            >
              <ProjectSidebarElement
                :active-project="ACTIVE_PROJECT"
                :can-drop="canDrop"
                :project="project"
                @select="select(project)"
                @part-drop="handlePartDrop(project, $event)"
                @confirm-action="confirmAction(project, $event)"
                @prompt-rename="promptRename(project)"
                @load-details="loadProjectDetail(project)"
              />
            </li>
            <ProjectSidebarBottom
              v-if="PAGINATION &&
                !PAGINATION_PAGES.includes(PAGINATION.last_page) &&
                PAGINATION_PAGES.length &&
                PROJECTS.length"
              @load-next-page="GET({ search: searchInput, clear: false, page: Number(Math.max(...PAGINATION_PAGES) + 1) })"
            />
          </ul>
          <BLoading
            :active="loading"
            :is-full-page="false"
          />
        </div>
      </section>
      <section
        v-else
        class="sidebar__projects-container"
      />
      <footer class="sidebar__footer is-relative">
        <div
          class="is-absolute"
          style="bottom: 0; left: 0; width: 100%; background-color: #37475A;"
        >
          <GmButton
            :disabled="loadingArchived"
            :loading="loadingArchived"
            class="archive-button is-flex is-justify-space-between
            is-align-items-center has-text-white"
            data-testid="project-archived-toggle"
            fullwidth
            @click="handleArchivedClick"
          >
            <span v-show="!loadingArchived">Archived projects</span>
            <BIcon
              :icon="'chevron-' + (showArchived ? 'down' : 'up')"
              style="margin-left: auto;"
            />
          </GmButton>
          <ul
            :class="{open: showArchived}"
            class="sidebar__projects-list archived"
            data-testid="archived-sidebar-project-list"
          >
            <li
              v-for="project in archivedProjects"
              :key="project.hash"
            >
              <ProjectSidebarElement
                :active-project="ACTIVE_PROJECT"
                :can-drop="canDrop"
                :project="project"
                @select="select(project)"
                @part-drop="handlePartDrop(project, $event)"
                @confirm-action="confirmAction(project, $event)"
                @prompt-rename="promptRename(project)"
                @load-details="loadProjectDetail(project)"
              />
            </li>
            <li v-if="!archivedProjects.length">
              <div
                class="is-flex is-flex-direction-column is-flex-align-centered p-5 is-size-7"
                style="color: rgb(203 203 203 / 75%);"
              >
                <p class="is-flex is-flex-align-centered">
                  <span>Click the </span>
                  <BIcon
                    icon="archive"
                    pack="fal"
                    style="font-size: 1rem; color: white; margin-right: 1px;"
                  />
                  <span> icon to archive</span>
                </p>
                <p>projects and see them here</p>
              </div>
            </li>
          </ul>
        </div>
      </footer>
      <Portal to="model-table-project-collapse">
        <button
          class="button-no-style is-hidden-desktop"
          @click="collapsed = false"
        >
          <div class="is-flex is-align-items-center is-size-7 my-1">
            <IconFont
              class="mr-2"
              icon="chevron-left"
            />
            <IconFont icon="folder" />
            <span class="ml-1 mt-1">
              {{ ACTIVE_PROJECT ? ACTIVE_PROJECT.name : 'Projects' }}
            </span>
          </div>
        </button>
      </Portal>
    </div>
  </section>
</template>

<script>
import {
  mapActions, mapGetters, mapState, mapMutations,
} from 'vuex';
import ProjectSidebarElement from '@/app-buyer/components/sidebar/ProjectSidebarElement.vue';
import {
  DETAIL, GET, PAGINATION, UPDATE,
} from '@/app-buyer/store/modules/types';
import { MOVE_RFQ, RFQ_MODULE } from '@/app-buyer/store/modules/rfq/types';
import { getDBTimeStamp } from '@/shared/mixins';
import accessDragData from '@/app-buyer/mixins/drag-drop-access';
import ProjectSidebarCreate from '@/app-buyer/components/sidebar/ProjectSidebarCreate.vue';
import URL_PARAMS from '@/app-buyer/consts/url-params';
import GmButton from '@/shared/components/gm-button/gm-button.vue';
import { AUTH_MODULE, LOGGED_IN } from '../../store/modules/auth/types';
import { METADATA, SET_METADATA, USER_MODULE } from '@/app-buyer/store/modules/user/types';
import {
  ACTIVE_PROJECT,
  ARCHIVED_LOADED,
  ARCHIVED_PROJECTS,
  GET_ARCHIVED,
  PROJECT_MODULE,
  PROJECTS,
  SET_ACTIVE_PROJECT,
  STORE,
  PAGINATION_PAGES,
  CURRENT_PROJECT,
} from '../../store/modules/projects/types';
import routeParameterHandler from '../../mixins/route-parameter-handler';
import IconFont from '../../../shared/components/icon-font/icon-font.vue';
import { findDraftModelFile } from '@/app-buyer/components/project/helpers';
import ProjectSidebarBottom from '@/app-buyer/components/sidebar/ProjectSidebarBottom.vue';

export default {
  name: 'ProjectSidebar',

  components: {
    ProjectSidebarBottom,
    GmButton,
    ProjectSidebarCreate,
    ProjectSidebarElement,
    IconFont,
  },

  mixins: [
    routeParameterHandler,
    getDBTimeStamp,
    accessDragData,
  ],

  data() {
    return {
      searchInput: '',
      searchResults: null,
      showArchived: false,
      collapsed: false,
      loading: false,
      archivedLoaded: false,
      loadingArchived: false,
      perPageLimit: 10,
      currentProject: null,
    };
  },

  computed: {
    ...mapState(AUTH_MODULE, {
      LOGGED_IN,
    }),

    ...mapState(USER_MODULE, {
      METADATA,
    }),

    ...mapGetters(PROJECT_MODULE, {
      ACTIVE_PROJECT,
    }),

    ...mapState(PROJECT_MODULE, {
      PROJECTS,
      ARCHIVED_PROJECTS,
      ARCHIVED_LOADED,
      PAGINATION,
      PAGINATION_PAGES,
      CURRENT_PROJECT,
    }),

    filteredProjects() {
      if (this.searchInput && this.searchResults) {
        return this.searchResults;
      }
      return this[PROJECTS];
    },

    archivedProjects() {
      return this[ARCHIVED_PROJECTS];
    },

    canDrop() {
      return this._dragType && ['model_file', 'model_file_supporting'].includes(this._dragType);
    },

  },
  watch: {
    $route: {
      handler(to) {
        const { [URL_PARAMS.PROJECT_HASH]: projectHash } = to.params;
        const project = this[PROJECTS]?.find((proj) => proj.hash === projectHash);
        if (project && this[ACTIVE_PROJECT]?.hash !== project.hash) {
          this[SET_ACTIVE_PROJECT]({ project: { hash: projectHash } });
        }
      },
      immediate: true,
    },
  },

  methods: {
    ...mapActions(PROJECT_MODULE, {
      GET,
      STORE,
      UPDATE,
      DETAIL,
      SET_ACTIVE_PROJECT,
      GET_ARCHIVED,
    }),

    ...mapActions(RFQ_MODULE, {
      MOVE_RFQ,
    }),

    ...mapMutations(USER_MODULE, {
      SET_METADATA,
    }),

    inputSearch() {
      this[GET]({
        clear: true, toFront: false, search: this.searchInput, force: true,
      });
    },

    handleCollapsedState() {
      if (this.collapsed) {
        this.collapsed = false;
      }
    },

    promptRename(project) {
      this.$buefy.dialog.prompt({
        message: 'What should be the new project name',
        inputAttrs: {
          value: project.name,
          maxlength: 30,
          minLength: 1,
        },
        confirmText: 'Rename project',
        type: 'is-v2-supporting-1',
        onConfirm: (value) => this.checkAndUpdate(project, value),
      });
    },

    checkForDuplicate(value) {
      const escapedValue = this.escapeSpecialCharacters(value);
      const projectWithSameName = this[PROJECTS].find((e) => e.name === value);
      const pattern = new RegExp(`^${escapedValue}\\d+$`);
      const num = this[PROJECTS].filter((e) => {
        const escapedName = this.escapeSpecialCharacters(e.name);
        return pattern.test(escapedName);
      }).length + 1;
      return {
        projectWithSameName,
        num,
      };
    },

    escapeSpecialCharacters(value) {
      if (!value) return value;
      return value.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    },

    checkAndUpdate(project, value) {
      const {
        projectWithSameName,
        num,
      } = this.checkForDuplicate(value);
      if (projectWithSameName && projectWithSameName.hash !== project.hash) {
        this.$buefy.dialog.confirm({
          message: `A project with this name already exists. Would you like to create <strong>${value} (${num})</strong>?`,
          confirmText: 'Rename project',
          cancelText: 'Go to project',
          canCancel: ['button'],
          type: 'is-warning',
          hasIcon: true,
          onConfirm: () => this[UPDATE]({
            project,
            key: 'name',
            value: `${value} (${num})`,
          }),
          onCancel: () => {
            if (this[ACTIVE_PROJECT] !== projectWithSameName) {
              this[SET_ACTIVE_PROJECT]({
                project: projectWithSameName,
                force: true,
              });
            }
          },
        });
      } else {
        this[UPDATE]({
          project,
          key: 'name',
          value,
        });
      }
    },

    promptForNewName() {
      const num = this[PROJECTS].filter((e) => {
        const escaped = this.escapeSpecialCharacters(e.name);
        return /^Project [0-9]+$/.test(escaped);
      }).length;
      this.$buefy.dialog.prompt({
        message: 'What is the name of your project?',
        confirmText: 'Create',
        inputAttrs: {
          value: `Project ${num + 1}`,
          placeholder: 'e.g. Project 1',
          maxlength: 30,
          minLength: 1,
        },
        type: 'is-v2-supporting-1',
        trapFocus: true,
        onConfirm: (name) => this.checkAndCreate({ name }),
      });
    },

    async checkAndCreate({ name }) {
      const {
        projectWithSameName,
        num,
      } = this.checkForDuplicate(name);
      if (projectWithSameName) {
        this.$buefy.dialog.confirm({
          message: `A project with this name already exists. Would you like to create <strong>${name} (${num})</strong>?`,
          confirmText: 'Create project',
          cancelText: 'Go to project',
          canCancel: ['button'],
          type: 'is-warning',
          hasIcon: true,
          onConfirm: () => this[STORE]({ name: `${name} (${num})` }),
          onCancel: () => {
            if (this.activeProject !== projectWithSameName) {
              this[SET_ACTIVE_PROJECT]({
                project: projectWithSameName,
                force: true,
              });
            }
          },
        });
      } else {
        const data = await this[STORE]({ name });
        this[SET_ACTIVE_PROJECT]({
          project: data,
          force: true,
        });
        // eslint-disable-next-line camelcase
        const projects_count = this[METADATA].projects_count + 1;
        this[SET_METADATA]({
          ...this[METADATA],
          projects_count,
        });
      }
    },

    async select(project) {
      if (this[ACTIVE_PROJECT]?.hash !== project.hash || this.$route.name !== 'rfq-form') {
        this[SET_ACTIVE_PROJECT]({
          project,
          force: true,
        });
      }
      const { matches } = window.matchMedia('all and (max-width: 769px)');
      if (matches) {
        this.collapsed = true;
      }
    },

    confirmAction(project, action) {
      this.$buefy.dialog.confirm({
        message: `Are you sure you want to <b>${action}</b> this project?`,
        confirmText: `${action.charAt(0)
          .toUpperCase() + action.slice(1)} project`,
        type: 'is-warning',
        onConfirm: () => {
          if (action === 'archive') {
            this[UPDATE]({
              project,
              key: 'archived_at',
              value: this._getDBTimeStamp(),
            });
          } else if (action === 'unarchive') {
            this[UPDATE]({
              project,
              key: 'archived_at',
              value: null,
            });
          }
          // eslint-disable-next-line camelcase
          const projects_count = action === 'unarchive' ? this[METADATA].projects_count + 1 : this[METADATA].projects_count - 1;
          this[SET_METADATA]({
            ...this[METADATA],
            projects_count,
          });
        },
      });
    },

    handlePartDrop(project, ev) {
      ev.preventDefault();
      if (this._dragData && ['model_file', 'model_file_supporting'].includes(this._dragType)) {
        const {
          model,
          hash,
          isDraft,
        } = this._dragData;
        this.$buefy.dialog.confirm({
          message: `Would you like to move the part <strong>${model.name}</strong> to the project <strong>${project.name}</strong>?`,
          confirmText: 'Move part',
          type: 'is-info',
          hasIcon: true,
          onConfirm: () => this[MOVE_RFQ]({
            hash,
            isDraft,
            to: project.hash,
          }),
        });
      }
      ev.dataTransfer.clearData();
    },

    async loadProjectDetail(project) {
      const hasUploading = project?.draft_rfqs?.some((d) => !findDraftModelFile(d));
      if (project._detailed && !hasUploading) return;
      await this[DETAIL](project);
    },

    handleArchivedClick() {
      this.showArchived = !this.showArchived;
      if (this[ARCHIVED_LOADED]) return;
      this[GET_ARCHIVED]('');
    },
  },
};
</script>

<style lang="scss" scoped>

.sidebar__ {
  &header,
  &footer {
    min-height: 5rem;
    width: 100%;
    z-index: 15;
  }

  &header {
    align-items: center;
    display: flex;
    flex-grow: 0;
    justify-content: center;
    padding: 1rem;
  }

  &projects-container {
    background-color: variables.$v2-neutral-2;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 100%;
    min-height: 0;
    overflow-y: hidden;
  }

  &projects-list {
    flex-grow: 1;

    &.archived {
      background-color: var(--button-lightblue);
      display: none;
      max-height: 50vh;
      overflow: auto;

      &.open {
        display: block;
      }
    }
  }
}

.tab-button {
  border-radius: 7px 7px 0 0 !important;
}

.sidebar-collapsable {
  background-color: variables.$v2-neutral-2;
  height: calc(100vh - var(--navbar-height));
  transition: max-width 0.2s;

  @media all and (max-width: variables.$tablet) {
    max-width: 100vw;
    width: 100vw;
    &.collapsed {
      max-width: 0;
    }
  }
}

$archive-color: #4b5a6b;
.archive-button {
  background-color: $archive-color;
  border-radius: 0;
  border-top: 1px solid #cdcdcd;

  &:hover,
  &:disabled,
  &:disabled:hover {
    background-color: lighten($archive-color, 5%) !important;
    color: white;
  }
}
</style>
