import {
  GET_BILLING_DATA,
  GET_COMPANY_DATA,
  GET_CONTACT_DATA,
  GET_DELIVERY_DATA,
  GET_INITIALS,
  GET_PERSONAL_DATA,
  GET_USER_NAME,
  GET_USER_ROLES,
  GET_USER_POLICIES,
  GET_PROFILE_COMPLETION,
  USER_DATA, GET_USER_DATA,
  HAS_DELIVERY_ADDRESS,
  HAS_BILLING_ADDRESS,
  GET_USER_ID,
} from './types';
import { getProfileStatus, mapUserData } from './user-mapping';

const contactProperties = ['post_code', 'street_address', 'town', 'county', 'country'];

export default {
  [GET_USER_DATA]: (state) => mapUserData(state[USER_DATA]),
  [GET_PROFILE_COMPLETION]: (state) => state[USER_DATA]
    && getProfileStatus(mapUserData(state[USER_DATA])),
  [GET_USER_NAME]: (state) => (state[USER_DATA] && state[USER_DATA].user ? state[USER_DATA].user.name : ''),
  [GET_INITIALS]: (state) => (state[USER_DATA] && state[USER_DATA].user ? state[USER_DATA].user.initials : ''),
  [GET_USER_ROLES]: (state) => (state[USER_DATA]
  && state[USER_DATA].user
    ? state[USER_DATA].user.roles
    : []),
  [GET_USER_POLICIES]: (state) => (state[USER_DATA]
  && state[USER_DATA].user
    ? state[USER_DATA].user.policies
    : []),
  [GET_DELIVERY_DATA]: (state) => state[USER_DATA] && state[USER_DATA].delivery_contact,
  [GET_BILLING_DATA]: (state) => state[USER_DATA] && state[USER_DATA].billing_contact,
  [GET_CONTACT_DATA]: (state) => state[USER_DATA] && state[USER_DATA].company_contact,
  [GET_COMPANY_DATA]: (state) => state[USER_DATA] && state[USER_DATA].profile,
  [GET_PERSONAL_DATA]: (state) => state[USER_DATA] && state[USER_DATA].user,
  [HAS_DELIVERY_ADDRESS]: (state) => state[USER_DATA]
    && state[USER_DATA].delivery_contact
    && contactProperties.every((e) => state[USER_DATA].delivery_contact[e]),
  [HAS_BILLING_ADDRESS]: (state) => state[USER_DATA]
    && state[USER_DATA].billing_contact
    && contactProperties.every((e) => state[USER_DATA].billing_contact[e]),
  [GET_USER_ID]: (state) => state[USER_DATA]?.user?.id,
};
