import accessFirstViewed from '@/app-buyer/mixins/access-first-viewed';

const customNotes = {
  mixins: [accessFirstViewed],
  data() {
    return {
      customNote: null,
    };
  },
  methods: {
    getNote(slug) {
      const hash = this._singleViewedModel?.draft_hash ?? this._singleViewedModel?.hash;
      const notes = localStorage.getItem(`notes_${hash}`);
      if (notes) {
        const notesObject = JSON.parse(notes);
        this.customNote = notesObject[slug];
        return this.customNote;
      }
      return null;
    },
  },
};

export default customNotes;
