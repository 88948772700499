/* eslint-disable */
import {
  ACCESS_TOKEN, LOGGED_IN, MASQUERADING, SET_ACCESS_TOKEN, SET_LOGGED_IN,
} from './types';
import { RESET_STATE } from '../types';
import getState from './state';
import { FORCE_AUTH, FORCED, UPLOAD_BEFORE_AUTH } from '@/app-buyer/store/modules/auth/types';

export default {
  // Sets the LOGGED_IN flag in the state
  [SET_LOGGED_IN](state, bool) {
    state[LOGGED_IN] = bool;
  },
  // Sets/Removes the ACCESS_TOKEN in the state and stores it in local storage
  [SET_ACCESS_TOKEN](state, { access_token, expires_in, masquerading } = {}) {
    state[ACCESS_TOKEN] = access_token;
    const expiresIn = new Date().getTime() + expires_in * 1000;
    if (access_token) {
      localStorage.setItem('gm_access_token', access_token);
    } else {
      localStorage.removeItem('gm_access_token');
    }
    if (expires_in) {
      localStorage.setItem('gm_access_token_expiration', expiresIn.toString());
    } else {
      localStorage.removeItem('gm_access_token_expiration');
    }
    if (masquerading) {
      state[MASQUERADING] = masquerading;
      localStorage.setItem('gm_masquerading', access_token);
    } else {
      state[MASQUERADING] = localStorage.getItem('gm_masquerading') === access_token;
    }
  },
  [FORCE_AUTH](state, type) {
    state[FORCED] = type;
  },
  [RESET_STATE](state) {
    state = { ...getState() };
  },
  [UPLOAD_BEFORE_AUTH](state, type) {
    state[UPLOAD_BEFORE_AUTH] = type
  }
};
