/* eslint-disable no-shadow */
import {
  ACTIVE_PROJECT,
  ACTIVE_PROJECT_MEMBER_COUNT,
  ACTIVE_PROJECT_MEMBERS,
  CURRENT_PROJECT,
  PROJECTS,
  ACTIVE_PROJECT_HASH,
} from '@/app-buyer/store/modules/projects/types';

export default {

  [ACTIVE_PROJECT]: (state) => (state[PROJECTS].find((p) => p.hash === state[ACTIVE_PROJECT_HASH]) && !state[CURRENT_PROJECT][0]
    ? state[PROJECTS].find((p) => p.hash === state[ACTIVE_PROJECT_HASH])
    : state[CURRENT_PROJECT][0]),
  [ACTIVE_PROJECT_MEMBERS]: (state, getters) => getters[ACTIVE_PROJECT]?.members,
  [ACTIVE_PROJECT_MEMBER_COUNT]: (state, getters) => getters[ACTIVE_PROJECT]?.members.length,
};
