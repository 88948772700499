<template>
  <InvoiceSummaryLayout>
    <span
      data-testid="project-name"
      v-html="projectName"
    />
    <template #subtitle>
      {{ invoice.rfq_count }} RFQs | {{ invoice.created_at | formatDate('LL') }}
    </template>
  </InvoiceSummaryLayout>
</template>

<script>
import InvoiceSummaryLayout from '@/app-buyer/views/orders/InvoiceSummaryLayout.vue';

export default {
  name: 'InvoiceTableProjectSummary',
  components: { InvoiceSummaryLayout },
  props: {
    invoice: {
      type: Object,
      required: true,
    },
  },

  computed: {
    projectName() {
      const allNames = this.invoice?.last_5_rfqs?.map((rfq) => rfq.project_name);
      return Array.from(new Set(allNames || [])).join('<br>');
    },
  },
};
</script>
