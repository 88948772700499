<template>
  <div class="orders-info-block-wrapper is-flex">
    <InvoiceRefCell
      class="reference cell-margin"
      :is-open="openDetailed.includes(order.id)"
      @click.native="$emit('handleDetailClick', order.id)"
    >
      {{ order.reference }}
    </InvoiceRefCell>
    <div
      class="summary is-flex is-flex-wrap-wrap is-align-items-center
        is-justify-content-space-between pr-4"
    >
      <InvoiceTableProjectSummary
        :invoice="order"
      />
      <InvoiceSummaryLayout>
        Total
        <template #subtitle>
          <template v-if="!!reorderingInvoiceId">
            <InvoiceTableReorderTotals
              :original-total="order.total_inc_vat"
              :selected-for-reorder="selectedForReorder"
              :new-quantities="newQuantities"
            />
          </template>
          <template v-else>
            {{ order.total_inc_vat | formatWithCurrency }}
          </template>
        </template>
      </InvoiceSummaryLayout>
      <InvoiceSummaryLayout>
        Ordered by
        <template #subtitle>
          {{ order.name }}
        </template>
      </InvoiceSummaryLayout>
    </div>
    <OrderFeedbackCell
      :invoice="order"
      class="feedback"
    />
    <div class="members is-flex is-justify-content-center is-align-items-center">
      <InvoiceTableProjectMembers :invoice="order" />
    </div>
    <InvoiceTableTracking
      :invoice="order"
      class="tracking small-cell-width"
    />
    <InvoiceTableReorderButton
      :reorder-initialised="!!reorderingInvoiceId"
      :loading="loading"
      :selected-count="selectedForReorder.length"
      class="reorder cell-margin"
      @start-reorder="handleReorder"
      @confirm-reorder="$emit('confirm-reorder')"
      @cancel-reorder="$emit('cancel-reorder')"
    />
  </div>
</template>

<script>
import InvoiceRefCell from '@/app-buyer/views/orders/InvoiceRefCell.vue';
import InvoiceTableProjectSummary from '@/app-buyer/views/orders/InvoiceTableProjectSummary.vue';
import InvoiceSummaryLayout from '@/app-buyer/views/orders/InvoiceSummaryLayout.vue';
import InvoiceTableProjectMembers from '@/app-buyer/views/orders/InvoiceTableProjectMembers.vue';
import InvoiceTableReorderButton from '@/app-buyer/views/orders/InvoiceTableReorderButton.vue';
import InvoiceTableTracking from '@/app-buyer/views/orders/InvoiceTableTracking.vue';
import InvoiceTableReorderTotals from '@/app-buyer/views/orders/InvoiceTableReorderTotals.vue';
import OrderFeedbackCell from '@/app-buyer/views/orders/OrderFeedbackCell.vue';

export default {
  components: {
    OrderFeedbackCell,
    InvoiceTableReorderTotals,
    InvoiceTableTracking,
    InvoiceTableReorderButton,
    InvoiceTableProjectMembers,
    InvoiceSummaryLayout,
    InvoiceTableProjectSummary,
    InvoiceRefCell,
  },
  props: {
    order: {
      type: Object,
      default: () => {},
    },

    loading: Boolean,

    pagination: {
      type: Object,
      default: () => ({}),
    },

    isSameVendor: Boolean,

    reorderingInvoiceId: {
      type: [String, Number],
      default: null,
    },

    selectedForReorder: {
      type: Array,
      default: () => [],
    },

    newQuantities: {
      type: Object,
      default: () => ({}),
    },

    openDetailed: {
      type: Array,
      default: () => [],
    },
  },

  methods: {
    handleReorder({ sameVendor }) {
      this.$emit('start-reorder', { order: this.order, sameVendor });
    },
  },
};

</script>

<style lang="scss" scoped>
  .orders-info-block-wrapper {
    .reference {
      width: 140px;
      padding: 1rem 0.5rem;
    }
    .summary {
      width: 400px;
    }
    .members {
      flex-grow: 1;
    }
    .cell-margin {
      margin: 1rem 0.5rem;
    }
    .feedback {
      width: 220px;
    }
    .small-cell-width {
      width: 169px;
    }
  }
</style>
