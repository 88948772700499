export const USER_DATA = 'USER_DATA';
export const GET_USER_DATA = 'GET_USER_DATA';
export const GET_PROFILE_COMPLETION = 'GET_PROFILE_COMPLETION';
export const GET_USER_NAME = 'GET_USER_NAME';
export const GET_USER_ROLES = 'GET_USER_ROLES';
export const GET_USER_POLICIES = 'GET_USER_POLICIES';
export const GET_INITIALS = 'GET_INITIALS';
export const GET_DELIVERY_DATA = 'GET_DELIVERY_DATA';
export const GET_BILLING_DATA = 'GET_BILLING_DATA';
export const GET_CONTACT_DATA = 'GET_CONTACT_DATA';
export const GET_COMPANY_DATA = 'GET_COMPANY_DATA';
export const GET_PERSONAL_DATA = 'GET_PERSONAL_DATA';
export const GET_METADATA = 'GET_METADATA';
export const SET_METADATA = 'SET_METADATA';
export const METADATA = 'METADATA';
export const HAS_DELIVERY_ADDRESS = 'HAS_DELIVERY_ADDRESS';
export const HAS_BILLING_ADDRESS = 'HAS_BILLING_ADDRESS';
export const USER_MODULE = 'USER_MODULE';
export const GET_USER_ID = 'GET_USER_ID';
