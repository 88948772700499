<template>
  <ValidationObserver v-slot="{ passes }">
    <div
      class="modal-card"
      style="max-height: 80vh; overflow-y: auto;"
    >
      <div
        class="modal-card-head p-6 has-background-white"
      >
        <div>
          <header class="mb-5">
            <p class="title is-6">
              Tell us a little more about your production requirements
            </p>
            <p class="subtitle is-7">
              In order to get you the right information for your quote, we just need a little bit
              more information about what stage you're at with your injection moulding job(s).
            </p>
          </header>
          <BField class="is-inline-block">
            <template #label>
              <p class="is-size-7 has-text-weight-bold">
                Preferred T1 sample delivery date <span class="has-text-danger">*</span>
              </p>
            </template>
            <BDatepicker
              v-model="formData.t1_sample_deliver_by"
              size="is-small"
              placeholder="Please select a t1Delivery"
              icon="calendar"
              :min-date="minSampleDeliveryDate"
            />
          </BField>
          <p class="is-size-7 has-text-weight-bold">
            What stage of development are you at? <span class="has-text-danger">*</span>
          </p>
          <p class="is-help-text is-size-7 mb-2">
            This helps us determine tooling specifications for your project.
          </p>
          <div class="mb-1">
            <BInputWithValidator
              v-model="formData.development_stage"
              input-type="radio"
              rules="required"
              name="development-stage"
              size="is-small"
              native-value="design-validation"
            >
              Design validation
            </BInputWithValidator>
          </div>
          <div class="mb-1">
            <BInputWithValidator
              v-model="formData.development_stage"
              input-type="radio"
              name="development-stage"
              size="is-small"
              native-value="rapid-production"
              rules="required"
            >
              Rapid production
            </BInputWithValidator>
          </div>
          <div class="mb-4">
            <BInputWithValidator
              v-model="formData.development_stage"
              input-type="radio"
              name="development-stage"
              size="is-small"
              native-value="production"
              rules="required"
            >
              Production
            </BInputWithValidator>
          </div>
          <BField
            v-if="formData.development_stage === 'production'"
            class="is-inline-block"
          >
            <template #label>
              <p class="is-size-7 has-text-weight-bold">
                How many units do you forecast?
              </p>
            </template>
            <BField>
              <BInputWithValidator
                v-model="formData.forecast_units"
                type="number"
                size="is-small"
                min="1"
                placeholder="1"
                rules="required"
              />
              <span
                class="mx-4 is-size-7"
                style="align-self: center;"
              >every</span>
              <BInputWithValidator
                v-model="formData.forecast_period"
                input-type="dropdown"
              >
                <template #trigger="{ active }">
                  <BButton
                    size="is-small"
                    :icon-right="`chevron-${active ? 'up' : 'down'}`"
                  >
                    {{ formData.forecast_period || 'Please select a frequency' }}
                  </BButton>
                </template>
                <BDropdownItem
                  v-for="val in FREQUENCIES"
                  :key="val"
                  :value="val"
                >
                  {{ val }}
                </BDropdownItem>
              </BInputWithValidator>
            </BField>
          </BField>
          <p class="is-size-7 has-text-weight-bold">
            Tooling preferences <span class="is-help-text">(optional)</span>
          </p>
          <p class="is-help-text is-size-7 mb-2">
            This helps us determine the right tooling configuration for specific requirements.
          </p>
          <div class="mb-1">
            <BCheckbox
              v-model="hasToolLife"
              size="is-small"
            >
              Tool/Mold life
            </BCheckbox>
          </div>
          <div
            v-if="hasToolLife"
            class="pl-4 mb-4"
          >
            <div
              v-for="val in TOOL_LIFE"
              :key="val"
            >
              <BInputWithValidator
                v-model="formData.tool_life"
                input-type="radio"
                size="is-small"
                :native-value="val"
                name="tool-life"
                rules="required"
              >
                {{ val }}
              </BInputWithValidator>
            </div>
          </div>
          <div class="mb-4">
            <BCheckbox
              v-model="hasToolingConfiguration"
              size="is-small"
            >
              Tooling configuration (e.g. multiple cavities)
            </BCheckbox>
            <BInputWithValidator
              v-if="hasToolingConfiguration"
              v-model="formData.tooling_config_desc"
              :disabled="!hasToolingConfiguration"
              rules="required"
              placeholder="Please describe your preference"
              class="ml-4"
              type="textarea"
            />
          </div>
          <BField>
            <template #label>
              <p class="is-size-7 has-text-weight-bold">
                Anything else you'd like to add? <span class="is-help-text">(optional)</span>
              </p>
              <p class="is-help-text is-size-7 mb-2">
                E.g. Questions about T1 dates, number of samples or other quantities.
              </p>
            </template>
            <BInput
              v-model="formData.additional_notes"
              maxlength="255"
              type="textarea"
            />
          </BField>
        </div>
      </div>
      <footer
        class="modal-card-foot has-background-white is-justify-content-space-between"
        style="flex: none;"
      >
        <GmButton
          class="has-text-weight-bold"
          type="dark"
          style="color: black"
          outlined
          @click="$emit('cancel')"
        >
          Cancel
        </GmButton>
        <GmButton
          type="is-v2-supporting-1"
          class="has-text-weight-bold"
          @click="passes(formatAndUpdate)"
        >
          Confirm requirements
        </GmButton>
      </footer>
    </div>
  </ValidationObserver>
</template>

<script>
import { ValidationObserver } from 'vee-validate';
import BInputWithValidator from '@/shared/components/inputs/BInputWithValidator.vue';

const FREQUENCIES = [
  'month',
  '3 months',
  '6 months',
  'year',
];

const TOOL_LIFE = [
  '10K',
  '50K',
  '100K',
  '250K',
  '500K',
  '1M',
];

export default {
  name: 'ProductionRequirementsModal',

  components: {
    BInputWithValidator,
    ValidationObserver,
  },

  props: {
    configuration: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      FREQUENCIES,
      TOOL_LIFE,
      hasToolLife: !!this.configuration?.tool_life,
      hasToolingConfiguration: !!this.configuration?.tooling_config_desc,
      formData: {
        development_stage: this.configuration?.development_stage,
        forecast_units: this.configuration?.forecast_units,
        forecast_period: this.configuration?.forecast_period,
        tool_life: this.configuration?.tool_life,
        tooling_config_desc: this.configuration?.tooling_config_desc,
        t1_sample_deliver_by: this.configuration?.t1_sample_deliver_by ? new Date(this.configuration?.t1_sample_deliver_by) : this.minSampleDeliveryDate,
        additional_notes: this.configuration?.additional_notes,
      },
    };
  },

  computed: {
    minSampleDeliveryDate() {
      const date = new Date();
      date.setDate(date.getDate() + 1);

      return date;
    },
  },

  watch: {
    developmentStage: {
      handler(newVal) {
        if (newVal !== 'production') {
          this.unitForeCast = {
            forecast_units: null,
            forecast_period: null,
          };
        }
      },
      immediate: true,
    },
  },

  methods: {
    formatAndUpdate() {
      const formatted = {
        ...this.formData,
        development_stage: this.formData.development_stage,
        forecast_units: this.formData.development_stage === 'production'
          ? this.formData.forecast_units
          : null,
        forecast_period: this.formData.development_stage === 'production'
          ? this.formData.forecast_period
          : null,
        t1_sample_deliver_by: this.add2Hours(this.formData.t1_sample_deliver_by),
      };
      this.$emit('update-config', { production_requirements: formatted });
    },

    // Add 2 hours to the selected datetime
    // due to BST messing with the date
    add2Hours(selectedDate) {
      const today = new Date(selectedDate);
      today.setHours(today.getHours() + 2);
      return today.toISOString();
    },
  },
};
</script>
