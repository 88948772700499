<template>
  <div class="no-quotes-wrapper is-black has-text-weight-bold is-size-7 py-5">
    We couldn't find any Quotes that match your current filters.
    Try changing one of the filters or <span><button
      class="button-no-style has-text-info is-size-7/6 is-underlined"
      @click="handleClearFilters"
    >
      Clear all filters
    </button></span>
  </div>
</template>

<script>
export default {
  name: 'NoFiltersMatch',
  methods: {
    handleClearFilters() {
      this.$emit('clear-filters');
    },
  },
};
</script>

<style lang="scss" scoped>

.no-quotes-wrapper {
  border-bottom: solid #dbdbdb 1px;
  border-top: solid #dbdbdb 1px;
}

</style>
