<template>
  <BModal
    :active.sync="showModal"
    :can-cancel="showThankYouMessage ? ['escape', 'x', 'outside', 'button'] : []"
    has-modal-card
  >
    <FeedbackThanks
      v-if="showThankYouMessage"
      @close="showModal = false"
    />
    <Component
      :is="componentData.component"
      v-for="componentData in requiredComponents"
      v-else
      :key="componentData.component.name"
      v-bind="componentData.props"
      @close="showModal = false"
      @submitted="showThankYouMessage = true"
    />
  </BModal>
</template>

<script>
import { mapState } from 'vuex';
import FeedbackOrder from '@/app-buyer/components/feedback-modal/FeedbackOrder.vue';
import Invoice from '@/app-buyer/models/Invoice';
import FeedbackThanks from '@/app-buyer/components/feedback-modal/FeedbackThanks.vue';
import { AUTH_MODULE, LOGGED_IN } from '@/app-buyer/store/modules/auth/types';
import STATUS_SEQ_NUM from '@/shared/consts/status-sequence-numbers';

const date = new Date();
date.setMonth(date.getMonth() - 1);
const createdAfterDate = date.toISOString().split('T')[0];

/* eslint-disable camelcase */
export default {
  name: 'FeedbackModal',
  components: { FeedbackThanks, FeedbackOrder },
  data() {
    return {
      showModal: false,
      requiredComponents: [],
      showThankYouMessage: false,
      daysToWait: 3,
    };
  },
  computed: {
    ...mapState(AUTH_MODULE, {
      LOGGED_IN,
    }),
  },
  watch: {
    [LOGGED_IN]: {
      handler(newValue) {
        if (newValue) {
          this.checkIfFeedbackRequired();
        }
      },
      immediate: true,
    },
  },
  methods: {
    async checkIfFeedbackRequired() {
      const orderFeedback = await this.checkOrderFeedbackNeeded();
      const components = [
        ...orderFeedback,
      ];
      this.requiredComponents = components;
      this.showModal = !!components.length;
    },
    async checkOrderFeedbackNeeded() {
      const query = new Invoice();
      query.where('type', 'paid');
      query.where('has_reached_status', 'customer-received');
      query.where('does_not_have_feedback', true);
      query.where('created_after', createdAfterDate);
      query.include('rfqLines.rfq.productLifecycle.status');
      const { data } = await query.get();
      if (!data.length) {
        return [];
      }
      const calls = data
        .filter((invoice) => {
          let customerReceivedDate = null;
          const { sequence_number, created_at } = invoice.last_5_rfqs[0]?.product_lifecycle?.status;

          if (sequence_number >= STATUS_SEQ_NUM.CUSTOMER_RECEIVED) customerReceivedDate = created_at;

          if (!customerReceivedDate) return false;

          const createdAt = new Date(customerReceivedDate);
          const daysBeforeShowing = createdAt.setDate(createdAt.getDate() + this.daysToWait);

          const dateToShowFeedbackModal = new Date(daysBeforeShowing).toISOString().split('T')[0];
          const todaysDate = new Date().toISOString().split('T')[0];

          const isFeedbackCoolingOffPeriod = dateToShowFeedbackModal >= todaysDate;

          return !this.$cookies.get(`dont_ask_about.${invoice.id}`) && !isFeedbackCoolingOffPeriod;
        })
        .map((invoice) => {
          const showQuery = new Invoice();
          showQuery.include([
            'rfqLines.rfq.configurationProperties',
            'rfqLines.rfq.order',
            'rfqLines.rfq.uploads',
            'rfqLines.rfq.project',
          ]);
          return showQuery.find(invoice.id);
        });
      if (!calls.length) {
        return [];
      }
      const responses = await Promise.all(calls);
      const result = [];
      result.push({
        component: FeedbackOrder,
        props: { invoices: responses.map((response) => response.data) },
      });
      return result;
    },
  },
};
</script>
