<template>
  <div
    class="modal-card"
    style="overflow: visible"
  >
    <div
      class="modal-card-body p-4"
      style="border-top-left-radius: 4px; border-top-right-radius: 4px; overflow: visible;"
    >
      <p class="is-size-6 mb-4 has-text-weight-bold">
        Which Project would you like to move the parts to?
      </p>
      <b-dropdown
        v-model="value"
        max-height="300px"
        scrollable
      >
        <template v-slot:trigger="{ active }">
          <b-button
            size="is-small"
            :icon-right="active ? 'chevron-up' : 'chevron-down'"
            style="min-width: 40ch; width: 368px;"
            class="is-justify-content-space-between"
          >
            <template>
              {{ buttonLabel }}
            </template>
          </b-button>
        </template>

        <b-dropdown-item
          v-for="project in projects"
          :key="`MRFQ-${project.hash}`"
          :value="project.name"
          @click="$emit('selected-option', selectedOption)"
        >
          {{ project.name }}
        </b-dropdown-item>
        <PartTableBulkOperationOptionLoadMore
          v-if="showLoadMore"
          @load-next-page="GET_MRFQ_PROJECTS({ page: Number(Math.max(...MRFQS_PAGINATION_PAGES) + 1 )}); "
        />
        <BLoading
          :active="loading"
          :is-full-page="false"
        />
      </b-dropdown>
    </div>
    <footer
      class="modal-card-foot"
    >
      <gm-button
        type="white"
        class="button"
        @click="$emit('close-modal', false);"
      >
        Cancel
      </gm-button>
      <gm-button
        type="v2-supporting-1"
        class="button"
        :disabled="!canSubmit"
        @click="$emit('moveRfqsToProject')"
      >
        {{ movePartsButtonText }}
      </gm-button>
    </footer>
  </div>
</template>

<script>
import {
  mapState, mapActions,
} from 'vuex';
import {
  RFQ_MODULE, SELECTED_PART_HASHES,
} from '@/app-buyer/store/modules/rfq/types';
import {
  PROJECTS_MRFQ,
  PROJECT_MODULE,
  GET_MRFQ_PROJECTS,
  MRFQS_PAGINATION_PAGES,
  MRFQS_PAGINATION,
} from '@/app-buyer/store/modules/projects/types';
import PartTableBulkOperationOptionLoadMore from '@/app-buyer/components/project/PartTableBulkOperationOptionLoadMore.vue';

export default {
  name: 'PartTableBulkOperationOption',
  components: {
    PartTableBulkOperationOptionLoadMore,
  },
  props: {
    closeModal: Boolean,
    selectedParts: Array,
    moveRfqsToProject: Function,
  },
  data() {
    return {
      value: '',
      nextPage: true,
      loading: false,
    };
  },
  computed: {
    ...mapState(RFQ_MODULE, {
      SELECTED_PART_HASHES,
    }),
    ...mapState(PROJECT_MODULE, {
      PROJECTS_MRFQ,
      MRFQS_PAGINATION_PAGES,
      MRFQS_PAGINATION,
    }),

    canSubmit() {
      return this.selectedOption !== undefined;
    },

    showLoadMore() {
      return this[MRFQS_PAGINATION]
  && !this[MRFQS_PAGINATION_PAGES].includes(this[MRFQS_PAGINATION].last_page)
  && this[PROJECTS_MRFQ].length;
    },

    movePartsButtonText() {
      return `${this[SELECTED_PART_HASHES].length === 1
        ? `Move ${this[SELECTED_PART_HASHES].length} part now`
        : `Move ${this[SELECTED_PART_HASHES].length} parts now`} `;
    },

    selectedOption() {
      return this.projects.find((e) => e.name === this.value);
    },
    buttonLabel() {
      return this.selectedOption ? this.selectedOption.name : 'Select a Project';
    },

    projects() {
      return this[PROJECTS_MRFQ].map((project) => project);
    },
  },
  methods: {
    ...mapActions(PROJECT_MODULE, {
      GET_MRFQ_PROJECTS,
    }),
  },
};
</script>
