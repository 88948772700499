<template>
  <div class="modal-card">
    <header class="modal-card-head has-background-white p-1" />
    <div class="modal-card-body px-6">
      <div class="is-flex is-align-items-center mb-4">
        <BIcon
          class="mr-4"
          icon="check-circle"
          style="font-size: 5rem; width: unset; height: unset;"
          type="is-success"
        />
        <div>
          <p class="has-text-weight-bold is-size-6 mb-2">
            Your quotes have been requested
          </p>
          <p class="is-size-7">
            You will receive your quotes within 24 hours
            but it could be sooner than that.
            <br>
            We will send you an email as soon as your
            quotes are ready.
          </p>
        </div>
      </div>
      <p class="has-text-weight-bold is-size-6 mb-2">
        What's next?
      </p>
      <p class="is-size-7">
        While you wait for your quotes, you can return
        to the Projects page to configure more parts and request more quotes - or head over to
        the quotes page and wait for your quotes to come in.
      </p>
    </div>
    <footer class="modal-card-foot is-justify-content-space-between">
      <BButton
        class="has-text-weight-bold"
        size="is-small"
        @click="$emit('close')"
      >
        Back to project
      </BButton>
      <BButton
        :disabled="showLoading"
        class="has-text-weight-bold"
        size="is-small"
        type="is-g-primary"
        @click="goToQuotes"
      >
        View my quotes
        <span
          v-if="showLoading"
          class="ml-1 loading-wheel"
        >
          <font-awesome-icon
            icon="spinner"
            spin
          />
        </span>
      </BButton>
    </footer>
  </div>
</template>

<script>

import { mapState } from 'vuex';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { QUOTES, QUOTES_MODULE } from '@/app-buyer/store/modules/quotes/types';

export default {
  name: 'RfqSuccessModalContent',
  components: {
    FontAwesomeIcon,
  },

  data() {
    return {
      openErrors: false,
      hasBeen5Secs: false,
    };
  },

  computed: {
    ...mapState(QUOTES_MODULE, {
      QUOTES,
    }),

    hasQuotesBeenFetched() {
      return !!this[QUOTES]?.length;
    },

    showLoading() {
      return !(this.hasBeen5Secs || this.hasQuotesBeenFetched);
    },
  },

  mounted() {
    setTimeout(() => {
      this.hasBeen5Secs = true;
    }, 5000);
  },

  methods: {
    goToQuotes() {
      this.$router.push({ name: 'quote-list' });
      this.$emit('close');
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
.loading-wheel {
  vertical-align: middle;
}
</style>
