<template>
  <div class="mesh-background">
    <article
      v-if="false"
      class="notification is-warning banner"
      role="alert"
    >
      <div class="media">
        <div class="media-content">
          We are experiencing some technical issues where some partners are unable to log in.
          Please <a
            target="_blank"
            href="https://support.google.com/accounts/answer/32050?hl=en&co=GENIE.Platform%3DDesktop"
          >delete any geomiq.com cookies</a> or open an <a
            target="_blank"
            href="https://support.google.com/chrome/answer/95464?hl=en-GB&co=GENIE.Platform%3DDesktop"
          >incognito</a> session if you are currently
          experiencing this issue until it is resolved. Thank you for your patience.
        </div>
      </div>
    </article>
    <div
      class="container is-flex is-flex-align-centered"
      style="height: 100%;"
    >
      <div
        class="card box is-flex is-flex-direction-column is-justify-center"
        style="width: 600px; max-width: 100vw; margin: auto;"
      >
        <div class="is-flex is-justify-center is-flex-align-centered p-md">
          <icon-font
            icon="geomiq-text"
            style="font-size: 1.2rem;"
          />
        </div>
        <p
          class="is-size-6 has-text-weight-bold is-width-100
          has-text-centered p-b-sm-important m-b-sm-important"
          style="border-bottom: 1px solid var(--bg-gray3);"
        >
          Login
        </p>
        <login-form />
        <div class="is-flex is-flex-align-centered">
          <router-link
            to="/register"
            class="gm-theme gm-button info outlined fullwidth small
            is-flex is-justify-center is-flex-align-centered m-t-sm m-r-xs"
            data-testid="register-link"
          >
            Sign up as a Buyer
          </router-link>
          <a
            :href="partnerForm"
            class="gm-theme gm-button info outlined fullwidth small
            is-flex is-justify-center is-flex-align-centered m-t-sm"
            data-testid="register-link"
          >
            Sign up as a Manufacturing Partner
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoginForm from '../../components/login-registration/login-form.vue';
import getEnvironmentVariable from '../../../shared/misc/env-variable';
import EmailVerification from '../../mixins/email-verification';

export default {
  name: 'LoginPage',
  components: {
    LoginForm,
  },
  mixins: [
    EmailVerification,
  ],
  data() {
    return {
      activeTab: 0,
    };
  },
  computed: {
    partnerForm() {
      return `${getEnvironmentVariable('VUE_APP_API_URL')}/partners/register`;
    },
  },
  mounted() {
    this.emailVerificationToast();
  },
};
</script>

<style scoped>

.notification.banner {
  top: 0;
  right: 0;
  width: 100%;
  max-width: 100%;
  position: absolute;
  border-radius: 0;
  padding: 1rem;
  margin-bottom: 0 !important;
  z-index: 9999;
}

</style>
