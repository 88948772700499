<template>
  <div class="wrapper">
    <!--    The list that will need the loading is inserted here -->
    <slot />
    <!--    An optional slot for a bottom element (eg. load button, loader) -->
    <slot name="bottom" />
  </div>
</template>

<script>
import { isMobile } from 'buefy/src/utils/helpers';

export default {
  name: 'InfiniteScroll',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    threshold: {
      type: Number,
      default: () => 30,
    },
  },
  data() {
    return {
      needTouch: false,
      scrollableParent: null,
    };
  },
  mounted() {
    this.scrollableParent = this.getScrollParent(this.$el);
    this.needTouch = isMobile.any();
    if (this.scrollableParent) {
      this.scrollableParent.addEventListener('scroll', this.checkPosition);
    } else {
      document.addEventListener('scroll', this.checkPosition);
    }
    if (this.needTouch) {
      document.addEventListener('touchmove', this.checkPosition);
    }
  },
  destroyed() {
    if (this.scrollableParent) {
      this.scrollableParent.removeEventListener('scroll', this.checkPosition);
    } else {
      document.removeEventListener('scroll', this.checkPosition);
    }
    if (this.needTouch) {
      document.removeEventListener('touchmove', this.checkPosition);
    }
  },
  methods: {
    /*
      * Checks if the bottom of the container is below a certain pixel amount
      * */
    checkPosition() {
      if (!this.disabled) {
        const rect = this.$el.getBoundingClientRect();
        const { bottom } = rect;
        if (bottom <= window.innerHeight + this.threshold) {
          this.$emit('bottom-reached');
        }
      }
    },

    /*
      * Go through the parent nodes until the first scrollable parent is found
      *
      * @param {HTMLElement} node    The element whose parents the method goes through
      * */
    getScrollParent(node) {
      const regex = /(auto|scroll)/;
      const style = (nodeWithStyle, prop) => getComputedStyle(nodeWithStyle, null)
        .getPropertyValue(prop);
      if (node == null) {
        return null;
      }
      if (regex.test(
        style(node, 'overflow')
                + style(node, 'overflow-y'),
      )) {
        return node;
      }
      return this.getScrollParent(node.parentNode);
    },
  },
};
</script>
