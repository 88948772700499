<template>
  <div
    class="part-handler-wrapper is-flex"
    :class="{'is-column' : isColumn}"
  >
    <button
      class="button-no-style is-hidden-desktop mr-2"
      style="width: 1rem; padding-top: 2px;"
      @click="$emit('update:is-open', !isOpen)"
    >
      <BIcon
        :icon="`chevron-${isOpen ? 'up' : 'down'}`"
        size="is-small"
      />
    </button>
    <div
      class="checkbox-wrapper"
      @click="handleClick"
      @mouseenter="handleHover(true)"
      @mouseleave="handleHover(false)"
    >
      <BCheckbox
        ref="checkbox"
        v-model="scopedIsSelected"
        class="checkbox"
        type="is-infoinvert"
      />
    </div>
    <PartThumbnail
      :part="part"
      :show-hover-state="showHovered"
    />
  </div>
</template>

<script>
import PartThumbnail from '@/app-buyer/components/project/PartThumbnail.vue';

export default {
  name: 'PartHandler',
  components: { PartThumbnail },
  props: {
    part: {
      type: Object,
      required: true,
    },
    isOpen: Boolean,
    isSelected: Boolean,
    showHovered: Boolean,
    isColumn: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      scopedIsSelected: this.isSelected,
    };
  },
  watch: {
    isSelected(nv, ov) {
      if (nv !== ov) this.scopedIsSelected = nv;
    },
  },
  methods: {
    handleClick() {
      this.scopedIsSelected = !this.scopedIsSelected;
      this.$emit('update:is-selected', this.scopedIsSelected);
    },
    handleHover(event) {
      let check = this.$refs.checkbox.$el.getElementsByClassName('check');
      check = [...check];

      check[0].style.borderColor = event ? '#1ea3dc' : '';
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
.part-handler-wrapper {
  &.is-column {
    flex-direction: column;

    .checkbox {
      margin-bottom: 1em;
    }
  }

  .checkbox-wrapper {
    display: flex;

    .checkbox {
      pointer-events: none;
    }
  }
}
</style>
