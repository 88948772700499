import { SET } from '../types';
import { INCOMING, OUTGOING } from './types';

export default {
  /**
     * Sets data in the state
     *
     * @param state
     * @param data
     * */
  [SET](state, data) {
    const { incoming, outgoing } = data.data;
    state[INCOMING] = incoming;
    state[OUTGOING] = outgoing;
  },
};
