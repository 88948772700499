<template>
  <div class="button-group-vertical mb-2">
    <GmButton
      outlined
      type="light"
      style="color: black;"
      icon-right="plus"
      icon-pack="fal"
      size="small"
      class="is-justify-space-between"
      @click="showInspectionModal = true"
    >
      Geomiq inspections
    </GmButton>
    <GmButton
      outlined
      type="light"
      style="color: black;"
      icon-right="plus"
      icon-pack="fal"
      size="small"
      class="is-justify-space-between"
      @click="showOtherExtrasModal = true"
    >
      Standard, Part Marking, Screws
    </GmButton>
    <BModal
      :active.sync="showInspectionModal"
      has-modal-card
      :can-cancel="[]"
    >
      <InspectionModal
        :configurations="configurations"
        :locked-configurations="lockedConfigurations"
        :lead-hash="leadHash"
        @close="showInspectionModal = false"
        @update-config="$emit('update-config', $event)"
      />
    </BModal>
    <BModal
      :active.sync="showOtherExtrasModal"
      has-modal-card
      width="90vw"
      :can-cancel="[]"
    >
      <OtherExtrasModal
        :configurations="configurations"
        :locked-configurations="lockedConfigurations"
        :lead-hash="leadHash"
        @effect="$emit('effect', $event)"
        @close="showOtherExtrasModal = false"
        @update-config="$emit('update-config', $event)"
      />
    </BModal>
  </div>
</template>
<script>
import InspectionModal from '@/app-buyer/components/project/InspectionModal.vue';
import OtherExtrasModal from '@/app-buyer/components/project/OtherExtrasModal.vue';

export default {
  name: 'PartFormDraftConfiguratorExtras',
  components: { OtherExtrasModal, InspectionModal },
  props: {
    leadHash: {
      type: String,
      default: null,
    },
    configurations: {
      type: Object,
      required: true,
    },
    lockedConfigurations: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showInspectionModal: false,
      showOtherExtrasModal: false,
    };
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .modal-card {
    width: 100%;
  }
}
</style>
