<template>
  <div class="dot-container">
    <h2
      v-show="message"
      :style="`padding-bottom: ${+radius + 0.5}rem`"
    >
      {{ message }}
    </h2>
    <div
      v-for="n in +dotAmount"
      :key="n"
      class="hidden-dot-container"
      :style="`animation-delay: ${n * 2}00ms; height: ${radius}rem;`"
    >
      <span
        class="dot"
        :style="`width: ${radius}rem; height: ${radius}rem;`"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'HorizontalDotLoader',
  props: {
    dotAmount: {
      type: Number,
      default: 5,
    },
    radius: {
      type: Number,
      default: 0.5,
    },
    message: {
      type: String,
      default: () => '',
    },
  },
};
</script>

<style lang="scss" scoped>
  .dot-container {
    padding-bottom: 1rem;
    overflow: hidden;
    position: relative;
    width: 100%;

    .hidden-dot-container {
      transform: translateX(-60%);
      animation: slide-left 2s cubic-bezier(0.28, 0.75, 0.75, 0.25) infinite;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 0.5rem;
      position: absolute;

      .dot {
        background-color: var(--geomiq-blue);
        border-radius: 50%;
        width: 0.5rem;
      }
    }
  }

  h2 {
    color: var(--text-lighter);
    letter-spacing: 1px;
    font-size: 1rem;
    font-weight: bold;
    text-transform: uppercase;
  }

  @keyframes slide-left {
    from {
      transform: translateX(-60%);
      opacity: 0;
    }

    40% {
      opacity: 1;
    }

    60% {
      opacity: 1;
    }

    to {
      transform: translateX(60%);
      opacity: 0;
    }
  }
</style>
