export const initialsMixin = {
  computed: {
    initials() {
      const { name } = this.user;
      if (!name) {
        return this.user.email.substring(0, 2).toUpperCase();
      }
      const nameParts = name.split(' ').reduce((res, curr, idx, arr) => {
        if (idx === 0 || idx === arr.length - 1) res.push(curr);
        return res;
      }, []);
      return nameParts.map((part) => part[0].toUpperCase()).join('');
    },
  },
};

export const avatarHueMixin = {
  computed: {
    hue() {
      return (Array.prototype.reduce
        .call(this.user.email
          .split('@')[0], (sum, char) => char.charCodeAt(0) + sum, 0) % 45) * 8;
    },
  },
};
