import { DO_ACTION } from './types';
import Api from '../../../api/api';
import ENDPOINTS from '../../../api/endpoints';
import { GET, SET } from '../types';
import {
  METADATA,
  SET_METADATA,
  USER_MODULE,
} from '@/app-buyer/store/modules/user/types';

export default {
  /**
     * Get the cart transfer lists
     *
     * @param {Object} context
     * @returns {Promise<Object>} data
     */
  async [GET]({ commit }) {
    const { data } = await Api.get(ENDPOINTS.CART_TRANSFER.INDEX, { params: { include: 'cart.items.rfq,sender,recipient' } })
      .catch((e) => e);
    commit(SET, data);
    return data;
  },
  /**
     * Do cart transfer action
     *
     * @param {Object} context
     * @param {Object} payload
     * @param {number} payload.id
     * @param {string} payload.action
     * @returns {Promise<Object>} data
     */
  async [DO_ACTION]({ dispatch, commit, rootState }, { id, action }) {
    const { data } = await Api.post(ENDPOINTS.CART_TRANSFER.DO_ACTION, null, {
      __pathParams: {
        id,
        action,
      },
    })
      .catch((e) => e);
    commit(`${USER_MODULE}/${SET_METADATA}`, {
      ...this[METADATA],
      cart_count: rootState[USER_MODULE][METADATA].cart_count + (data?.transfer_count || 0),
    }, { root: true });
    dispatch(GET, data);
    return data;
  },
};
