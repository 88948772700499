import { DialogProgrammatic } from 'buefy';
import ENDPOINTS from '../../../api/endpoints';
import Api from '../../../api/api';
import {
  AGREE_TO_TERMS,
  GET,
  SET,
  TERMS_AND_CONDITIONS_MODAL,
  UPDATE,
  UNSUBSCRIBE,
} from '../types';
import { mapUserDataToBEFormat } from './user-mapping';
import { GET_METADATA, GET_USER_POLICIES, SET_METADATA } from './types';
import { SETUP, WEBSOCKET_MODULE } from '../web-sockets/types';
import { ACCESS_TOKEN, AUTH_MODULE, LOGGED_IN } from '../auth/types';

export default {
  /**
     * Gets the user data
     * @param commit
     * @param dispatch
     * @param rootState
     * @returns {Promise<boolean>}
     */
  async [GET]({ commit, dispatch, rootState }) {
    if (!rootState[AUTH_MODULE][LOGGED_IN]) return null;
    const { data } = await Api.post(ENDPOINTS.USER.INDEX)
      .catch((e) => e);
    commit(SET, data);
    dispatch(
      `${WEBSOCKET_MODULE}/${SETUP}`,
      {
        id: data.user.id,
        token: rootState[AUTH_MODULE][ACCESS_TOKEN],
      },
      { root: true },
    );
    return data;
  },
  /**
     * Updates the user data then fetches the updated data
     * @param dispatch
     * @param data
     * @returns {Promise<boolean>}
     */
  async [UPDATE]({ dispatch }, data) {
    await Api.post(ENDPOINTS.USER.UPDATE, mapUserDataToBEFormat(data))
      .catch((e) => e);
    dispatch(GET);
    return true;
  },
  /**
     * Get metadata for user (Consists of Rfq, OrDer and Cart totals
     * @param {Object} context
     * @returns {Promise<Object>}
     */
  async [GET_METADATA]({ commit, rootState }) {
    if (!rootState[AUTH_MODULE][LOGGED_IN]) return null;
    const { data } = await Api.get(ENDPOINTS.USER.METADATA)
      .catch((e) => e);
    commit(SET_METADATA, data);
    return data;
  },
  /**
     * Agrees to terms and conditions
     * @param dispatch
     * @param data
     * @returns {Promise<boolean>}
     */
  async [AGREE_TO_TERMS]({ dispatch }, data) {
    await Api.post(ENDPOINTS.USER.AGREE_TO_TERMS, data)
      .catch((e) => e);
    dispatch(GET);
    return true;
  },

  async [UNSUBSCRIBE](data) {
    await Api.post(ENDPOINTS.USER.UNSUBSCRIBE, data)
      .catch((e) => e);
    return true;
  },

  async [TERMS_AND_CONDITIONS_MODAL]({ dispatch, getters }, data) {
    if (!getters[GET_USER_POLICIES].includes('buyer_terms') && !data.masquerading) {
      DialogProgrammatic.confirm({
        message: `
                    Welcome to the buyer portal! <br /> Before continuing, please make sure to read and agree to our
                    <a href="https://geomiq.com/user-terms-conditions/" target="_blank">Terms and Conditions</a>.
                `,
        confirmText: 'I agree',
        type: 'is-warning',
        canCancel: false,
        onConfirm: () => dispatch(AGREE_TO_TERMS, { policy: 'buyer_terms' }),
      });
    }
  },
};
