<template>
  <div class="is-flex is-flex-direction-column is-height-100">
    <BUpload
      :class="{'is-flex-grow-1': !hasSupportingFiles}"
      drag-drop
      native
      multiple
      :loading="loading"
      @input="startUpload"
    >
      <div
        class="is-flex is-justify-content-center is-align-items-center is-flex-direction-column
            is-height-100 has-background-g-light-3 has-background-hover-darken-g-light-3"
      >
        <AnimatedGeomiqLogo
          :scale="2"
          fill="#338dbc"
          stroke="#338dbc"
          :duration="0"
        />
        <h2 class="title is-5">
          Drag and drop or click here
        </h2>
        <p class="subtitle is-6 has-text-weight-bold">
          to upload your files
        </p>
      </div>
    </BUpload>
    <div
      v-if="hasSupportingFiles"
      class="is-flex-grow-1"
    >
      <div
        v-for="file in supportingFiles"
        :key="file.id"
        class="pdf-wrapper"
      >
        <p class="title is-6">
          {{ file.client_original_name }}
        </p>
        <embed
          class="is-width-100 is-height-100"
          :src="file.url"
          type="application/pdf"
        >
        <hr>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import AnimatedGeomiqLogo
  from '@/shared/components/loaders/animated-geomiq-logo/animated-geomiq-logo.vue';
import { part3DconfigMixin } from '@/app-buyer/components/project/mixins';
import {
  APPEND_FILE,
  GET_VIEWED,
  RFQ_MODULE,
} from '@/app-buyer/store/modules/rfq/types';

export default {
  name: 'SupportingFileEditor',
  components: { AnimatedGeomiqLogo },
  mixins: [part3DconfigMixin],
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapGetters(RFQ_MODULE, {
      part: GET_VIEWED,
    }),
  },
  methods: {
    ...mapActions(RFQ_MODULE, {
      APPEND_FILE,
    }),
    startUpload(files) {
      if (this.hasConfiguration3D) {
        this.$buefy.dialog.confirm({
          message: 'When uploading supporting files, the 3D Tool configuration will be <b>removed</b>.',
          confirmText: 'Upload files',
          cancelText: 'Keep configuration',
          onConfirm: () => {
            this.removeSpecifications();
            this.uploadSupportingFiles(files);
          },
          type: 'is-danger',
          hasIcon: true,
        });
      }
    },
    async uploadSupportingFiles(files) {
      this.loading = true;
      try {
        // eslint-disable-next-line no-restricted-syntax
        for (const file of files) {
          // eslint-disable-next-line no-await-in-loop
          await this[APPEND_FILE]({
            draft: this.part,
            file,
          });
        }
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
<style
  lang="scss"
  scoped
>
.pdf-wrapper {
  width: 100%;
  height: 100%;

  &:not(:first-child) {
    margin-top: 5rem;
  }

  &:not(:last-child) {
    margin-bottom: 3rem;
  }
}
</style>
