<template>
  <div
    v-if="error && Object.keys(error).length"
    class="my-6"
  >
    <div class="is-flex is-align-items-center mb-2">
      <BIcon
        icon="minus-circle"
        type="is-danger"
      />
      <p class="has-text-weight-bold is-size-6">
        There was an issue with some of your quotes
      </p>
    </div>
    <p class="is-size-7">
      Due to an issue with some of your part files or configurations, your quotes
      were unable to be requested. <a
        v-if="!openErrors"
        @click="openErrors = true"
      >
        Click here to view these issues
      </a>
    </p>
    <BCollapse :open="!!openErrors">
      <BMessage
        class="is-size-7 mt-4"
        size="is-small"
        type="is-danger"
      >
        <ul
          style="list-style: disc"
        >
          <li class="ml-3">
            <ul v-if="Object.keys(error).length">
              <li
                v-for="(errorArr, errorKey) in error"
                :key="errorKey"
              >
                <template v-if="error.draft">
                  <b>{{ error.draft.name }}</b>
                  <br>
                </template>
                <b>{{ errorKey }}:</b>
                {{ errorArr.join(', ') }}
              </li>
            </ul>
            <p v-else>
              <template v-if="error.draft">
                <b>{{ error.draft.name }}</b>
                <br>
              </template>
              There seems to be an issue with our system, please try again or contact us!
            </p>
          </li>
        </ul>
      </BMessage>
    </BCollapse>
  </div>
</template>
<script>
export default {
  name: 'RfqUnsuccessfulMessage',
  props: {
    error: {
      type: Object,
      default: () => {
      },
    },
    initialOpenState: Boolean,
  },

  data() {
    return {
      openErrors: this.initialOpenState,
    };
  },
};
</script>
