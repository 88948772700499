<template>
  <div class="card px-6 py-5 is-flex is-align-items-center is-flex-direction-column">
    <BIcon
      icon="check-circle"
      type="is-success"
      size="is-large"
    />
    <h2
      class="is-size-4 has-text-weight-bold has-text-dark mb-4"
    >
      Thank you for your feedback!
    </h2>
    <div class="has-text-centered">
      <GmButton
        type="v2-supporting-1"
        @click="$emit('close')"
      >
        <b>
          Back to the app
        </b>
      </GmButton>
    </div>
  </div>
</template>

<script>
import GmButton from '@/shared/components/gm-button/gm-button.vue';

export default {
  name: 'FeedbackThanks',
  components: { GmButton },
  mounted() {
    setTimeout(() => {
      this.$emit('close');
    }, 2000);
  },
};
</script>

<style scoped>

</style>
