<template>
  <Tippy
    ref="tippy"
    :enabled="!!message"
    arrow
    class="is-inline-block"
    interactive
    placement="right"
  >
    <template #trigger>
      <div
        :class="`has-background-${bgType}`"
        class="part-status-tag"
      >
        <div class="part-status-tag-icon-wrapper">
          <BIcon
            v-if="loading"
            class="spinning-animation"
            icon="spinner"
            type="is-white"
          />
          <BIcon
            v-else
            :icon="icon"
            style="font-size: .6rem;"
            type="is-white"
          />
        </div>
        <div
          v-show="message && status === 'warning'"
          class="part-status-tag-icon-wrapper"
        >
          <BIcon
            icon="info-circle"
            type="is-white"
          />
        </div>
      </div>
    </template>
    <div
      class="has-text-left p-4"
      style="font-size: 13px;"
    >
      <div v-html="message" />
      <div
        v-for="(def, index) in componentDefs"
        :key="index"
        class="mt-2"
      >
        <Component
          :is="def.component"
          v-bind="def.props"
          @loading="loading = $event"
          v-on="def.on"
        />
      </div>
    </div>
  </Tippy>
</template>

<script>
export default {
  name: 'PartStatusTag',

  props: {
    status: {
      type: String,
      default: 'danger',
    },

    message: {
      type: [String, Object, Boolean],
      default: null,
    },

    componentDefs: {
      type: Array,
      default: null,
    },
  },

  data() {
    return {
      loading: false,
    };
  },

  computed: {
    bgType() {
      switch (this.status) {
        case 'success':
          return 'success';
        case 'warning':
          return 'success';
        case 'danger':
          return 'warning';
        default:
          return 'light';
      }
    },

    icon() {
      switch (this.status) {
        case 'success':
          return 'check';
        case 'warning':
          return 'check';
        case 'danger':
          return 'minus';
        default:
          return 'check';
      }
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
$size: 1.2rem;

.part-status-tag {
  align-items: center;
  border: 2px solid white;
  border-radius: $size;
  display: inline-flex;

  &-icon-wrapper {
    align-items: center;
    display: flex;
    height: $size;
    justify-content: center;
    width: $size;
  }
}
</style>
