import { mapActions } from 'vuex';
import { CHATS_MODULE } from '@/app-buyer/store/modules/chats/types';
import { CREATE } from '@/app-buyer/store/modules/types';

const openQuoteChat = {
  methods: {
    ...mapActions(CHATS_MODULE, {
      CREATE,
    }),
    async openQuoteChat(rfq) {
      const chat = await this[CREATE]({ id: rfq.id });
      await this.$router.push(`/messages/${chat.id}`);
    },
  },
};

export default openQuoteChat;
