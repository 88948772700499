/* eslint-disable camelcase */
import Vue from 'vue';
import URL_PARAMS from '@/app-buyer/consts/url-params';
import router from '@/app-buyer/router';
import {
  RFQ_CREATED_EVENT,
  RFQ_UPDATED_EVENT,
  SET_MODEL_PARSE_FAILED,
  UPDATE_UPLOADS,
  WEBSOCKET_MODULE,
} from '@/app-buyer/store/modules/web-sockets/types';
import {
  ALL_RFQS,
  DRAFT_RFQS,
  RFQ_MODULE,
  RFQS,
} from '@/app-buyer/store/modules/rfq/types';
import {
  DRAFT_RFQ_MODEL,
  DRAFT_RFQ_MODEL_PREVIEW,
  RFQ_MODEL,
  RFQ_MODEL_PREVIEW,
} from '@/shared/consts/slugs';
import { findModelFile } from '@/app-buyer/components/project/helpers';
import {
  ACTIVE_PROJECT_HASH,
  ARCHIVED_PROJECTS,
  CURRENT_PROJECT,
  GET_ARCHIVED,
  GET_MRFQ_PROJECTS,
  GET_MRFQ_PROJECTS_M,
  LEAVE_PROJECT_CHANNEL,
  LISTEN_PROJECT_CHANNEL,
  PROJECTS,
  SET_ACTIVE_PROJECT,
  SET_ACTIVE_PROJECT_M,
  SET_CURRENT_PROJECT_M,
  SET_MRFQS_PAGINATION,
  SET_MRFQS_PAGINATION_PAGES,
  SET_PAGINATION_PAGES,
  STORE,
} from './types';
import {
  DETAIL, GET, SET, SET_PAGINATION, UPDATE,
} from '../types';
import Project from '../../../models/Project';

const WSActions = {
  ModelFileBegunParsingEvent: [
    UPDATE_UPLOADS,
  ],
  ModelFileFailedParsingEvent: [
    UPDATE_UPLOADS,
    SET_MODEL_PARSE_FAILED,
  ],
  ModelFileParsedEvent: [
    UPDATE_UPLOADS,
  ],
  'Rfq.RfqCreatedEvent': [
    RFQ_CREATED_EVENT,
  ],
  'Rfq.RfqUpdatedEvent': [
    RFQ_UPDATED_EVENT,
  ],
};

export default {
  /**
   * Gets the array of project for the current user
   *
   * @param {Object} context
   * @param {Object} payload
   * @param {Object} payload.filters    The filters to be applied to the call
   * */

  async [GET]({ commit }, {
    clear = true,
    page = 1,
    limit = 10,
    search = '',
    go_to = '',
    toFront = false,
  }) {
    const query = new Project().orderBy('-created_at').include([
      'draftRfqs',
      'members.user',
      'members.role',
      'unorderedRfqsCount',
    ]).where('archived', false);

    if (limit) query.limit(limit);
    if (go_to) query.params({ go_to });

    if (page) query.page(page);
    if (search) query.where('search', search);

    const { data, meta } = await query.get().catch((e) => e.response);

    commit(SET, {
      data: data.map((p) => ({
        ...p,
        _loading: true,
      })),
      toFront,
      clear,
    });

    let activeProject = data[0];
    if (go_to) activeProject = data.find((d) => d.hash === go_to);
    commit(SET_ACTIVE_PROJECT_M, {
      hash: activeProject?.hash,
    });

    commit(SET_PAGINATION, {
      ...meta,
    });
    commit(SET_PAGINATION_PAGES, {
      current_page: meta.current_page,
      search,
      clear,
    });
    return data;
  },

  async [GET_ARCHIVED]({ commit }, { clear = true, page = 1, limit = 10 }) {
    const { data } = await new Project().orderBy('-created_at').include([
      'draftRfqs',
      'members.user',
      'members.role',
    ]).where('archived', true)
      .limit(limit)
      .page(page)
      .get()
      .catch((e) => e.response);
    commit(SET, {
      data: data.map((p) => ({
        ...p,
        _loading: true,
      })),
      clear,
      toFront: false,
      isArchived: true,
    });
    return data;
  },

  /**
   * Creates a project
   *
   * @param {Object} context
   * @param projectData    The data to be persisted
   * */
  async [STORE]({ commit, dispatch, state }, projectData) {
    const project = new Project(projectData);
    const data = await project.save();
    if (data) {
      commit(SET, {
        data: [data],
        toFront: true,
      });
      if (!state[ACTIVE_PROJECT_HASH]) {
        dispatch(SET_ACTIVE_PROJECT, { project: data });
      }
    }
    return data;
  },

  /**
   * Updates a projects property
   *
   * @param {Object} context
   * @param {Object} payload
   * @param {Object} payload.project    The project to be updated
   * @param {string} payload.key        The project's property to be updated
   * @param {*} payload.value      The value the property is set to
   * */
  async [UPDATE]({ commit, getters, state }, { project, key, value }) {
    const data = await new Project({ ...project, ...{ [key]: value } }).save();
    if (data) {
      commit(UPDATE, {
        hash: data.hash,
        data,
        // archived_at is null when being unarchived
        isUnarchived: value === null && true,
      });
      if (state[ACTIVE_PROJECT_HASH] === data.hash) {
        const nextProjectHash = getters[ARCHIVED_PROJECTS]?.length
          && state[ARCHIVED_PROJECTS][0].hash;
        if (!nextProjectHash) {
          commit(`${RFQ_MODULE}/${SET}`, { clear: true }, { root: true });
        }
        router.push(`/project/${nextProjectHash || ''}`);
      }
    }
    return data;
  },

  /**
   * Get the details for a project
   *
   * @param {Object} context
   * @param project
   * */
  async [DETAIL]({ commit }, project) {
    if (project?.archived_at) return null;
    if (project) {
      Vue.set(project, '_loading', true);
      const { data } = await new Project().include([
        'unorderedRfqsCount',
        'members.user',
        'members.role',
        'draftRfqs.configurationProperties',
        'draftRfqs.uploads',
        'draftRfqs.uploads.parserMetadata',
        'draftRfqs.project',
        'draftRfqs.productionRequirements',
        'draftRfqs.rfqsCount', // Must go last!!
      ]).find(project.hash);
      commit(UPDATE, {
        hash: project.hash,
        data: { ...data, _detailed: true, _loading: false },
      });
      return data;
    }
    return null;
  },

  /**
   * Sets the currently active project
   *
   * @param {Object} context
   * @param {Object} payload
   * @param {Object} payload.project    The id of the project to be set as active
   * @param {boolean} payload.force    The id of the project to be set as active
   * */

  async [SET_ACTIVE_PROJECT]({ state, commit, dispatch }, {
    project, force, noRedirect,
  }) {
    if (!state[PROJECTS].find((p) => p.hash === state[ACTIVE_PROJECT_HASH])) {
      const query = new Project().orderBy('-created_at').include([
        'draftRfqs',
        'members.user',
        'members.role',
        'unorderedRfqsCount',
      ]).where('archived', false);
      if (project?.hash) query.where('search_hash', project.hash);

      const { data: currentProject } = await query.get().catch((e) => e.response);

      commit(SET_CURRENT_PROJECT_M, currentProject);
    } else {
      const currentProject = [project];
      commit(SET_CURRENT_PROJECT_M, currentProject);
    }
    const stateProject = state[PROJECTS].find((e) => e.hash === project.hash) || state[PROJECTS].find((e) => e.hash === project.hash) !== undefined
      ? state[PROJECTS].find((e) => e.hash === project.hash)
      : state[CURRENT_PROJECT][0];
    if (stateProject) {
      dispatch(LEAVE_PROJECT_CHANNEL, state[ACTIVE_PROJECT_HASH]);
      commit(SET_ACTIVE_PROJECT_M, stateProject);
      dispatch(LISTEN_PROJECT_CHANNEL, stateProject.hash);
      const route = {
        name: 'rfq-form',
        params: { [URL_PARAMS.PROJECT_HASH]: stateProject.hash },
      };
      if ((!noRedirect
          && (router.currentRoute
            && router.currentRoute?.name?.includes('rfq-form')
            && !router.currentRoute.path.includes(project.hash)))
        || router.currentRoute?.name === undefined
        || force) {
        await router.push(route);
      }
      if (stateProject._detailed) {
        const { rfqs = [], draft_rfqs: draftRfqs = [] } = stateProject;
        commit(`${RFQ_MODULE}/${SET}`, {
          rfqs,
          draftRfqs,
          clear: true,
        }, { root: true });
      } else {
        const data = await dispatch(DETAIL, stateProject);
        const { rfqs = [], draft_rfqs: draftRfqs = [] } = data;
        commit(`${RFQ_MODULE}/${SET}`, {
          rfqs,
          draftRfqs,
          clear: true,
        }, { root: true });
      }
    }
  },

  [LEAVE_PROJECT_CHANNEL]({ state }, { hash, leaveAllExcept }) {
    if (leaveAllExcept?.length) {
      // eslint-disable-next-line no-undef
      const openChannels = Echo.connector.channels;

      let channelsToClose = [];

      // eslint-disable-next-line no-restricted-syntax
      for (const key in openChannels) {
        if (key.toLowerCase().includes('project')) {
          channelsToClose = [...channelsToClose, key];
        }
      }

      let filteredChannelsToClose = [];
      channelsToClose.forEach((c) => {
        let hasMatch = false;
        leaveAllExcept.forEach((l) => {
          if (c.includes(l)) hasMatch = true;
        });
        if (!hasMatch) filteredChannelsToClose = [...filteredChannelsToClose, c];
      });

      filteredChannelsToClose.forEach((channel) => {
        // eslint-disable-next-line no-undef
        Echo.leave(channel);
      });
      return;
    }
    // eslint-disable-next-line no-undef
    const toLeaveHash = hash || state[ACTIVE_PROJECT_HASH];
    // eslint-disable-next-line no-undef
    if (toLeaveHash && Echo.connector.channels[`Project.${toLeaveHash}`]) {
      // eslint-disable-next-line no-undef
      Echo.leave(`Project.${toLeaveHash}`);
    }
  },

  [LISTEN_PROJECT_CHANNEL]({ state, dispatch }, hash) {
    const toListenHash = hash || state[ACTIVE_PROJECT_HASH];
    // eslint-disable-next-line no-undef
    if (toListenHash && !Echo.connector.channels[`Project.${toListenHash}`]) {
      // eslint-disable-next-line no-undef
      const channel = Echo.channel(`Project.${toListenHash}`);
      Object.entries(WSActions).forEach(([eventName, actions]) => {
        channel.listen(eventName, (event) => {
          // eslint-disable-next-line no-restricted-syntax
          actions.forEach((actionName) => {
            dispatch(actionName, event);
          });
        });
      });
    }
  },

  [UPDATE_UPLOADS]({ rootState }, event) {
    const { preview, upload } = event;
    const projectRfqs = rootState[RFQ_MODULE][RFQS];
    const draftRfqs = rootState[RFQ_MODULE][DRAFT_RFQS];
    const allRfqs = rootState[RFQ_MODULE][ALL_RFQS];

    const toUpdate = [...projectRfqs, ...draftRfqs, ...allRfqs].filter((p) => p.hash === event.hash);

    toUpdate.forEach((part) => {
      if (part) {
        const modelFileIdx = part.uploads.findIndex((e) => [DRAFT_RFQ_MODEL, RFQ_MODEL].includes(e.type?.slug));
        const previewIdx = part.uploads.findIndex((e) => [DRAFT_RFQ_MODEL_PREVIEW, RFQ_MODEL_PREVIEW].includes(e.type?.slug));
        if ([DRAFT_RFQ_MODEL, RFQ_MODEL].includes(upload?.type?.slug)) {
          if (modelFileIdx === -1) {
            part.uploads.push(upload);
          } else {
            part.uploads.splice(modelFileIdx, 1, upload);
          }
        }
        if (upload.parser_metadata) {
          const modelFile = findModelFile(part);
          Vue.set(modelFile, 'parser_metadata', upload.parser_metadata);
        }
        if (preview) {
          if (previewIdx === -1) {
            part.uploads.push(preview);
          } else {
            part.uploads.splice(previewIdx, 1, preview);
          }
        }
      }
    });
  },
  [SET_MODEL_PARSE_FAILED]({ rootState }, { hash }) {
    const projectRfqs = rootState[RFQ_MODULE][RFQS];
    const draftRfqs = rootState[RFQ_MODULE][DRAFT_RFQS];
    const allRfqs = rootState[RFQ_MODULE][ALL_RFQS];

    const toUpdate = [...projectRfqs, ...draftRfqs, ...allRfqs].filter((p) => p.hash === hash);

    toUpdate.forEach((part) => {
      Vue.set(part, 'modelParseFailed', true);
    });
  },

  async [GET_MRFQ_PROJECTS]({ commit }, { page = 1 }) {
    const query = new Project().orderBy('-created_at').where('archived', false).limit(15);
    if (page) query.page(page);

    const { data, meta } = await query.get().catch((e) => e.response);

    commit(GET_MRFQ_PROJECTS_M, data);
    commit(SET_MRFQS_PAGINATION, {
      ...meta,
    });
    commit(SET_MRFQS_PAGINATION_PAGES, {
      current_page: meta.current_page,
    });

    return data;
  },

  [RFQ_CREATED_EVENT]({ dispatch }, event) {
    dispatch(`${WEBSOCKET_MODULE}/${RFQ_CREATED_EVENT}`, event, { root: true });
  },

  [RFQ_UPDATED_EVENT]({ dispatch }, event) {
    dispatch(`${WEBSOCKET_MODULE}/${RFQ_UPDATED_EVENT}`, event, { root: true });
  },
};
