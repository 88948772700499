<template>
  <div
    class="editable-card card"
    data-testid="company-information"
    @click.prevent.stop
  >
    <section
      v-show="!editing"
      class="card-content"
    >
      <p class="subtitle is-7">
        <span>Company name</span>
        <span data-testid="company_name">{{ companyData.company_name }}</span>
      </p>
      <p class="subtitle is-7">
        <span>Company phone</span>
        <span data-testid="company_phone">{{ companyData.phone_number }}</span>
      </p>
      <p class="subtitle is-7">
        <span>Company VAT</span><span data-testid="company_vat">{{ companyData.vat_number }}</span>
      </p>
      <p class="subtitle is-7">
        <span>EORI number</span><span data-testid="eori_number">{{ companyData.eori_number }}</span>
      </p>
      <p class="subtitle is-7">
        <span>Number of employees</span>
        <span data-testid="company_number_of_employees">{{ companyData.number_of_employees }}</span>
      </p>
      <p class="subtitle is-7">
        <span>Company description</span>
        <span data-testid="company_description">{{ companyData.company_description }}</span>
      </p>
    </section>
    <section
      v-show="editing"
      class="card-content"
      data-testid="company-information-edit"
    >
      <b-field
        label-position="on-border"
        label="Company name"
      >
        <b-input
          v-model="editableData.company_name"
          size="is-small"
          type="text"
          placeholder="Company name"
          data-testid="company_name_edit"
        />
      </b-field>
      <b-field
        label-position="on-border"
        label="Company phone"
      >
        <b-input
          v-model="editableData.phone_number"
          size="is-small"
          type="text"
          placeholder="Company phone"
          data-testid="company_phone_edit"
        />
      </b-field>
      <b-field
        label-position="on-border"
        label="Company VAT"
      >
        <b-input
          v-model="editableData.vat_number"
          size="is-small"
          type="text"
          placeholder="Company VAT"
          data-testid="company_vat_edit"
        />
      </b-field>
      <b-field
        label-position="on-border"
        label="EORI number"
      >
        <b-input
          v-model="editableData.eori_number"
          size="is-small"
          type="text"
          placeholder="EORI number"
          data-testid="eori_number_edit"
        />
      </b-field>
      <b-field
        label-position="on-border"
        label="Number of employees"
      >
        <b-input
          v-model="editableData.number_of_employees"
          size="is-small"
          type="text"
          placeholder="Number of employees"
          data-testid="company_number_of_employees_edit"
        />
      </b-field>
      <b-field
        label-position="on-border"
        label="Company description"
      >
        <b-input
          v-model="editableData.company_description"
          size="is-small"
          type="text"
          placeholder="Company description"
          data-testid="company_description_edit"
        />
      </b-field>
    </section>
    <footer class="card-footer">
      <a
        v-show="!editing"
        class="card-footer-item"
        data-testid="company-edit-button"
        @click.stop.prevent="startEdit"
      >Edit</a>
      <a
        v-show="editing"
        class="card-footer-item"
        data-testid="company-save-button"
        @click.stop.prevent="save"
      >Save</a>
      <a
        v-show="editing"
        class="card-footer-item"
        @click.stop.prevent="editing = false"
      >Cancel</a>
    </footer>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { GET_COMPANY_DATA, USER_DATA, USER_MODULE } from '../../store/modules/user/types';
import { UPDATE } from '../../store/modules/types';

export default {
  name: 'UserProfileCompanyInformation',
  data() {
    return {
      editing: false,
      editableData: {},
    };
  },
  computed: {
    ...mapGetters(USER_MODULE, {
      companyData: GET_COMPANY_DATA,
    }),
    ...mapState(USER_MODULE, {
      userData: USER_DATA,
    }),
  },
  methods: {
    ...mapActions(USER_MODULE, {
      updateUserData: UPDATE,
    }),
    startEdit() {
      this.editableData = JSON.parse(JSON.stringify(this.companyData));
      this.editing = true;
    },
    save() {
      try {
        const copy = JSON.parse(JSON.stringify(this.userData));
        copy.profile = { ...copy.profile, ...this.editableData };
        this.updateUserData(copy)
          .then(() => {
            this.editing = false;
          })
          .catch((e) => console.error(e));
      } catch (e) {
        console.error('incorrect user data');
      }
    },
  },
};
</script>

<style scoped>

</style>
