<script>
/* eslint-disable vue/no-reserved-keys */

import registerNotificationVuexModule, {
  _ADD_NOTIFICATION,
  _NOTIFICATION_MODULE,
  _NOTIFICATIONS, _REMOVE_NOTIFICATION,
} from './vuex-setup';

export default {
  name: 'NotificationProvider',
  provide() {
    return {
      addNotification: this.addNotification,
      removeNotification: this.removeNotification,
    };
  },
  data() {
    return {
      _notifications: [],
      _vuexConnected: false,
    };
  },
  computed: {
    notifications() {
      return (this.$store
        && this.$store.state[_NOTIFICATION_MODULE]
        && this.$store.state[_NOTIFICATION_MODULE][_NOTIFICATIONS])
        || this._notifications;
    },
  },
  created() {
    if (this.$store && !this.$store[_NOTIFICATION_MODULE]) {
      registerNotificationVuexModule(this.$store, this.generateId);
      this._vuexConnected = true;
    }
  },
  methods: {
    /**
             * @param {Object} notificationConfig
             * @param {string} notificationConfig.message
             * @param {string} notificationConfig.type
             * @param {number} notificationConfig.duration
             * @param {string} notificationConfig.icon
             * @param {string} notificationConfig.confirmText
             * @param {string} notificationConfig.cancelText
             * @param {Function} notificationConfig.onConfirm
             * @param {Function} notificationConfig.onCancel
             * @returns {Object} notification
             */
    addNotification({
      message,
      type = 'is-info',
      duration,
      indefinite,
      icon,
      confirmText,
      cancelText,
      onConfirm,
      onCancel,
      unique,
      portalName,
    }) {
      const id = this.generateId();
      const notification = {
        id,
        message,
        type,
        duration,
        indefinite,
        icon,
        confirmText,
        cancelText,
        onConfirm,
        onCancel,
        unique,
        portalName,
      };
      if (this._vuexConnected) {
        this.$store.commit(`${_NOTIFICATION_MODULE}/${_ADD_NOTIFICATION}`, notification);
      } else if (unique) {
        const present = this.notifications.find((e) => e.message === message);
        if (!present) {
          this.notifications.push(notification);
        }
      } else {
        this.notifications.push(notification);
      }
      return notification;
    },
    removeNotification(id) {
      if (this._vuexConnected) {
        this.$store.commit(`${_NOTIFICATION_MODULE}/${_REMOVE_NOTIFICATION}`, id);
      } else {
        const index = this.notifications.findIndex((e) => e.id === id);
        if (index > -1) {
          this.notifications.splice(index, 1);
        }
      }
    },
    generateId() {
      return Math.random()
        .toString(36)
        .substring(7);
    },
  },
  render() {
    return this.$scopedSlots.default({
      notifications: this.notifications,
    });
  },
};
</script>
