import { mapState, mapGetters } from 'vuex';
import { RFQ_MODULE, REVISE_REQUOTE_MODAL_RFQS } from '@/app-buyer/store/modules/rfq/types';
import { REFERENCE_MODULE, REFERENCE_DATA_OBJECT_BY_ID } from '@/app-buyer/store/modules/reference-data/types';

const createConfigurationObject = {
  computed: {
    ...mapState(RFQ_MODULE, {
      REVISE_REQUOTE_MODAL_RFQS,
    }),
    ...mapGetters(REFERENCE_MODULE, {
      REFERENCE_DATA_OBJECT_BY_ID,
    }),
  },
  methods: {
    createConfig({ hash, properties }) {
      const originalConfig = this[REVISE_REQUOTE_MODAL_RFQS]?.rfqs.filter((rfq) => rfq.hash === hash);

      let updatedConfigurationObject = {
        ...originalConfig[0].configuration_object,
      };

      for (const property in properties) {
        const configProperties = this[REFERENCE_DATA_OBJECT_BY_ID][properties[property]];

        if (configProperties && property !== 'quantity_initial' && property !== 'quantity_production') {
          updatedConfigurationObject = {
            ...updatedConfigurationObject,
            [property]: {
              id: configProperties?.id,
              slug: configProperties?.slug,
              name: configProperties?.name,
              entity_name: configProperties?.entity_name,
              string_value: configProperties?.string_value,
            },
          };
        }
      }

      return updatedConfigurationObject;
    },
  },
};

export default createConfigurationObject;
