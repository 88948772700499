<template>
  <Tippy
    v-if="show"
    :visible="show"
    :enabled="show"
    trigger="manual"
    interactive
    :hide-on-click="false"
    arrow
  >
    <div
      v-if="show"
      class="p-2 has-text-left"
    >
      Are you sure you would like to apply
      <b style="white-space: nowrap;">{{ confirm.property }}</b>
      to
      <a
        style="white-space: nowrap; text-decoration: underline;"
        class="has-text-white"
        @mouseenter="highlight(confirm.ids)"
        @mouseleave="highlight(null)"
      >{{ confirm.count }} parts</a>
      ?
      <div class="is-flex is-align-items-center is-justify-space-between mt-4">
        <GmButton
          size="small"
          type="info"
          rounded
          outlined
          @click="handleClick('cancel')"
        >
          Cancel
        </GmButton>
        <GmButton
          size="small"
          type="info"
          rounded
          @click="handleClick('confirm')"
        >
          Apply to selected to parts ({{ confirm.count }})
        </GmButton>
      </div>
    </div>
  </Tippy>
</template>
<script>
export default {
  name: 'ConfirmApplicableTooltip',

  inject: {
    setHighlightedParts: {
      default: null,
    },
  },

  props: {
    confirmApplicable: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      canOpen: false,
      confirm: null,
    };
  },

  computed: {
    show() {
      return this.canOpen;
    },
  },

  watch: {
    confirmApplicable(nv) {
      if (nv) {
        this.canOpen = true;
        this.confirm = this.confirmApplicable;
      }
    },
  },

  methods: {
    highlight(ids) {
      if (this.setHighlightedParts) {
        this.setHighlightedParts(ids);
      }
    },
    handleClick(emit) {
      this.$emit(emit, { canApply: this.confirm.canApply });
      this.canOpen = false;
      this.confirm = null;
    },
  },
};
</script>
