/* eslint-disable camelcase */
import { GET_ACCESS_TOKEN, SET_ACCESS_TOKEN } from './types';
import Api from '../../../api/api';
import ENDPOINTS from '../../../api/endpoints';

export default {
  /**
     * Get the access token to use for autodesk calls
     *
     * @param {Object} context
     * @returns {Promise<string>} access_token
     */
  async [GET_ACCESS_TOKEN]({ commit }) {
    const { data: { access_token } } = await Api.get(ENDPOINTS.AUTODESK.TOKEN).catch((e) => e);
    commit(SET_ACCESS_TOKEN, access_token);
    return access_token;
  },
};
