<template>
  <b-radio
    v-bind="$attrs"
    :value="value"
    :native-value="nativeValue"
    class="m-x-xs"
    v-on="$listeners"
    @input="update"
  >
    <slot />{{ showName ? properties[0] && `(${properties[0].name})` : "" }}
  </b-radio>
</template>
<script>
import { formInput } from '@/app-buyer/mixins/form-input';

export default {
  name: 'ConfiguratorElementRadio',
  mixins: [formInput],
  props: {
    showName: {
      type: Boolean,
      default: () => false,
    },
  },
  computed: {
    trueValue() {
      return this.properties.find((e) => e.boolean_value)?.id;
    },
    falseValue() {
      return this.properties.find((e) => e.boolean_value === false)?.id;
    },
  },
};
</script>
