<template>
  <header class="is-flex is-align-items-center is-justify-space-between pt-6 mx-6 mb-4">
    <template v-if="!!ACTIVE_PROJECT">
      <ProjectContentHeaderTitle />
      <ProjectMembers with-invite />
    </template>
  </header>
</template>

<script>
import { mapGetters } from 'vuex';
import ProjectContentHeaderTitle from '@/app-buyer/components/project/ProjectContentHeaderTitle.vue';
import ProjectMembers from '@/app-buyer/components/project/ProjectMembers.vue';
import { ACTIVE_PROJECT, PROJECT_MODULE } from '@/app-buyer/store/modules/projects/types';

export default {
  name: 'ProjectContentHeader',
  components: { ProjectMembers, ProjectContentHeaderTitle },
  computed: {
    ...mapGetters(PROJECT_MODULE, {
      ACTIVE_PROJECT,
    }),
  },
};
</script>

<style lang="scss" scoped>
.pt-6 {
  @media all and (max-width: 1500px) {
    padding-top: 1.5rem !important;
  }
}
.mx-6 {
  @media all and (max-width: 1500px) {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }
}
</style>
