<template>
  <div
    :class="hasMultipleQuotes ? 'mt-5' : 'my-5'"
    class="header-wrapper"
  >
    <div class="header-grid pl-2 mx-1">
      <p
        class="has-text-weight-bold header-grid-project whitespace-nowrap is-size-6"
      >
        {{ projectsString }} |
        {{ getRfqCount() }}
        RFQs
        <span class="is-hidden-touch">|</span>
      </p>
      <div class="header-grid-project-members">
        <ProjectMembers
          v-for="project in projects"
          :key="project.hash"
          :max="7"
          :project="project"
          with-invite
          @update:project="$emit('update:project', $event)"
          @click.stop
        />
      </div>
      <div class="header-grid-updated-at">
        <p>Last Updated: {{ listing.updated_at | formatDate('YYYY/MM/DD - h:mm a') }}</p>
      </div>
      <div class="header-grid-cart-button">
        <tippy
          :enabled="showOtherVendorQuotesModal"
          :visible="showOtherVendorQuotesModal"
          arrow
          interactive
          trigger="manual"
          @hidden="showOtherVendorQuotesModal = false"
        >
          <div class="p-4">
            <QuoteConfirmPrompt
              v-if="showOtherVendorQuotesModal"
              :loading="addingAllToCart"
              has-other-vendor-quotes
              @confirm="handleAddAllToCart(true)"
            />
          </div>
          <BButton
            v-if="!allPartsInCart"
            slot="trigger"
            :disabled="addingAllToCart || !quotesThatCanBeAddedToCart.length"
            :loading="addingAllToCart"
            class="px-4 has-text-weight-bold"
            expanded
            icon-left="cart-plus"
            icon-pack="fal"
            size="is-small"
            style="white-space: nowrap;"
            type="is-info"
            @click="attemptAddAllToCart"
          >
            Add all to cart
          </BButton>
        </tippy>
        <BButton
          v-if="allPartsInCart"
          outlined
          size="is-small"
          type="is-danger"
          data-testid="remove_all_from_cart_button"
          :loading="removingAllFromCart"
          @click="handleRemoveAllFromCart()"
        >
          Remove all from cart
        </BButton>
      </div>
      <div class="header-grid-options-button">
        <QuoteListingOptions
          :listing="listing"
          :has-load-more="hasLoadMore"
          :has-been-loaded="hasBeenLoaded"
          class="is-flex is-justify-content-flex-end"
          @load-more="$emit('load-more', $event)"
          @canceled="$emit('cancelled')"
        />
      </div>
    </div>
    <div
      v-if="hasMultipleQuotes"
      class="is-flex px-4 mt-5"
      style="z-index: 1; margin-bottom: -1px;"
    >
      <GroupedQuoteHeaderButton
        v-for="(group, index) in quoteGroups"
        :key="group.id"
        :active="index === active"
        :all-rfqs-length="listing.rfqs.length"
        :data="group"
        :index="index"
        @click="handleGroupClick(index)"
      />
    </div>
    <div class="has-background-white">
      <GroupedQuoteHeaderMobile
        v-if="activeGroup && hasMultipleQuotes"
        :data="activeGroup"
        :index="active"
        :quotes-that-can-be-added-to-cart="quotesThatCanBeAddedToCart"
        @add-all-to-cart="addAllToCart"
      />
    </div>
    <BModal
      :active.sync="showConfirmNotesModal"
      has-modal-card
    >
      <ConfirmNotesModal
        :quotes="simpleQuoteData"
        @close="showConfirmNotesModal = false"
        @accept-all-notes="addAllToCart"
      />
    </BModal>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { AUTH_MODULE, MASQUERADING } from '@/app-buyer/store/modules/auth/types';
import GroupedQuoteHeaderButton
  from '@/app-buyer/components/grouped-quotes/GroupedQuoteHeaderButton.vue';
import GroupedQuoteHeaderMobile
  from '@/app-buyer/components/grouped-quotes/GroupedQuoteHeaderMobile.vue';
import ProjectMembers from '@/app-buyer/components/project/ProjectMembers.vue';
import QuoteListingOptions from '@/app-buyer/components/grouped-quotes/QuoteListingOptions.vue';
import QuoteConfirmPrompt from '@/app-buyer/components/grouped-quotes/QuoteConfirmPrompt.vue';
import addRemoveQuoteCart from '@/app-buyer/mixins/add-remove-quote-cart';
import ConfirmNotesModal from '@/app-buyer/components/grouped-quotes/ConfirmNotesModal.vue';
import { GET_ALL_QUOTE_RFQS, QUOTES_MODULE } from '@/app-buyer/store/modules/quotes/types';
import getEnvironmentVariable from '@/shared/misc/env-variable';

export default {
  components: {
    GroupedQuoteHeaderButton,
    GroupedQuoteHeaderMobile,
    ProjectMembers,
    QuoteListingOptions,
    QuoteConfirmPrompt,
    ConfirmNotesModal,
  },

  mixins: [
    addRemoveQuoteCart,
  ],

  props: {
    listing: {
      type: Object,
      required: true,
    },
    hasLoadMore: {
      type: Boolean,
      default: false,
    },
    hasBeenLoaded: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      active: 0,
      openDetailed: [],
      addingAllToCart: false,
      removingAllFromCart: false,
      selected: null,
      showOtherVendorQuotesModal: false,
      loadingRfqs: {},
      refreshKey: 0,
      showConfirmNotesModal: false,
      simpleQuoteData: [],
      projectsString: '',
      projects: [],
      rfqCount: null,
    };
  },

  computed: {
    ...mapState(AUTH_MODULE, {
      MASQUERADING,
    }),

    activeGroup() {
      return this.quoteGroups[this.active];
    },
    earliestPartInGroup() {
      return this.listing.rfqs.reduce((earliestDate, rfq) => {
        if (!earliestDate) return rfq.created_at;
        const e = new Date(earliestDate);
        const c = new Date(rfq.created_at);
        return e > c ? rfq.created_at : earliestDate;
      }, null);
    },

    /**
     * Whether any single RFQ has quotes from more than one vendor.
     * @returns {boolean}
     */
    hasMultipleQuotes() {
      return this.listing.rfqs.some((rfq) => rfq.quotes.length > 1);
    },

    hasQuotesInCartFromOtherGroup() {
      return this.quoteGroups.some((group, index) => index !== this.active
        && group.quotes.some((quote) => !!quote.cart_item));
    },

    hasNotesOnPartFromGroup() {
      if (this.activeGroup) return this.activeGroup?.quotes.some((quote) => quote?.notes?.length);
      let notes = false;

      this.listing?.rfqs.forEach((rfq) => {
        const note = rfq.quotes.some((quote) => quote?.notes?.length);
        if (note) notes = true;
      });

      return notes;
    },

    quoteGroupIdsInCartFromOtherGroups() {
      return this.quoteGroups.filter((group, index) => index !== this.active
          && group.quotes.some((quote) => !!quote.cart_item))?.map((group) => group.id)
        || [];
    },
    allPartsInCart() {
      if (this.hasMultipleQuotes && this.quoteGroups?.length > 1) {
        return this.activeGroup?.quotes.every((quote) => !!quote.cart_item);
      }
      if (!this.hasMultipleQuotes && this.quoteGroups?.length === 1) {
        return this.quoteGroups[0].quotes.every((quote) => !!quote.cart_item);
      }
      if (!this.hasMultipleQuotes && this.quoteGroups?.length > 1) {
        return this.quoteGroups.every((quoteGroup) => quoteGroup.quotes.every((quote) => !!quote.cart_item));
      }
      return false;
    },
    activeGroupQuoteIdsInCart() {
      const quotesInCart = this.activeGroup?.quotes.filter((quote) => !!quote.cart_item)
        || (this.quoteGroups.length === 1
          && this.quoteGroups[0].quotes.filter((quote) => !!quote.cart_item));
      return quotesInCart?.map((quote) => quote.id);
    },
    openDetailedAsObject() {
      return Object.fromEntries(this.openDetailed.map((id) => [id, true]));
    },

    quoteGroups() {
      let quoteGroups = this.listing?.quoteGroups.map((group) => ({ ...group, quotes: [] }));
      // Nest each RFQ quote under their respective quote group
      this.listing.rfqs.forEach((rfq) => {
        rfq?.quotes?.forEach((quote) => {
          const group = quoteGroups.find((e) => e.id === quote.quote_group_id);
          if (group) {
            group.quotes?.push(quote);
          }
        });
      });
      quoteGroups = quoteGroups?.filter((g) => g?.quotes?.length);
      // If recommended flairs are present, set them as the first items in the quoteGroup
      const recommendedQuotes = [];
      const remainingQuotes = [];

      quoteGroups.forEach((rec) => {
        if (rec.flair === 'recommended') {
          recommendedQuotes.push(rec);
        } else {
          remainingQuotes.push(rec);
        }
      });

      quoteGroups = [...recommendedQuotes, ...remainingQuotes];
      return quoteGroups;
    },

    quotesThatCanBeAddedToCart() {
      if (!this.hasMultipleQuotes) {
        return this.quoteGroups.flatMap((group) => group.quotes);
      }

      return (
        this.activeGroup?.quotes?.filter((quote) => !quote.cart_item)
      ) || [];
    },
  },

  mounted() {
    this.handleProjectData();
  },

  methods: {
    ...mapActions(QUOTES_MODULE, {
      GET_ALL_QUOTE_RFQS,
    }),

    getRfqCount() {
      return this.listing.active_rfq_count;
    },

    removeAllCartItems(quoteIds) {
      console.log('quoteIds');
      this.listing.rfqs.forEach((rfq) => {
        rfq.quotes.forEach((quote) => {
          if (quoteIds.includes(quote.id)) {
            this.$set(quote, 'cart_item', null);
          }
        });
      });
    },

    createSimpleQuoteRow(arr) {
      let simpleQuotes = [];

      arr.forEach((quote, i) => {
        const rfq = this.listing.rfqs[i];

        const simpleQuoteRow = {
          notes: quote.notes,
          manufacturing_time: quote.delivery.range,
          name: rfq.name,
          ref: rfq.ref,
          quantity_initial: rfq.quantity_initial,
          quantity_production: rfq.quantity_production,
          material: rfq.configuration_object.material.name,
          service: rfq.configuration_object.service.name,
          uploads: rfq.uploads,
        };

        simpleQuotes = [...simpleQuotes, simpleQuoteRow];
      });

      return simpleQuotes;
    },

    attemptAddAllToCart() {
      if (this.hasQuotesInCartFromOtherGroup && this.hasMultipleQuotes) {
        this.showOtherVendorQuotesModal = true;
      } else if (this.hasNotesOnPartFromGroup) {
        if (this.activeGroup) {
          this.simpleQuoteData = this.createSimpleQuoteRow(this.activeGroup.quotes);
        } else {
          let quotes = [];
          this.listing.rfqs.forEach((rfq) => {
            quotes = [...quotes, ...rfq.quotes];
          });
          this.simpleQuoteData = this.createSimpleQuoteRow(quotes);
        }

        this.showConfirmNotesModal = true;
      } else {
        this.addAllToCart();
      }
    },

    async handleRemoveAllFromCart() {
      this.removingAllFromCart = true;
      let quoteIds;

      if (this.quoteGroupIdsInCartFromOtherGroups.length > 0 && this.hasMultipleQuotes) {
        quoteIds = await this.removeQuotesFromCartByQuoteGroupIds(this.quoteGroupIdsInCartFromOtherGroups);
      } else if (this.quoteGroupIdsInCartFromOtherGroups.length > 0) {
        quoteIds = await this.removeQuotesFromCartByQuoteGroupIds([...this.quoteGroupIdsInCartFromOtherGroups, this.activeGroup.id]);
      } else {
        quoteIds = await this.removeQuotesFromCartByQuoteGroupIds([this.activeGroup.id]);
      }

      this.removeAllCartItems(quoteIds);
      this.removingAllFromCart = false;
    },

    async handleAddAllToCart(removeQuotesInCart = false) {
      this.addingAllToCart = true;
      if (removeQuotesInCart) {
        await this.handleRemoveAllFromCart();
      }
      await this.addAllToCart();
      this.addingAllToCart = false;
      this.showOtherVendorQuotesModal = false;
    },

    async addAllToCart() {
      this.showConfirmNotesModal = false;
      this.addingAllToCart = true;

      let quoteIds = [];
      let canAdd = [];

      if (this.hasLoadMore) {
        const { data } = await this[GET_ALL_QUOTE_RFQS]({ hash: this.listing.id });
        quoteIds = data[0].rfqs
          .map((rfq) => rfq.quotes
            .filter((quote) => (this.hasMultipleQuotes ? quote.quote_group_id === this.activeGroup.id : true))).flat()
          .map((quote) => quote.id);
      } else quoteIds = this.quotesThatCanBeAddedToCart.map((quote) => quote.id);

      canAdd = quoteIds.filter((id) => !this.activeGroupQuoteIdsInCart.includes(id));
      let response;

      try {
        response = await this.addMultipleQuotesToCart(canAdd);
      } catch (e) {
        this.$buefy.toast.open({
          message: 'Could not add parts to cart',
          type: 'is-danger',
        });
      }

      response?.data?.data?.forEach((cartItem) => {
        const quote = this.quotesThatCanBeAddedToCart.find((q) => q.id === cartItem.quote_proposal_id);
        if (quote) {
          this.$set(quote, 'cart_item', cartItem);
        }
        // SEGMENT TRACKING
        if (getEnvironmentVariable('VUE_APP_SEGMENT_ENABLED')) {
          window.analytics.track('Cart added', {
            isMasquerading: !!this[MASQUERADING],
            currency: 'GBP',
            value: cartItem.total_inc_vat,
            content_ids: cartItem?.quote?.product_lifecycle_id,
            content_type: 'product',
          });
        }
      });

      this.addingAllToCart = false;
    },

    handleGroupClick(index) {
      this.active = index;
      this.$emit('update-active-quote-group', { listingId: this.listing.id, index });
    },

    handleProjectData() {
      this.projects = this.listing.rfqs.reduce((projects, rfq) => {
        if (projects.find((p) => p.hash === rfq.project_hash)) return projects;
        return [
          ...projects,
          {
            hash: rfq.project_hash,
            name: rfq.project_name,
            members: rfq.project_members,
          },
        ];
      }, []);

      const csl = this.projects.map((p) => p.name).join(', ');
      this.projectsString = `Project${this.projects.length > 1 ? 's' : ''}: ${csl}`;
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
.header-grid {
  align-items: center;
  display: grid;
  grid-gap: 0.7rem;
  grid-template-areas: "project members date cartbutton options";
  grid-template-columns: min-content 1fr auto auto auto;
  justify-content: space-between;
  padding-right: 0.3rem;

  &-project {
    grid-area: project;
  }

  &-project-members {
    grid-area: members;
  }

  &-updated-at {
    grid-area: date;
  }

  &-cart-button {
    grid-area: cartbutton;
  }

  &-options-button {
    grid-area: options;
    width: 2.8rem;
  }

  @media all and (max-width: 1023px) {
    display: grid;
    grid-template-areas:
      "project options"
      "members members"
      "date date"
      "cartbutton cartbutton";
    grid-template-columns: auto min-content;
    margin-bottom: .5rem;
    .header-grid-options-button {
      width: inherit;
    }
  }
}

.group-well {
  background-color: rgb(250, 250, 250);
  border-top: 1px solid #dbdbdb;
  box-shadow: inset 0 5px 5px -5px rgb(0 0 0 / 10%),
  inset 0 -5px 5px -5px rgb(0 0 0 / 10%);
}

.px-6 {
  @media all and (max-width: variables.$breakpoint-lg) {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  @media all and (max-width: variables.$breakpoint-md) {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

@keyframes loading {
  to {
    background-size: 100% 5px;
  }
}

tr.is-loading-file {
  animation: loading 60s ease-in-out;
  background-image: linear-gradient(to right, variables.$info 0%, variables.$info 100%) !important;
  background-position: 0 100%;
  background-repeat: no-repeat;
  background-size: 0 5px;
  box-shadow: inset 0 -5px 0 0 rgba(variables.$info, .25);
  opacity: .75;
  transition: background-size 0.8s cubic-bezier(0.19, 1, 0.155, 1.01) 0s;
}
</style>
