<template>
  <div data-testid="registration-details" class="card-wrapper">
    <p class="title is-7">
      Registration details
    </p>
    <div class="editable-card card">
      <div data-testid="registration-details-content" class="card-content">
        <p
          v-for="field in fields"
          :key="field.label"
          class="subtitle is-7"
          :data-testid="field.testId"
        >
          <span>{{ field.label }}</span>
          <span :data-testid="field.value">{{ Array.isArray(field.string) ? field.string.join(', ') : field.string }}</span>
        </p>
      </div>
      <footer class="card-footer">
        <a
          class="card-footer-item"
          @click="showModal = true"
          data-testid="registration-details-open-modal"
        >Edit</a>
      </footer>
    </div>
    <BModal
      :active.sync="showModal"
      has-modal-card
    >
      <div class="modal-card">
        <div class="modal-card-body">
          <RegistrationDetails
            :is-profile-edit="true"
            @close="showModal = false"
          />
        </div>
      </div>
    </BModal>
  </div>
</template>

<script>

import { mapState } from 'vuex';
import RegistrationDetails from '@/app-buyer/components/registration/RegistrationDetails.vue';
import { USER_DATA, USER_MODULE } from '@/app-buyer/store/modules/user/types';
import INDUSTRY_SEGMENTS from '@/app-buyer/components/registration/industry-segments.json';
import JOB_TITLES from '@/app-buyer/components/registration/job-titles.json';

export default {
  name: 'UserProfileRegistrationDetails',
  components: { RegistrationDetails },
  data() {
    return {
      showModal: false,
      INDUSTRY_SEGMENTS,
      JOB_TITLES,
    };
  },
  computed: {
    ...mapState(USER_MODULE, {
      USER_DATA,
    }),
    fields() {
      const profileFields = this[USER_DATA]?.buyer_profile_fields;
      if (profileFields) {
        const industry = {
          label: 'Industry segment',
          testId: 'industry_segments',
          string: INDUSTRY_SEGMENTS[profileFields.find((pf) => pf.field === 'industry_segments')?.values?.[0]],
          value: profileFields.find((pf) => pf.field === 'industry_segments')?.values?.[0],
        };
        const services = {
          label: 'Services',
          testId: 'demand_specific_services',
          string: profileFields.find((pf) => pf.field === 'demand_specific_services')?.values.map((service) => {
            switch (service) {
              case 'cnc':
                return 'CNC machining';
              case 'injection':
                return 'Injection moulding';
              case 'sheet_metal':
                return 'Sheet metal';
              case 'three_d':
                return '3D Printing';
              default:
                return null;
            }
          }).filter((e) => e) || [],
          value: profileFields.find((pf) => pf.field === 'demand_specific_services')?.values?.[0],
        };
        const title = {
          label: 'Job title',
          testId: 'title',
          string: JOB_TITLES[profileFields.find((pf) => pf.field === 'title')?.values?.[0]],
          value: profileFields.find((pf) => pf.field === 'title')?.values?.[0],
        };

        return {
          industry,
          services,
          title,
        };
      }
      return {};
    },
  },
};

</script>
