<template>
  <portal to="modal">
    <b-modal
      :active="isVisible"
      has-modal-card
      :can-cancel="canCancel"
      @close="() => setModal({visible: false})"
    >
      <section class="card">
        <div class="card-content">
          <b-tabs
            v-model="activeTab"
            @input="tabClicked"
          >
            <b-tab-item
              label="Login"
              data-testid="login-mode"
            >
              <login-form />
            </b-tab-item>
            <b-tab-item
              label="Sign up"
              data-testid="registration-mode"
            >
              <registration-form />
            </b-tab-item>
          </b-tabs>
        </div>
      </section>
    </b-modal>
  </portal>
</template>

<script>
import {
  mapActions, mapGetters, mapMutations, mapState,
} from 'vuex';
import {
  FORCE,
  GET_LOGIN_MODAL_MODE,
  IS_LOGIN_MODAL_VISIBLE, NAVIGATION_MODULE,
  SET_LOGIN_MODAL,
} from '../../store/modules/navigation/types';
import { AUTH_MODULE, LOGIN, REGISTER } from '../../store/modules/auth/types';
import LoginForm from './login-form.vue';
import RegistrationForm from './registration-form.vue';

export default {
  name: 'LoginRegistration',
  components: {
    RegistrationForm,
    LoginForm,
  },
  data() {
    return {
      activeTab: 0,
    };
  },
  computed: {
    ...mapState(NAVIGATION_MODULE, {
      forced: FORCE,
    }),
    ...mapGetters(NAVIGATION_MODULE, {
      isVisible: IS_LOGIN_MODAL_VISIBLE,
      mode: GET_LOGIN_MODAL_MODE,
    }),
    canCancel() {
      return this.forced ? [] : ['escape', 'x', 'outside', 'button'];
    },
  },
  watch: {
    mode: {
      handler(newVal) {
        this.activeTab = newVal === 'login' ? 0 : 1;
      },
      immediate: true,
    },
  },
  methods: {
    ...mapMutations(NAVIGATION_MODULE, {
      setModal: SET_LOGIN_MODAL,
    }),
    ...mapActions(AUTH_MODULE, {
      login: LOGIN,
      register: REGISTER,
    }),
    tabClicked() {
      this.setModal({
        visible: true,
        mode: (this.activeTab ? 'register' : 'login'),
        force: this.forced,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
  .card {
    max-width: 100%;
    width: 600px;
    margin: auto;

    .card-content {
      padding: 0.5rem;
    }
  }
</style>
