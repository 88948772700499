<template>
  <div>
    <ProjectMembers
      v-for="project in projects"
      :key="project.hash"
      :project="project"
      with-invite
    />
  </div>
</template>
<script>
import { mapState } from 'vuex';
import ProjectMembers from '@/app-buyer/components/project/ProjectMembers.vue';
import { PROJECT_MODULE, PROJECTS } from '@/app-buyer/store/modules/projects/types';

export default {
  name: 'InvoiceTableProjectMembers',
  components: { ProjectMembers },
  props: {
    invoice: {
      type: Object,
      required: true,
    },
  },
  computed: {

    ...mapState(PROJECT_MODULE, {
      PROJECTS,
    }),

    projects() {
      const allProjectHashes = this.invoice?.last_5_rfqs?.map((rfq) => rfq.project_hash);
      return Array.from(new Set(allProjectHashes || []))
        .map((hash) => this[PROJECTS].find((p) => p.hash === hash)).filter(Boolean);
    },
  },
};
</script>
