<template>
  <BField>
    <BInput
      v-model="innerValue"
      icon-right="search"
      :placeholder="placeholder"
      v-bind="$attrs"
      data-testid="search-input"
      @input="debounceUpdate"
    />
  </BField>
</template>
<script>
import { debounce } from 'lodash/function';

export default {
  name: 'DebounceInput',
  inheritAttrs: false,

  props: {
    value: {
      type: String,
      default: null,
    },
    debounceTime: {
      type: Number,
      default: 500,
    },
    placeholder: {
      type: String,
      default: 'Search for a part/quote',
    },
  },

  data() {
    return {
      innerValue: null,
    };
  },

  computed: {
    debounceUpdate() {
      return debounce((val) => {
        this.$emit('input', val);
      }, this.debounceTime);
    },
  },

  watch: {
    value: {
      handler(val) {
        this.innerValue = val;
      },
      immediate: true,
    },
  },
};
</script>
