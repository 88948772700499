import {
  MODEL, PARSER, PDF_PATH, UUID, VISIBLE,
} from './types';

export default function getState() {
  return {
    [MODEL]: null,
    [PARSER]: null,
    [UUID]: null,
    [PDF_PATH]: null,
    [VISIBLE]: false,
  };
}
