<template>
  <div>
    <input
      v-show="false"
      ref="fileInput"
      type="file"
      @input="updateModelFile"
    >
    <BDropdown
      aria-role="list"
      data-testid="part-options-trigger"
      position="is-bottom-left"
      append-to-body
    >
      <template #trigger>
        <BButton
          class="option-button"
          icon-left="ellipsis-h"
          size="is-small"
        />
      </template>
      <BDropdownItem
        v-for="item in options"
        :key="cleanString(item.label)"
        :has-link="['a', 'RouterLink'].includes(item.component)"
        aria-role="listitem"
      >
        <Component
          :is="item.component || 'div'"
          :class="cleanString(item.label)"
          class="is-flex is-flex-align-centered py-2"
          v-bind="item.bind"
          v-on="item.on"
        >
          <div
            class="mr-3"
            style="flex: none; width: 1rem; margin-bottom: -1px;"
          >
            <BIcon
              :icon="item.icon"
              pack="fal"
            />
          </div>
          <p class="has-text-left is-flex-grow-1">
            {{ item.label }}
          </p>
        </Component>
      </BDropdownItem>
    </BDropdown>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex';
import {
  RFQ_MODULE, SET_VIEWED, UPDATE_DRAFT, BATCH_DELETE,
} from '@/app-buyer/store/modules/rfq/types';
import { PDF } from '@/shared/consts/slugs';
import { viewPDF } from '@/app-buyer/components/project/mixins';
import { findModelFile } from '@/app-buyer/components/project/helpers';
import { AUTH_MODULE, MASQUERADING } from '@/app-buyer/store/modules/auth/types';
import getEnvironmentVariable from '@/shared/misc/env-variable';

export default {
  name: 'PartOptions',

  mixins: [viewPDF],

  props: {
    part: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      options: [
        {
          icon: 'edit',
          label: 'Upload revision',
          on: {
            click: () => {
              if (this.$refs.fileInput) this.$refs.fileInput.click();
            },
          },
        },

        {
          icon: 'eye',
          label: 'View file',
          on: {
            click: () => {
              this.view();
            },
          },
        },

        // Can be added back when implemented on BE
        // {
        //   icon: 'clone',
        //   label: 'Duplicate file',
        //   on: {
        //     click: () => {
        //       console.warn('NEEDS TO BE IMPLEMENTED');
        //     },
        //   },
        // },

        {
          icon: 'trash',
          label: 'Remove file',
          on: {
            click: () => {
              this.$buefy.dialog.confirm({
                message: 'Are you sure you want to <b>remove</b> this part?',
                confirmText: 'Remove',
                onConfirm: () => this[BATCH_DELETE]([this.part]),
                type: 'is-danger',
              });
            },
          },
        },
      ],
    };
  },

  computed: {
    ...mapState(AUTH_MODULE, {
      MASQUERADING,
    }),
    modelFile() {
      return findModelFile(this.part);
    },
  },

  methods: {
    ...mapActions(RFQ_MODULE, {
      UPDATE_DRAFT,
      BATCH_DELETE,
    }),

    ...mapMutations(RFQ_MODULE, {
      SET_VIEWED,
    }),

    view() {
      if (!this.modelFile) return;
      if (this.modelFile.extension === PDF) {
        this.viewPDF(this.modelFile);
      } else {
        this[SET_VIEWED](this.part);
      }
    },

    async updateModelFile(event) {
      const modelFile = event?.target?.files?.[0];
      if (!modelFile) return;
      const properties = { ...this.part.configuration };
      properties['file-type'] = this.part?.configuration?.['file-type'];
      if (properties['file-type']) {
        properties.project_hash = this.part.project_hash;
        properties.name = modelFile.name;
        await this[UPDATE_DRAFT]({
          draft: this.part,
          properties,
          files: {
            modelFile,
          },
          immediate: true,
        });

        // SEGMENT TRACKING
        if (getEnvironmentVariable('VUE_APP_SEGMENT_ENABLED')) {
          window.analytics.track('Model file modified', {
            isMasquerading: !!this[MASQUERADING],
            projectHash: this.part.project_hash,
            draftRfq: {
              name: modelFile.name,
              draftHash: this.part.hash,
            },
          });
        }
      }
    },
    cleanString(str) {
      return str.trim().toLowerCase().replaceAll(' ', '-');
    },
  },
};
</script>

<style scoped>
.option-button {
  width: 2rem;
  height: 2rem;
  color: black !important;
}
</style>
