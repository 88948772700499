<template>
  <div>
    <tr class="is-flex">
      <td
        colspan="9"
        style="padding-top: 2rem !important;"
      >
        <div class="is-flex">
          <BCheckbox
            v-show="group.parts.length"
            :key="renderKey"
            v-model="areAllInGroupSelected"
            :indeterminate="areSomeInGroupSelected"
            class="has-text-weight-bold mr-3"
          >
            {{ group.name.toUpperCase() }} Files
          </BCheckbox>
          <div
            v-if="group.name.toLowerCase() === 'pdf'"
            class="is-flex"
          >
            <BIcon
              type="is-info"
              size="is-small"
              icon="info-circle"
              class="mr-1"
            />
            <p class="has-text-info">
              {{ pdfMessage }}
            </p>
          </div>
        </div>
      </td>
    </tr>
    <template v-for="part in group.parts">
      <PartTableGroupRow
        :key="part.hash"
        :is-open.sync="openedParts[part.hash]"
        :part="part"
        @shift-select="$emit('shift-select', part)"
      />
      <PartTableGroupRowForm
        v-show="openedParts[part.hash]"
        :key="`form${part.hash}`"
        :is-open="openedParts[part.hash]"
        :part="part"
      />
    </template>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import PartTableGroupRow from '@/app-buyer/components/project/PartTableGroupRow.vue';
import { RFQ_MODULE, SELECTED_PART_HASHES } from '@/app-buyer/store/modules/rfq/types';
import { partSelectMixin } from '@/app-buyer/components/project/mixins';
import PartTableGroupRowForm from '@/app-buyer/components/project/PartTableGroupRowForm.vue';

export default {
  name: 'PartTableGroup',
  components: {
    PartTableGroupRowForm,
    PartTableGroupRow,
  },
  mixins: [partSelectMixin],
  props: {
    group: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      renderKey: 0,
      openedParts: {},
      pdfMessage: 'We can quote quicker, more accurately, and manufacture even faster with a 3D part file than with a PDF',
    };
  },
  computed: {
    ...mapState(RFQ_MODULE, {
      SELECTED_PART_HASHES,
    }),
    partHashesInGroup() {
      return this.group.parts.map((part) => part.hash);
    },
    areSomeInGroupSelected() {
      return !this.areAllInGroupSelected
        && this.group.parts.some((part) => this[SELECTED_PART_HASHES].includes(part.hash));
    },
    areAllInGroupSelected: {
      get() {
        return this.group.parts.every((part) => this[SELECTED_PART_HASHES].includes(part.hash));
      },
      set(value) {
        const { areSomeInGroupSelected } = this;
        const newHashes = (value && !areSomeInGroupSelected)
          ? [...this[SELECTED_PART_HASHES], ...this.partHashesInGroup]
          : this[SELECTED_PART_HASHES].filter((hash) => !this.partHashesInGroup.includes(hash));
        this.selectParts(...newHashes);
        if (areSomeInGroupSelected) {
          this.$nextTick(() => this.renderKey++);
        }
      },
    },
  },
  watch: {
    group: {
      handler(newVal) {
        newVal.parts.forEach((part) => {
          if (!Object.prototype.hasOwnProperty.call(this.openedParts, part.hash)) {
            this.openedParts = {
              ...this.openedParts,
              [part.hash]: false,
            };
          }
        });
      },
      immediate: true,
    },
  },
};
</script>
