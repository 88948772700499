import {
  ERROR,
} from '@/shared/consts/slugs';
import { getParserErrorMessage } from '@/app-buyer/mixins/check-uploads';
import RfqFileUpload from '@/app-buyer/components/grouped-quotes/RfqFileUpload.vue';
import { findModelFile } from '@/app-buyer/components/project/helpers';

const missingModelFile = (rfq, modelFile) => {
  if (modelFile == null) {
    return {
      message: 'Model file is missing, please upload a new file.',
      type: ERROR,
      component: RfqFileUpload,
      props: {
        part: rfq,
      },
    };
  }
  return false;
};

const hasParserIssue = (rfq, modelFile) => {
  if (modelFile?.parser_metadata?.failed_at) {
    return {
      message: getParserErrorMessage(modelFile.parser_metadata.status),
      type: ERROR,
      component: RfqFileUpload,
      props: {
        part: rfq,
      },
    };
  }
  return false;
};

const getRfqIssues = (rfq) => {
  const modelFile = findModelFile(rfq);

  return [
    hasParserIssue(rfq, modelFile),
    missingModelFile(rfq, modelFile),
  ];
};

export default getRfqIssues;
