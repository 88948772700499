<template>
  <BModal
    :active="!!part"
    has-modal-card
    width="90vw"
    :can-cancel="(
      showFileUpload ||
      !canBeConfigured3D ||
      hasSupportingFiles
    ) ? ['escape', 'x', 'outside', 'button'] : []"
    @close="SET_VIEWED(null)"
  >
    <div
      v-if="!!part"
      class="modal-card"
    >
      <header
        class="modal-card-head is-flex is-justify-space-between
      has-background-white is-align-items-flex-start px-6 pt-6"
      >
        <template v-if="canBeConfigured3D && part._draft">
          <div
            class="button-group mr-4"
          >
            <GmButton
              :outlined="showFileUpload"
              type="info"
              rounded
              class="viewer-mode-button is-size-7 has-text-weight-bold"
              @click="goToConfigurator"
            >
              3D Configuration
            </GmButton>
            <GmButton
              :outlined="!showFileUpload"
              type="info"
              rounded
              class="viewer-mode-button is-size-7 has-text-weight-bold"
              @click="goToFileUpload"
            >
              Supporting file
            </GmButton>
          </div>
          <BMessage
            v-show="!showFileUpload"
            size="is-small"
            type="is-info"
            class="info-message"
          >
            <p class="is-size-7 has-text-weight-bold">
              You can upload a supporting file as a PDF or instead you can use our fast 3D
              configuration tool below to assign threads and basic tolerances.
            </p>
            <p class="is-size-7">
              Note: You cannot upload both a supporting file
              <b>and</b>
              use the 3D configuration tool
            </p>
          </BMessage>
        </template>
      </header>
      <div class="modal-card-body py-0 px-6">
        <SupportingFileEditor
          v-if="showFileUpload"
        />
        <template v-else>
          <GServeEditor
            v-if="parser === 'g-serve'"
            :part="part"
            @close="SET_VIEWED(null)"
          />
          <AutodeskViewer
            v-else-if="parser === 'autodesk'"
            :uuid="uuid"
          />
        </template>
      </div>
      <footer class="modal-card-foot has-background-white px-6 pb-6">
        <PortalTarget
          class="is-width-100 columns is-justify-space-between is-vcentered"
          name="viewer-modal-bottom"
          multiple
        />
      </footer>
    </div>
  </BModal>
</template>

<script>
import { mapGetters, mapState, mapMutations } from 'vuex';
import { GET_VIEWED, RFQ_MODULE, SET_VIEWED } from '@/app-buyer/store/modules/rfq/types';
import { part3DconfigMixin } from '@/app-buyer/components/project/mixins';
import GmButton from '@/shared/components/gm-button/gm-button.vue';
import GServeEditor from '@/app-buyer/components/project/GServeEditor.vue';
import SupportingFileEditor from '@/app-buyer/components/project/SupportingFileEditor.vue';
import AutodeskViewer from '@/app-buyer/components/project/AutodeskViewer.vue';
import { AUTH_MODULE, MASQUERADING } from '@/app-buyer/store/modules/auth/types';
import getEnvironmentVariable from '@/shared/misc/env-variable';

export default {
  name: 'PartViewer',
  components: {
    AutodeskViewer, SupportingFileEditor, GServeEditor, GmButton,
  },
  mixins: [part3DconfigMixin],
  data() {
    return {
      showFileUpload: false,
    };
  },
  computed: {
    ...mapState(AUTH_MODULE, {
      MASQUERADING,
    }),
    ...mapGetters(RFQ_MODULE, {
      part: GET_VIEWED,
    }),
  },
  watch: {
    part(nv, ov) {
      if (nv !== ov && getEnvironmentVariable('VUE_APP_SEGMENT_ENABLED')) {
        // SEGMENT TRACKING
        window.analytics.track('3D tool opened', {
          isMasquerading: !!this[MASQUERADING],
          projectHash: this.part.project_hash,
          draftRfq: {
            name: this.part.name,
            draftHash: this.part.hash,
            fileType: this.part.configuration_object?.['file-type']?.slug,
          },
        });
      }
    },
  },
  methods: {
    ...mapMutations(RFQ_MODULE, {
      SET_VIEWED,
    }),
    goToFileUpload() {
      if (this.showFileUpload) return;
      if (this.hasConfiguration3D) {
        this.$buefy.dialog.confirm({
          message: 'You have already configured this part with the 3D tool. Uploading a supporting file will remove this configuration.',
          confirmText: 'I understand',
          cancelText: 'Cancel',
          type: 'is-info',
          hasIcon: true,
          onConfirm: () => {
            this.showFileUpload = true;
          },
        });
      } else {
        this.showFileUpload = true;
      }
    },
    goToConfigurator() {
      if (!this.showFileUpload) return;
      if (this.hasSupportingFiles) {
        this.$buefy.dialog.confirm({
          message: 'You have already uploaded supporting files for this part. Using the 3D tool will remove this files.',
          confirmText: 'I understand',
          cancelText: 'Cancel',
          type: 'is-info',
          hasIcon: true,
          onConfirm: () => {
            this.showFileUpload = false;
          },
        });
      } else {
        this.showFileUpload = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-card {
  width: 90vw;
  height: 90vw;
}

.viewer-mode-button {
  width: 200px;
  padding: .5rem;
}

.info-message {
  background-color: rgba(variables.$info, 0.25);
}

::v-deep {
  .upload-draggable {
    width: 100%;
  }
}

.modal-card {
  @media all and (max-width: 1024px) {
    min-height: 80vh;
  }
}
</style>
