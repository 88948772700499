import {
  ORDERS, ORDERS_FILTERS, ORDERS_META, SET_ORDERS, SET_ORDERS_FILTERS,
  SET_ORDERS_META,
} from '@/app-buyer/store/modules/orders/types';

export default {

  [SET_ORDERS](state, data) {
    state[ORDERS] = data;
  },
  [SET_ORDERS_META](state, data) {
    state[ORDERS_META] = data;
  },
  [SET_ORDERS_FILTERS](state, data) {
    state[ORDERS_FILTERS] = data;
  },
};
