import {
  ACTIVE,
  ALL_RFQS,
  BASIC_DRAFT_RFQS,
  DRAFT_RFQ_ISSUES,
  DRAFT_RFQS,
  DWG_DRAFTS,
  DXF_DRAFTS,
  GET_ALL_SELECTED_PARTS,
  GET_FIRST_SELECTED_PART,
  GET_VIEWED,
  IGS_DRAFTS,
  INSTANT_DRAFT_RFQS,
  ONE_ACTIVE,
  PARTS_IN_CURRENT_PROJECT_COUNT,
  PDF_DRAFTS,
  REVISE_REQUOTE_MODAL_RFQS,
  REVISE_REQUOTE_ISSUES,
  REVISE_REQUOTE_SELECTED_RFQS,
  RFQ_ISSUES,
  RFQS,
  SELECTED,
  SELECTED_PART_HASHES,
  SORT_BY,
  SORTED_DRAFTS,
  STEP_DRAFTS,
  STL_DRAFTS,
  UPLOAD_PERCENT,
  UPLOADING_PARTS,
  VIEWED,
} from './types';
import {
  DWG,
  DXF,
  IGES,
  IGS,
  PDF,
  STEP,
  STL,
  STP,
} from '@/shared/consts/slugs';
import getDraftIssues from '@/app-buyer/store/modules/rfq/draftIssues';
import getRfqIssues from '@/app-buyer/store/modules/rfq/rfqIssues';
import { getProperty } from '@/app-buyer/mixins/get-property';

function getExtensionFromDraft(draft) {
  return draft.configuration_object?.['file-type']?.slug || draft.uploads?.find((e) => e.type?.slug === 'draft-rfq-model' || e.type.slug === 'rfq-model')
    ?.extension
    ?.toLowerCase();
}

export default {
  // Returns an array of the basic (non instant) draft-rfqs in the active project
  [BASIC_DRAFT_RFQS](state) {
    return (state[DRAFT_RFQS] && state[DRAFT_RFQS].filter((e) => !e.is_instant)) || [];
  },
  // Returns an array of the instant draft-rfqs in the active project
  [INSTANT_DRAFT_RFQS](state) {
    return (state[DRAFT_RFQS] && state[DRAFT_RFQS].filter((e) => e.is_instant)) || [];
  },
  // Returns an array of currently selected draft-rfqs in the active project
  [SELECTED](state) {
    return (state[DRAFT_RFQS] && state[DRAFT_RFQS].filter((e) => e.selected)) || [];
  },
  // Returns the list of the active (configured) rfqs/draft-rfqs in the active project
  [ACTIVE](state) {
    return [...state[DRAFT_RFQS], ...state[RFQS]].filter((e) => e.configuring) || [];
  },
  // Returns true if only one rfq/draft-rfq is active in the active project
  [ONE_ACTIVE](state) {
    return [...state[DRAFT_RFQS], ...state[RFQS]].filter((e) => e.configuring).length === 1 || [];
  },
  [SORTED_DRAFTS](state) {
    const { field = 'created_at', type: Type = Date, direction = 1 } = state[SORT_BY];
    return state[DRAFT_RFQS].slice().sort((partA, partB) => {
      const aValue = new Type(getProperty(partA, field));
      const bValue = new Type(getProperty(partB, field));

      if (aValue > bValue) {
        return direction;
      }

      if (aValue < bValue) {
        return -1 * direction;
      }

      return 0;
    });
  },
  [STEP_DRAFTS](state, getters) {
    return getters[SORTED_DRAFTS].filter((e) => {
      const extension = getExtensionFromDraft(e);
      return extension === STEP || extension === STP;
    });
  },
  [PDF_DRAFTS](state, getters) {
    return getters[SORTED_DRAFTS].filter((e) => {
      const extension = getExtensionFromDraft(e);
      return extension === PDF;
    });
  },
  [IGS_DRAFTS](state, getters) {
    return getters[SORTED_DRAFTS].filter((e) => {
      const extension = getExtensionFromDraft(e);
      return extension === IGES || extension === IGS;
    });
  },
  [DWG_DRAFTS](state, getters) {
    return getters[SORTED_DRAFTS].filter((e) => {
      const extension = getExtensionFromDraft(e);
      return extension === DWG;
    });
  },
  [DXF_DRAFTS](state, getters) {
    return getters[SORTED_DRAFTS].filter((e) => {
      const extension = getExtensionFromDraft(e);
      return extension === DXF;
    });
  },
  [STL_DRAFTS](state, getters) {
    return getters[SORTED_DRAFTS].filter((e) => {
      const extension = getExtensionFromDraft(e);
      return extension === STL;
    });
  },
  [UPLOADING_PARTS](state) {
    return state[DRAFT_RFQS].filter((e) => e.uploading);
  },
  [UPLOAD_PERCENT](state) {
    const uploading = state[DRAFT_RFQS]?.map((p) => p.uploadPercent).filter((p) => p);
    const total = uploading.reduce((r, c) => r + c, 0);
    return total / uploading.length;
  },
  [PARTS_IN_CURRENT_PROJECT_COUNT](state) {
    return (state[DRAFT_RFQS].length + state[RFQS].length) || 0;
  },
  [GET_ALL_SELECTED_PARTS](state) {
    return state[SELECTED_PART_HASHES].map(
      (hash) => state[DRAFT_RFQS].find((draft) => draft.hash === hash)
        || state[RFQS].find((rfq) => rfq.hash === hash),
    ).filter(Boolean);
  },
  [GET_FIRST_SELECTED_PART](state) {
    return state[SELECTED_PART_HASHES].reduce((found, current) => {
      if (found) return found;
      return state[DRAFT_RFQS].find((draft) => draft.hash === current)
        || state[RFQS].find((rfq) => rfq.hash === current);
    }, null);
  },
  [DRAFT_RFQ_ISSUES](state, getters, rootState, rootGetters) {
    return state[DRAFT_RFQS]?.filter((d) => !d.uploading).reduce((res, draft) => {
      const issues = getDraftIssues(draft, rootGetters, rootState);
      return {
        ...res,
        [draft.hash]: issues,
      };
    }, {}) || {};
  },
  [RFQ_ISSUES](state) {
    return state[ALL_RFQS]?.reduce((res, rfq) => {
      const issues = getRfqIssues(rfq);
      if (issues) {
        return {
          ...res,
          [rfq.hash]: issues,
        };
      }
      return res;
    }, {}) || {};
  },
  [REVISE_REQUOTE_ISSUES](state, getters, rootState, rootGetters) {
    return state[REVISE_REQUOTE_MODAL_RFQS]?.rfqs?.reduce((res, revision) => {
      const issues = getDraftIssues(revision, rootGetters, rootState);
      return {
        ...res,
        [revision.hash]: issues,
      };
    }, {}) || {};
  },
  [REVISE_REQUOTE_SELECTED_RFQS](state) {
    let sRfqs = [];
    if (state[REVISE_REQUOTE_MODAL_RFQS]?.selectedRfqs?.length) {
      state[REVISE_REQUOTE_MODAL_RFQS].selectedRfqs.forEach((selectedHash) => {
        const rfqObj = state[REVISE_REQUOTE_MODAL_RFQS]?.rfqs?.find((rfq) => rfq.hash === selectedHash);
        if (Object.values(rfqObj).length) {
          sRfqs = [...sRfqs, rfqObj];
        }
      });
    }
    return sRfqs;
  },
  [GET_VIEWED](state) {
    return [
      ...state[DRAFT_RFQS],
      ...state[RFQS],
      ...state[ALL_RFQS],
    ].find((p) => p.hash === state[VIEWED]?.hash) || state[VIEWED] || null;
  },
};
