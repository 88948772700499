import qs from 'qs';

const paramsSerializer = (params) => qs.stringify(params, {
  arrayFormat: 'brackets',
  encode: false,
});

export const parseUrlParams = (urlString) => qs.parse(urlString);

export default paramsSerializer;
