/* eslint-disable class-methods-use-this */
import Model from './Model';

export default class CartItem extends Model {
  resource() {
    return 'cart-items';
  }

  primaryKey() {
    return 'id';
  }
}
