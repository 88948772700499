import DeliveryCountryForm
  from '@/app-buyer/components/delivery-country-form/DeliveryCountryForm.vue';

const deliveryCountryPrompt = {
  methods: {
    confirmDeliveryCountry(selected = false) {
      return new Promise((resolve, reject) => {
        this.$buefy.modal.open({
          parent: this,
          component: DeliveryCountryForm,
          props: {
            selected,
          },
          events: {
            submit: (deliveryCountry) => {
              resolve({ deliveryCountry, selected });
            },
          },
          onCancel: () => {
            reject();
          },
          width: '700px',
          hasModalCard: true,
          trapFocus: true,
        });
      });
    },
  },
};

export default deliveryCountryPrompt;
