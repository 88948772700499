<template>
  <div @click.stop>
    <tippy
      v-if="!quote.cart_item"
      :enabled="promptForConfirmation || showOtherVendorQuotesModal || rfqHasAnotherQuote"
      :visible="promptForConfirmation || showOtherVendorQuotesModal || rfqHasAnotherQuote"
      arrow
      interactive
      trigger="manual"
      @hidden="hideModals"
    >
      <div>
        <QuoteConfirmPrompt
          v-if="quote.id && promptForConfirmation"
          :client-notes="clientNotes"
          confirm-text="Approve and add to cart"
          :quote-id="quote.id"
          :loading="loading"
          class="p-4"
          @confirm="handleAddToCart"
        />
      </div>
      <div>
        <QuoteConfirmPrompt
          v-if="rfqHasAnotherQuote"
          :quote-id="quote.id"
          rfq-has-another-quote
          :loading="loading"
          class="p-4"
          @confirm="handleAddToCart(true)"
        />
      </div>
      <BButton
        slot="trigger"
        class="px-5 has-text-weight-bold"
        size="is-small"
        icon-left="cart-plus"
        type="is-info"
        :loading="loading"
        data-testid="add_to_cart_button"
        :disabled="loading || disabled"
        icon-pack="fal"
        @click="attemptAddToCart"
      >
        Add to cart
      </BButton>
    </tippy>
    <BButton
      v-if="quote.cart_item"
      outlined
      size="is-small"
      type="is-danger"
      data-testid="remove_from_cart_button"
      :disabled="loading || disabled"
      :loading="loading"
      @click="removeFromCart"
    >
      Remove from cart
    </BButton>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import { AUTH_MODULE, MASQUERADING } from '@/app-buyer/store/modules/auth/types';
import addRemoveQuoteCart from '@/app-buyer/mixins/add-remove-quote-cart';
import QuoteConfirmPrompt from '@/app-buyer/components/grouped-quotes/QuoteConfirmPrompt.vue';
import getEnvironmentVariable from '@/shared/misc/env-variable';

export default {
  name: 'GroupedQuotesCartButtons',
  components: { QuoteConfirmPrompt },
  mixins: [addRemoveQuoteCart],
  props: {
    rfq: {
      type: Object,
      required: true,
    },
    quote: {
      type: Object,
      required: false,
      default() {
        return {};
      },
    },
    quoteGroupIdsInCartFromOtherGroups: {
      type: Array,
      required: true,
    },
    hasMultipleQuotes: {
      type: Boolean,
      required: false,
    },
    rfqsAndTheirActiveQuotesIds: {
      type: Object,
      required: false,
      default() {
        return {};
      },
    },
    inactiveQuoteInTheCart: {
      type: Object,
      required: false,
      default() {
        return {};
      },
    },
    disabled: Boolean,
  },
  data() {
    return {
      promptForConfirmation: false,
      showOtherVendorQuotesModal: false,
      rfqHasAnotherQuote: false,
      loading: false,
    };
  },
  computed: {
    ...mapState(AUTH_MODULE, {
      MASQUERADING,
    }),
    clientNotes() {
      return this.quote?.notes?.map((e) => e.content).join(' | ');
    },
    hasOtherVendorQuotesInCart() {
      return this.quoteGroupIdsInCartFromOtherGroups.length;
    },
  },
  methods: {
    hideModals() {
      this.promptForConfirmation = false;
      this.showOtherVendorQuotesModal = false;
      this.rfqHasAnotherQuote = false;
    },
    attemptAddToCart() {
      const confirmed = localStorage.getItem(`${this.quote.id}_confirmed`);
      if (!confirmed && this.clientNotes) {
        this.promptForConfirmation = true;
      } else if (this.rfqsAndTheirActiveQuotesIds[this.rfq.id]) {
        this.rfqHasAnotherQuote = true;
      } else {
        this.finalizeCartAdd();
      }
    },
    async handleAddToCart(removeOtherQuoteInCart = false) {
      this.loading = true;
      if (removeOtherQuoteInCart) {
        await this.removeQuoteFromCartByCartItemId(this.inactiveQuoteInTheCart.cart_item.id);
        this.$set(this.inactiveQuoteInTheCart, 'cart_item', null);
        await this.finalizeCartAdd();
      } else if (this.rfqsAndTheirActiveQuotesIds[this.rfq.id]) {
        this.promptForConfirmation = false;
        this.rfqHasAnotherQuote = false;
      } else {
        await this.finalizeCartAdd();
      }
      this.loading = false;
    },
    async finalizeCartAdd() {
      this.loading = true;
      try {
        const cartItem = await this.addQuoteToCart(this.quote.id);
        this.$set(this.quote, 'cart_item', cartItem);

        // SEGMENT TRACKING
        if (getEnvironmentVariable('VUE_APP_SEGMENT_ENABLED')) {
          window.analytics.track('Cart added', {
            isMasquerading: !!this[MASQUERADING],
            currency: 'GBP',
            value: cartItem?.quote?.total_inc_vat,
            content_ids: [cartItem.product_lifecycle_id],
            content_type: 'product',
          });
        }
      } catch (e) {
        this.$buefy.toast.open({
          message: 'Could not add part to cart',
          type: 'is-danger',
        });
      } finally {
        this.loading = false;
      }
    },
    async removeFromCart() {
      this.loading = true;
      try {
        await this.removeQuoteFromCartByCartItemId(this.quote.cart_item.id);
        this.$set(this.quote, 'cart_item', null);
      } catch (e) {
        this.$buefy.toast.open({
          message: 'Could not remove part from cart',
          type: 'is-danger',
        });
      } finally {
        this.loading = false;
      }
    },
  },
};

</script>
