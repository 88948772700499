import Api from '../../../api/api';
import ENDPOINTS from '../../../api/endpoints';
import { GET, SET } from '../types';
import { SET_LOADING } from '@/app-buyer/store/modules/rfq/types';

export default {
  /**
     * Get the total list of options from the db
     *
     * @param {Object} context
     * @returns {Promise<Object>} data
     */
  async [GET]({ commit }) {
    commit(SET_LOADING, true);
    const { data } = await Api.get(ENDPOINTS.REFERENCE.PROPERTIES, { params: { append: ['parent_ids'] } })
      .catch((e) => e);
    commit(SET, data);
    return data;
  },
};
