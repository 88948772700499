<template>
  <BField>
    <FileUpload
      accept=".iges,.igs,.dwg,.dxf,.stp,.step,.pdf,.stl"
      multiple
      @upload="createQuotes"
    >
      <BButton
        tag="a"
        icon-left="plus"
        type="is-info"
      >
        Upload more parts
      </BButton>
    </FileUpload>
  </BField>
</template>
<script>
import FileUpload from '@/app-buyer/components/file-upload/file-upload.vue';
import baseUpload from '@/app-buyer/mixins/base-upload';

export default {
  name: 'PartTableHeaderUpload',
  components: { FileUpload },
  mixins: [baseUpload],
};
</script>
