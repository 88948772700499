<template>
  <div class="has-text-whiten">
    <h2 class="is-size-5-touch is-size-3 mb-1 has-text-weight-bold has-text-white is-uppercase">
      <slot name="title" />
    </h2>
    <p class="has-text-white is-size-7 mb-4">
      <slot name="subtitle" />
    </p>
    <slot />
  </div>
</template>

<script>
export default {
  name: 'RegistrationLayout',
};
</script>

<style scoped>
h2.is-size-3 {
  letter-spacing: 1px;
  line-height: 2rem;
}
</style>
