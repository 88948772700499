<template>
  <validation-observer v-slot="{ passes }">
    <form
      data-testid="registration-form"
      @submit.prevent.stop
    >
      <transition-group
        class="input-wrapper"
        mode="out-in"
        name="slide-left"
        tag="ul"
      >
        <li
          key="1"
          class="input-wrapper"
        >
          <b-input-with-validator
            v-model="email"
            :field-type="messages.email && messages.email[0] && 'is-danger'"
            :message="messages.email && messages.email[0]"
            label="Business Email"
            name="email"
            placeholder="Enter email..."
            rules="required|email"
            vid="email"
            trim
            @input="validateEmail"
          />
          <b-message
            v-if="showResend"
            type="is-warning"
            class="pt-3"
          >
            An account already exists for this email address, to register please click the link in your email.<br>
            <a
              v-if="!resendSubmitted"
              @click="handleResend"
            >Resend email</a>
            <section v-else>
              Email Sent
            </section>
          </b-message>
        </li>
        <li
          key="2"
          class="input-wrapper"
        >
          <b-input-with-validator
            v-model="password"
            :field-type="messages.password && messages.password[0] && 'is-danger'"
            :message="messages.password && messages.password[0]"
            label="Password"
            name="password"
            placeholder="Enter password..."
            rules="required"
            type="password"
            vid="password"
          />
        </li>
        <li
          key="3"
          class="input-wrapper"
        >
          <b-input-with-validator
            v-model="name"
            :field-type="messages.name && messages.name[0] && 'is-danger'"
            :message="messages.name && messages.name[0]"
            label="Name"
            name="name"
            placeholder="Enter name..."
            rules="required"
            vid="name"
          />
        </li>
        <li
          key="4"
          class="input-wrapper"
        >
          <b-input-with-validator
            v-model="contact_number"
            :field-type="messages.contact_number && messages.contact_number[0] && 'is-danger'"
            :message="messages.contact_number && messages.contact_number[0]"
            label="Contact number"
            name="phone"
            placeholder="Enter contact number..."
            rules="required"
            vid="contact-number"
          />
        </li>
        <li
          key="6"
          class="input-wrapper"
        >
          <b-input-with-validator
            v-model="agreement"
            input-type="checkbox"
            name="terms"
            rules="required"
            vid="terms"
          >
            I agree to the <a
              href="https://geomiq.com/user-terms-conditions/"
              target="_blank"
            >Terms and conditions</a> and the
            <a
              href="https://geomiq.com/privacy-policy/"
              target="_blank"
            >Privacy policy</a>
          </b-input-with-validator>
        </li>
        <li
          key="7"
          class="input-wrapper"
        >
          <b-field>
            <b-checkbox
              v-model="subscribe"
              name="subscribe"
            >
              Subscribe to marketing details
            </b-checkbox>
          </b-field>
        </li>
        <li
          key="10"
          class="input-wrapper"
        >
          <b-field>
            <gm-button
              :disabled="submitted"
              :loading="submitted"
              data-testid="sign-up-button"
              fullwidth
              has-shadow
              native-type="submit"
              type="v2-supporting-1"
              @click="passes(startRegister)"
            >
              <b>Sign up</b>
            </gm-button>
          </b-field>
        </li>
      </transition-group>
      <input
        id="zc_gad"
        name="zc_gad"
        type="hidden"
        value=""
      >
    </form>
  </validation-observer>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
import { ValidationObserver } from 'vee-validate';
import { debounce } from 'lodash/function';
import { NAVIGATION_MODULE, SET_LOGIN_MODAL } from '@/app-buyer/store/modules/navigation/types';
import Api from '@/app-buyer/api/api';
import ENDPOINTS from '@/app-buyer/api/endpoints';
import getEnvironmentVariable from '../../../shared/misc/env-variable';
import notificationInjection
from '../../../shared/components/notification/notification-injection-mixin';
import { AUTH_MODULE, REGISTER } from '../../store/modules/auth/types';

export default {
  name: 'RegistrationForm',
  components: {
    ValidationObserver,
  },
  mixins: [notificationInjection],
  data() {
    return {
      email: '',
      password: '',
      name: '',
      formattedContactNumber: '',
      agreement: false,
      subscribe: true,
      submitted: false,
      messages: {},
      showResend: false,
      resendSubmitted: false,
    };
  },
  computed: {
    contact_number: {
      get() {
        return this.formattedContactNumber;
      },
      set(value) {
        this.formattedContactNumber = value;
        this.$nextTick(() => {
          this.formattedContactNumber = this.formattedContactNumber.replace(/[^\-+0-9]/g, '');
        });
      },
    },
  },
  methods: {
    ...mapActions(AUTH_MODULE, {
      register: REGISTER,
    }),
    ...mapMutations(NAVIGATION_MODULE, {
      setModal: SET_LOGIN_MODAL,
    }),
    async startRegister() {
      let token = null;
      if (getEnvironmentVariable('VUE_APP_RECAPTCHA_KEY')) {
        token = await this.getRecaptchaToken();
      }
      this.submitted = true;
      const response = await this.register({
        email: this.email,
        password: this.password,
        name: this.name,
        contact_number: this.contact_number,
        subscribe: this.subscribe,
        user_type: 'buyer',
        agreement: this.agreement,
        captcha: token,
      });
      if (response.status < 300) {
        this._addNotification({
          type: 'is-success',
          message: 'Successfully signed up!',
        });
        this.setModal({ visible: false, mode: 'register', force: true });
        // GTM event for sign up
        if (this.$gtm) {
          this.$gtm.trackEvent({
            event: 'user-action',
            user_id: response.data?.id,
            'user-action-type': 'sign-up',
            email: this.email,
          });
        }
      } else {
        this.messages = response.data.errors;
      }
      this.submitted = false;
    },
    async getRecaptchaToken() {
      return new Promise((resolve) => {
        window.grecaptcha.ready(async () => {
          const token = await window.grecaptcha.execute(
            getEnvironmentVariable('VUE_APP_RECAPTCHA_KEY'),
          );
          resolve(token);
        });
      });
    },
    validateEmail: debounce(async function validateEmail() {
      let token = null;
      if (getEnvironmentVariable('VUE_APP_RECAPTCHA_KEY')) {
        token = await this.getRecaptchaToken();
      }
      const { data, status } = await Api.post(ENDPOINTS.VALIDATIONS.EMAIL, {
        email: this.email,
        captcha: token,
      }).catch((e) => e.response);
      if (status < 300) {
        this.messages = {
          ...this.messages,
          email: null,
        };
        this.showResend = false;
      } else if (data?.errors?.email[0] === 'An account already exists for this email address.') {
        this.messages = {
          ...this.messages,
          email: null,
        };
        this.showResend = true;
      } else {
        this.messages = {
          ...this.messages,
          email: data?.errors?.email,
        };
        this.showResend = false;
      }
    }, 500),
    async handleResend() {
      await Api.post(ENDPOINTS.AUTH.REGISTER_BASIC_RESEND, {
        email: this.email,
      });

      this.resendSubmitted = true;
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
.input-wrapper {
  margin: 1rem 0;

  &:last-child {
    margin-bottom: 0;
  }
}
</style>
