import axios from 'axios';
import { SnackbarProgrammatic as SnackBar } from 'buefy';
import { Model } from 'vue-api-query';
import getEnvironmentVariable from '../../shared/misc/env-variable';

// TODO this way axios will not attach the xsrf token
axios.defaults.xsrfCookieName = null;

const accessTokenSubscriptionHandler = {
  subscribers: [],
  refreshing: false,
  onRefresh() {
    this.subscribers = this.subscribers.filter((callback) => callback());
  },
};

const Api = axios.create({
  baseURL: `${getEnvironmentVariable('VUE_APP_API_URL')}/api`,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  withCredentials: true,
});

export const responseErrorHandler = async (error) => {
  const originalRequest = error.config;
  const accessToken = localStorage.getItem('gm_access_token');
  // If response is 401 and call failed for the first time and the access token is present
  // we try refreshing the token
  if (error.response.status === 401 && !originalRequest._retry && accessToken) {
    // If no token refreshing has started yet we call /api/refresh and set the flag for
    // refreshing to true
    if (!accessTokenSubscriptionHandler.refreshing) {
      accessTokenSubscriptionHandler.refreshing = true;
      originalRequest._retry = true;
      const { status, data } = await axios.post(`${getEnvironmentVariable('VUE_APP_API_URL')}/api/refresh`, { access_token: accessToken }, {
        withCredentials: true,
        xsrfCookieName: null,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
        .catch((innerError) => innerError.response);
      // If the call is successful we set the new token in localstorage and
      // run all the subscribed calls + the one that started the refreshing
      if (status < 300) {
        localStorage.setItem('gm_access_token', data.access_token);
        if (localStorage.getItem('gm_masquerading') === accessToken) {
          localStorage.setItem('gm_masquerading', data.access_token);
        }
        accessTokenSubscriptionHandler.onRefresh(data.access_token);
        originalRequest.headers.Authorization = `Bearer ${localStorage.getItem('gm_access_token')}`;
      } else {
        // If the refresh call fails the user is logged out and redirected to the
        // login page
        SnackBar.open({
          type: 'is-warning',
          message: 'Your session has expired, please log in again!',
          confirmText: 'OK',
        });
        localStorage.removeItem('gm_access_token');
        window.location.href = `${getEnvironmentVariable('VUE_APP_BASE_URL')}/login`;
      }
      accessTokenSubscriptionHandler.refreshing = false;
      // Return the updated call
      return axios(originalRequest);
    }
    // If refreshing is already happening the call is placed in a subscriber array
    // until a new token is set
    return new Promise((resolve) => {
      accessTokenSubscriptionHandler.subscribers.push(() => {
        originalRequest.headers.Authorization = `Bearer ${localStorage.getItem('gm_access_token')}`;
        resolve(axios(originalRequest));
        return false;
      });
    });
  }
  if (error.response.status >= 500 && originalRequest.__forceRetry) {
    originalRequest.__forceRetry = false;
    return new Promise((innerResolve) => {
      setTimeout(() => innerResolve(axios(originalRequest)), 2000);
    });
  }
  // Else reject the call
  return Promise.reject(error);
};

export function resolvePathParams(path, params) {
  let mutablePath = path;
  Object.keys(params)
    .forEach((key) => {
      const wrapped = `{${key}}`;
      if (mutablePath.includes(wrapped)) {
        mutablePath = mutablePath.replace(wrapped, params[key]);
      }
    });
  return mutablePath;
}

Api.interceptors.response.use((response) => response, responseErrorHandler);

/* eslint-disable no-param-reassign */
Api.interceptors.request.use((config) => {
  const token = localStorage.getItem('gm_access_token');
  if (token) {
    config.headers.Authorization = `Bearer ${localStorage.getItem('gm_access_token')}`;
  }
  if (config.__pathParams) {
    config.url = resolvePathParams(config.url, config.__pathParams);
    delete config.__pathParams;
  }
  return config;
});
/* eslint-enable no-param-reassign */

export default Api;

Model.$http = Api;
