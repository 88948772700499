<template>
  <div class="is-flex is-justify-content-center">
    <slot :show-feedback-modal="startShowFeedbackModal">
      <GmButton
        size="small"
        type="v2-supporting-1"
        class="has-text-weight-medium px-5"
        :loading="isPreparingFeedbackModal"
        @click="startShowFeedbackModal"
      >
        Leave Feedback
      </GmButton>
    </slot>
    <BModal :active.sync="isFeedbackModalVisible">
      <FeedbackOrder
        :invoices="[innerInvoice]"
        :can-delay="false"
        @close="handleFeedbackClose"
        @submitted="handleFeedbackClose(true)"
      />
    </BModal>
  </div>
</template>

<script>
import Invoice from '@/app-buyer/models/Invoice';
import FeedbackThanks from '@/app-buyer/components/feedback-modal/FeedbackThanks.vue';
import FeedbackOrder from '@/app-buyer/components/feedback-modal/FeedbackOrder.vue';

export default {
  name: 'OrderFeedbackButton',
  components: { FeedbackOrder },
  props: {
    invoice: {
      type: Object,
      default: null,
    },
    order: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      isPreparingFeedbackModal: false,
      isFeedbackModalVisible: false,
      innerInvoice: null,
    };
  },
  watch: {
    invoice: {
      handler(inv) {
        this.innerInvoice = inv;
      },
      immediate: true,
    },
  },
  methods: {
    async startShowFeedbackModal() {
      if (!this.innerInvoice && this.order) {
        this.isPreparingFeedbackModal = true;
        const query = new Invoice();
        query.include([
          'rfqLines.rfq.configurationProperties',
          'rfqLines.rfq.order',
          'rfqLines.rfq.uploads',
        ]);
        const { data } = await query.find(this.order?.rfq?.paid_invoice_line?.invoice?.id);
        if (data) {
          this.innerInvoice = data;
          this.isPreparingFeedbackModal = false;
        } else {
          this.showError();
        }
        this.isPreparingFeedbackModal = false;
      } else if (this.innerInvoice) {
        this.isFeedbackModalVisible = true;
        this.isPreparingFeedbackModal = false;
      } else {
        this.showError();
      }
    },
    showError() {
      this.$buefy.toast.open({
        type: 'is-danger',
        message: 'Could not load order details for feedback!',
      });
      this.isPreparingFeedbackModal = false;
    },
    handleFeedbackClose(success) {
      this.isFeedbackModalVisible = false;
      if (success) {
        this.isFeedbackSubmitted = true;
        const modal = this.$buefy.modal.open({
          parent: this,
          component: FeedbackThanks,
        });
        setTimeout(() => {
          modal.close();
        }, 2000);
      }
    },
  },
};
</script>

<style scoped>

</style>
