<template>
  <div
    :class="{'is-mobile': isMobile}"
    class="part-form-footer"
  >
    <div class="is-flex is-align-items-center is-justify-content-space-between mb-4">
      <BRadio
        v-model="requestMode"
        :name="`${requestMode}${isMobile ? '_mobile': ''}`"
        class="is-size-7"
        native-value="selected"
      >
        Request selected
      </BRadio>
      <BRadio
        v-model="requestMode"
        :name="`${requestMode}${isMobile ? '_mobile': ''}`"
        class="is-size-7"
        native-value="all"
      >
        Request all
      </BRadio>
      <GmButton
        :disabled="requestMode !== 'all' && !SELECTED_PART_HASHES.length"
        is-link
        size="small"
        style="margin-left:13%;"
        type="info"
        @click="showSummary = true"
      >
        View summary
      </GmButton>
    </div>
    <GmButton
      :disabled="!canSubmit"
      :loading="projectContext.submitting"
      class="has-text-weight-bold"
      data-testid="submit-rfq-btn"
      fullwidth
      type="g-primary"
      @click="$emit('request-quotes', requestMode === 'selected')"
    >
      {{ submitBtnText }}
    </GmButton>
    <portal to="modal">
      <BModal
        :active.sync="showSummary"
        custom-class="animation-content-full"
        has-modal-card
      >
        <ConfiguratorDraftsSummary :request-mode="requestMode" />
      </BModal>
    </portal>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import {
  DRAFT_RFQ_ISSUES,
  DRAFT_RFQS,
  GET_ALL_SELECTED_PARTS,
  RFQ_MODULE,
  SELECTED_PART_HASHES,
} from '@/app-buyer/store/modules/rfq/types';
import ConfiguratorDraftsSummary
  from '@/app-buyer/components/configurator/configurator-body/configurator-drafts/configurator-drafts-summary.vue';

export default {
  name: 'PartFormFooter',

  components: { ConfiguratorDraftsSummary },

  inject: {
    projectContext: {
      default: {},
    },
  },

  props: {
    isMobile: Boolean,
  },

  data() {
    return {
      showSummary: false,
      showNotes: false,
      requestMode: ['selected', 'all'].includes(this.$route.query?.request) ? this.$route.query?.request : 'selected',
    };
  },

  computed: {
    ...mapState(RFQ_MODULE, {
      DRAFT_RFQS,
      SELECTED_PART_HASHES,
    }),

    ...mapGetters(RFQ_MODULE, {
      DRAFT_RFQ_ISSUES,
      GET_ALL_SELECTED_PARTS,
    }),

    submittableDraftCount() {
      // const errorsCount = Object.values(this[DRAFT_RFQ_ISSUES])
      //   .filter((e) => {
      //     if (typeof e === 'object' && e != null) {
      //       return Object.values(e).some((issue) => issue.blockSubmit);
      //     }
      //     return !!e;
      //   }).length;
      // return this[DRAFT_RFQS].length - errorsCount;
      return this[DRAFT_RFQS].length;
    },

    submittableSelectedCount() {
      // return this[GET_ALL_SELECTED_PARTS].filter((d) => {
      //   const issues = this[DRAFT_RFQ_ISSUES][d.hash];
      //   if (typeof issues === 'object' && issues != null) {
      //     return Object.values(issues).every((issue) => !issue?.blockSubmit);
      //   }
      //   return !issues;
      // }).length;
      return this[GET_ALL_SELECTED_PARTS].length;
    },

    submitBtnText() {
      const submitCount = this.requestMode === 'all'
        ? this.submittableDraftCount
        : this.submittableSelectedCount;
      return `Get ${this.requestMode} quotes (${submitCount}/${this[DRAFT_RFQS].length})`;
    },

    canSubmit() {
      return !this.projectContext.submitting
        && this.submittableDraftCount
        && (this.requestMode === 'selected' ? this[GET_ALL_SELECTED_PARTS].length : true);
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
.part-form-footer {
  background-color: #ffffff;
  background-size: cover;
  border: 1px solid #dbdbdb;
  border-radius: 5px;
  bottom: 1rem;
  box-shadow: 0 5px 15px -5px rgba(0, 0, 0, 0.2);
  padding: 1rem;
  position: fixed;
  right: 2rem;
  width: 26rem;
  z-index: 4;

  @media all and (max-width: 1500px) {
    right: 1rem;
    width: 20rem;
  }

  &.is-mobile {
    bottom: 4rem;
    left: 0;
    position: absolute;
    right: 0;
    width: unset;
  }
}
</style>
