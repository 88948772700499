<template>
  <span
    :class="[type, size, {outlined, fullwidth, loading, rounded, multiline}]"
    class="gm-tag gm-theme"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot />
  </span>
</template>

<script>
import { gmThemeProps } from '../../mixins';

export default {
  name: 'GmTag',
  mixins: [gmThemeProps],
  props: {
    multiline: {
      type: Boolean,
      default: () => false,
    },
  },
};
</script>

<style lang="scss" scoped>
  .gm-tag {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 0.8rem;
    box-shadow: none !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &.multiline {
      white-space: normal;
    }
  }
</style>
