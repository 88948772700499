<template>
  <div
    v-if="isAvailable"
    class="mb-2"
  >
    <ConfirmApplicableTooltip
      :confirm-applicable="confirmApplicable"
      @cancel="confirmApplicable = null"
      @confirm="finaliseUpdate(confirmApplicable.canApply)"
    />
    <BField
      custom-class="mb-1 is-size-7 has-text-weight-normal"
      expanded
    >
      <template
        v-if="showLabel"
        #label
      >
        <span class="is-flex is-align-items-center">
          {{ label }}
          <Tippy
            v-if="tooltipMessage"
            tag="span"
            arrow
          >
            <template #trigger>
              <BIcon
                type="is-info"
                size="is-small"
                icon="info-circle"
                class="ml-1"
              />
            </template>
            <div
              class="has-text-left p-2"
              v-html="tooltipMessage"
            />
          </Tippy>
        </span>
        <template v-if="secondaryTooltipMessage">
          <i>{{ secondaryTooltipMessage }}</i>
        </template>
      </template>
      <Component
        :is="componentType"
        :entity="def.entity"
        :values="values"
        :properties="options"
        :lead-value="leadValue"
        :placeholder="placeholder"
        :disabled="leadLockedConfig[def.entity]"
        size="is-small"
        @input="startUpdate"
        @set-note="$emit('set-note', $event)"
      >
        {{ label }}
      </Component>
    </BField>
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex';
import { INPUT_TYPES } from '@/app-buyer/components/configurator/configurator-body/configurator-drafts/configurator-element/configurator-types';
import {
  REFERENCE_DATA,
  REFERENCE_DATA_OBJECT_BY_ID,
  REFERENCE_MODULE,
} from '@/app-buyer/store/modules/reference-data/types';
import sorting from '@/app-buyer/mixins/sorting';
import ConfiguratorElementSelect
  from '@/app-buyer/components/configurator/configurator-body/configurator-drafts/configurator-element/configurator-element-select.vue';
import ConfiguratorElementRadioButton
  from '@/app-buyer/components/configurator/configurator-body/configurator-drafts/configurator-element/configurator-element-radio-button.vue';
import ConfiguratorElementCollapsableInput
  from '@/app-buyer/components/configurator/configurator-body/configurator-drafts/configurator-element/ConfiguratorElementCollapsableInput.vue';
import ConfiguratorElementMaterial
  from '@/app-buyer/components/configurator/configurator-body/configurator-drafts/configurator-element/configurator-element-material.vue';
import ConfiguratorElementCheckbox
  from '@/app-buyer/components/configurator/configurator-body/configurator-drafts/configurator-element/configurator-element-checkbox.vue';
import ConfiguratorElementNumberInput
  from '@/app-buyer/components/configurator/configurator-body/configurator-drafts/configurator-element/configurator-element-number-input.vue';
import ConfiguratorElementRadio
  from '@/app-buyer/components/configurator/configurator-body/configurator-drafts/configurator-element/configurator-element-radio.vue';
import ConfiguratorElementMinMax
  from '@/app-buyer/components/configurator/configurator-body/configurator-drafts/configurator-element/configurator-element-min-max.vue';
import { safeMultipleUpdate } from '@/app-buyer/components/project/mixins';
import ConfirmApplicableTooltip from '@/app-buyer/components/project/ConfirmApplicableTooltip.vue';

const cachedData = {};

const filterValidOptions = (property, entity, dependencies) => {
  // only return true for properties that have the right entity
  if (property.entity_slug !== entity) return false;
  // all dependencies must either be unset or included in the possible parents array
  return dependencies.every((dep) => property.parent_ids.includes(dep));
};

const sortOptions = (originalData, sortingFunction) => {
  const data = sortingFunction(originalData, ['string_value']).reverse();
  const toStartOrEnd = [['not required'], ['custom', 'other']];
  toStartOrEnd.forEach((pos, index) => {
    pos.forEach((str) => {
      const idx = data.findIndex((prop) => prop.string_value
        ?.toLowerCase().indexOf(str) > -1);
      if (idx > -1) {
        const obj = data.splice(idx, 1)[0];
        // first array goes to start, second array goes to end of sorted values
        data[index ? 'push' : 'unshift'](obj);
      }
    });
  });
  return data;
};

export default {
  name: 'DraftConfiguratorElement',

  components: { ConfirmApplicableTooltip },

  mixins: [sorting, safeMultipleUpdate],

  props: {
    def: {
      type: Object,
      required: true,
    },
    configurations: {
      type: Object,
      default: () => ({}),
    },
    leadHash: {
      type: String,
      required: true,
    },
    disabled: Boolean,
  },

  data() {
    return {
      label: null,
      placeholder: null,
      options: [],
      confirmApplicable: null,
    };
  },

  computed: {
    ...mapState(REFERENCE_MODULE, {
      REFERENCE_DATA,
    }),

    ...mapGetters(REFERENCE_MODULE, {
      REFERENCE_DATA_OBJECT_BY_ID,
    }),

    isAvailable() {
      return this.def.type === INPUT_TYPES.NUMBER || (this.hasAllDependencies && this.hasOptions);
    },

    showLabel() {
      return ![INPUT_TYPES.RADIO, INPUT_TYPES.CHECKBOX].includes(this.def.type);
    },

    dependencies() {
      const { dependencies } = this.def;
      const arr = (
        dependencies?.get
          ? dependencies.get(this.leadConfiguration)
          : dependencies
      ) || [];
      return arr.map((e) => this.leadConfiguration[e]);
    },

    hasAllDependencies() {
      const { dependencies } = this.def;
      return dependencies?.length || this.dependencies.every((e) => e != null);
    },

    hasOptions() {
      return !!this.options?.length;
    },

    leadConfiguration() {
      return this.configurations[this.leadHash];
    },

    leadValue() {
      return this.leadConfiguration[this.def.entity];
    },

    values() {
      // Get all values from configurations
      const allValues = Object.values(this.configurations).map((conf) => conf[this?.def?.entity]);
      // Remove duplicate values
      return Array.from(new Set(allValues));
    },

    componentType() {
      switch (this.def.type) {
        case INPUT_TYPES.SELECT:
          return ConfiguratorElementSelect;
        case INPUT_TYPES.COLLAPSABLE_INPUT:
          return ConfiguratorElementCollapsableInput;
        case INPUT_TYPES.AUTOCOMPLETE:
          return ConfiguratorElementMaterial;
        case INPUT_TYPES.CHECKBOX:
          return ConfiguratorElementCheckbox;
        case INPUT_TYPES.RADIO_BUTTON:
          return ConfiguratorElementRadioButton;
        case INPUT_TYPES.NUMBER:
          return ConfiguratorElementNumberInput;
        case INPUT_TYPES.RADIO:
          return ConfiguratorElementRadio;
        case INPUT_TYPES.MIN_MAX:
          return ConfiguratorElementMinMax;
        default:
          return ConfiguratorElementSelect;
      }
    },

    tooltipMessage() {
      return this.def.tooltip?.get(this.leadConfiguration);
    },

    secondaryTooltipMessage() {
      return this.def.tooltip?.secondary(this.leadConfiguration);
    },
  },

  watch: {
    dependencies: {
      handler() {
        this.loadData();
      },
      immediate: true,
      deep: true,
    },
  },

  methods: {
    loadData() {
      const {
        type, entity, sortMethod, label,
      } = this.def;
      // In case of a number input not much needs to be done
      if (type === INPUT_TYPES.NUMBER) {
        this.label = label || '';
        return;
      }
      /* In every other case the reference data needs to be filtered to get the options.
         The results are cached in case there are multiple instances of the same component.
       */
      const identifier = `${entity.toString()},${[this.dependencies].join(',')}`;
      if (!cachedData[identifier] && type !== INPUT_TYPES.NUMBER) {
        const data = this[REFERENCE_DATA]?.filter(
          (property) => filterValidOptions(property, entity, this.dependencies),
        );
        if (sortMethod) {
          cachedData[identifier] = sortOptions(data, this[sortMethod]);
        } else {
          cachedData[identifier] = data;
        }
      }
      this.options = cachedData[identifier];

      // Labels are calculated based on the properties or if it's defined in the def
      if (this.type === INPUT_TYPES.CHECKBOX) {
        this.label = label
            || cachedData[identifier][0]?.string_value
            || cachedData[identifier][0]?.entity_name;
      } else if (this.type === INPUT_TYPES.RADIO) {
        this.label = label;
      } else {
        this.label = label || cachedData[identifier]?.[0]?.entity_name;
      }

      this.placeholder = `Select ${this.label}`;
    },
  },
};
</script>
