<template>
  <section class="is-width-100">
    <div class="is-flex">
      <router-link
        v-for="(step, index) in steps"
        :key="step.name"
        :to="{name: step.routeName}"
        tag="div"
        :class="{ active: step.active }"
        class="is-flex-grow-1 is-flex is-flex-align-centered is-justify-flex-start step-mark p-x-xs"
        style="cursor: pointer;"
      >
        <span class="is-flex is-flex-align-centered is-justify-center m-r-sm">
          {{ index + 1 }}
        </span>
        <p>
          {{ step.name }}
        </p>
      </router-link>
    </div>
    <div class="small-progress-wrapper is-flex is-justify-flex-start m-t-sm">
      <span
        :style="'transition: width 0.2s;' + `width: ${progress}%`"
        class="small-progress"
      />
    </div>
  </section>
</template>

<script>
export default {
  name: 'StepIndicator',
  props: {
    activeStep: {
      type: Number,
      default: () => 1,
    },
  },
  computed: {
    progress() {
      return Math.round((this.steps.filter((e) => e.active).length / this.steps.length) * 100);
    },
    steps() {
      return (
        [
          {
            name: 'upload',
            routeName: 'rfq-form',
            active: this.activeStep >= 1,
          },
          {
            name: 'quote',
            routeName: 'quote-list',
            active: this.activeStep >= 2,
          },
          {
            name: 'purchase',
            routeName: 'cart',
            active: this.activeStep >= 3,
          },
        ]);
    },
  },
};
</script>
