<template>
  <span class="is-hidden-desktop">
    <a @click="active = true">
      Watch video
    </a>
    <BModal :active.sync="active">
      <div
        style="padding:56.25% 0 0 0;position:relative;"
        class="my-4"
      >
        <iframe
          allow="
      autoplay;
      fullscreen;
      picture-in-picture"
          allowfullscreen
          frameborder="0"
          src="https://player.vimeo.com/video/536361036?autoplay=1?loop=1&title=0&byline=0&portrait=0&muted=1"
          style="position:absolute;top:0;left:0;width:100%;height:100%;"
        />
      </div>
    </BModal>
  </span>
</template>
<script>
export default {
  name: 'RegistrationVideoLink',
  data() {
    return {
      active: false,
    };
  },
};
</script>
<style scoped>
option {
  color: black;
}
</style>
