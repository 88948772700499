<template>
  <BTag
    type="is-success"
    rounded
    class="is-half-transparent px-5"
    data-testid="order-details-status"
  >
    {{ status.label }}
  </BTag>
</template>
<script>
import STATUSES from '@/app-buyer/views/order-details/order-statuses.json';

export default {
  name: 'OrderListElementStatus',
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  computed: {
    status() {
      if (!this.order?.product_lifecycle?.statuses) return null;
      return STATUSES.reduce((result, currentStatus) => {
        const found = this.order.product_lifecycle.statuses
          .find((e) => currentStatus?.slugs?.includes(e.slug));
        if (found) {
          return {
            ...currentStatus,
            date: found.created_at,
          };
        }
        return result;
      }, null);
    },
  },
};
</script>
