<template>
  <div :class="{ 'mb-5': hasBottomMargin }">
    <h1 class="is-size-5 has-text-weight-bold mb-4">
      {{ pageTitle }}
    </h1>
    <div class="filters-wrap">
      <slot name="filters" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    pageTitle: {
      type: String,
      default: '',
    },
    hasBottomMargin: {
      type: Boolean,
      default: true,
    },
  },

};
</script>

<style lang="scss" scoped>
.filters-wrap {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
}
</style>
