import {
  MODEL, PARSER, PDF_PATH, SET_VIEWER_DATA, SHOW_VIEWER, UUID, VISIBLE,
} from './types';
import { RESET_STATE } from '../types';
import getState from './state';

export default {
  /**
     * Sets the parser and uuid in the state
     *
     * @param state
     * @param data
     * */
  [SET_VIEWER_DATA](state, {
    model, parser, uuid, pdfPath = null,
  }) {
    state[MODEL] = model;
    if (pdfPath) {
      state[PDF_PATH] = pdfPath;
      state[PARSER] = null;
      state[UUID] = null;
    } else {
      state[PDF_PATH] = null;
      state[PARSER] = parser;
      state[UUID] = uuid;
    }
  },
  /**
     * Set the visibility of the viewer
     * @param state
     * @param bool
     */
  [SHOW_VIEWER](state, bool) {
    state[VISIBLE] = bool;
  },
  // eslint-disable-next-line no-unused-vars
  [RESET_STATE](state) {
    // eslint-disable-next-line no-param-reassign
    state = Object.assign(state, getState());
  },
};
