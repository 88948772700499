<template>
  <div class="gm-dropdown search-dropdown is-relative">
    <b-dropdown
      ref="dropdown"
      v-bind="$attrs"
      position="is-bottom-left"
      expanded
      :type="type"
      :value="value"
      v-on="$listeners"
      @active-change="focusInput"
    >
      <button
        slot="trigger"
        ref="trigger-button"
        v-bind="$attrs"
        class="button is-small is-fullwidth is-result"
        :class="{'is-rounded' : rounded}"
      >
        <p
          class="subtitle is-7"
          :style="!selected && 'opacity: 0.5'"
        >
          {{ selected ? selected[visibleKey] : placeholder }}
          <icon-font
            style="font-size: 0.4rem; color: var(--text-light); font-weight: lighter;"
            icon="chevron-down"
          />
        </p>
      </button>
      <div
        v-if="grouped"
        class="outside-wrapper"
      >
        <b-dropdown-item
          style="padding: 0.5rem; position: sticky; top: 0; background-color: white; z-index: 1"
        >
          <b-input
            ref="search"
            v-model="searchInput"
            :placeholder="placeholder"
            custom-class="is-small"
            expanded
            icon="search"
            rounded
            @click.native.stop
            @input="resetNavigation"
            @keydown.stop.prevent
            @keyup.native.stop.prevent="navigate"
          />
        </b-dropdown-item>
        <section
          v-for="(optionGroup, key) in filteredOptions"
          :key="key"
          class="wrapper"
        >
          <p
            class="title is-7 m-b-none-important p-sm-important"
          >
            {{ key }}
          </p>
          <b-dropdown-item
            v-for="(option, index) in optionGroup"
            :key="option.id || option[visibleKey]"
            :class="{'is-highlighted' : group === key && highlightedIndex === index}"
            :value="option[valueKey]"
          >
            <p
              class="subtitle is-7 m-b-none"
            >
              {{ option[visibleKey] }}
            </p>
          </b-dropdown-item>
        </section>
      </div>
      <div
        v-else
        class="outside-wrapper"
      >
        <b-dropdown-item
          style="padding: 0.5rem; position: sticky; top: 0; background-color: white; z-index: 1"
        >
          <b-input
            ref="search"
            v-model="searchInput"
            v-bind="$attrs"
            :placeholder="placeholder"
            custom-class="is-small"
            expanded
            icon="search"
            :rounded="rounded"
            @click.native.stop
            @input="resetNavigation"
            @keydown.stop.prevent
            @keyup.native.stop.prevent="navigate"
          />
        </b-dropdown-item>
        <b-dropdown-item
          v-for="(option, index) in filteredOptions"
          :key="option.id || option[visibleKey]"
          :class="{'is-highlighted' : highlightedIndex === index}"
          :value="option[valueKey]"
          @click="select(option)"
        >
          <p
            class="subtitle is-7 m-b-none"
            style="word-break: normal;"
          >
            {{ option[visibleKey] }}
          </p>
        </b-dropdown-item>
      </div>
    </b-dropdown>
  </div>
</template>

<script>
import * as Fuse from 'fuse.js';
import IconFont from '../icon-font/icon-font.vue';

export default {
  components: { IconFont },
  props: {
    value: {
      type: Number,
      default: () => null,
    },
    options: {
      type: [Array, Object],
      required: true,
    },
    placeholder: {
      type: String,
      default: () => null,
    },
    type: {
      type: String,
      default: () => null,
    },
    message: {
      type: String,
      default: () => null,
    },
    grouped: {
      type: Boolean,
      default: () => false,
    },
    visibleKey: {
      type: String,
      required: true,
    },
    valueKey: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: () => null,
    },
    hasIcon: {
      type: Boolean,
      default: () => true,
    },
    rounded: {
      type: Boolean,
      default: () => false,
    },
    hasLabel: {
      type: Boolean,
      default: () => true,
    },

  },
  data() {
    return {
      searchInput: '',
      highlightedIndex: 0,
      group: null,
      fuse: null,
    };
  },
  computed: {
    filteredOptions() {
      const result = this.fuse.search(this.searchInput);
      if (result.length) {
        if (this.grouped) {
          return result.reduce((reduced, current) => {
            const identifier = current.__fuzzy_identifier;
            if (!reduced[identifier]) {
              // eslint-disable-next-line no-param-reassign
              reduced[identifier] = [];
            }
            reduced[identifier].push(current);
            return reduced;
          }, {});
        }
        return result;
      }
      return this.options;
    },
    selected() {
      if (this.grouped) {
        let result = null;
        Object.keys(this.options)
          .forEach((key) => {
            const found = this.options[key].find((e) => e[this.valueKey] === this.value);
            if (found) {
              result = found;
            }
          });
        return result;
      }
      return this.options.find((e) => e[this.valueKey] === this.value);
    },
  },
  watch: {
    options: {
      handler() {
        const keys = [this.visibleKey];
        const list = [];
        if (this.grouped) {
          Object.keys(this.options)
            .forEach((keyPrefix) => {
              this.options[keyPrefix].forEach((option) => {
                // eslint-disable-next-line no-param-reassign
                option.__fuzzy_identifier = keyPrefix;
                list.push(option);
              });
            });
        } else {
          this.options.forEach((option) => list.push(option));
        }
        const searchOptions = {
          shouldSort: false,
          location: 0,
          threshold: 0.2,
          distance: 50,
          maxPatternLength: 25,
          minMatchCharLength: 2,
          keys,
        };
        this.fuse = new Fuse(list, searchOptions);
      },
      immediate: true,
    },
  },
  methods: {
    select(selected) {
      this.searchInput = '';
      this.highlightedIndex = -1;
      this.$emit('input', selected[this.valueKey]);
      this.$nextTick(() => {
        if (this.$refs.dropdown.isActive) {
          this.$refs.dropdown.toggle();
        }
      });
    },
    navigate(event) {
      const groups = Object.keys(this.filteredOptions);
      const [first] = groups;
      if (event.key === 'ArrowDown') {
        if (!this.group) {
          this.group = first;
        }
        if (this.highlightedIndex < this.filteredOptions[this.group].length) {
          this.highlightedIndex++;
        } else {
          const index = groups.indexOf(this.group);
          if (index < groups.length - 1) {
            this.highlightedIndex = 0;
            this.group = groups[index + 1];
          }
        }
        this.scrollToHighlighted();
      } else if (event.key === 'ArrowUp') {
        if (!this.group) {
          this.group = first;
        }
        if (this.highlightedIndex > 0) {
          this.highlightedIndex--;
        } else {
          const index = groups.indexOf(this.group);
          if (index > 0) {
            this.group = groups[index - 1];
            this.highlightedIndex = this.filteredOptions[this.group].length - 1;
          }
        }
        this.scrollToHighlighted();
      } else if (event.key === 'Enter') {
        this.select(this.filteredOptions[this.group][this.highlightedIndex]);
      }
    },
    scrollToHighlighted() {
      this.$nextTick(() => {
        const highlighted = this.$el.getElementsByClassName('is-highlighted')[0];
        if (highlighted) {
          highlighted.scrollIntoView({
            block: 'nearest',
          });
        }
      });
    },
    resetNavigation() {
      this.$nextTick(() => {
        const [first] = Object.keys(this.filteredOptions);
        this.group = first;
        this.highlightedIndex = 0;
      });
    },
    focusInput(isActive) {
      setTimeout(() => {
        if (isActive) {
          this.$refs.search.focus();
          this.$refs['trigger-button'].addEventListener('keyup', this.preventEnter);
        } else {
          this.$refs['trigger-button'].removeEventListener('keyup', this.preventEnter);
        }
      }, 500);
    },
    preventEnter(event) {
      if (event.key === 'Enter') {
        event.preventDefault();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .outside-wrapper {
    max-height: 20rem;
    overflow-y: auto;
  }

  .title,
  .subtitle {
    margin: 0;
  }

  .title {
    padding: 0.5rem;
  }

  .is-result:active,
  .is-result:focus {
    border-color: #FBAD39 !important;
    box-shadow: none !important;
  }

  .is-result p {
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .is-highlighted {
    background-color: var(--bg-gray3);
  }
</style>
