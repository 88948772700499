import accessDragData from '@/app-buyer/mixins/drag-drop-access';

const draggableElement = {
  mixins: [accessDragData],
  data() {
    return {
      isDragged: false,
    };
  },
  methods: {
    onDragStart(data, type) {
      this.isDragged = true;
      this._setDragData(data, type);
    },
    onDragEnd() {
      this.isDragged = false;
      this._removeDragData();
    },
  },
};

export default draggableElement;
