<template>
  <div>
    <ConfirmApplicableTooltip
      :confirm-applicable="confirmApplicable"
      @cancel="cancelNote"
      @confirm="handleConfirm"
    />
    <p class="is-size-7">
      Notes
    </p>
    <BInput
      v-model="notes"
      maxlength="250"
      type="textarea"
      @input="debounceUpdate"
    />
  </div>
</template>
<script>
import { debounce } from 'lodash/function';
import { safeMultipleUpdate } from '@/app-buyer/components/project/mixins';
import ConfirmApplicableTooltip from '@/app-buyer/components/project/ConfirmApplicableTooltip.vue';

export default {
  name: 'PartFormNoteComponent',
  components: { ConfirmApplicableTooltip },
  mixins: [safeMultipleUpdate],
  props: {
    configurations: {
      type: Object,
      required: true,
    },
    leadHash: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      notes: null,
    };
  },
  computed: {
    values() {
      return Array.from(new Set(Object.values(this.configurations).map((e) => e.notes)));
    },
    leadNote() {
      return this.configurations?.[this.leadHash]?.notes;
    },
  },
  watch: {
    leadNote: {
      handler(value) {
        this.notes = value;
      },
      immediate: true,
    },
  },
  methods: {
    debounceUpdate: debounce(function doDebounceUpdate() {
      this.startUpdate(this.notes, { entitySlug: 'notes' });
    }, 500),
    cancelNote() {
      this.notes = this.leadNote;
      this.confirmApplicable = null;
    },
    handleConfirm({ canApply }) {
      this.finaliseUpdate(canApply, 'notes');
    },
  },
};
</script>
