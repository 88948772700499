<template>
  <span
    :class="`icon-${icon}`"
    v-bind="$attrs"
  >
    <span
      v-if="paths"
      class="is-relative"
    >
      <span
        v-for="n in paths"
        :key="n"
        :style="n > 1 && 'position: absolute; top: 0; left: 0;'"
        :class="'path' + n"
      />
    </span>
  </span>
</template>

<script>
export default {
  name: 'IconFont',
  props: {
    icon: {
      type: String,
      default: () => null,
    },
    paths: {
      type: Number,
      default: () => null,
    },
  },
};
</script>
