/* eslint-disable no-param-reassign */
export const _NOTIFICATION_MODULE = '_NOTIFICATION_MODULE';
export const _NOTIFICATIONS = '_NOTIFICATIONS';
export const _ADD_NOTIFICATION = '_ADD_NOTIFICATION';
export const _REMOVE_NOTIFICATION = '_REMOVE_NOTIFICATION';

export default function registerNotificationVuexModule(store, idGenerator) {
  store.registerModule(_NOTIFICATION_MODULE, {
    namespaced: true,
    state: {
      [_NOTIFICATIONS]: [],
    },
    mutations: {
      [_ADD_NOTIFICATION](state, notification) {
        if (notification.unique) {
          const present = state[_NOTIFICATIONS].find((e) => e.message === notification.message);
          if (!present) {
            notification.id = idGenerator();
            state[_NOTIFICATIONS].push(notification);
          }
        } else {
          notification.id = idGenerator();
          state[_NOTIFICATIONS].push(notification);
        }
      },
      [_REMOVE_NOTIFICATION](state, id) {
        const index = state[_NOTIFICATIONS].findIndex((e) => e.id === id);
        if (index > -1) {
          state[_NOTIFICATIONS].splice(index, 1);
        }
      },
    },
  });
}
