<template>
  <a
    v-if="trackingUrl"
    class="tracking-btn gm-theme gm-button outlined small gm-grey-blue
        has-background-white px-4 py-2 has-text-weight-bold has-text-centered"
    :href="trackingUrl"
    download
    target="_blank"
  >
    Track the shipment
  </a>
  <div v-else />
</template>

<script>
export default {
  name: 'InvoiceTableTracking',

  props: {
    invoice: {
      type: Object,
      required: true,
    },
  },

  computed: {
    trackingUrl() {
      const rfqs = this.invoice.rfqs || this.invoice.last_5_rfqs;
      const trackingUrls = rfqs?.map((rfq) => rfq?.delivery_line?.delivery?.tracking_url);
      if (trackingUrls?.every((e) => e === trackingUrls[0])) {
        return trackingUrls[0];
      }
      return null;
    },
  },
};
</script>
<style scoped>
  .tracking-btn {
    margin: 1rem 0.5rem;
  }
  .has-background-white:hover {
    background-color: #338dbc !important;
  }
</style>
