import Vue from 'vue';
import { SET } from '../types';
import {
  DATA, SET_SPECIFICATION, SPECIFICATION, UPDATE_STATE,
} from './types';

export default {
  /**
     * Sets data in the state
     *
     * @param state
     * @param data
     * */
  [SET](state, {
    model, gView, holes, bosses, threadInputs, tolerances,
  }) {
    Vue.set(state[DATA], model.hash, {
      holes, bosses, threadInputs, tolerances,
    });
    if (gView) Vue.set(state[DATA], 'gView', gView);
  },
  [SET_SPECIFICATION](state, data) {
    state[SPECIFICATION] = data;
  },
  [UPDATE_STATE]() {},
};
