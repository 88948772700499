/* eslint-disable no-param-reassign,camelcase */
import Vue from 'vue';
import pushEcommerceEvent from '@/shared/misc/ga4-ecommerce';
import { GET_USER_ID, USER_MODULE } from '@/app-buyer/store/modules/user/types';
import Api from '../../../api/api';
import ENDPOINTS from '../../../api/endpoints';
import {
  ADD, DELETE, GET, PAGINATION, SET,
} from '../types';
import {
  CONFIRM_PAYMENT,
  FETCH_PAYMENT_METHODS,
  GENERATE_FORMAL_QUOTE,
  GENERATE_PROFORMA,
  PAY,
  PAY_VIA_CREDIT,
  PAYMENT_METHOD,
  PAYMENT_METHODS,
  SAVE_CARD,
  SET_PAYMENT_METHOD,
  SET_STRIPE,
  SETUP_STRIPE,
} from './types';
import StripePayment from '../../../../shared/misc/stripe-payment';

export default {
  /**
     * Get all cart items
     *
     * @param {Object} context
     * @param {Object} payload
     * @param {boolean} payload.next
     * @param {Object} payload.params
     * @returns {Promise<Object>} data
     */
  async [GET]({ commit, state }, { params = {}, next } = {}) {
    if (next) {
      if (state[PAGINATION].nextPage) {
        params.page = state[PAGINATION].nextPage;
      } else {
        return;
      }
    } else if (!params.page) {
      params.page = 1;
    }
    try {
      const { data } = await Api.get(ENDPOINTS.CART.SHOW, { params });
      if (data?.data) {
        commit(SET, {
          data: data.data,
          clear: !next,
        });
      } else {
        commit(SET, {
          data: { items: [] },
          clear: true,
        });
      }
    } catch (e) {
      commit(SET, {
        data: { items: [] },
        clear: true,
      });
    }
  },

  /**
     * Generates a proforma for the current cart
     * @param context
     * @param client_reference  Optional parameter to put on the proforma
     * @returns {Promise<Object>}
     */
  async [GENERATE_PROFORMA](context, client_reference) {
    const { data } = await Api.post(
      ENDPOINTS.CART.GENERATE_PROFORMA,
      { params: { client_reference } },
    );
    return data;
  },

  /**
     * Generates a formal quote for the current cart
     * @param context
     * @param client_reference  Optional parameter to put on the quote
     * @returns {Promise<Object>}
     */
  async [GENERATE_FORMAL_QUOTE](context, client_reference) {
    const { data } = await Api.post(
      ENDPOINTS.CART.GENERATE_FORMAL_QUOTE,
      null,
      { params: { client_reference } },
    );
    return data;
  },

  /**
     * Add a part to the cart and refreshes the cart list
     * @param {Object} context
     * @param {Object} rfq
     * @returns {Promise<Object>}
     */
  async [ADD]({ dispatch, rootGetters }, rfq) {
    const { data, status } = await Api.post(ENDPOINTS.CART.ITEMS.STORE, {
      quote_proposal_id: rfq.proposal.id,
    })
      .catch((e) => e.response);
    if (status === 200) {
      dispatch(GET);
      Vue.set(rfq, 'is_in_current_user_cart', true);
      pushEcommerceEvent(
        'add_to_cart',
        {
          items: [
            {
              item_name: rfq.name,
              item_id: rfq.id,
              price: rfq.proposal?.unit_cost / 100,
              quantity: rfq.quantity_initial,
              item_category: rfq.configuration_object?.service?.string_value,
              item_category2: rfq.configuration_object?.material?.string_value,
            },
          ],
        },
        rootGetters[`${USER_MODULE}/${GET_USER_ID}`],
      );
    }
    return {
      data,
      status,
    };
  },

  /**
     * Removes an item from the cart
     * @param {Object} context
     * @param item
     * @returns {Promise<boolean>}
     */
  async [DELETE]({ commit, dispatch, rootGetters }, item) {
    const cartItemId = item.cart_item ? item.cart_item.id : item.id;

    const { data, status } = await Api.delete(`${ENDPOINTS.CART.ITEMS.REMOVE}/${cartItemId}`)
      .catch((e) => e.response);
    if (status === 200) {
      commit(DELETE, item);
      Vue.set(item, 'is_in_current_user_cart', false);
      dispatch(GET);
      pushEcommerceEvent(
        'remove_from_cart',
        {
          items: [
            {
              item_name: item.name,
              item_id: item.quote_id || item.id,
              price: item.unit_cost || item.proposal?.unit_cost,
              quantity: item.quantity_initial,
              item_category: item.configuration_object?.service?.string_value,
              item_category2: item.configuration_object?.material?.string_value,
            },
          ],
        },
        rootGetters[`${USER_MODULE}/${GET_USER_ID}`],
      );
    }
    return {
      data,
      status,
    };
  },

  /**
     *
     * @param commit
     * @param style
     * @returns {Promise<StripePayment>}
     */
  async [SETUP_STRIPE]({ commit }, style) {
    const sp = new StripePayment(style);
    commit(SET_STRIPE, sp);
    return sp;
  },

  /**
     * Initiate payment
     * @param state
     * @param payment_method_id
     * @param client_reference
     * @returns {Promise<AxiosResponse<T>>}
     */
  [PAY]({ state }, { payment_method_id, client_reference }) {
    return Api.post(ENDPOINTS.CART.PAY, {
      payment_method_id,
      client_reference,
      save_card: state[SAVE_CARD],
    });
  },

  /**
   * Initiate payment via Credit
   * @param context
   * @param client_reference
   * @param order_reference_file
   * @returns {Promise<Object>}
   */
  async [PAY_VIA_CREDIT](context, { client_reference, order_reference_file }) {
    const formData = new FormData();
    formData.append('client_reference', client_reference);
    if (order_reference_file) {
      formData.append('order_reference_file', order_reference_file);
    }

    const { data } = await Api.post(ENDPOINTS.CART.PAY_VIA_CREDIT, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }).catch((e) => e.response);

    return data;
  },

  /**
     * Check payment success
     * @param context
     * @param {Object} payload
     * @param {string} payload.payment_intent_id
     * @param {string} payload.client_reference
     * @param {boolean} payload.save_card
     * @returns {Promise<Object>}
     */
  async [CONFIRM_PAYMENT](context, { payment_intent_id, client_reference, save_card }) {
    const { data } = await Api.post(ENDPOINTS.CART.CONFIRM_PAYMENT, {
      payment_intent_id,
      client_reference,
      save_card,
    })
      .catch((e) => e.response);
    return data;
  },

  /**
     * Get all available payment methods (eg. saved cards)
     * @param commit
     * @param state
     * @returns {Promise<boolean|T>}
     */
  async [FETCH_PAYMENT_METHODS]({ commit, state }) {
    const { data } = await Api.get(ENDPOINTS.USER.PAYMENT_METHODS);
    if (data?.data) {
      state[PAYMENT_METHODS] = data.data;
      commit(SET_PAYMENT_METHOD, state[PAYMENT_METHOD] = data?.[0]?.payment_method_id || null);
      return data;
    }
    return false;
  },
};
