<template>
  <span />
</template>

<script>
import getEnvironmentVariable from '../../../shared/misc/env-variable';

const luckyOrangeBody = `
        window.__lo_site_id = 157380;
        (function() {
            var wa = document.createElement('script'); wa.type = 'text/javascript'; wa.async = true;
            wa.src = 'https://d10lpsik1i8c69.cloudfront.net/w.js';
            var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(wa, s);
        })();
    `;
// SEGMENT TRACKING TO REMOVE
const gTagScript = `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','${getEnvironmentVariable('VUE_APP_GTM_ID')}');
    `;
// SEGMENT TRACKING TO REMOVE

const segmentScript = `
  !function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};analytics._writeKey="${getEnvironmentVariable('VUE_APP_SEGMENT_ID')}";;analytics.SNIPPET_VERSION="4.15.3";
  analytics.load("${getEnvironmentVariable('VUE_APP_SEGMENT_ID')}");
  analytics.page();
  }}();
`;

const vwoScript = `
window._vwo_code = window._vwo_code || (function(){
var account_id=588829,
settings_tolerance=2000,
library_tolerance=2500,
use_existing_jquery=false,
is_spa=1,
hide_element='body',

f=false,d=document,code={use_existing_jquery:function(){return use_existing_jquery;},library_tolerance:function(){return library_tolerance;},finish:function(){if(!f){f=true;var a=d.getElementById('_vis_opt_path_hides');if(a)a.parentNode.removeChild(a);}},finished:function(){return f;},load:function(a){var b=d.createElement('script');b.src=a;b.type='text/javascript';b.innerText;b.onerror=function(){_vwo_code.finish();};d.getElementsByTagName('head')[0].appendChild(b);},init:function(){
window.settings_timer=setTimeout(function () {_vwo_code.finish() },settings_tolerance);var a=d.createElement('style'),b=hide_element?hide_element+'{opacity:0 !important;filter:alpha(opacity=0) !important;background:none !important;}':'',h=d.getElementsByTagName('head')[0];a.setAttribute('id','_vis_opt_path_hides');a.setAttribute('type','text/css');if(a.styleSheet)a.styleSheet.cssText=b;else a.appendChild(d.createTextNode(b));h.appendChild(a);this.load('https://dev.visualwebsiteoptimizer.com/j.php?a='+account_id+'&u='+encodeURIComponent(d.URL)+'&f='+(+is_spa)+'&r='+Math.random());return settings_timer; }};window._vwo_settings_timer = code.init(); return code; }());
`;

const scripts = [
  {
    body: luckyOrangeBody,
    required: getEnvironmentVariable('VUE_APP_ENV') === 'production',
    target: document.head,
  },
  {
    body: vwoScript,
    required: getEnvironmentVariable('VUE_APP_ENV') === 'production',
    target: document.head,
  },
  {
    src: 'https://js.hs-scripts.com/9195657.js',
    required: getEnvironmentVariable('VUE_APP_ENV') === 'production',
    target: document.body,
    attributes: {
      id: 'hs-script-loader',
      async: true,
      defer: true,
    },
  },
  {
    src: `https://www.google.com/recaptcha/api.js?render=${getEnvironmentVariable('VUE_APP_RECAPTCHA_KEY')}`,
    required: !!getEnvironmentVariable('VUE_APP_RECAPTCHA_KEY'),
    target: document.body,
  },
  // SEGMENT TRACKING
  {
    body: segmentScript,
    required: getEnvironmentVariable('VUE_APP_SEGMENT_ENABLED'),
    target: document.head,
  },
  // SEGMENT TRACKING TO REMOVE
  {
    body: gTagScript,
    required: getEnvironmentVariable('VUE_APP_ENV') === 'production',
    target: document.head,
  },
  // SEGMENT TRACKING TO REMOVE
];

export default {
  name: 'ExternalScripts',
  mounted() {
    scripts.forEach(this.addScript);
  },
  methods: {
    addScript(obj) {
      if (obj.required) {
        const script = document.createElement('script');
        if (obj.attributes) {
          Object.entries(obj.attributes).forEach(([attribute, value]) => {
            script.setAttribute(attribute, value === true ? attribute : value);
          });
        }
        if (obj.body) {
          const innerScript = document.createTextNode(obj.body);
          script.appendChild(innerScript);
        }
        if (obj.src) script.src = obj.src;
        obj.target.appendChild(script);
      }
    },
  },
};
</script>

<style scoped>

</style>
