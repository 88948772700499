import { Model as BaseModel } from 'vue-api-query';
import getEnvironmentVariable from '../../shared/misc/env-variable';

export default class Model extends BaseModel {
  // eslint-disable-next-line class-methods-use-this
  baseURL() {
    return `${getEnvironmentVariable('VUE_APP_API_URL')}/api/v2`;
  }

  request(config) {
    return this.$http.request(config);
  }
}
