<template>
  <section
    v-if="USER_DATA"
    class="is-flex is-flex-direction-column p-6"
    style="min-height: calc(100vh - var(--navbar-height))"
  >
    <PageHeader
      :has-bottom-margin="false"
      page-title="Your Quotes"
    >
      <template #filters>
        <fieldset
          class="mr-4"
          style="flex-basis: 300px; flex-shrink: 2"
        >
          <BField>
            <ProjectPicker v-model="projectFilter" />
          </BField>
        </fieldset>
        <fieldset
          class="mr-4"
          style="flex-basis: 300px; flex-shrink: 2"
        >
          <BField>
            <StatusFilter v-model="statusFilter" />
          </BField>
        </fieldset>
        <PortalTarget
          v-if="$route.query.origin"
          name="quote-list-header"
        />
        <fieldset
          v-else
          style="min-width: 100px; flex-basis: 300px"
        >
          <DebounceInput
            v-model="searchString"
            :loading="loading"
          />
        </fieldset>
        <span>
          <button
            v-show="hasFiltersSelected"
            class="button-no-style has-text-info is-size-7/6 is-underlined ml-4 mt-2"
            @click="handleClearFilters"
          >
            Clear all filters
          </button>
        </span>
      </template>
    </PageHeader>

    <InfoTable
      ref="infoTable"
      :heading-length="quoteColumns.length"
      :loading="isLoading"
      :pagination="pagination"
      :table-data="quoteListings"
      :has-been-loaded="hasBeenLoaded"
      type="quotes"
      @cancelled="handleCancelled"
      @duplicated="loadQuotes"
      @load-more="loadAllRfqs"
      @page-change="loadQuotes({ page: $event })"
      @clear-filters="handleClearFilters"
      @update:project="updateProject"
    >
      <template #table-header>
        <StickyTableHeader
          v-if="!firstLoad && quoteListings.length"
          :columns="quoteColumns"
        />
      </template>
    </InfoTable>
  </section>
</template>

<script>
/* eslint-disable camelcase */
import { mapActions, mapMutations, mapState } from 'vuex';
import {
  METADATA,
  SET_METADATA,
  USER_DATA,
  USER_MODULE,
} from '@/app-buyer/store/modules/user/types';
import { RFQ_MODULE } from '@/app-buyer/store/modules/rfq/types';
import {
  GET_ALL_QUOTE_RFQS,
  GET_QUOTES,
  QUOTES,
  QUOTES_FILTERS,
  QUOTES_LOADING,
  QUOTES_META,
  QUOTES_MODULE,
} from '@/app-buyer/store/modules/quotes/types';
import {
  LEAVE_PROJECT_CHANNEL,
  LISTEN_PROJECT_CHANNEL,
  PROJECT_MODULE,
} from '@/app-buyer/store/modules/projects/types';
import { SET_ALL } from '@/app-buyer/store/modules/types';
import ProjectPicker from '@/app-buyer/components/project/ProjectPicker.vue';
import DebounceInput from '@/app-buyer/components/project/DebounceInput.vue';
import StatusFilter from '@/app-buyer/views/quote-list/StatusPicker.vue';
import InfoTable from '@/app-buyer/components/info-table/InfoTable.vue';
import StickyTableHeader from '@/app-buyer/views/orders/StickyTableHeader.vue';
import PageHeader from '@/app-buyer/components/global/PageHeader.vue';
import { quoteColumns } from '@/app-buyer/components/info-table/table-columns';

const findRfq = (listing, id) => listing.rfqs.some((rfq) => rfq.id === id);
const getScrollParent = (node) => {
  if (node == null) return null;
  if (node.scrollHeight > node.clientHeight) {
    return node;
  }
  return getScrollParent(node.parentNode);
};

export default {
  name: 'QuotesPage',
  components: {
    StatusFilter,
    DebounceInput,
    ProjectPicker,
    InfoTable,
    StickyTableHeader,
    PageHeader,
  },
  data() {
    return {
      loading: false,
      isReviseLoading: false,
      fromState: false,
      searchString: this.$route?.query?.search || '',
      innerSearchString: null,
      projectFilter: null,
      statusFilter: this.$route?.query?.status || null,
      quoteListings: [],
      pagination: null,
      openDetailed: [],
      sortDirection: 'desc',
      sorter: 'created_at',
      firstLoad: true,
      hasBeenLoaded: false,
      quoteColumns,
    };
  },

  computed: {
    ...mapState(QUOTES_MODULE, {
      QUOTES,
      QUOTES_META,
      QUOTES_FILTERS,
      QUOTES_LOADING,
    }),

    ...mapState(USER_MODULE, {
      USER_DATA,
    }),

    ...mapState(USER_MODULE, {
      METADATA,
    }),

    openDetailedAsObject() {
      return Object.fromEntries(this.openDetailed.map((id) => [id, true]));
    },

    earliestPartInGroup() {
      return Object.fromEntries(
        this.quoteListings.map((listing) => {
          const earliest = listing.rfqs.reduce((earliestDate, rfq) => {
            if (!earliestDate) return rfq.created_at;
            const e = new Date(earliestDate);
            const c = new Date(rfq.created_at);
            return e > c ? rfq.created_at : earliestDate;
          }, null);
          return [listing.id, earliest];
        }),
      );
    },

    hasnt_reached_status() {
      return this.statusFilter === 'quote-requested' ? 'quote-sent' : 'ordered';
    },

    hasFiltersSelected() {
      return this.searchString || this.projectFilter || this.statusFilter;
    },
    activeProjects() {
      // Subscribe to all project websockets
      let projectIds = [];

      this.quoteListings.forEach((d) => {
        d.rfqs.forEach((rfq) => {
          projectIds = [...projectIds, rfq.project_hash];
        });
      });

      return [...new Set(projectIds)];
    },

    isLoading() {
      return (this.loading || this.isReviseLoading);
    },
  },

  watch: {
    searchString(value) {
      if (!this.fromState) this.loadQuotes({ search: value, page: 1 });
    },

    projectFilter(value) {
      if (!this.fromState) this.loadQuotes({ projectFilter: value, page: 1 });
    },

    statusFilter(value) {
      if (!this.fromState) {
        this.loadQuotes({
          statusFilter: value !== 'quote-requested' ? value : null,
          page: 1,
        });
      }
    },
    [QUOTES]: {
      handler(newValue) {
        this.quoteListings = newValue;
      },
      deep: true,
    },

    [QUOTES_LOADING](value) {
      this.isReviseLoading = !!value;

      setTimeout(() => {
        this.isReviseLoading = false;
      }, 5000);
    },
  },

  created() {
    const unsubscribe = this.$store?.subscribe(({ type, payload }) => {
      if (type === 'WEBSOCKET_MODULE/RFQ_STATUS_CHANGED' && payload.id) {
        this.checkAndRefresh(payload.id);
      }
    });

    this.$once('hook:beforeDestroy', () => unsubscribe?.());
  },

  beforeDestroy() {
    this[LEAVE_PROJECT_CHANNEL]({ leaveAllExcept: this.activeProjects });
  },

  async mounted() {
    if (!this[QUOTES]?.length) {
      this.$nextTick(async () => {
        await this.loadQuotes({ draft_hash: this.$route?.query?.origin });
        this.subscribeToWebsockets();
      });
    } else {
      this.fromState = true;

      this.firstLoad = false;
      this.quoteListings = this[QUOTES];
      this.pagination = this[QUOTES_META];
      this.statusFilter = this[QUOTES_FILTERS]?.filters?.['activeRfqs.has_reached_status'];
      this.searchString = this[QUOTES_FILTERS]?.search;
      this.projectFilter = this[QUOTES_FILTERS]?.activeRfqs?.project_hash;
      await this.loadSideEffects(this[QUOTES], this[QUOTES_META]);

      this.subscribeToWebsockets();
      this.fromState = false;
    }
  },

  methods: {
    ...mapActions(QUOTES_MODULE, {
      GET_QUOTES,
      GET_ALL_QUOTE_RFQS,
    }),
    ...mapMutations(RFQ_MODULE, {
      SET_ALL,
    }),
    ...mapMutations(USER_MODULE, {
      SET_METADATA,
    }),
    ...mapActions(PROJECT_MODULE, {
      LEAVE_PROJECT_CHANNEL,
      LISTEN_PROJECT_CHANNEL,
    }),

    subscribeToWebsockets() {
      this.activeProjects.forEach((projectId) => {
        this[LISTEN_PROJECT_CHANNEL](projectId);
      });
    },

    handleCancelled() {
      // eslint-disable-next-line camelcase
      const quotes_without_orders_count = this[METADATA]?.quotes_without_orders_count - 1;
      this[SET_METADATA]({
        ...this[METADATA],
        quotes_without_orders_count,
      });

      this.loadQuotes();
    },

    async loadQuotes({
                       page = +this.$route?.query?.page || 1,
                       search = this.searchString,
                       projectFilter = this.projectFilter,
                       has_reached_status = this.statusFilter,
                       hasnt_reached_status = this.hasnt_reached_status,
                     } = {}) {
      this.loading = true;
      try {
        const filters = {
          'activeRfqs.hasnt_reached_status': hasnt_reached_status,
          'activeRfqs.has_reached_status': has_reached_status,
          'activeRfqs.project_hash': projectFilter,
        };
        const { data, meta } = await this[GET_QUOTES]({ page, search, filters });
        this.quoteListings = data;
        this.pagination = meta;
        await this.loadSideEffects(data, meta);
        this.subscribeToWebsockets();
      } catch {
        this.$buefy.toast.open({
          message: 'Something went wrong!',
        });
      } finally {
        this.loading = false;
        this.firstLoad = false;
      }
    },

    async loadAllRfqs(id) {
      const hash = id.id;
      const { data } = await this[GET_ALL_QUOTE_RFQS]({ hash });
      if (data) {
        const index = this.quoteListings.findIndex((quote) => quote.id === hash);
        if (index > -1) {
          this.quoteListings[index].rfqs = data[0].rfqs;
        }
      }
      this.$refs.infoTable.handleLoadMoreLoading(false, id.i);
      this.hasBeenLoaded = true;
      this.reset();
      return null;
    },

    reset() {
      this.$nextTick(() => {
        this.hasBeenLoaded = false
      })
    },

    async loadSideEffects(data, meta, draftHash) {
      const scrollParent = getScrollParent(this.$el);
      if (scrollParent) {
        scrollParent.scrollTo(0, 0);
      }
      await this.$router.replace({
        ...this.$route,
        query: {
          ...this.$route.query,
          origin: draftHash,
          page: meta.current_page,
        },
      }).catch(() => {
      });
      this.syncAllRfqsToState(data);
      this.$nextTick(() => {
        this.openDetailed = data.map(({ id }) => id);
      });
    },

    syncAllRfqsToState(listings) {
      const allRfqs = listings.reduce(
        (res, listing) => [...res, ...(listing?.rfqs || [])],
        [],
      );

      this[SET_ALL]({
        data: allRfqs || [],
        clear: true,
      });
    },

    checkAndRefresh(id) {
      const isOnCurrentPage = this.quoteListings.some((listing) => findRfq(listing, id));
      if (isOnCurrentPage) {
        this.loadQuotes(this.pagination.current_page, null, false);
      }
    },

    updateProject(project) {
      this.quoteListings = this.quoteListings.map((listing) => {
        const newRfqs = listing.rfqs.map((rfq) => {
          if (rfq.project_hash !== project.hash) return rfq;
          return {
            ...rfq,
            project_hash: project.hash,
            project_name: project.name,
            project_members: project.members,
          };
        });
        return {
          ...listing,
          rfqs: newRfqs,
        };
      });
    },

    handleClearFilters() {
      this.searchString = null;
      this.projectFilter = null;
      this.statusFilter = null;
      const { status, search, ...query } = this.$route.query;
      this.$router.replace({ query });
    },

    handleUpdatingRfqs(requestedQuoteInfo) {
      console.log(requestedQuoteInfo);
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
.p-6 {
  @media all and (max-width: variables.$breakpoint-lg) {
    padding: 20px !important;
  }

  @media all and (max-width: variables.$breakpoint-md) {
    padding: 1rem !important;
  }
}
</style>
