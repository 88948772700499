/* eslint-disable camelcase */
import { VIEWER_MODULE } from '@/app-buyer/store/modules/viewer/types';
import {
  LOGIN,
  LOGIN_SUCCESS,
  LOGOUT,
  MASQUERADING,
  REGISTER,
  SET_ACCESS_TOKEN,
  SET_LOGGED_IN,
  UPLOAD_BEFORE_AUTH,
} from './types';
import {
  NAVIGATION_MODULE,
  ON_LOGIN_REDIRECT,
  SET_LOGIN_MODAL,
} from '../navigation/types';
import ENDPOINTS from '../../../api/endpoints';
import Api from '../../../api/api';
import router from '../../../router';
import {
  CHANNEL_SETUP, GET, RESET_STATE, TERMS_AND_CONDITIONS_MODAL,
} from '../types';
import { GET_METADATA, USER_DATA, USER_MODULE } from '../user/types';
import {
  ATTACH_RFQS, DRAFT_RFQS, RFQ_MODULE, SET_DRAFT_COUNT, SET_UPLOADED_DRAFTS,
} from '../rfq/types';
import getEnvironmentVariable from '../../../../shared/misc/env-variable';
import {
  PROJECT_MODULE,
  PROJECTS,
  ACTIVE_PROJECT,
  SET_ACTIVE_PROJECT,
} from '../projects/types';
import { CHATS_MODULE, GET_SUBSCRIPTIONS } from '../chats/types';

export default {
  /**
     * Logs in the user
     *
     * @param {Object} context
     * @param {Object} payload
     * @param {string} payload.email
     * @param {string} payload.password
     * @param {string} payload.remember
     * @returns {Promise<Object>}
     */
  async [LOGIN]({ commit, dispatch }, { email, password, remember }) {
    const { status, data } = await Api.post(ENDPOINTS.AUTH.LOGIN, {
      email,
      password,
      remember,
    })
      .catch((e) => e.response);
    if (status === 200) {
      if (data.sso_token) {
        const apiUrl = getEnvironmentVariable('VUE_APP_API_URL');
        window.location = `${apiUrl}/sso/${data.sso_token}`;
      } else {
        commit(SET_LOGGED_IN, true);
        dispatch(LOGIN_SUCCESS, { ...data, email });
      }
    }
    return {
      status,
      data,
    };
  },
  /**
     * Logs out the user
     *
     * @param {Object} context
     * @returns {Promise<boolean>}
     */
  async [LOGOUT]({ state, commit }) {
    commit(`${USER_MODULE}/${RESET_STATE}`, {}, { root: true });
    commit(`${VIEWER_MODULE}/${RESET_STATE}`, {}, { root: true });
    commit(`${PROJECT_MODULE}/${RESET_STATE}`, {}, { root: true });
    commit(`${RFQ_MODULE}/${RESET_STATE}`, {}, { root: true });
    commit(`${CHATS_MODULE}/${RESET_STATE}`, {}, { root: true });
    await Api.post(ENDPOINTS.AUTH.LOGOUT)
      .catch((e) => e.response);
    window.sessionStorage.removeItem('vuex');
    const masquerading = state[MASQUERADING];
    if (masquerading) {
      localStorage.removeItem('gm_masquerading');
      window.location.href = getEnvironmentVariable('VUE_APP_API_URL');
    }
    commit(RESET_STATE);
    commit(SET_ACCESS_TOKEN, {});
    commit(SET_LOGGED_IN, false);
    if (
      router.currentRoute.name !== 'rfq-form-no-selected'
      && router.currentRoute.path !== '/'
    ) {
      await router.push('/');
    }
    return true;
  },
  /**
     * Does the required steps after login
     *
     * @param {Object} context
     * @param {Object} responseData
     * @param {string} responseData.access_token
     * @param {string} responseData.expires_in
     * @param {boolean} responseData.masquerading
     * @param {string} responseData.email
     * @returns {Promise<boolean>}
     */
  async [LOGIN_SUCCESS]({
    commit, dispatch, rootState, state,
  }, {
    access_token, expires_in, masquerading, email,
  }) {
    commit(SET_ACCESS_TOKEN, {
      access_token,
      expires_in,
      masquerading,
    });
    commit(SET_LOGGED_IN, true);
    const redirectRouteNames = ['rfq-form-no-selected', 'login', 'reset-password'];
    const redirect = rootState[NAVIGATION_MODULE][ON_LOGIN_REDIRECT];
    commit(`${NAVIGATION_MODULE}/${SET_LOGIN_MODAL}`, { visible: false }, { root: true });
    await dispatch(`${USER_MODULE}/${GET}`, {}, { root: true });

    // If the user has uploaded a file(s) before signing in or registering,
    // we need to ensure all drafts are attached with the user
    // data. Then we set the DRAFT_COUNT to the number of
    // files which we then watch for in Project.vue.
    if (state[UPLOAD_BEFORE_AUTH]) {
      await dispatch(`${RFQ_MODULE}/${ATTACH_RFQS}`, {}, { root: true });
      commit(`${RFQ_MODULE}/${SET_UPLOADED_DRAFTS}`, 0, { root: true });
      commit(`${RFQ_MODULE}/${SET_DRAFT_COUNT}`, rootState[RFQ_MODULE][DRAFT_RFQS].length, { root: true });
    }

    const isMasquerading = masquerading || localStorage.getItem('gm_masquerading');
    localStorage.setItem('gm_has_account', new Date().getTime()
      .toString());
    dispatch(`${CHATS_MODULE}/${CHANNEL_SETUP}`, { id: rootState[USER_MODULE][USER_DATA].profile.user_id, isMonitor: true }, { root: true });
    dispatch(`${CHATS_MODULE}/${GET}`, {}, { root: true });
    dispatch(`${CHATS_MODULE}/${GET_SUBSCRIPTIONS}`, {}, { root: true });
    dispatch(`${USER_MODULE}/${GET_METADATA}`, {}, { root: true });
    if (redirect) {
      await router.push(redirect);
    } else if (redirectRouteNames.includes(router.currentRoute.name)) {
      await router.push({ name: 'rfq-form' });
    }

    // SEGMENT TRACKING TO REMOVE
    if (!isMasquerading && getEnvironmentVariable('VUE_APP_GTM_ENABLED')) {
      const user_id = rootState[USER_MODULE][USER_DATA].user.id;
      // eslint-disable-next-line no-unused-expressions
      window.dataLayer?.push({
        event: 'user-action',
        'interaction-type': false,
        target: null,
        'target-properties': null,
        'user-action-type': 'login',
        user_id,
      });
    }
    // SEGMENT TRACKING TO REMOVE

    // SEGMENT TRACKING
    if (getEnvironmentVariable('VUE_APP_SEGMENT_ENABLED')) {
      window.analytics.track('User logged in', {
        email,
        isMasquerading: !!isMasquerading,
      });
      window.analytics.alias(rootState[USER_MODULE][USER_DATA].user.id);
    }

    if (isMasquerading) {
      const {
        // eslint-disable-next-line no-shadow
        token_type, expires_in, access_token, isMasquerading, ...query
      } = (router.currentRoute.query || {});
      await router.push({ ...router.currentRoute, query });
    }
    dispatch(`${USER_MODULE}/${TERMS_AND_CONDITIONS_MODAL}`, { masquerading: isMasquerading }, { root: true });
    return true;
  },
  /**
     * Registers a user
     *
     * @param {Object} context
     * @param {Object} userdata
     * @returns {Promise<Object>}
     */
  async [REGISTER]({ dispatch }, userdata) {
    // eslint-disable-next-line no-param-reassign
    userdata.user_type = 'buyer';
    const { data, status } = await Api.post(ENDPOINTS.AUTH.REGISTER, userdata)
      .catch((e) => e.response);
    if (status < 300) {
      // SEGMENT TRACKING
      if (getEnvironmentVariable('VUE_APP_SEGMENT_ENABLED')) {
        window.analytics.track('User registered', {
          email: userdata.email,
        });
      }

      await dispatch(LOGIN, userdata);
      return {
        status,
        data,
      };
    }
    return {
      status,
      data,
    };
  },
};
