<template>
  <FormWithBackendValidation
    #default="{ submit, form, submitting }"
    :fields="['email']"
    :url="url"
    @submit="handleSubmit"
    @error="handleError"
  >
    <ValidationObserver v-slot="{ passes }">
      <form @submit.prevent="passes(submit)">
        <div class="card">
          <header class="card-header">
            <p class="card-header-title">
              Reset password
            </p>
          </header>
          <section
            class="card-content p-4"
          >
            <BInputWithValidator
              v-model="form.email"
              label="Email"
              placeholder="Enter email..."
              rules="required|email"
              vid="email"
              data-testid="reset-email-field"
            />
            <BMessage
              v-if="showResend"
              type="is-warning"
              class="pt-3"
            >
              An account already exists for this email address, to register please click the link in your email.<br>
              <a
                v-if="!resendSubmitted"
                @click="handleResend"
              >Resend email</a>
              <p v-else>
                Email Sent
              </p>
            </BMessage>
          </section>
          <footer class="card-footer">
            <div class="card-footer-item">
              <BButton
                type="is-v2-supporting-1"
                native-type="submit"
                expanded
                :loading="submitting"
                class="has-text-weight-bold"
                data-testid="reset-email-submit"
              >
                Send reset password email
              </BButton>
            </div>
          </footer>
        </div>
      </form>
    </ValidationObserver>
  </FormWithBackendValidation>
</template>

<script>
import { ValidationObserver } from 'vee-validate';
import FormWithBackendValidation from '@/shared/components/form/FormWithBackendValidation.vue';
import Api from '@/app-buyer/api/api';
import BInputWithValidator from '../../../shared/components/inputs/BInputWithValidator.vue';
import ENDPOINTS from '../../api/endpoints';
import notificationInjection from '../../../shared/components/notification/notification-injection-mixin';

export default {
  name: 'EmailPasswordResetForm',
  components: {
    FormWithBackendValidation,
    BInputWithValidator,
    ValidationObserver,
  },
  mixins: [notificationInjection],
  data() {
    return {
      url: ENDPOINTS.AUTH.EMAIL_PASSWORD,
      showResend: false,
      resendSubmitted: false,
    };
  },
  methods: {
    handleSubmit() {
      this._addNotification({
        type: 'is-success',
        message: 'Password reset email sent',
      });
      this.$parent.close();
    },
    handleError(response) {
      if (response.data?.errors?.email[0] === 'An account already exists for this email address.') {
        this.showResend = true;
      } else {
        this._addNotification({
          type: 'is-danger',
          message: response?.data.error || 'Something went wrong...',
        });
      }
    },
    async handleResend() {
      await Api.post(ENDPOINTS.AUTH.REGISTER_BASIC_RESEND, {
        email: this.email,
      });
      this.resendSubmitted = true;
    },
  },
};
</script>

<style scoped>
  .card {
    max-width: 100%;
    width: 400px;
  }
</style>
