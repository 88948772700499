export const VALUE_TYPES = {
  STRING_VALUE: 'string_value',
  DECIMAL_VALUE: 'decimal_value',
  OBJECT_VALUE: 'object_value',
};

export const INPUT_TYPES = {
  COLLAPSABLE_INPUT: 'COLLAPSABLE_INPUT',
  SELECT: 'SELECT',
  AUTOCOMPLETE: 'AUTOCOMPLETE',
  RADIO: 'RADIO',
  RADIO_BUTTON: 'RADIO_BUTTON',
  CHECKBOX: 'CHECKBOX',
  TEXT: 'TEXT',
  NUMBER: 'NUMBER',
  MIN_MAX: 'MIN_MAX',
};
