<template functional>
  <div class="is-width-100 shiny-effect">
    <div
      v-for="n in props.range"
      :key="n"
      class="is-flex is-flex-align-centered is-width-100"
      style="height: 60px;"
    >
      <span
        class="shiny-effect-child m-x-xs is-height-40"
        style="width: 3rem;"
      />
      <span
        class="shiny-effect-child m-x-xs"
        style="width: 48px; height: 48px;"
      />
      <span class="shiny-effect-child is-flex-grow-1 m-x-xs is-height-40" />
      <span class="shiny-effect-child is-flex-grow-1 m-x-xs is-height-40" />
      <span class="shiny-effect-child is-flex-grow-1 m-x-xs is-height-40" />
      <span class="shiny-effect-child is-flex-grow-1 m-x-xs is-height-40" />
      <span
        class="shiny-effect-child m-x-xs is-height-40"
        style="width: 10rem;"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'PartElementPlaceholder',
  functional: true,
  props: {
    range: {
      type: Number,
      default: () => 1,
    },
  },
};
</script>

<style scoped>
.shiny-effect-child {
  border-radius: 5px;
}
</style>
