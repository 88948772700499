/* eslint-disable func-names,no-use-before-define */
import Vue from 'vue';
import Router from 'vue-router';
import { parse, stringify } from 'qs';
import {
  LOGGED_IN, LOGIN_SUCCESS, AUTH_MODULE, MASQUERADING,
} from '@/app-buyer/store/modules/auth/types';
import QuotesPage from '@/app-buyer/views/quote-list/QuotesPage.vue';
import Project from '@/app-buyer/views/project/Project.vue';
import loginPage from '../views/login-page/login-page.vue';
import registrationPage from '../views/registration-page/registration-page.vue';
import URL_PARAMS from '../consts/url-params';
import getEnvironmentVariable from '@/shared/misc/env-variable';

if (!process || process.env.NODE_ENV !== 'test') {
  Vue.use(Router);
}
/**
 * Check if the browser already has a token in local storage, if yes, call the LOGIN_SUCCESS action
 */
const checkToken = () => {
  const token = localStorage.getItem('gm_access_token');
  const store = router?.app?.$store;
  const loggedIn = store?.state?.AUTH_MODULE?.[LOGGED_IN];
  if (token && !loggedIn) {
    store.dispatch(`AUTH_MODULE/${LOGIN_SUCCESS}`, { access_token: token });
  }
  return token;
};

/**
 * Check if the user is authenticated already, if yes go
 * to next route else go to main page and show login modal
 * @param to
 * @param from
 * @param next
 */
const checkAuth = (to, from, next) => {
  if (checkToken()) {
    next();
  } else {
    router.app.$store.commit('NAVIGATION_MODULE/SET_LOGIN_REDIRECT', to.path);
    router.push('/login');
  }
};
/**
 * Check if user is partial and needs to fill in data
 * @type {VueRouter}
 */
const checkPartial = () => {
  const store = router?.app?.$store;
  return store?.state?.USER_MODULE?.USER_DATA?.user?.partial;
};

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'root',
      component: Project,
      meta: {
        title: 'Root',
        showsProjects: true,
      },
    },
    {
      path: '/login',
      name: 'login',
      component: loginPage,
      meta: {
        title: 'Login',
      },
    },
    {
      path: '/register/:state?',
      name: 'register',
      component: registrationPage,
      meta: {
        title: 'Register',
      },
      props: true,
    },
    {
      path: '/password/reset/:token',
      name: 'reset-password',
      component: () => import(/* webpackChunkName: "reset-password" */ '../views/reset-password-page/ResetPasswordPage.vue'),
      meta: {
        title: 'Reset password',
      },
    },
    {
      path: '/email/unsubscribe/:userHashId/:type?',
      name: 'unsubscribe',
      component: () => import(/* webpackChunkName: "unsubscribe" */ '../views/email-subscription/UnsubscribePage.vue'),
      meta: {
        title: 'Email Subscription',
      },
    },
    {
      path: `/project/:${URL_PARAMS.PROJECT_HASH}?/:${URL_PARAMS.DRAFT_HASH}?`,
      name: 'rfq-form',
      component: Project,
      props: true,
      meta: {
        title: 'Projects',
        showsProjects: true,
      },
    },
    {
      path: `/quotes/:${URL_PARAMS.RFQ_ID}?`,
      name: 'quote-list',
      component: QuotesPage,
      meta: {
        requireAuth: true,
        title: 'Quotes',
      },
    },
    {
      path: '/orders/:type?',
      name: 'orders',
      component: () => import(/* webpackChunkName: "orders" */ '../views/orders/OrdersPage.vue'),
      props: true,
      meta: {
        requireAuth: true,
        title: 'Orders',
      },
    },
    {
      path: '/orders/:id',
      name: 'order-group',
      component: () => import(/* webpackChunkName: "order-group" */ '../views/order-group/order-group.vue'),
      meta: {
        requireAuth: true,
        title: 'Order group',
      },
      props: true,
    },
    {
      path: '/profile',
      name: 'user-profile',
      component: () => import(/* webpackChunkName: "user-profile" */ '../views/user-profile/user-profile.vue'),
      meta: {
        requireAuth: true,
        title: 'Profile',
      },
    },
    {
      path: '/order/:id',
      name: 'order-details',
      component: () => import(/* webpackChunkName: "order-details" */ '../views/order-details/order-details.vue'),
      props: true,
      meta: {
        requireAuth: true,
        title: 'Order details',
      },
    },
    {
      path: `/messages/:${URL_PARAMS.CHAT_ID}?`,
      name: 'messages',
      component: () => import(/* webpackChunkName: "messages" */ '../views/messages/messages.vue'),
      meta: {
        requireAuth: true,
        title: 'Messages',
      },
    },
    {
      path: `/invoices/:${URL_PARAMS.INVOICE_TYPE}?`,
      name: 'invoice-list',
      props: true,
      component: () => import(/* webpackChunkName: "invoice-list" */ '../views/invoice-list/invoice-list.vue'),
      meta: {
        requireAuth: true,
        title: 'Invoices',
      },
    },
    {
      path: `/invoice/:${URL_PARAMS.INVOICE_ID}`,
      name: 'invoice-details',
      component: () => import(/* webpackChunkName: "invoice-details" */ '../views/invoice-details/invoice-details.vue'),
      meta: {
        requireAuth: true,
        title: 'Invoice details',
      },
    },
    {
      path: '/cart',
      component: () => import(/* webpackChunkName: "cart" */ '../views/cart/cart.vue'),
      children: [
        {
          path: '',
          name: 'cart',
          component: () => import(/* webpackChunkName: "cart-list" */ '../components/cart-component/CartList.vue'),
          meta: {
            requireAuth: true,
            title: 'Cart',
          },
        },
        {
          path: 'checkout',
          name: 'cart-checkout',
          component: () => import(/* webpackChunkName: "cart-checkout" */ '../components/cart-component/CartCheckout.vue'),
          meta: {
            requireAuth: true,
            title: 'Checkout',
          },
          props: {
            stripePayment: null,
          },
        },
        {
          path: 'confirmation',
          name: 'cart-confirmation',
          component: () => import(/* webpackChunkName: "cart-confirmation" */ '../components/cart-component/CartConfirmation.vue'),
          meta: {
            requireAuth: true,
            title: 'Confirmation',
          },
        },
      ],
    },
    {
      path: `/cart-transfer/:${URL_PARAMS.CART_TRANSFER_DIRECTION}?`,
      name: 'cart-transfer',
      component: () => import(/* webpackChunkName: "cart-transfer" */ '../views/cart-transfer/cart-transfer.vue'),
      meta: {
        requireAuth: true,
        title: 'Cart transfer',
      },
    },
    {
      path: '/email/verify/:userId',
      name: 'email-verification',
      component: () => import(/* webpackChunkName: "email-verify" */ '../views/email-verification/email-verification.vue'),
      meta: {
        title: 'Email verification',
      },
    },
    {
      path: '/complete-registration',
      name: 'finish-register',
      component: () => import(/* webpackChunkName: "finish-register" */ '../views/finish-register/finish-register.vue'),
      meta: {
        title: 'Finish register',
      },
    },
    {
      path: '*',
      component: () => import(/* webpackChunkName: "not-found" */ '../views/not-found/not-found.vue'),
      meta: {
        title: 'Page not found',
        requireAuth: true,
      },
    },
  ],
  parseQuery(query) {
    return parse(query);
  },
  stringifyQuery(query) {
    const result = stringify(query);
    return result ? (`?${result}`) : '';
  },
});

const handleProjectGetter = async (to) => {
  const store = router?.app?.$store;
  let isHashProjectLoaded = false;

  if (!store?.state?.AUTH_MODULE?.[LOGGED_IN]) return;
  const isAnyProjectLoaded = !!store.state.PROJECT_MODULE?.PROJECTS?.length;

  if (to?.params?.PROJECT_HASH) {
    // if the param has a PROJECT_HASH then check to see if the hash
    // exists within the PROJECTS array
    isHashProjectLoaded = store.state.PROJECT_MODULE?.PROJECTS?.some(
      (p) => p.hash === to?.params?.PROJECT_HASH,
    );
    // if not then check to see if the PROJECTS array is populated
  }

  if (!isHashProjectLoaded && !isAnyProjectLoaded) {
    await store.dispatch('PROJECT_MODULE/GET', { go_to: to.params.PROJECT_HASH, clear: !!to.params.PROJECT_HASH });
    // set the active project to the param if it exists
    let project = store.getters['PROJECT_MODULE/ACTIVE_PROJECT'] || store.state.PROJECT_MODULE?.PROJECTS[0];
    if (to.params.PROJECT_HASH) project = { hash: to.params.PROJECT_HASH };

    store.dispatch('PROJECT_MODULE/SET_ACTIVE_PROJECT', { project });
  }
};

/**
 * Before each navigation step we check the user token, if the route has the `requireAuth` property
 * set to true in the `meta` property another step is done to redirect the user if they are not
 * authenticated.
 */
router.beforeEach((to, from, next) => {
  checkToken();
  // eslint-disable-next-line no-param-reassign
  if (to.name === 'register') to.meta.title = `${to.meta.title}${to.params?.state ? ` ${to.params?.state}` : ''}`;

  if (to.name !== 'finish-register' && checkPartial()) {
    next({ name: 'finish-register' });
  } else if (to.name === 'finish-register' && !checkPartial()) {
    next('/');
  }
  document.title = `Geomiq${to?.meta?.title ? ` | ${to.meta.title}` : ''}`;
  if (to.meta && to.meta.requireAuth === true) {
    checkAuth(to, from, next);
  } else {
    next();
  }
});

router.afterEach((to) => {
  handleProjectGetter(to);
  let fullUrl = router.options.base ? router.options.base : '';
  if (!fullUrl.endsWith('/')) {
    fullUrl += '/';
  }
  fullUrl += to.fullPath.startsWith('/') ? to.fullPath.substr(1) : to.fullPath;

  // SEGMENT TRACKING
  if (getEnvironmentVariable('VUE_APP_SEGMENT_ENABLED') && window.analytics) {
    window.analytics.page(`${to.meta?.title ?? 'No meta.title on page'}`, {
      isMasquerading: !!router?.app?.$store.state[AUTH_MODULE][MASQUERADING],
    });
  }

  // SEGMENT TRACKING TO REMOVE
  if (!window.dataLayer) return;
  window.dataLayer.push({
    event: 'content-view',
    'content-name': fullUrl,
    'conent-view-name': to.name,
    user_id: router.app.$store.state?.USER_MODULE?.USER_DATA?.user?.id,
  });
  // SEGMENT TRACKING TO REMOVE
});

export default router;
