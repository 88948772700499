<template>
  <div class="is-flex is-width-100 is-align-items-center">
    <configurator-element-number-input
      :lead-value="minValue"
      v-bind="$attrs"
      class="is-flex-grow-1"
      placeholder="min"
      @input="minValue = $event"
    />
    <div class="seperator">
      -
    </div>
    <configurator-element-number-input
      :lead-value="maxValue"
      class="is-flex-grow-1"
      v-bind="$attrs"
      placeholder="max"
      @input="maxValue = $event"
    />
  </div>
</template>

<script>
import ConfiguratorElementNumberInput from './configurator-element-number-input.vue';
import { formInput } from '@/app-buyer/mixins/form-input';

export default {
  name: 'ConfiguratorElementMinMax',
  components: {
    ConfiguratorElementNumberInput,
  },
  mixins: [formInput],
  data() {
    return {
      newValue: this.leadValue,
      correctedValue: false,
    };
  },
  computed: {
    minValue: {
      get() {
        return this.newValue && +this.newValue.min;
      },
      set(value) {
        let result = {};

        if (!this.newValue) {
          result = {
            min: value,
          };
        } else {
          result = {
            min: value,
            max: this.newValue.max,
          };
        }

        this.$emit('input', result);

        this.$nextTick(() => {
          this.newValue = result;
        });
      },
    },
    maxValue: {
      get() {
        return this.newValue && +this.newValue.max;
      },
      set(value) {
        let result = {};

        if (!this.newValue) {
          result = {
            max: value,
          };
        } else {
          result = {
            min: this.newValue.min,
            max: value,
          };
        }

        this.$emit('input', result);

        this.$nextTick(() => {
          this.newValue = result;
        });
      },
    },
  },
  watch: {
    leadValue() {
      this.newValue = this.leadValue;
    },
  },
  beforeDestroy() {
    this.$emit('input', null);
  },
};
</script>

<style
    lang="scss"
    scoped
>
.seperator {
  padding-left: 10px;
  padding-right: 10px;
}
</style>
