<template>
  <section
    class="p-6"
    :class="{'is-height-100' : !compact}"
  >
    <FileUpload
      accept=".iges,.igs,.dwg,.dxf,.stp,.step,.pdf,.stl"
      :class="{'is-height-100' : !compact}"
      multiple
      drag-drop
      :label-style="{
        height: '100%'
      }"
      data-testid="main-upload"
      @upload="createQuotes"
    >
      <div
        class="drop-area__inner is-align-items-center
        is-height-100 has-background-g-light-3 has-background-hover-darken-g-light-3
        is-height-100 uploader-content has-cursor-pointer"
      >
        <template v-if="compact">
          <div class="py-4 has-text-centered">
            <h2 class="title is-5">
              Drag and drop or click here
            </h2>
            <p class="subtitle is-7 has-text-weight-bold">
              to upload your files
            </p>
            <p class="is-size-7">
              Maximum file size: 100MB , Accepted file
              types: STEP .STP .IGES .IGS .PDF .DXF .DWG .STL
            </p>
          </div>
        </template>
        <template v-else>
          <div
            class="drop-area__inner__left  is-align-items-center is-justify-content-center"
          >
            <AnimatedGeomiqLogo
              :scale="4"
              fill="#338dbc"
              stroke="#338dbc"
              :duration="0"
            />
            <h2 class="title is-3 has-text-centered">
              Drag and drop or click here
            </h2>
            <p class="subtitle is-6 has-text-weight-bold  has-text-centered">
              to upload your files
            </p>
            <p class="is-size-6  has-text-centered">
              Accepted file types
            </p>
            <i class="is-size-7  has-text-centered">Maximum file size: 100MB</i>
          </div>

          <BTable
            :data="data"
            :mobile-cards="false"
            class="is-hidden-touch"
          >
            <BTableColumn
              #default="{ row }"
              field="type"
            >
              {{ row.type }}
            </BTableColumn>
            <BTableColumn
              field="im"
              centered
            >
              <template #header>
                Injection <br> Moulding
              </template>
              <template #default="{ row }">
                <BIcon
                  :type="row.im ? 'is-dark' : 'is-light'"
                  :icon="row.im ? 'check' : 'times'"
                />
              </template>
            </BTableColumn>
            <BTableColumn
              field="cnc"
              centered
            >
              <template #header>
                CNC <br> Machining
              </template>
              <template #default="{ row }">
                <BIcon
                  :type="row.cnc ? 'is-dark' : 'is-light'"
                  :icon="row.cnc ? 'check' : 'times'"
                />
              </template>
            </BTableColumn>
            <BTableColumn
              field="sheet"
              centered
            >
              <template #header>
                Sheet <br> Metal
              </template>
              <template #default="{ row }">
                <BIcon
                  :type="row.sheet ? 'is-dark' : 'is-light'"
                  :icon="row.sheet ? 'check' : 'times'"
                />
              </template>
            </BTableColumn>
            <BTableColumn
              field="threed"
              centered
            >
              <template #header>
                3D <br> Printing
              </template>
              <template #default="{ row }">
                <BIcon
                  :type="row.threed ? 'is-dark' : 'is-light'"
                  :icon="row.threed ? 'check' : 'times'"
                />
              </template>
            </BTableColumn>
          </BTable>
        </template>
      </div>
    </FileUpload>
  </section>
</template>

<script>
import FileUpload from '@/app-buyer/components/file-upload/file-upload.vue';
import baseUpload from '@/app-buyer/mixins/base-upload';
import AnimatedGeomiqLogo
  from '@/shared/components/loaders/animated-geomiq-logo/animated-geomiq-logo.vue';

export default {
  name: 'PartUpload',
  components: { AnimatedGeomiqLogo, FileUpload },
  mixins: [baseUpload],
  props: {
    compact: Boolean,
  },
  data() {
    return {
      data: [
        {
          type: '.STEP', im: true, cnc: true, sheet: true, threed: false,
        },
        {
          type: '.STP', im: true, cnc: true, sheet: true, threed: false,
        },
        {
          type: '.IGES', im: true, cnc: true, sheet: true, threed: false,
        },
        {
          type: '.IGS', im: true, cnc: true, sheet: true, threed: false,
        },
        {
          type: '.PDF', im: true, cnc: true, sheet: true, threed: false,
        },
        {
          type: '.DXF', im: false, cnc: false, sheet: true, threed: false,
        },
        {
          type: '.DWG', im: false, cnc: false, sheet: true, threed: false,
        },
        {
          type: '.STL', im: false, cnc: false, sheet: false, threed: true,
        },
      ],
    };
  },
};
</script>

<style
  lang="scss"
  scoped
>
.uploader-content {
  border-radius: 5px;
}

.p-6 {
  @media all and (max-width: 1500px) {
    padding: 1.5rem !important;
  }
}

::v-deep {
  .table {
    background-color: transparent;
    margin-top: 1rem;

    td {
      font-size: 13px;
      padding: 0;
    }
  }

  .drop-area {
    height: 100%;
  }
}
// *** small laptops height fix ***
// this makes sure elements don't overflow top and bottom of the parent div on small laptops
// and creates 2 columns instead of 1
.drop-area__inner{
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
}

.drop-area__inner__left {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-evenly;

}

@media only screen and (max-height: 769px) {
  .drop-area__inner{
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
  }

  .drop-area__inner__left{
    display: flex;
    flex-flow: column nowrap;
  }
}
// *** small laptops height fix ***
</style>
