<template>
  <button
    :class="{ 'can-view': canViewPart, 'is-hovered': showHoverState }"
    :disabled="!canViewPart"
    class="button-no-style"
    @click.stop="view"
  >
    <figure
      :style="imageStyle"
      class="image part-image is-flex is-align-items-center
      is-justify-content-center has-background-g-light-2"
    >
      <BIcon
        v-if="checkFailingAfter30Mins && !isBeingRequested
          || (hasFailedAt && !isBeingRequested) ||
          (hasError && !isBeingRequested)
          || !modelFile && !part.uploadPercent && !part.updatingFile && !part.awaitingDispatch && !part.uploading"
        icon="exclamation-circle"
      />
      <img
        v-else-if="imagePath && !isBeingRequested"
        :alt="`Image of ${part.name}`"
        :src="imagePath"
      >
      <div
        v-else-if="hasCompletedAt && !isBeingRequested
          || (modelFile && modelFile.extension && modelFile.extension.toLowerCase() === 'pdf' && !isBeingRequested)"
        class="custom-thumbnail"
      >
        <div class="border">
          <p class="text">
            {{ modelFile.extension.toUpperCase() }}
          </p>
        </div>
      </div>
      <tippy
        v-else
        arrow
        boundry="viewport"
        content="Analysing file."
        offset="0, 15"
        placement="right"
      >
        <template #trigger>
          <BIcon
            custom-class="fa-spin"
            icon="sync"
            pack="fal"
          />
        </template>
      </tippy>
      <div class="view-icon-wrapper">
        <BIcon
          v-show="canViewPart"
          icon="eye"
        />
      </div>
    </figure>
  </button>
</template>
<script>
import { mapMutations, mapState, mapGetters } from 'vuex';
import { RFQ_MODULE, SET_VIEWED, DRAFT_RFQ_ISSUES } from '@/app-buyer/store/modules/rfq/types';
import { DRAFT_RFQ_MODEL_PREVIEW, PDF, RFQ_MODEL_PREVIEW } from '@/shared/consts/slugs';
import { viewPDF } from '@/app-buyer/components/project/mixins';
import { findModelFile } from '@/app-buyer/components/project/helpers';
import { hasBeen30Mins } from '@/app-buyer/store/modules/rfq/draftIssues';
import { PENDING_REVISIONS, QUOTES_MODULE } from '@/app-buyer/store/modules/quotes/types';

export default {
  name: 'PartThumbnail',
  mixins: [viewPDF],

  props: {
    part: {
      type: Object,
      required: true,
    },

    size: {
      type: [String, Number],
      default: 44,
    },

    canView: {
      type: Boolean,
      default: true,
    },

    showHoverState: Boolean,
  },

  computed: {
    ...mapState(QUOTES_MODULE, {
      PENDING_REVISIONS,
    }),

    ...mapGetters(RFQ_MODULE, {
      DRAFT_RFQ_ISSUES,
    }),

    modelFile() {
      return findModelFile(this.part);
    },

    imagePath() {
      return this.part.uploads.find((u) => [DRAFT_RFQ_MODEL_PREVIEW, RFQ_MODEL_PREVIEW].includes(u?.type?.slug))?.url;
    },

    imageStyle() {
      const size = typeof this.size === 'string' ? this.size : `${this.size}px`;
      return {
        width: size,
        height: size,
      };
    },
    canViewPart() {
      return ((this.imagePath && this.hasCompletedAt)
          || this.modelFile?.extension.toLowerCase() === 'pdf')
        && this.canView;
    },
    checkFailingAfter30Mins() {
      return this.modelFile?.extension.toLowerCase() !== PDF
        && hasBeen30Mins(this.modelFile?.created_at)
        && !this.hasFailedAt
        && !this.hasCompletedAt;
    },
    hasCompletedAt() {
      return !!this.modelFile?.parser_metadata?.completed_at;
    },
    hasFailedAt() {
      return !!this.modelFile?.parser_metadata?.failed_at;
    },
    isBeingRequested() {
      return !!this[PENDING_REVISIONS]?.includes(this.part.hash);
    },
    hasError() {
      return (DRAFT_RFQ_ISSUES?.[this.part?.hash] && DRAFT_RFQ_ISSUES[this.part?.hash]?.[0]?.type === 'error');
    },
  },
  methods: {
    ...mapMutations(RFQ_MODULE, {
      SET_VIEWED,
    }),

    updateIsSelected(isSelected) {
      this.$emit('update:is-selected', isSelected);
    },

    view() {
      if (!this.modelFile) return;
      if (this.modelFile.extension.toLowerCase() === PDF) {
        this.viewPDF(this.modelFile);
      } else {
        this[SET_VIEWED](this.part);
      }
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
.part-image {
  border-radius: 5px;
  overflow: hidden;
}

.custom-thumbnail {
  font-size: 0.55rem;
  font-weight: 600;

  .border {
    align-items: center;
    border: solid black 1px;
    border-radius: 5px;
    display: flex;
    height: 35px;
    justify-content: center;
    width: 35px;
  }
}

.can-view {
  .view-icon-wrapper {
    align-items: center;
    background-color: rgba(0, 0, 0, 0.2);
    color: white;
    display: flex;
    inset: 0;
    justify-content: center;
    opacity: 0;
    position: absolute;
    transition: opacity .2s;
  }

  &.is-hovered .view-icon-wrapper,
  &:hover .view-icon-wrapper {
    opacity: 1;
  }
}
</style>
