<template>
  <tr
    :class="{'bordered pt-5': isBordered}"
  >
    <td :class="{'pl-5': isSub, 'has-text-weight-bold': data.bold}">
      {{ data.title }}
    </td>
    <td
      class="text-align-right"
    >
      <template v-if="!data.value">
        --
      </template>
      <template v-else-if="data.applyFilter">
        {{ data.value | formatWithCurrency(isPenny) }}
      </template>
      <template v-else>
        {{ data.value }}
      </template>
    </td>
  </tr>
</template>
<script>
export default {
  name: 'QuoteRow',
  props: {
    data: {
      type: Object,
      default: () => null,
    },
    isSub: Boolean,
    isPenny: Boolean,
    isBordered: Boolean,
  },
};
</script>
<style scoped>
.bordered {
  border-top: 1px solid lightGrey;
}
</style>
