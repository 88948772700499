import Quote from '@/app-buyer/models/Quote';
import {
  GET_ALL_QUOTE_RFQS, GET_QUOTES, SET_QUOTES, SET_QUOTES_FILTERS,
  SET_QUOTES_META,
} from './types';

export default {
  async [GET_QUOTES]({ commit }, {
    page,
    search,
    filters = {
      'activeRfqs.hasnt_reached_status': 'Ordered',
    },
  }) {
    const appendIncludes = [
      'activeRfqs.configurationProperties',
      'activeRfqs.project.members.role',
      'activeRfqs.project.members.user',
      'activeRfqs.sentQuotes.cartItem.cart',
      'activeRfqs.sentQuotes.entries.type',
      'activeRfqs.sentQuotes.notes',
      'activeRfqs.uploads',
      'activeRfqs.uploads.parserMetadata',
      'activeRfqsCount',
      'activeRfqs.sentQuotes.productionQuote',
    ];
    const query = new Quote();
    query.include(appendIncludes);
    query.append(['is_in_current_user_cart']);
    query.where('activeRfqs.limit', 5);
    query.params({ per_page: 5 });
    query.orderBy('-created_at');

    if (filters) {
      Object.entries(filters).forEach(([filterBy, filterValue]) => {
        if (filterValue != null) query.where(filterBy, filterValue);
      });
    }

    if (search) query.where('search', search);

    query.page(page);

    const { data, meta } = await query.get().catch((e) => e.response);

    commit(SET_QUOTES, data);
    commit(SET_QUOTES_META, meta);
    commit(SET_QUOTES_FILTERS, { page, filters, search });

    return { data, meta };
  },
  async [GET_ALL_QUOTE_RFQS]({ commit }, { hash }) {
    const query = new Quote();
    const appendIncludes = [
      'activeRfqs.uploads',
      'activeRfqs.configurationProperties',
      'activeRfqs.sentQuotes',
      'activeRfqs.sentQuotes.cartItem.cart',
      'activeRfqs.sentQuotes.notes',
      'activeRfqsCount',
      'activeRfqs.uploads.parserMetadata',
      'activeRfqs.sentQuotes.entries.type',
      'activeRfqs.sentQuotes.productionQuote',
      'activeRfqs.project',
    ];
    query.include(appendIncludes);
    query.append(['is_in_current_user_cart']);
    query.custom(`quotes/${hash}`);

    const { data } = await query.get().catch((e) => e.response);

    return { data };
  },
};
