<template>
  <div>
    <GmButton
      style="width: 100%"
      :loading="loading"
      is-link
      size="small"
      :disabled="loading"
      @click="$emit('load-next-page')"
    >
      Load more projects
    </GmButton>
  </div>
</template>

<script>
import {
  mapState,
} from 'vuex';
import {
  RFQ_MODULE, SELECTED_PART_HASHES,
} from '@/app-buyer/store/modules/rfq/types';
import {
  PROJECTS_MRFQ,
  PROJECT_MODULE,

} from '@/app-buyer/store/modules/projects/types';
import { isComponentVisible } from '@/shared/mixins';

export default {
  name: 'PartTableBulkOperationOptionLoadMore',
  mixins: [isComponentVisible],
  props: {
    closeModal: Boolean,
    loading: Boolean,
  },
  data() {
    return {
      value: '',
      nextPage: true,
    };
  },
  computed: {
    ...mapState(RFQ_MODULE, {
      SELECTED_PART_HASHES,
    }),
    ...mapState(PROJECT_MODULE, {
      PROJECTS_MRFQ,
    }),

    movePartsButtonText() {
      return `${this[SELECTED_PART_HASHES].length === 1
        ? `Move ${this[SELECTED_PART_HASHES].length} part now`
        : `Move ${this[SELECTED_PART_HASHES].length} parts now`} `;
    },
    selectedOption() {
      return this.projects.find((e) => e === this.value);
    },
    buttonLabel() {
      return this.selectedOption ? this.selectedOption : 'Select a Project';
    },

    projects() {
      return this[PROJECTS_MRFQ].map((project) => project);
    },
  },
  watch: {
    isComponentVisible: {
      handler(isVisible) {
        if (isVisible && !this.loading) {
          this.$emit('load-next-page');
        }
      },
    },
  },
};
</script>
