<template>
  <div class="is-relative">
    <b-input
      ref="input-wrapper"
      v-model="formattedValue"
      :min="min || 0"
      :max="max"
      :use-html5-validation="false"
      v-bind="$attrs"
      :placeholder="placeholder || 0"
      inputmode="numeric"
      pattern="^(\d*|\d{1,3}(,\d{3})*?)$"
      custom-class="select-target"
      @focus="showButton = true"
      @blur="blurCheck"
    />
    <div
      v-show="showButton"
      class="is-absolute flex is-flex-direction-column is-justify-center is-flex-align-centered"
    >
      <button
        class="button-no-style"
        @mousedown.prevent.stop
        @click.stop.prevent="handleButton(1)"
      >
        <b-icon
          icon="sort-up"
          style="font-size: 0.5rem; padding: 0; width: 0; height: 0;"
        />
      </button>
      <button
        class="button-no-style"
        @mousedown.prevent.stop
        @click.stop.prevent="handleButton(-1)"
      >
        <b-icon
          icon="sort-down"
          style="font-size: 0.5rem; padding: 0; width: 0; height: 0;"
        />
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ConfiguratorElementNumberInput',
  props: {
    leadValue: {
      type: [Number, String],
      default: () => null,
    },
    min: {
      type: Number,
      default: () => 0,
    },
    max: {
      type: Number,
      default: () => undefined,
    },
    placeholder: {
      type: String,
      default: () => '',
    },
  },
  data() {
    return {
      newValue: this.leadValue,
      correctedValue: false,
      showButton: false,
    };
  },
  computed: {
    formattedValue: {
      get() {
        return this.newValue?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      },
      set(newVal) {
        const oldVal = this.newValue;
        const value = newVal.replace(/,/g, '');
        this.newValue = value;
        if (value) {
          const isInteger = Number.isInteger(+value);
          if (isInteger) {
            const numberValue = Number.parseInt(value, 10);
            if (numberValue >= (this.min || 0)) {
              this.$emit('input', numberValue);
            }
            this.$nextTick(() => {
              this.newValue = numberValue;
            });
          } else {
            this.$nextTick(() => {
              this.newValue = oldVal;
            });
          }
        }
      },
    },
  },
  watch: {
    leadValue(val) {
      this.newValue = val;
    },
  },
  methods: {
    blurCheck() {
      this.showButton = false;
      if (!Number.isInteger(+this.newValue) || this.newValue < (this.min || 0)) {
        this.newValue = this.min || 0;
        this.$emit('input', this.newValue);
      }
    },
    handleButton(num) {
      this.newValue += num;
      this.$emit('input', this.newValue);
    },
  },
};
</script>

<style lang="scss" scoped>
  .is-absolute {
    top: 50%;
    right: 0.2rem;
    transform: translateY(-50%);
  }

  .button-no-style {
    height: 0.4rem;
    width: 0.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>
