<template>
  <svg
    :class="{'loading' : loading}"
    :style="`
         width: ${36.73 * scale}px;
         height: ${32.01 * scale}px;
         --scale: ${scale};
         --stroke: ${stroke};
         --stroke-width: ${strokeWidth};
         --fill: ${fill};
         --duration: ${duration}s`"
    class="animated-geomiq-logo"
  >
    <title>geomiq</title>
    <path
      :stroke="stroke"
      :d="path"
    />
  </svg>
</template>

<script>
export default {
  name: 'AnimatedGeomiqLogo',
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    scale: {
      type: Number,
      default: 2.5,
    },
    duration: {
      type: Number,
      default: 2,
    },
    strokeWidth: {
      type: Number,
      default: 0.5,
    },
    stroke: {
      type: String,
      default: '#000',
    },
    fill: {
      type: String,
      default: '#000',
    },
  },
  data() {
    return {
      path: 'M27.178-0h-17.626c-0.276 0.003-0.516 0.154-0.644 0.377l-0.002 0.004-8.806 15.247c-0.063 0.106-0.1\n'
        + '        0.235-0.1 0.372s0.037 0.265 0.102 0.375l-0.002-0.003 8.806 15.265c0.132 0.224 0.372 0.372 0.646\n'
        + '        0.373h9.629c0.411-0.002 0.743-0.335 0.743-0.746 0 0 0 0 0 0v0-14.96c0.001-0.041 0.035-0.074\n'
        + '        0.076-0.074 0.020 0 0.039 0.008 0.053 0.021l3.041 3.041c0.269 0.268 0.641 0.434 1.051\n'
        + '        0.434s0.781-0.166 1.051-0.434l0.123-0.123c0.267-0.27 0.433-0.641 0.433-1.051s-0.165-0.781-0.433-1.051l0\n'
        + '        0-4.741-4.749-1.177-1.169c-0.27-0.267-0.641-0.433-1.051-0.433s-0.781 0.165-1.051 0.433l0-0-5.902\n'
        + '        5.891c-0.268 0.269-0.434 0.641-0.434 1.051s0.166 0.781 0.434 1.051l0.123 0.123c0.269 0.269 0.64 0.435\n'
        + '        1.051 0.435s0.782-0.166 1.051-0.435v0l3.023-3.015c0.013-0.014 0.032-0.022 0.053-0.022 0.041 0 0.074\n'
        + '        0.033 0.074 0.074 0 0 0 0.001 0 0.001v-0 12.47c0 0.001 0 0.002 0 0.003 0 0.041-0.033 0.074-0.074\n'
        + '        0.074-0 0-0 0-0 0h-5.326c-0.002 0-0.004 0-0.006 0-0.271\n'
        + '        0-0.508-0.145-0.638-0.362l-0.002-0.003-6.989-12.113c-0.062-0.107-0.098-0.235-0.098-0.372s0.037-0.265\n'
        + '        0.1-0.375l-0.002 0.004 6.989-12.095c0.132-0.223 0.372-0.37 0.646-0.37 0 0 0 0 0 0h13.967c0.002-0\n'
        + '        0.005-0 0.008-0 0.274 0 0.514 0.147 0.644 0.367l0.002 0.003 6.989 12.095c0.062 0.107 0.098\n'
        + '        0.235 0.098 0.372s-0.036 0.265-0.1 0.375l0.002-0.004-6.989 12.113c-0.131 0.224-0.37\n'
        + '        0.373-0.644 0.373-0.001 0-0.001 0-0.002 0h-2.080c-0.41 0-0.743 0.333-0.743 0.743v1.663c0\n'
        + '        0 0 0 0 0 0 0.411 0.332 0.744 0.743 0.746h3.47c0.001 0 0.001 0 0.002 0 0.548 0 1.027-0.297\n'
        + '        1.284-0.739l0.004-0.007 8.607-14.892c0.063-0.106 0.1-0.235 0.1-0.372s-0.037-0.265-0.102-0.375l0.002\n'
        + '        0.003-8.82-15.247c-0.129-0.224-0.364-0.374-0.635-0.381l-0.001-0z',
    };
  },
};
</script>

<style lang="scss" scoped>
  .animated-geomiq-logo {
    align-items: center;
    animation: logo-appear var(--duration) reverse ease-in-out;
    display: flex;
    fill: var(--fill);
    justify-content: center;
    margin: 1rem;
    stroke: var(--stroke);
    stroke-dasharray: 270px;
    stroke-width: var(--strokeWidth);
    text-align: center;
    overflow: visible;

    path {
      transform: scale(var(--scale));
    }

    &.loading {
      animation: logo-looping var(--duration) ease-in-out infinite;
    }

    .success {
      fill: var(--button-green)
    }

    .error {
      fill: var(--button-warning)
    }
  }

  @keyframes logo-appear {
    100% {
      stroke-dashoffset: 135px;
      stroke-dasharray: 135;
      fill: transparent;
      transform: translateY(1rem);
      opacity: 0;
    }

    80% {
      opacity: 0;
    }

    50% {
      opacity: 100%;
    }

    35% {
      fill: transparent;
    }
  }

  @keyframes logo-looping {
    0% {
      stroke-dashoffset: 270px;
      fill: transparent;
      transform: translateY(1rem);
      opacity: 0;
    }

    75% {
      stroke-dashoffset: 0;
      fill: var(--fill);
      transform: translateY(0);
      opacity: 1;
    }

    100% {
      fill: transparent;
      transform: translateY(-1rem);
      opacity: 0;
    }
  }
</style>
