<template>
  <thead class="sticky-thead">
    <tr>
      <th
        v-if="showCheckbox"
        class="checkbox-wrapper"
      >
        <BCheckbox
          v-model="selected"
          :indeterminate="showIndeterminate"
          :value="selected"
          size="is-small"
        />
      </th>
      <th
        v-for="(col, i) in columns"
        :key="`${col.name}-${i}`"
        class="py-5 has-text-weight-bold is-hidden-touch is-size-7"
        :style="col.customStyle"
        draggable="false"
      >
        <div class="th-wrap">
          <span class="column-text is-relative">{{ col.name }}</span>
        </div>
      </th>
    </tr>
  </thead>
</template>

<script>
export default {
  props: {
    columns: {
      type: Array,
      required: true,
    },
    isAllSelected: {
      type: Boolean,
      default: false,
    },
    nonSelected: {
      type: Boolean,
      default: true,
    },
    showCheckbox: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    selected: {
      get() {
        return this.isAllSelected;
      },
      set(newVal) {
        this.$emit('update:is-all-selected', newVal);
      },
    },
    showIndeterminate() {
      return !this.isAllSelected && this.showCheckbox && !this.nonSelected;
    },
  },
};
</script>

<style lang="scss" scoped>
.sticky-thead {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 2;
  .checkbox-wrapper {
    vertical-align: middle;
  }
  .column-text {
    white-space: nowrap;
  }
  th {
    border-bottom: solid 1px #dbdbdb;
  }
}
</style>
