import mutations from './mutations';
import getters from './getters';
import getState from './state';

export default {
  namespaced: true,
  state: getState(),
  mutations,
  getters,
};
