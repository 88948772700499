<template>
  <div class="px-6 pb-6 is-flex-grow-1">
    <PartTableHeader />
    <PartTable />
  </div>
</template>

<script>
import PartTableHeader from '@/app-buyer/components/project/PartTableHeader.vue';
import PartTable from '@/app-buyer/components/project/PartTable.vue';

export default {
  name: 'PartTableWrapper',
  components: { PartTable, PartTableHeader },
};
</script>

<style lang="scss" scoped>
.px-6 {
  @media all and (max-width: 1500px) {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
}
</style>
