<template>
  <section class="is-height-100">
    <HorizontalDotLoader v-show="loading" />
    <div
      class="is-flex is-height-100"
    >
      <aside
        v-if="!hasSupportingFiles && canBeConfigured3D"
        class="px-1 is-hidden-touch"
        style="flex: none; width: 400px; overflow: auto;"
      >
        <div class="has-text-centered mb-4">
          <h2 class="title is-size-7">
            Threads
          </h2>
          <p class="subtitle is-size-7">
            This tool detects holes and bosses suitable for threading based on the feature
            sizes. See standard CNC Tap Chart for required feature sizes to use this tool
          </p>
        </div>
        <div
          v-if="editableHoles"
          class="card feature-card mb-4"
        >
          <div class="card-content">
            <p class="title is-6  has-text-weight-bold">
              Holes
            </p>
            <p class="subtitle is-7">
              {{ editableHoles.length }} hole(s) detected
            </p>
            <ul v-if="gViewInstance && editableHoles">
              <li
                v-for="hole in editableHoles"
                :key="hole.ID"
                class="is-flex is-flex-align-centered m-y-xs"
              >
                <BSelect
                  :placeholder="hole.type"
                  :value="hole.selected"
                  class="is-flex-grow-1"
                  expanded
                  size="is-small"
                  @input="setThreading(hole.ID, $event)"
                >
                  <option
                    v-for="size in hole.threads.thread_size"
                    :key="size"
                    :value="size"
                  >
                    {{ size }}
                  </option>
                </BSelect>
                <b-button
                  class="m-l-xs"
                  icon-left="eye"
                  size="is-small"
                  @click="gViewInstance.actionViewHoleByID(hole.ID)"
                />
                <BButton
                  v-show="hole.selected"
                  class="m-l-xs"
                  icon-left="trash"
                  outlined
                  size="is-small"
                  type="is-danger"
                  @click="removeThreading(hole.ID)"
                />
              </li>
            </ul>
          </div>
        </div>
        <div
          v-if="editableBosses"
          class="card feature-card mb-4"
        >
          <div class="card-content">
            <p class="title is-6 has-text-weight-bold">
              Bosses
            </p>
            <p class="subtitle is-7">
              {{ editableBosses.length }} boss(es) detected
            </p>
            <ul v-if="gViewInstance && editableBosses">
              <li
                v-for="boss in editableBosses"
                :key="boss.ID"
                class="is-flex is-flex-align-centered m-y-xs"
              >
                <BSelect
                  :placeholder="boss.type"
                  :value="boss.selected"
                  class="is-flex-grow-1"
                  expanded
                  size="is-small"
                  @input="setThreading(boss.ID, $event)"
                >
                  <option
                    v-for="size in boss.threads.thread_size"
                    :key="size"
                    :value="size"
                  >
                    {{ size }}
                  </option>
                </BSelect>
                <BButton
                  class="m-l-xs"
                  icon-left="eye"
                  size="is-small"
                  @click="gViewInstance.actionViewBossByID(boss.ID)"
                />
                <BButton
                  v-show="boss.selected"
                  class="m-l-xs"
                  icon-left="trash"
                  outlined
                  size="is-small"
                  type="is-danger"
                  @click="removeThreading(boss.ID)"
                />
              </li>
            </ul>
          </div>
        </div>
        <div
          v-if="tolerances"
          class="card feature-card mb-4"
        >
          <div class="card-content">
            <p class="title is-6  has-text-weight-bold">
              Tolerances
            </p>
            <p class="subtitle is-7">
              {{ tolerances.length }} tolerance(s) added
            </p>
            <ul
              v-if="gViewInstance"
            >
              <li
                v-for="(tolerance, index) in tolerances"
                :key="`tolerance${index}`"
                class="is-flex is-flex-align-start m-y-sm"
              >
                <BField
                  :label="`Location ${index + 1}`"
                  :message="null"
                  :type="null"
                  class="is-flex-grow-1 m-b-none-important"
                  label-position="on-border"
                >
                  <BInput
                    :has-counter="false"
                    :maxlength="6"
                    :placeholder="tolerance.type"
                    :use-html5-validation="false"
                    :value="tolerance.tolerance"
                    expanded
                    size="is-small"
                    type="number"
                    @input="updateTolerance($event, index)"
                  />
                </BField>
                <b-button
                  class="m-l-xs"
                  icon-left="trash"
                  outlined
                  size="is-small"
                  type="is-danger"
                  @click="removeTolerance(index)"
                />
              </li>
            </ul>
            <BButton
              v-if="gViewInstance"
              expanded
              size="is-small"
              type="is-info"
              outlined
              @click="gViewInstance.actionToleranceMode"
            >
              Add tolerance
            </BButton>
          </div>
        </div>
      </aside>
      <div
        class="is-flex is-flex-grow-1 p-1 py-4 is-relative"
        :class="{'has-background-info' : toleranceMode}"
        style="border-radius: 5px;"
      >
        <p
          v-show="toleranceMode"
          style="top: 0; left: 0; right: 0;"
          class="has-text-white is-absolute is-size-7 has-text-centered has-text-weight-bold"
        >
          Add tolerance
        </p>
        <div
          id="g-view-target"
          class="is-relative is-flex-grow-1"
          style="border-radius: 5px; overflow: hidden; min-height: 200px;"
          :class="{'tolerance-mode': toleranceMode}"
        />
      </div>
      <Portal to="viewer-modal-bottom">
        <div
          v-if="gViewInstance"
          class="column is-narrow columns is-vcentered"
        >
          <div class="column is-narrow">
            <GmButton
              outlined
              type="info"
              class="mr-4"
              @click="gViewInstance.actionResetView"
            >
              Reset camera
            </GmButton>
          </div>
          <div class="column is-narrow">
            <BSwitch
              type="info"
              :value="transparent"
              @input="toggleTransparent"
            >
              Transparent mode
            </BSwitch>
          </div>
          <div class="column is-narrow">
            <BSwitch
              type="info"
              :value="featuresShown"
              @input="toggleFeatures"
            >
              Features
            </BSwitch>
          </div>
        </div>
        <div class="columns column is-narrow">
          <div class="column is-narrow">
            <GmButton
              v-if="canBeConfigured3D && !hasSupportingFiles"
              type="info"
              :loading="saving"
              :disabled="saving"
              @click="saveAndClose"
            >
              Save and return
            </GmButton>
            <GmButton
              v-if="canBeConfigured3D && hasSupportingFiles"
              type="dark"
              :loading="removingFiles"
              :disabled="removingFiles"
              @click="startUse3DTool"
            >
              Use 3D Tool
            </GmButton>
          </div>
        </div>
      </Portal>
    </div>
  </section>
</template>

<script>
import { mapActions } from 'vuex';
import { part3DconfigMixin } from '@/app-buyer/components/project/mixins';
import GViewer from '@/shared/g-serve/g-view';
import getEnvironmentVariable from '@/shared/misc/env-variable';
import { SET } from '@/app-buyer/store/modules/types';
import {
  REMOVE_SUPPORTING_FILE,
  RFQ_MODULE,
  UPDATE_DRAFT,
} from '@/app-buyer/store/modules/rfq/types';
import HorizontalDotLoader
  from '@/shared/components/loaders/horizontal-dot-loader/horizontal-dot-loader.vue';
import { SHOW_VIEWER } from '@/app-buyer/store/modules/viewer/types';

const parent = 'g-view-target';
const path = getEnvironmentVariable('VUE_APP_API_URL');

let gView;

export default {
  name: 'GServeEditor',
  components: { HorizontalDotLoader },
  mixins: [part3DconfigMixin],
  props: {
    part: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      transparent: false,
      featuresShown: false,
      toleranceMode: false,
      gViewInstance: null,
      saving: false,
      removingFiles: false,
    };
  },
  mounted() {
    this.setup();
  },
  methods: {
    ...mapActions(RFQ_MODULE, {
      REMOVE_SUPPORTING_FILE,
    }),
    /**
     * Sets up the GViewer instance, collects the displayable data
     * in from g-serve and set's it in the G_SERVE_MODULE
     * @returns {Promise<void>}
     */
    async setup() {
      const uid = this.modelFile?.parser_metadata?.parser_uuid
        || this.modelFile?.parser_metadata?.uuid;
      if (!uid) {
        this.$buefy.toast.open('Could not find configuration data');
      }
      this.$nextTick(() => {
        this.loading = true;
        if (!gView) {
          gView = new GViewer();
          gView.setServer(path);
        }
        gView.renderParent(parent);
        gView.loadUID(uid);
        this.gViewInstance = gView;
        gView.onReady(async () => {
          setTimeout(() => {
            this.loading = false;
          }, 300);
          if (gView) {
            const holes = gView.getFeatureHoles();
            const tolerances = gView.getTolerances();
            const bosses = gView.getFeatureBosses();
            const threadInputs = gView.getThreadInputs();
            // eslint-disable-next-line no-restricted-syntax
            for (const hole of holes) {
              const savedThreading = threadInputs && threadInputs.find((e) => e.ID === hole.ID);
              if (savedThreading) {
                hole.selected = savedThreading.thread_size;
              }
            }
            // eslint-disable-next-line no-restricted-syntax
            for (const boss of bosses) {
              const savedThreading = threadInputs && threadInputs.find((e) => e.ID === boss.ID);
              if (savedThreading) {
                boss.selected = savedThreading.thread_size;
              }
            }
            this[SET]({
              model: this.part,
              holes,
              tolerances,
              bosses,
              threadInputs,
            });
            this.boundingBox = gView.getBoundingBox();
          }
        });

        gView.onStateNewTolerance(() => {
          const holes = gView.getFeatureHoles();
          const tolerances = gView.getTolerances();
          const bosses = gView.getFeatureBosses();
          const threadInputs = gView.getThreadInputs();
          this[SET]({
            holes,
            tolerances,
            bosses,
            threadInputs,
          });
        });

        gView.onStateToleranceMode((toleranceMode) => {
          this.toleranceMode = toleranceMode;
        });
      });
    },

    toggleTransparent() {
      this.transparent = !this.transparent;
      gView.actionOpacitySwitch();
    },

    toggleFeatures() {
      this.featuresShown = !this.featuresShown;
      gView.actionFeaturesView();
    },

    updateTolerance(val, index) {
      gView.actionSetToleranceByID(index, val);
      this.updateState();
      const copy = JSON.parse(JSON.stringify(this.part));
      copy.configuration.tolerance = this.activeToleranceObject.id;
      copy.configuration_object.tolerance = this.activeToleranceObject;
      this[UPDATE_DRAFT](copy);
      this.changed = true;
    },

    removeTolerance(index) {
      gView.actionRemoveToleranceByID(index);
      this.updateState();
      this.changed = true;
    },

    setThreading(id, value) {
      gView.actionSetThreadByID(id, value);
      this.updateState();
      this.changed = true;
    },

    removeThreading(id) {
      gView.actionRemoveThreadByID(id);
      this.updateState();
      this.changed = true;
    },

    async saveAndClose() {
      if (!this.changed) {
        this.$emit('close');
        return;
      }
      this.saving = true;
      this.changed = false;
      const response = await gView.actionSaveSpecification().catch((e) => e.response);
      if (response?.status < 300) {
        this.$emit('close');
      } else {
        this._addNotification({
          message: '<b>Your configuration could not be saved this time.</b>',
          type: 'warning',
        });
      }
      this.saving = false;
    },

    updateState() {
      const holes = gView.getFeatureHoles();
      const tolerances = gView.getTolerances();
      const bosses = gView.getFeatureBosses();
      const threadInputs = gView.getThreadInputs();
      // eslint-disable-next-line no-restricted-syntax
      for (const hole of holes) {
        const savedThreading = threadInputs && threadInputs.find((e) => e.ID === hole.ID);
        if (savedThreading) {
          hole.selected = savedThreading.thread_size;
        } else {
          hole.selected = null;
        }
      }
      // eslint-disable-next-line no-restricted-syntax
      for (const boss of bosses) {
        const savedThreading = threadInputs && threadInputs.find((e) => e.ID === boss.ID);
        if (savedThreading) {
          boss.selected = savedThreading.thread_size;
        } else {
          boss.selected = null;
        }
      }
      this[SET]({
        model: this.part,
        holes,
        tolerances,
        bosses,
        threadInputs,
      });
    },

    async removeSupportingFiles() {
      this.removingFiles = true;
      const calls = this.supportingFiles
        .map((file) => this[REMOVE_SUPPORTING_FILE]({ file, part: this.part }));
      await Promise.all(calls);
      this.removingFiles = false;
    },

    startUse3DTool() {
      this.$buefy.dialog.confirm({
        message: 'If you would like to use the 3D Tool, first we need to remove all uploaded supporting files.',
        confirmText: 'Remove files and use tool',
        onConfirm: () => {
          this.removeSupportingFiles();
        },
        hasIcon: true,
        type: 'is-danger',
      });
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>

.feature-card {
  border: 1px solid #dbdbdb;
  border-radius: 5px;
  box-shadow: none;
}

.tolerance-mode {
  box-shadow: inset 0 5px 0 5px variables.$info;
  position: relative;
}
</style>
