/* eslint-disable no-mixed-operators,
camelcase,
no-underscore-dangle,
no-param-reassign */
import Vue from 'vue';
import { debounce, memoize, wrap } from 'lodash';
import { DialogProgrammatic } from 'buefy';
import {
  _ADD_NOTIFICATION,
  _NOTIFICATION_MODULE,
} from '@/shared/components/notification/vuex-setup';
import { DRAFT_RFQ_SUPPORTING_FILE } from '@/shared/consts/slugs';
import {
  ADD_TO_DRAFTS,
  APPEND_FILE,
  ATTACH_RFQS,
  BATCH_CREATE_DRAFT_RFQ,
  BATCH_DELETE,
  CREATE_DRAFT_RFQ,
  CREATE_RFQS,
  DEBOUNCE_DRAFT_UPDATE,
  DRAFT_DETAIL,
  DRAFT_RFQS,
  GET_ALL_RFQS,
  GET_ALL_RFQS_BY_PROJECT,
  GET_PRESETS,
  MOVE_RFQ,
  REMOVE_SUPPORTING_FILE,
  REVISE_RFQS,
  RFQ_DETAIL,
  RFQS,
  SAVE_DRAFT_UPDATE,
  SET_FORM_ERRORS,
  SET_PRESETS,
  SET_PROGRESS,
  SET_PROPERTY,
  SET_UPLOADED_DRAFTS,
  SWAP_SUPPORTING_FILES,
  SWAP_UPLOADING,
  UPDATE_CALLS,
  UPDATE_DRAFT,
  UPDATE_RFQ,
  UPLOADED_DRAFTS,
} from '@/app-buyer/store/modules/rfq/types';

import {
  ACTIVE_PROJECT_HASH,
  GET_UPDATED_PROJECTS_MRFQ,
  LISTEN_PROJECT_CHANNEL,
  PROJECT_MODULE,
  PROJECTS,
  REMOVE_FROM_PROJECT,
  SET_ACTIVE_PROJECT,
} from '@/app-buyer/store/modules/projects/types';
import { findModelFile } from '@/app-buyer/components/project/helpers';
import Api from '../../../api/api';
import ENDPOINTS from '../../../api/endpoints';
import paramsSerializer from '../../../../shared/misc/paramsSerializer';
import {
  DELETE, SET, SET_ALL, UPDATE,
} from '../types';

import router from '../../../router';
import {
  AUTH_MODULE,
  FORCE_AUTH,
  LOGGED_IN,
  MASQUERADING,
} from '../auth/types';
import {
  METADATA, SET_METADATA, USER_DATA, USER_MODULE,
} from '../user/types';
import { LOGIN_MODAL_VISIBLE, NAVIGATION_MODULE } from '../navigation/types';
import { REFERENCE_DATA, REFERENCE_MODULE } from '../reference-data/types';
import Project from '@/app-buyer/models/Project';
import getEnvironmentVariable from '@/shared/misc/env-variable';
import {
  QUOTES,
  QUOTES_MODULE,
  SET_PENDING_REVISIONS,
  SET_QUOTES,
  SET_REQUESTED_QUOTE_INFO,
} from '@/app-buyer/store/modules/quotes/types';

/**
 * Sets up the formData for rfq creation and update
 * @param {Object} payload
 * @param {Object} payload.properties          Appends the properties set here to the formData
 * @param {Object} payload.files
 * @param {File} payload.files.modelFile       Appends the model file to the formData
 * @param {File} payload.files.supportingFiles  Appends the supporting file to the formData
 * */
const setUpFormData = ({ properties = {}, files = {} }) => {
  const formData = new FormData();
  if (files.modelFile) {
    formData.append('model_file', files.modelFile);
  }
  if (files.supportingFiles) {
    // eslint-disable-next-line no-restricted-syntax
    for (const file of files.supportingFiles) {
      formData.append('supporting_files[]', file);
    }
  }
  Object.keys(properties).forEach((key) => {
    if (typeof properties[key] === 'object' && properties[key] !== null) {
      Object.entries(properties[key]).forEach((entry) => {
        const [entryKey, entryValue] = entry;

        const embeddedKey = `${key}[${entryKey}]`;

        formData.append(embeddedKey, entryValue || '');
      });
    } else {
      formData.append(key, properties[key] || '');
    }
  });
  return formData;
};

const cleanRfqRequestData = ({ draftOrRfq, isReviseRequote = false }) => {
  let requestData = [];

  draftOrRfq.forEach((rfq) => {
    // Destructure draft or rfq to flatten out configuration object
    let rfqObj = {};
    const {
      name,
      quantity_initial,
      quantity_production,
      revision,
      project_hash,
      configuration,
      delivery_country,
      notes,
      production_requirements,
      files,
    } = rfq;

    rfqObj = {
      ...rfqObj,
      ...configuration,
      name,
      quantity_initial,
      quantity_production,
      revision,
      project_hash,
      delivery_country,
      files,
      notes,
    };

    if (production_requirements) {
      rfqObj.production_requirements = (({
        development_stage,
        t1_sample_deliver_by,
        forecast_units,
        forecast_period,
        tool_life,
        tooling_config_desc,
        additional_notes,
      }) => ({
        development_stage,
        t1_sample_deliver_by,
        forecast_units,
        forecast_period,
        tool_life,
        tooling_config_desc,
        additional_notes,
      }))(production_requirements);
    }

    // Apply the id / hash the new rfq is created from
    if (isReviseRequote) {
      rfqObj.origin_rfq_id = rfq.id;
      rfqObj.draft_rfq_hash = rfq.draft_hash;
      rfqObj.revised_from_id = rfq.id;
      rfqObj.listing_hash = rfq.listing_hash;
    } else {
      rfqObj.draft_rfq_hash = rfq.hash;
    }

    requestData = [...requestData, rfqObj];
  });

  return requestData;
};

/**
 * Generates a dummy draft-rfq with an id to show the user
 * @param {Object} files
 * @param {Object} properties
 * @param {File} files.modelFile    The uploaded file of which the name we show in the dummy element
 * */
const generateUploading = (files, properties) => {
  const hashed = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
  return {
    name: files?.modelFile?.name || properties?.name || 'new part',
    uploadPercent: files?.modelFile ? 1 : 0,
    uploading_hash: hashed,
    hash: hashed,
    __extension: files?.modelFile?.name?.split('.').pop().toUpperCase() || null,
    uploading: true,
    ctSource: null,
  };
};

function queueHandlerFactory(maxExecuting = 4, checkTimeout = 500) {
  return {
    queue: [],
    executing: 0,
    checkTimeout,
    maxExecuting,
    async add(fn) {
      if (this.executing >= this.maxExecuting) {
        this.queue.push(fn);
        return this.waitForExecution(fn);
      }
      return this.execute(fn);
    },
    async execute(fn) {
      this.executing += 1;
      const result = await fn();
      this.executing -= 1;
      return result;
    },
    waitForExecution(fn) {
      return new Promise((resolve) => {
        const obj = this;
        const interval = setInterval(() => {
          if (obj.executing < obj.maxExecuting && obj.queue.indexOf(fn) === 0) {
            clearInterval(interval);
            obj.queue.shift();
            resolve(obj.execute(fn));
          }
        }, checkTimeout);
      });
    },
  };
}

const queueHandler = queueHandlerFactory(4);
const supportingQueueHandler = queueHandlerFactory(4);

// This block of code is a backup just in case
// the websockets decide to not work *cough*
const manualTimerDurations = [30000, 60000, 180000];
// Timer durations set to fire at 30s, 1min, then 3mins only
const manuallyCheckUploads = ({
  state,
  commit,
  dispatch,
  draft,
  uploadHashName,
  loop = 0,
}) => {
  setTimeout(async () => {
    const dr = state[DRAFT_RFQS].find((d) => d.hash === draft.hash);
    const upload = dr?.uploads.find((u) => u.hash_name === uploadHashName);
    if (upload?.parser_metadata?.failed_at
      || upload?.parser_metadata?.completed_at
      || upload?.extension.toLowerCase() === 'pdf'
    ) return;

    const updatedDr = await dispatch(DRAFT_DETAIL, dr);
    const updatedDrUploadIndex = updatedDr.data.data.uploads.findIndex(
      (u) => u.hash_name === uploadHashName,
    );

    if (updatedDr?.data.data.uploads[updatedDrUploadIndex]?.parser_metadata?.failed_at
      || updatedDr?.data.data.uploads[updatedDrUploadIndex]?.parser_metadata?.completed_at
      || updatedDr?.data.data.uploads[updatedDrUploadIndex]?.extension.toLowerCase() === 'pdf') {
      commit(SET_PROPERTY, {
        model: updatedDr.data.data,
        property: 'uploads',
        value: updatedDr.data.data.uploads,
      });
      return;
    }

    if (loop === manualTimerDurations.length - 1) return;

    const newLoop = loop + 1;
    manuallyCheckUploads({
      state,
      commit,
      dispatch,
      draft,
      uploadHashName,
      loop: newLoop,
    });
  }, manualTimerDurations[loop]);
};

export default {
  /**
   * Gets all rfqs for the user
   * @param {Object} context
   * @param {Object} payload
   * @param {Object} payload.params      Params that will be applied to the call
   * */
  async [GET_ALL_RFQS]({ commit, rootState }, payload = {}) {
    const { params } = payload;
    commit(SET_ALL, {
      data: [],
      clear: true,
    });
    if (!params.page) {
      params.page = 1;
    }
    const config = {
      params,
      paramsSerializer,
    };
    const {
      data: { data },
      meta,
    } = await Api.get(ENDPOINTS.RFQS.INDEX, config);
    commit(SET_ALL, {
      data,
      clear: true,
    });
    if (meta?.total) {
      commit(`${USER_MODULE}/${SET_METADATA}`, {
        ...rootState[USER_MODULE][METADATA],
        quotes_without_orders_count: meta.total,
      }, { root: true });
    }
    return data;
  },

  /**
   * Gets all rfqs and draft-rfqs for a project
   * @param {Object} context
   * @param {Object} payload
   * @param {number} payload.hash         The project hash that we are getting the rfqs for
   * @param {boolean} payload.clear       Clears the stored rfqs and draft-rfqs from the state
   * @param {Object} payload.selected     If this is set it will be the set as
   *                                      the selected rfq/draft otherwise
   *                                      the first draft-rfq/rfq in the array
   * */
  async [GET_ALL_RFQS_BY_PROJECT]({ commit, state }, {
    hash,
    clear,
    selected,
  }) {
    const draftConfig = {
      params: {
        filter: { project_hash: hash },
        include: ['configurationProperties', 'uploads', 'uploads.parserMetadata', 'project'],
        // TODO Needs to be made optional
        page: 1,
        limit: 200,
      },
      paramsSerializer,
    };
    const rfqConfig = {
      params: {
        filter: {
          project_hash: hash,
        },
        include: [
          'configurationProperties',
          'uploads',
          'uploads.parserMetadata',
          'order',
          'sentQuotes.cartItem',
          'project',
        ],
        // TODO Needs to be made optional
        page: 1,
        limit: 200,
      },
      paramsSerializer,
    };

    const { data: { data: draftRfqs } } = await Api.get(ENDPOINTS.DRAFT_RFQS.INDEX, draftConfig);
    const { data: { data: rfqs } } = await Api.get(ENDPOINTS.RFQS.INDEX, rfqConfig);

    commit(SET, {
      rfqs,
      draftRfqs,
      clear,
      hasSelected: true,
    });
    const all = [...state[DRAFT_RFQS], ...state[RFQS]];
    if (selected) {
      all.forEach((e) => {
        if (selected.includes(e.hash)) {
          Vue.set(e, 'configuring', true);
        } else {
          Vue.set(e, 'configuring', false);
        }
      });
    } else if (all.length && !all.some((e) => e.configuring)) {
      all.some((e) => {
        if (!e.uploading) {
          Vue.set(e, 'configuring', true);
        }
        return !e.uploading;
      });
    }
  },

  /**
   * Creates an rfq from a draft or creates a duplicate rfq
   * note: Draft rfqs can not be duplicated
   * @param {Object} context
   * @param {Object} payload
   * @param {Array} payload.draftOrRfq
   * @returns {Promise<Object>}
   */
  async [CREATE_RFQS]({ commit, dispatch, rootState }, {
    draftOrRfq,
    isReviseRequote,
  }) {
    const requestData = cleanRfqRequestData({ draftOrRfq, isReviseRequote });

    const { data, status } = await Api.post(
      ENDPOINTS.RFQS.INDEX,
      { rfqs: requestData },
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    ).catch(({ response }) => {
      commit(SET_FORM_ERRORS, response.data.errors);
      const error = {
        data: response.data,
        status: response.status,
      };
      throw error;
    });

    if (status < 300) {
      // SEGMENT TRACKING
      if (getEnvironmentVariable('VUE_APP_SEGMENT_ENABLED')) {
        window.analytics.track('Rfq created', {
          rfqsRequested: `${draftOrRfq.length}`,
          rfqs: draftOrRfq.map((rfq) => ({
            category: rfq?.configuration_object?.service?.slug,
            category2: rfq?.configuration_object?.material?.slug,
            name: rfq?.name,
            // This information is not returned anymore with the batch endpoint.
            // We would need to rework the return data
            // or this event to get this data back.

            // qNumber: rfq?.data.ref,
            // id: rfq?.hash,
            deliveryCountry: rfq?.delivery_country,
          })),
          isMasquerading: !!rootState[AUTH_MODULE][MASQUERADING],
        });
      }

      // Clear existing form errors
      commit(SET_FORM_ERRORS, []);
      // dispatch(`${PROJECT_MODULE}/${DETAIL}`, { hash: data[0].project_hash }, { root: true });
      commit(`${QUOTES_MODULE}/${SET_REQUESTED_QUOTE_INFO}`, data.job_listings, { root: true });
      commit(`${USER_MODULE}/${SET_METADATA}`, {
        ...rootState[USER_MODULE][METADATA],
        quotes_without_orders_count: rootState[USER_MODULE][METADATA].quotes_without_orders_count + 1,
      }, { root: true });
      return { data, status };
    }
    return data;
  },
  async [BATCH_CREATE_DRAFT_RFQ]({
    commit, state, rootState, dispatch,
  }, { draftRfqs, blockAutoSelect, isDragged }) {
    const placeHolders = [];
    draftRfqs.forEach((draftConfig) => {
      const uploading = generateUploading(null, draftConfig);
      placeHolders.push(uploading);
      commit(ADD_TO_DRAFTS, uploading);
    });
    const { data: { data } } = await Api.post(ENDPOINTS.DRAFT_RFQS.INDEX, { 'draft-rfqs': draftRfqs }).catch(() => {
      placeHolders.forEach((e) => commit(DELETE, e));
    });

    // SEGMENT TRACKING
    if (getEnvironmentVariable('VUE_APP_SEGMENT_ENABLED')) {
      window.analytics.track('Draft rfq created', {
        draftRfqsCreated: data.length,
        isMasquerading: !!rootState[AUTH_MODULE][MASQUERADING],
        projectHash: draftRfqs[0]?.project_hash,
        isDragged,
        draftRfqs: data.map((draftRfq) => ({
          name: draftRfq.name,
          fileType: draftRfq.configuration_object?.['file-type']?.slug,
          draftHash: draftRfq.hash,
        })),
      });
    }

    data.forEach((draft, index) => {
      if (!blockAutoSelect
        && ![...state[RFQS], ...state[DRAFT_RFQS]].find((e) => e.configuring)
        && index === 0) {
        draft.selected = true;
        draft.configuring = true;
      }
      commit(SWAP_UPLOADING, {
        uploading: placeHolders.shift(),
        model: draft,
      });
      commit(SET_PROGRESS, {
        hash: draft.hash,
        progress: draft.progress,
      });
    });
    if (!rootState[AUTH_MODULE][LOGGED_IN]) {
      const hashids = JSON.parse(router.currentRoute.query.drafts || '[]');
      const appendable = data.map((e) => e.hash);
      const newHashids = hashids.concat(appendable);
      router.push({
        name: 'rfq-form',
        query: { drafts: JSON.stringify(newHashids) },
      });
      setTimeout(() => {
        if (!rootState[NAVIGATION_MODULE][LOGIN_MODAL_VISIBLE]) {
          dispatch(`${PROJECT_MODULE}/${LISTEN_PROJECT_CHANNEL}`, data[0]?.project_hash, { root: true });
          const hasAccount = localStorage.getItem('gm_has_account');
          commit(`${AUTH_MODULE}/${FORCE_AUTH}`, hasAccount ? 'login' : 'register', { root: true });
        }
      });
    } else if (!rootState[PROJECT_MODULE][PROJECTS].find((e) => e.hash === data[0].project_hash)) {
      commit(`${PROJECT_MODULE}/${SET}`, {
        data: [{
          hash: data[0].project_hash,
          members: data[0].project_members,
          name: data[0].project_name,
        }],
      }, { root: true });
      dispatch(`${PROJECT_MODULE}/${SET_ACTIVE_PROJECT}`, {
        project: {
          hash: data[0].project_hash,
          members: data[0].project_members,
        },
        noRedirect: true,
      }, { root: true });
    }
    return data;
  },
  /**
   * Uploads a file and creates an dummy draft-rfq while it's uploading then
   * swaps it out with the created draft-rfq
   *
   * @param {Object} context
   * @param {Object} payload
   * @param {Object} payload.properties            These properties will be
   *                                               applied to the created draft-rfq
   * @param {Object} payload.files                 This contains the main and
   *                                               supporting files
   * @param {File} payload.files.modelFile         This will be attached as
   *                                               the model file for the draft-rfq
   * @param {File} payload.files.supportingFile    This will be attached as
   *                                               the supporting file for
   *                                               the draft-rfq (optional)
   * */
  async [CREATE_DRAFT_RFQ]({
    commit, rootState, state, dispatch,
  }, {
    properties,
    blockAutoSelect = false,
    isDragged = false,
  }) {
    const uploading = generateUploading(null, properties);
    commit(ADD_TO_DRAFTS, uploading);
    const response = await Api.post(ENDPOINTS.DRAFT_RFQS.INDEX, { 'draft-rfqs': [properties] }, {}).catch((error) => {
      commit(DELETE, uploading);
      return error.response;
    });
    if (response.status > 300) {
      return response;
    }
    const { data: { data }, status } = response;
    if (!data) {
      return {};
    }
    const model = data[0];
    if (!blockAutoSelect && ![...state[RFQS], ...state[DRAFT_RFQS]].find((e) => e.configuring)) {
      model.selected = true;
      model.configuring = true;
    }
    commit(SWAP_UPLOADING, {
      uploading,
      model,
    });
    commit(SET_PROGRESS, {
      hash: model.hash,
      progress: model?.progress,
    });

    if (!rootState[AUTH_MODULE][LOGGED_IN]) {
      const hashids = JSON.parse(router.currentRoute.query.drafts || '[]');
      hashids.push(model.hash);
      router.push({
        name: 'rfq-form',
        query: { drafts: JSON.stringify(hashids) },
      });
      setTimeout(() => {
        if (!rootState[NAVIGATION_MODULE][LOGIN_MODAL_VISIBLE]) {
          const hasAccount = localStorage.getItem('gm_has_account');
          DialogProgrammatic.alert({
            message: `Please ${hasAccount ? 'log in to your account' : 'create an account'} to continue.`,
            type: 'is-info',
            hasIcon: true,
            onConfirm: () => {
              if (hasAccount) {
                router.push('login');
              } else {
                router.push('/register');
              }
            },
          });
        }
      });
    } else if (!rootState[PROJECT_MODULE][PROJECTS].find((e) => e.hash === model.project_hash)) {
      commit(`${PROJECT_MODULE}/${SET}`, {
        data: [{
          hash: model.project_hash,
          members: model.project_members,
          name: '',
        }],
      }, { root: true });
      dispatch(`${PROJECT_MODULE}/${SET_ACTIVE_PROJECT}`, {
        project: {
          hash: model.project_hash,
          members: model.project_members,
        },
        noRedirect: true,
      }, { root: true });
    }
    return {
      model,
      status,
    };
  },

  /**
   * Returns the details of a rfq
   * @param {Object} context
   * @param {Object} rfq    The rfq that we want to get the details for
   * */
  async [RFQ_DETAIL](context, { id }) {
    const response = await Api.get(ENDPOINTS.RFQS.CONFIGURATION, {
      __pathParams: { id },
      params: {
        append: 'name',
        include: 'entity',
      },
    });
    return response;
  },

  /**
   * Returns the details of a draft-rfq
   * @param {Object} context
   * @param {Object} draft    The draft-rfq that we want to get the details for
   * */
  async [DRAFT_DETAIL](context, draft) {
    const { hash } = draft;
    const response = await Api.get(ENDPOINTS.DRAFT_RFQS.DETAIL, {
      __pathParams: { hash },
    });
    return response;
  },

  /**
   * Updates a draft-rfq, properties provided will
   * overwrite the existing draft properties even if null,
   * same applies to modelFile and supportingFile.
   *
   * (a new supporting file list replaces the existing one so if you want to append a file to the
   * list of supporting files use the ADD_SUPPORTING_FILE_TO_DRAFT action).
   *
   * @param {Object} context
   * @param {Object} payload
   * @param {Object} payload.draft                The draft-rfq to be updated
   * @param {Object} payload.properties           The properties that will be
   *                                              added/updated on the draft-rfq
   * @param {Object} payload.files                If set adds/updates the uploaded files
   * @param {File} payload.files.modelFile        If set adds/updates the model
   *                                              file of the draft-rfq
   * @param {Array} payload.files.supportingFiles If set adds/updates the supporting
   *                                              file of the draft-rfq
   * */
  async [UPDATE_DRAFT]({
    commit, dispatch, rootState, state,
  }, {
    draft,
    properties,
    files,
    immediate = false,
    reviseRFQ = false,
  }) {
    const {
      project_hash = draft.project_hash,
      name = draft.name,
      notes = draft.notes,
      revision = draft.revision,
      quantity_initial = draft.quantity_initial,
      quantity_production = draft.quantity_production,
    } = properties;

    const copy = {
      ...draft,
      ...{
        project_hash,
        name,
        notes,
        revision,
        quantity_initial,
        quantity_production,
      },
    };

    if (files && files.modelFile && findModelFile(draft.uploads)) {
      const extension = files.modelFile.name.split('.').pop();
      copy.configuration['file-type'] = rootState[REFERENCE_MODULE][REFERENCE_DATA]?.find((e) => e.slug === extension?.toLowerCase())?.id;
    }

    copy.configuration_object = Object.keys(copy.configuration).reduce((res, e) => {
      res[e] = rootState[REFERENCE_MODULE][REFERENCE_DATA]?.find((d) => d.id === copy.configuration[e]);
      return res;
    }, {});
    commit(UPDATE_DRAFT, copy);

    if (rootState[PROJECT_MODULE][PROJECTS].find((p) => p.hash === project_hash)) {
      const currentProject = rootState[PROJECT_MODULE][PROJECTS].find((p) => p.hash === project_hash);

      const draftRfqs = state[DRAFT_RFQS];
      commit(`${PROJECT_MODULE}/${UPDATE}`, {
        hash: project_hash,
        data: { ...currentProject, draft_rfqs: [...draftRfqs] },
      }, { root: true });
    }

    setTimeout(() => {
      draft._blockForm = false;
    });
    commit(SET_PROPERTY, {
      model: draft,
      property: 'awaitingDispatch',
      value: true,
    });
    const startUpdate = () => {
      if (immediate) {
        commit(SET_PROPERTY, {
          model: draft,
          property: 'awaitingDispatch',
          value: false,
        });
        return dispatch(SAVE_DRAFT_UPDATE, {
          draft,
          properties,
          files,
        });
      }
      return dispatch(DEBOUNCE_DRAFT_UPDATE, {
        draft,
        properties,
        files,
      });
    };
    if (files && (files.modelFile || files.supportingFiles?.length)) {
      const res = await queueHandler.add(startUpdate);
      return res;
    }
    return startUpdate();
  },

  [DEBOUNCE_DRAFT_UPDATE]: wrap(
    memoize(
      () => debounce(({ dispatch }, {
        draft,
        properties,
        files,
      }) => dispatch(SAVE_DRAFT_UPDATE, {
        draft,
        properties,
        files,
      }), 500), (...args) => args[1].draft.hash,
    ), (fn, ...payload) => fn(...payload)(...payload),
  ),

  async [SAVE_DRAFT_UPDATE]({
    state, commit, dispatch, rootState,
  }, { draft, properties, files }) {
    const { hash } = draft;
    if (state[UPDATE_CALLS][hash]) {
      state[UPDATE_CALLS][hash] += 1;
    } else {
      state[UPDATE_CALLS][hash] = 1;
    }
    commit(SET_PROPERTY, {
      model: draft,
      property: 'updating',
      value: true,
    });
    if (files) {
      commit(SET_PROPERTY, {
        model: draft,
        property: 'updatingFile',
        value: true,
      });
    }
    const formData = setUpFormData({
      properties,
      files,
    });
    formData.append('_method', 'PUT');
    const { data: { data: model } } = await Api.post(ENDPOINTS.DRAFT_RFQS.DETAIL, formData, {
      __pathParams: { hash },
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: (progressEvent) => {
        if (files && files.modelFile) {
          const percent = Math.round(+progressEvent.loaded / +progressEvent.total * 100);
          commit(SET_PROPERTY, {
            model: draft,
            property: 'uploadPercent',
            value: percent,
          });
        }
      },
    }).catch(({ response }) => {
      commit(SET_FORM_ERRORS, response.data.errors);
      commit(SET_PROPERTY, {
        model: draft,
        property: 'updating',
        value: false,
      });
      commit(SET_PROPERTY, {
        model: draft,
        property: 'updatingFile',
        value: false,
      });
      commit(SET_PROGRESS, {
        hash,
        progress: {
          is_complete: false,
          required: [],
        },
      });
      return response;
    }).finally(() => {
      state[UPDATE_CALLS][hash] -= 1;
    });

    if (model) {
      commit(SET_PROPERTY, {
        model: draft,
        property: 'uploads',
        value: model.uploads,
      });
    }
    if (files) {
      const uploadHashName = model.uploads.find((u) => u.type.slug === 'draft-rfq-model');
      manuallyCheckUploads({
        state,
        commit,
        dispatch,
        draft,
        uploadHashName: uploadHashName?.hash_name,
      });
    }

    if (model && !state[UPDATE_CALLS][hash]) {
      commit(SET_PROGRESS, {
        hash: model.hash,
        progress: model?.progress,
      });
      commit(SET_PROPERTY, {
        model: draft,
        property: 'updating',
        value: false,
      });
      commit(SET_PROPERTY, {
        model: draft,
        property: 'updatingFile',
        value: false,
      });
      setTimeout(() => {
        commit(SET_PROPERTY, {
          model: draft,
          property: 'uploadPercent',
          value: 0,
        });
      });
    }

    // This is here to keep track of the number of attached
    // drafts have that have attempted to be uploaded.
    // This is used with DRAFT_COUNT, which is the
    // total number of drafts to be uploaded.
    commit(SET_UPLOADED_DRAFTS, state[UPLOADED_DRAFTS] + 1);

    if (!rootState[PROJECT_MODULE][PROJECTS].length) {
      // The draft update API endpoint will also add the given user as the
      // owner of the drafts project if no owner exists. This is required
      // due to the project potentially being created prior to the user
      // registering and being subsequently logged in.

      // The need for this body of code is due to the LOGIN_SUCCESS handler not
      // ensuring the projects fetch call only happens after the update draft.
      // As a result, the projects fetch call will come back empty due to
      // the user not belonging to any projects until after the update.

      // TODO: Ensure projects GET call comes after ATTACH_RFQS in LOGIN_SUCCESS
      commit(`${PROJECT_MODULE}/${SET}`, {
        data: [{
          hash: model.project_hash,
          name: model.project_name,
          members: model.project_members,
        }],
      }, { root: true });
    }

    return {
      model,
      progress: model?.progress,
      updating: draft.updating,
    };
  },

  /**
   * Update rfq details
   * @param context
   * @param {Object} payload
   * @param {Object} payload.rfq
   * @param {Object} payload.properties
   * @returns {Promise<Object>}
   */
  async [UPDATE_RFQ]({ commit }, { rfq, properties, files }) {
    const { hash } = rfq;
    Vue.set(rfq, 'updating', true);
    const formData = setUpFormData({
      properties,
      files,
    });
    formData.append('_method', 'PUT');
    const { data } = await Api.post(ENDPOINTS.RFQS.UPDATE, formData, {
      __pathParams: { hash },
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    if (data) {
      commit(UPDATE_RFQ, data);
    }
    Vue.set(rfq, 'updating', true);
    return data;
  },

  async [REVISE_RFQS]({ rootState, commit }, { revisedRfqs, jobListings }) {
    const requestData = cleanRfqRequestData({ draftOrRfq: revisedRfqs });

    const resetRfqCreatedEventCount = rootState[QUOTES_MODULE]?.[QUOTES]?.map((q) => ({
      ...q,
      rfqCreatedEventCount: 0,
    }));

    commit(`${QUOTES_MODULE}/${SET_QUOTES}`, resetRfqCreatedEventCount, { root: true });

    try {
      const res = await Api.post(
        ENDPOINTS.RFQS.REVISE,
        { rfqs: requestData },
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      );
      commit(`${QUOTES_MODULE}/${SET_REQUESTED_QUOTE_INFO}`, [jobListings], { root: true });
      commit(`${QUOTES_MODULE}/${SET_PENDING_REVISIONS}`, revisedRfqs.map((r) => r.hash), { root: true });
      return res;
    } catch (err) {
      console.log(err);
      throw err;
    }
  },

  /**
   * Move rfq/draft-rfq between projects
   * @param {Object} context
   * @param {Object} payload
   * @param {Number} payload.hash
   * @param {Number} payload.to
   * @param {boolean} payload.isDraft
   */
  async [MOVE_RFQ]({
    dispatch, commit, state,
  }, {
    hash, to, isDraft,
  }) {
    const rfq = [...state[DRAFT_RFQS], ...state[RFQS]].find((e) => e.hash === hash);
    if (rfq) {
      /* in this case the rfq is removed before the update so there's
      a more seamless user experience and to prevent the user from
      dragging again before the response arrives */
      commit(DELETE, rfq);
      if (rfq.__draft || isDraft) {
        await dispatch(UPDATE_DRAFT, {
          draft: rfq,
          properties: { project_hash: to },
        });
      } else {
        await dispatch(UPDATE_RFQ, {
          rfq,
          properties: { project_hash: to },
        });
      }
      return true;
    }
    return false;
  },

  /**
   * Update from/to projects in sidebar [PROJECTS]
   * @param to hash
   */
  // [PROJECTS] in sidebar are preloaded. We need to preload from/to projects again
  // to show proper rfqs when clicking on them
  // Only when from/to projects are inside sidebar [PROJECTS]
  async [GET_UPDATED_PROJECTS_MRFQ]({ rootState }, { to }) {
    if (rootState[PROJECT_MODULE][PROJECTS]?.find((project) => project.hash === to)) {
      const toQuery = new Project().include([
        'draftRfqs',
        'members.user',
        'members.role',
        'unorderedRfqsCount',
      ]).where('search_hash', to);

      const { data: toProject } = await toQuery.get().catch((e) => e.response);

      const toIndex = rootState[PROJECT_MODULE][PROJECTS]?.findIndex((project) => project.hash === to);
      rootState[PROJECT_MODULE][PROJECTS].splice(toIndex, 1, toProject[0]);
    }
    const from = rootState[PROJECT_MODULE][ACTIVE_PROJECT_HASH];

    const fromQuery = new Project().include([
      'draftRfqs',
      'members.user',
      'members.role',
      'unorderedRfqsCount',
    ]).where('search_hash', from);

    const { data: fromProject } = await fromQuery.get().catch((e) => e.response);

    const fromIndex = rootState[PROJECT_MODULE][PROJECTS]?.findIndex((project) => project.hash === from);
    rootState[PROJECT_MODULE][PROJECTS].splice(fromIndex, 1, fromProject[0]);
  },

  /**
   * Soft deletes a draft-rfq or rfq
   * @param {Object} context
   * @param {Object} model    The draft-rfq/rfq that needs to be removed
   * */
  async [DELETE]({ commit }, model) {
    const token = localStorage.getItem('gm_access_token');
    const { hash } = model;
    const path = model.__draft ? ENDPOINTS.DRAFT_RFQS.DETAIL : ENDPOINTS.RFQS.DETAIL;
    commit(DELETE, model);
    commit(`${PROJECT_MODULE}/${REMOVE_FROM_PROJECT}`, model, { root: true });
    const response = await Api.delete(path, {
      __pathParams: { hash },
      params: { token },
    }).catch((e) => e.response);
    return response;
  },

  /**
   * Soft deletes a draft-rfq
   * @param {Object} context
   * @param {Array} models    The draft-rfqs that need to be removed
   * */
  async [BATCH_DELETE]({ commit, rootState }, models) {
    try {
      await Api.delete(ENDPOINTS.DRAFT_RFQS.INDEX, {
        params: { hashids: models.map((model) => model.hash) },
      });

      // SEGMENT TRACKING
      if (getEnvironmentVariable('VUE_APP_SEGMENT_ENABLED')) {
        window.analytics.track('Draft rfq deleted', {
          isMasquerading: !!rootState[AUTH_MODULE][MASQUERADING],
          projectHash: models[0]?.project_hash,
          draftRfqsDeleted: models?.length,
          draftRfqs: models.map((draftRfq) => ({
            name: draftRfq.name,
            fileType: draftRfq.configuration_object?.['file-type']?.slug,
            draftHash: draftRfq.hash,
          })),
        });
      }

      models.forEach((model) => {
        commit(DELETE, model);
        commit(`${PROJECT_MODULE}/${REMOVE_FROM_PROJECT}`, model, { root: true });
      });
    } catch (err) {
      console.log(err);
    }
  },

  /**
   * Appends files to the draft
   * @param context
   * @param {Object} payload
   * @param {Object} payload.draft
   * @param {File} payload.file
   * @param {string} payload
   * @returns {Promise<Object>}
   */
  async [APPEND_FILE]({ commit, rootState }, { draft, file, type }) {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('type', type || DRAFT_RFQ_SUPPORTING_FILE);
    const { data, status } = await supportingQueueHandler.add(() => Api.post(
      ENDPOINTS.DRAFT_RFQS.MANAGE_UPLOADED,
      formData,
      {
        __pathParams: { hash: draft.hash },
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    )).catch((e) => e.response);
    if (status < 300) {
      commit(SET_PROPERTY, {
        model: draft,
        property: 'uploads',
        value: [...draft.uploads, data],
      });

      // SEGMENT TRACKING
      if (getEnvironmentVariable('VUE_APP_SEGMENT_ENABLED')) {
        window.analytics.track('Supporting file created', {
          isMasquerading: !!rootState[AUTH_MODULE][MASQUERADING],
          projectHash: draft?.project_hash,
          draftHash: draft.hash,
          supportingFile: {
            name: data.client_original_name,
            fileType: data.extension,
          },
        });
      }
    } else {
      const message = data?.message || 'We couldn\'t upload your file!';
      commit(`${_NOTIFICATION_MODULE}/${_ADD_NOTIFICATION}`, {
        message,
        type: 'is-danger',
        icon: 'exclamation-circle',
      }, { root: true });
    }
    return data;
  },

  /**
   * Load and set presets for configurator
   * @param {Object} context
   * @returns {Promise<Object>}
   */
  async [GET_PRESETS]({ commit }) {
    const { data } = await Api.get(ENDPOINTS.PRESETS.INDEX, { params: { include: 'configurationProperties' } });
    commit(SET_PRESETS, data);
    return data;
  },

  /**
   * Attach files that were uploaded while the user was not signed in
   * @param {Object} context
   * @returns {Promise<Object>}
   */
  async [ATTACH_RFQS]({ state, dispatch, rootState }) {
    const user_id = rootState[USER_MODULE][USER_DATA]?.user?.id;
    // const attachedProjects = [];
    if (state[DRAFT_RFQS].length && user_id) {
      state[DRAFT_RFQS].forEach((draft) => {
        dispatch(UPDATE_DRAFT, {
          draft,
          properties: { user_id },
        });
      });
    }
    return null;
  },

  async [REMOVE_SUPPORTING_FILE]({ state, commit, rootState }, { part, file }) {
    try {
      const response = await Api.delete(ENDPOINTS.DRAFT_RFQS.DELETE_UPLOADED, {
        __pathParams: {
          hash: part.hash,
          id: file.id,
        },
      });
      const draft = state[DRAFT_RFQS].find((d) => d.hash === part.hash);
      commit(UPDATE_DRAFT, {
        ...draft,
        uploads: draft.uploads.filter((u) => u.id !== file.id),
      });

      // SEGMENT TRACKING
      if (getEnvironmentVariable('VUE_APP_SEGMENT_ENABLED')) {
        window.analytics.track('Supporting file deleted', {
          isMasquerading: !!rootState[AUTH_MODULE][MASQUERADING],
          projectHash: part?.project_hash,
          draftHash: part.hash,
          supportingFile: {
            name: file.client_original_name,
            fileType: file.extension,
          },
        });
      }

      return response;
    } catch (e) {
      return e.response;
    }
  },

  async [SWAP_SUPPORTING_FILES]({ state, commit }, {
    file,
    originalOwner,
    newOwner,
  }) {
    const oldPart = state[DRAFT_RFQS].find((e) => e.hash === originalOwner);
    const newPart = state[DRAFT_RFQS].find((e) => e.hash === newOwner);

    let oldUploads;
    let newUploads;

    if (oldPart) {
      oldUploads = [...oldPart.uploads];
      commit(UPDATE_DRAFT, {
        ...oldPart,
        uploads: oldPart.uploads.filter((e) => e.id !== file.id),
      });
    }

    if (newPart) {
      newUploads = [...newPart.uploads];
      commit(UPDATE_DRAFT, {
        ...newPart,
        uploads: [...(newPart.uploads || []), file],
      });
    }

    try {
      await Api.put(
        ENDPOINTS.DRAFT_RFQS.MANAGE_UPLOADED,
        { detach: file.id },
        {
          __pathParams: {
            hash: originalOwner,
          },
        },
      );
      await Api.put(
        ENDPOINTS.DRAFT_RFQS.MANAGE_UPLOADED,
        { attach: file.id },
        {
          __pathParams: {
            hash: newOwner,
          },
        },
      );
    } catch (e) {
      if (oldPart) {
        oldUploads = [...oldPart.uploads];
        commit(UPDATE_DRAFT, {
          ...oldPart,
          uploads: oldUploads,
        });
      }

      if (newPart) {
        newUploads = [...newPart.uploads];
        commit(UPDATE_DRAFT, {
          ...newPart,
          uploads: newUploads,
        });
      }
    }
  },
};
