<template>
  <div class="confirm-notes-modal-wrapper modal-card">
    <div class="modal-card-head has-background-white is-flex is-flex-direction-column is-align-items-start">
      <div class="is-flex is-flex-direction-column is-align-items-start pb-4">
        <h1 class="is-size-6 has-text-left has-text-weight-bold pb-1">
          There are notes to review and approve
        </h1>
        <p class="is-size-7 has-text-left">
          Have you reviewed the Geomiq notes related to these jobs?
        </p>
      </div>
      <QuoteRowSimple
        v-for="quote in quotes"
        :key="quote.reference"
        :quote="quote"
      />
    </div>
    <div class="modal-card-foot">
      <BButton
        class="cancel-btn has-text-weight-bold is-flex-grow-1"
        label="Cancel"
        @click="$emit('close')"
      />
      <BButton
        class="approve-btn has-text-weight-bold is-flex-grow-1"
        label="Approve all notes"
        type="is-v2-supporting-1"
        @click="$emit('accept-all-notes')"
      />
    </div>
  </div>
</template>

<script>
import QuoteRowSimple from '@/app-buyer/components/grouped-quotes/QuoteRowSimple.vue';

export default {
  components: {
    QuoteRowSimple,
  },
  props: {
    quotes: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.confirm-notes-modal-wrapper {
  .modal-card-head {
    height: 22rem;
    overflow-y: auto;
  }
  .modal-card-foot {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-areas: 'cancel . approve';
    .cancel-btn {
      grid-area: cancel;
    }
    .approve-btn {
      grid-area: approve;
    }
  }
}
</style>
