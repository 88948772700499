var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"p-6",class:{'is-height-100' : !_vm.compact}},[_c('FileUpload',{class:{'is-height-100' : !_vm.compact},attrs:{"accept":".iges,.igs,.dwg,.dxf,.stp,.step,.pdf,.stl","multiple":"","drag-drop":"","label-style":{
      height: '100%'
    },"data-testid":"main-upload"},on:{"upload":_vm.createQuotes}},[_c('div',{staticClass:"drop-area__inner is-align-items-center\n      is-height-100 has-background-g-light-3 has-background-hover-darken-g-light-3\n      is-height-100 uploader-content has-cursor-pointer"},[(_vm.compact)?[_c('div',{staticClass:"py-4 has-text-centered"},[_c('h2',{staticClass:"title is-5"},[_vm._v("\n            Drag and drop or click here\n          ")]),_c('p',{staticClass:"subtitle is-7 has-text-weight-bold"},[_vm._v("\n            to upload your files\n          ")]),_c('p',{staticClass:"is-size-7"},[_vm._v("\n            Maximum file size: 100MB , Accepted file\n            types: STEP .STP .IGES .IGS .PDF .DXF .DWG .STL\n          ")])])]:[_c('div',{staticClass:"drop-area__inner__left  is-align-items-center is-justify-content-center"},[_c('AnimatedGeomiqLogo',{attrs:{"scale":4,"fill":"#338dbc","stroke":"#338dbc","duration":0}}),_c('h2',{staticClass:"title is-3 has-text-centered"},[_vm._v("\n            Drag and drop or click here\n          ")]),_c('p',{staticClass:"subtitle is-6 has-text-weight-bold  has-text-centered"},[_vm._v("\n            to upload your files\n          ")]),_c('p',{staticClass:"is-size-6  has-text-centered"},[_vm._v("\n            Accepted file types\n          ")]),_c('i',{staticClass:"is-size-7  has-text-centered"},[_vm._v("Maximum file size: 100MB")])],1),_c('BTable',{staticClass:"is-hidden-touch",attrs:{"data":_vm.data,"mobile-cards":false}},[_c('BTableColumn',{attrs:{"field":"type"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var row = ref.row;
return [_vm._v("\n            "+_vm._s(row.type)+"\n          ")]}}])}),_c('BTableColumn',{attrs:{"field":"im","centered":""},scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v("\n              Injection "),_c('br'),_vm._v(" Moulding\n            ")]},proxy:true},{key:"default",fn:function(ref){
    var row = ref.row;
return [_c('BIcon',{attrs:{"type":row.im ? 'is-dark' : 'is-light',"icon":row.im ? 'check' : 'times'}})]}}])}),_c('BTableColumn',{attrs:{"field":"cnc","centered":""},scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v("\n              CNC "),_c('br'),_vm._v(" Machining\n            ")]},proxy:true},{key:"default",fn:function(ref){
    var row = ref.row;
return [_c('BIcon',{attrs:{"type":row.cnc ? 'is-dark' : 'is-light',"icon":row.cnc ? 'check' : 'times'}})]}}])}),_c('BTableColumn',{attrs:{"field":"sheet","centered":""},scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v("\n              Sheet "),_c('br'),_vm._v(" Metal\n            ")]},proxy:true},{key:"default",fn:function(ref){
    var row = ref.row;
return [_c('BIcon',{attrs:{"type":row.sheet ? 'is-dark' : 'is-light',"icon":row.sheet ? 'check' : 'times'}})]}}])}),_c('BTableColumn',{attrs:{"field":"threed","centered":""},scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v("\n              3D "),_c('br'),_vm._v(" Printing\n            ")]},proxy:true},{key:"default",fn:function(ref){
    var row = ref.row;
return [_c('BIcon',{attrs:{"type":row.threed ? 'is-dark' : 'is-light',"icon":row.threed ? 'check' : 'times'}})]}}])})],1)]],2)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }