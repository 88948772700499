<template>
  <div class="breadcrumb-component">
    <ul class="is-width-100 crumbs">
      <li
        v-for="(crumb, key, index) in crumbs"
        :key="crumb.name"
        class="crumb"
        :class="{'disabled' : crumb.disabled}"
        :style="`transition-delay: ${index / 10}s`"
      >
        <router-link
          :to="crumb.to"
          active-class="is-active"
        >
          {{ crumb.name }}
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'Breadcrumbs',
  props: {
    crumbs: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
    .breadcrumb-component {
        margin: 1.5rem;

        .crumbs {
            display: flex;

            .crumb {
                display: flex;
                flex-grow: 0;
                align-items: center;
                color: var(--text-lighter);

                &::after {
                    content: '/';
                    margin: 0 0.5rem;
                }

                &:last-child {
                    &::after {
                        content: none;
                    }
                }

                &.disabled {
                    pointer-events: none;
                }

                a {
                    color: inherit;
                    font-size: 0.625rem;
                    font-weight: bold;
                    letter-spacing: 1px;
                    text-transform: uppercase;

                    &.is-active {
                        color: var(--geomiq-blue);
                    }
                }

                span {
                    margin: 0 1rem
                }
            }
        }
    }
</style>
