<template>
  <component
    :is="tag"
    v-bind="$attrs"
    v-on="$listeners"
  />
</template>

<script>
import lottie from 'lottie-web';

export default {
  name: 'Lottie',
  props: {
    options: {
      type: Object,
      default: () => {},
    },
    animationName: {
      type: String,
      default: () => null,
    },
    tag: {
      type: String,
      default: () => 'div',
    },
  },
  data() {
    return {
      animation: null,
    };
  },
  mounted() {
    const defaultOptions = {
      container: this.$el,
      renderer: 'svg',
      loop: false,
      autoplay: false,
      // eslint-disable-next-line global-require,import/no-dynamic-require
      animationData: this.animationName ? require(`@/assets/animations/${this.animationName}.json`) : null,
    };

    /**
    * Available methods:
    *
    * play
    * pause
    * stop
    * setSpeed(speed)              Default is 1
    * goToAndStop(value, isFrame)  isFrame defines if first argument is a time based value or a
    *                              frame based (default false).
    * goToAndPlay(value, isFrame)  isFrame defines if first argument is a time based value or a
    *                              frame based (default false).
    * setDirection(direction)      1 is forward, -1 is reverse
    * destroy
    *
    */
    this.animation = lottie.loadAnimation({ ...defaultOptions, ...this.options });
    this.$emit('ready', this.animation);
  },
  destroyed() {
    if (this.animation) {
      this.animation.destroy();
    }
  },
};
</script>
