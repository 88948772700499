import { render, staticRenderFns } from "./user-profile-personal-information.vue?vue&type=template&id=71193989&scoped=true&"
import script from "./user-profile-personal-information.vue?vue&type=script&lang=js&"
export * from "./user-profile-personal-information.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71193989",
  null
  
)

export default component.exports