import { debounce } from 'lodash/function';
import getEnvironmentVariable from '@/shared/misc/env-variable';
import Api from '@/app-buyer/api/api';
import ENDPOINTS from '@/app-buyer/api/endpoints';

const validateEmailMixin = {
  data() {
    return {
      email: null,
      messages: null,
      emailValidating: false,
    };
  },
  methods: {
    async getRecaptchaToken() {
      return new Promise((resolve) => {
        window.grecaptcha.ready(async () => {
          const token = await window.grecaptcha.execute(
            getEnvironmentVariable('VUE_APP_RECAPTCHA_KEY'),
          );
          resolve(token);
        });
      });
    },
    validateEmail: debounce(async function validateEmail(invitee = false) {
      let token = null;
      if (getEnvironmentVariable('VUE_APP_RECAPTCHA_KEY')) {
        token = await this.getRecaptchaToken();
      }
      this.emailValidating = true;
      const { data, status } = await Api.post(invitee ? ENDPOINTS.VALIDATIONS.INVITEEEMAIL : ENDPOINTS.VALIDATIONS.EMAIL, {
        email: this.email,
        captcha: token,
      }).catch((e) => e.response)
        .finally(() => {
          this.emailValidating = false;
        });
      if (status < 300) {
        this.messages = {
          ...this.messages,
          email: null,
        };
      } else {
        this.messages = {
          ...this.messages,
          email: data?.errors?.email,
        };
      }
    }, 500),
  },
};

export default validateEmailMixin;
