const STATUS_SEQ_NUM = {
  QUOTE_REQUESTED: 1,
  CANCELLED: 2,
  CONFIGURATION_REVIEW: 3,
  AWAITING_REDACTION: 4,
  NEEDS_ATTESTING: 5,
  REDACTED: 6,
  JOB_LISTED: 7,
  QUOTE_SENT: 8,
  PENDING_REORDER: 9,
  ORDERED: 10,
  ORDER_CANCELLED: 11,
  HELD: 12,
  AWAITING_ORDER_ACCEPTANCE: 13,
  LIGHTNING_JOB: 14,
  NEEDS_AWARDING: 15,
  JOB_ACCEPTED: 16,
  PRODUCTION_STARTED: 17,
  PRODUCTION_COMPLETE: 18,
  QUALITY_CONTROL: 19,
  INTAKE_REQUESTED: 20,
  VENDOR_SHIPPED: 21,
  GEOMIQ_RECEIVED: 22,
  QC_COMPLETED: 23,
  GEOMIQ_SHIPPED: 24,
  CUSTOMER_RECEIVED: 25,
  JOB_COMPLETED: 26,
  PAYMENT_REQUESTED: 27,
};

export default STATUS_SEQ_NUM;
