<template>
  <div
    v-if="isInjectionMoulding"
    class="mb-2"
  >
    <label class="label mb-1 is-size-7 has-text-weight-normal">
      <span class="is-flex is-align-items-center">Production requirements
        <span class="has-text-danger ml-1">*</span>
      </span>
    </label>
    <GmButton
      :type="hasProdReq && hasValidProdReq ? 'light' : 'danger'"
      class="prod-req-btn py-2 is-justify-space-between"
      fullwidth
      icon-pack="fal"
      icon-right="plus"
      outlined
      size="small"
      style="color: black;"
      @click="showProjectRequirementsModal = true"
    >
      {{ prodReqString }}
    </GmButton>
    <BModal
      :active.sync="showProjectRequirementsModal"
      has-modal-card
    >
      <ProductionRequirementsModal
        :configuration="configuration"
        @cancel="showProjectRequirementsModal = false"
        @update-config="updateConfig"
      />
    </BModal>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex';
import {
  REFERENCE_DATA_OBJECT_BY_SLUG,
  REFERENCE_MODULE,
} from '@/app-buyer/store/modules/reference-data/types';
import ProductionRequirementsModal
  from '@/app-buyer/components/project/ProductionRequirementsModal.vue';
import {
  GET_ALL_SELECTED_PARTS,
  PRODUCTION_REQUIREMENTS_MODAL,
  RFQ_MODULE,
  SET_PRODUCTION_REQUIREMENTS_MODAL,
  REVISE_REQUOTE_MODAL_RFQS,
  REVISE_REQUOTE_SELECTED_RFQS,
} from '@/app-buyer/store/modules/rfq/types';

export default {
  name: 'ProductionRequirements',
  components: { ProductionRequirementsModal },
  props: {
    leadHash: {
      type: String,
      default: null,
    },
    configurations: {
      type: Object,
      required: true,
    },
    lockedConfigurations: {
      type: Object,
      required: true,
    },
    isReviseRequote: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showProjectRequirementsModal: false,
    };
  },
  computed: {
    ...mapGetters(REFERENCE_MODULE, {
      REFERENCE_DATA_OBJECT_BY_SLUG,
    }),
    ...mapGetters(RFQ_MODULE, {
      GET_ALL_SELECTED_PARTS,
      REVISE_REQUOTE_SELECTED_RFQS,
    }),
    ...mapState(RFQ_MODULE, {
      PRODUCTION_REQUIREMENTS_MODAL,
      REVISE_REQUOTE_MODAL_RFQS,
    }),

    selectedParts() {
      return this.isReviseRequote ? this[REVISE_REQUOTE_SELECTED_RFQS] : this[GET_ALL_SELECTED_PARTS];
    },

    leadConfig() {
      return this.configurations?.[this.leadHash];
    },
    configuration() {
      return this.selectedParts
        ?.find((p) => p.hash === this.leadHash)?.production_requirements || {};
    },
    isInjectionMoulding() {
      return this.leadConfig.service === (this[REFERENCE_DATA_OBJECT_BY_SLUG] && this[REFERENCE_DATA_OBJECT_BY_SLUG]?.['injection-moulding']?.id);
    },
    hasProdReq() {
      return this.selectedParts.every((part) => part.production_requirements);
    },
    hasValidProdReq() {
      return this[GET_ALL_SELECTED_PARTS].every((part) => new Date(part?.production_requirements?.t1_sample_deliver_by) > new Date());
    },
    prodReqString() {
      let prodReq = [];
      this.selectedParts.forEach((part) => {
        if (part.production_requirements) {
          // eslint-disable-next-line camelcase
          const { t1_sample_deliver_by, development_stage } = part.production_requirements;

          let developmentStage = development_stage.replace(/-/g, ' ');
          developmentStage = developmentStage[0].toUpperCase()
            + developmentStage.slice(1).toLowerCase();

          let cleanDate = new Date(t1_sample_deliver_by);
          cleanDate = cleanDate.toLocaleDateString('en-GB');

          prodReq = [...prodReq, `${cleanDate} - ${developmentStage}`];
        } else {
          prodReq = [...prodReq, 'Add your production requirements'];
        }
      });

      let hasDuplicates = false;

      prodReq.forEach((req) => {
        if (req !== prodReq[0]) hasDuplicates = true;
      });

      if (!hasDuplicates) return prodReq[0];
      return `Multiple selected (${prodReq.length})`;
    },
  },
  watch: {
    [PRODUCTION_REQUIREMENTS_MODAL](nv) {
      if (nv) this.showProjectRequirementsModal = true;
    },
  },
  methods: {
    ...mapMutations(RFQ_MODULE, {
      SET_PRODUCTION_REQUIREMENTS_MODAL,
    }),
    updateConfig(projectConfig) {
      const validHashes = Object.entries(this.configurations).filter(([, config]) => config.service === this[REFERENCE_DATA_OBJECT_BY_SLUG]?.['injection-moulding']?.id).map(([hash]) => hash);
      if (validHashes.length) {
        validHashes.forEach((hash) => {
          const part = this.selectedParts?.find((p) => p.hash === hash);
          if (!part) return;
          this.$set(part, 'production_requirements', projectConfig?.production_requirements);
        });
        Object.entries(projectConfig).forEach(([entitySlug, value]) => {
          this.$emit('update-config', {
            entitySlug,
            canApply: validHashes.reduce((res, hash) => ({ ...res, [hash]: value }), {}),
          });
        });
      }
      this.showProjectRequirementsModal = false;
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
.prod-req-btn:hover {
  background-color: white !important;
  cursor: pointer;
}

</style>
