<template>
  <div>
    <tippy
      :key="id"
      content="Chat"
      placement="bottom"
      arrow
      boundary="window"
    >
      <slot
        :open-chat="openChat"
        :loading="loading"
        :error="error"
      >
        <BTooltip :active="error">
          <BButton
            :loading="loading"
            :icon-left="!!error ? 'exclamation-triangle' : 'envelope'"
            class="option-button"
            @click="openChat"
          />
          <template #content>
            <p class="has-text-weight-bold">
              There was an error.
            </p>
            <p>{{ error }}</p>
            <p>Click to try again.</p>
          </template>
        </BTooltip>
      </slot>
    </tippy>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import { CHATS_MODULE } from '@/app-buyer/store/modules/chats/types';
import { CREATE } from '@/app-buyer/store/modules/types';

export default {
  name: 'ChatButton',

  props: {
    id: {
      type: [Number, String],
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      error: null,
    };
  },

  methods: {
    ...mapActions(CHATS_MODULE, {
      CREATE,
    }),
    async openChat() {
      this.loading = true;
      this.error = null;
      try {
        const chat = await this[CREATE]({ id: this.id, type: this.type });
        await this.$router.push(`/messages/${chat.id}`);
      } catch (e) {
        this.error = e;
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
