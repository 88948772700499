<template>
  <div class="is-relative is-flex-grow-1">
    <h1
      class="title mb-4"
      style="font-size: 20px;"
    >
      Configure your parts
      <template v-if="selectedPartAmount > 1">
        ({{ selectedPartAmount }})
      </template>
    </h1>
    <template v-if="!!selectedPartAmount">
      <PartFormDraftConfigurator
        :parts="GET_ALL_SELECTED_PARTS"
      />
    </template>
    <PartFormPlaceholder
      v-else
      :has-parts="hasParts"
      class="mt-6"
    />
    <PartFormFooter
      v-if="hasParts"
      @request-quotes="$emit('request-quotes', $event)"
    />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { DRAFT_RFQS, GET_ALL_SELECTED_PARTS, RFQ_MODULE } from '@/app-buyer/store/modules/rfq/types';
import PartFormPlaceholder from '@/app-buyer/components/project/PartFormPlaceholder.vue';
import PartFormDraftConfigurator
  from '@/app-buyer/components/project/PartFormDraftConfigurator.vue';
import PartFormFooter from '@/app-buyer/components/project/PartFormFooter.vue';

export default {
  name: 'PartForm',
  components: { PartFormFooter, PartFormDraftConfigurator, PartFormPlaceholder },
  computed: {
    ...mapState(RFQ_MODULE, {
      DRAFT_RFQS,
    }),
    ...mapGetters(RFQ_MODULE, {
      GET_ALL_SELECTED_PARTS,
    }),
    selectedPartAmount() {
      return this[GET_ALL_SELECTED_PARTS]?.length;
    },
    hasParts() {
      return !!this[DRAFT_RFQS].length;
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
::v-deep {
  .collapse-trigger > button.button,
  .dropdown-trigger > button.button {
    min-height: 35px;
  }

  .field.has-addons .button {
    border-radius: 5px;
    min-height: 35px;
  }
}
</style>
