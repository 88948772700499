import {
  GET_ALL_ORDER_RFQS, GET_ORDERS, SET_ORDERS, SET_ORDERS_FILTERS,
  SET_ORDERS_META,
} from './types';
import Api from '@/app-buyer/api/api';
import ENDPOINTS from '@/app-buyer/api/endpoints';
import paramsSerializer from '@/shared/misc/paramsSerializer';

const include = [
  'rfqLines.rfq.order.productLifecycle.statuses',
  'rfqLines.rfq.order.productLifecycle.status',
  'rfqLines.rfq.deliveryLine.delivery.courier',
  'rfqLines.rfq.configurationProperties',
  'rfqLines.rfq.uploads.parserMetadata',
  'rfqLines.rfq.project',
  'feedback',
];

export default {
  // eslint-disable-next-line camelcase
  async [GET_ORDERS]({ commit }, { page, filter, per_page = 5 }) {
    const { data } = await Api.get(ENDPOINTS.INVOICES.INDEX, {
      params: {
        page,
        per_page,
        include,
        filter,
      },
      paramsSerializer,
    });

    commit(SET_ORDERS, data.data);
    commit(SET_ORDERS_META, data.meta);
    commit(SET_ORDERS_FILTERS, { page, filter });

    return { data: data.data, meta: data.meta };
  },
  async [GET_ALL_ORDER_RFQS]({ commit }, { hash }) {
    const { data, status } = await Api.get(ENDPOINTS.INVOICES.SHOW, {
      params: {
        include,
      },
      __pathParams: {
        id: hash,
      },
      paramsSerializer,
    });

    return { data: { data }, status };
  },
};
