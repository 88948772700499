const ENDPOINTS = {
  AUTH: {
    LOGIN: '/login',
    WEB_LOGIN: '/panel/login',
    REGISTER: '/register',
    LOGOUT: '/logout',
    WEB_LOGOUT: 'panel/logout',
    EMAIL_PASSWORD: '/password/email',
    RESET_PASSWORD: '/password/reset',
    CHANGE_PASSWORD: '/password/change',
    RESEND_VERIFICATION_EMAIL: '/email/resend',
    EMAIL_VERIFY: 'email/verify',
    REGISTER_BASIC_RESEND: 'register-basic-resend',
  },
  USER: {
    INDEX: '/getuserprofile',
    UPDATE: '/updateuserprofile',
    METADATA: '/user/metadata',
    PAYMENT_METHODS: '/user/payment-methods',
    AGREE_TO_TERMS: '/user/policies/agree',
    UNSUBSCRIBE: '/notification-subscriptions',
  },
  PROFILE: {
    SUGGEST: '/profile/address-suggest',
    DETAIL: '/profile/address-detailed-info',
  },
  PROJECTS: {
    INDEX: '/v2/projects',
    SHOW: '/v2/projects/{hash}',
  },
  RFQS: {
    INDEX: '/v2/rfqs',
    CONFIGURATION: '/v2/rfqs/{id}/configuration',
    UPDATE: '/v2/rfqs/{hash}',
    REVISE: '/v2/rfqs/revise',
    DETAIL: '/v2/rfqs/{hash}',
    CANCEL: '/v2/rfqs/{hash}/cancel',
  },
  DRAFT_RFQS: {
    INDEX: '/v2/draft-rfqs',
    DETAIL: '/v2/draft-rfqs/{hash}',
    DELETE_UPLOADED: '/v2/draft-rfqs/{hash}/uploads/{id}',
    MANAGE_UPLOADED: '/v2/draft-rfqs/{hash}/uploads',
  },
  QUOTE_GROUPS: {
    INDEX: 'v2/quote-groups',
    DETAIL: 'v2/quote-groups/{id}',
  },
  PRESETS: {
    INDEX: '/v2/presets',
  },
  REFERENCE: {
    PROPERTIES: '/v2/reference/properties',
  },
  AUTODESK: {
    TOKEN: '/autodesk/token',
  },
  MESSAGES: {
    INDEX: '/buyer/messages',
    DETAIL: '/v2/messages',
    SEND: '/messages/{id}',
    MESSAGE_COUNT: '/messages/{type}/{id}/count',
  },
  CHAT: {
    INDEX: '/v2/chats',
    SUBSCRIPTIONS: '/v2/chats/current-user/subscriptions',
    SHOW: '/v2/chats/{id}',
    MESSAGES: '/v2/chats/{id}/messages',
  },
  INVOICES: {
    INDEX: '/v2/invoices',
    SHOW: '/v2/invoices/{id}',
  },
  CART: {
    SHOW: '/v2/cart',
    UPDATE: '/v2/cart',
    PAY: '/v2/cart/pay',
    PAY_VIA_CREDIT: '/v2/cart/confirm-payment/credit',
    CONFIRM_PAYMENT: '/v2/cart/confirm-payment',
    GENERATE_PROFORMA: '/v2/cart/invoice',
    GENERATE_FORMAL_QUOTE: '/v2/cart/formal-quote',
    ITEMS: {
      STORE: '/v2/cart-items',
      REMOVE: '/v2/cart-items',
    },
    APPLY_DISCOUNT_CODE: '/discount-code/apply',
    CANCEL_DISCOUNT_CODE: '/discount-code/cancel',
  },
  CART_TRANSFER: {
    INDEX: '/cart-transfers',
    STORE: '/cart-transfers',
    DO_ACTION: '/cart-transfers/{id}/{action}',
  },
  ORDERS: {
    INDEX: '/v2/orders',
    SHOW: '/v2/orders/{id}',
    FEEDBACK: '/order-feedback',
    REORDER: '/v2/client/orders/{id}/reorder',
    BULK_REORDER: '/v2/client/orders/bulk-reorder',
    REORDER_COST: '/v2/client/orders/reorder-cost',
  },
  G_SERVE: {
    SPECIFICATION: 'v2/model-parse/{urn}/specification',
    DATA: '/v2/model-parse/{urn}/features',
  },
  UPLOADS: {
    DETAIL: '/v2/uploads/{id}',
  },
  VALIDATIONS: {
    EMAIL: '/validations/email',
    INVITEEEMAIL: '/validations/email/invitee',
  },
};

export default ENDPOINTS;
