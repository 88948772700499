<template>
  <div
    v-if="isReceived"
    class="is-flex  is-align-items-center is-justify-content-center"
  >
    <InvoiceSummaryLayout>
      Your rating
      <template #subtitle>
        <div class="is-flex">
          <div
            v-for="n in 5"
            :key="n"
          >
            <BIcon
              :type="rating >= n ? 'is-v2-supporting-1' : 'is-light'"
              icon="star"
              size="is-small"
              style="font-size: 1rem;"
            />
          </div>
        </div>
      </template>
    </InvoiceSummaryLayout>
    <OrderFeedbackButton
      v-if="!rating"
      :invoice="invoice"
    >
      <template #default="{ showFeedbackModal }">
        <BButton
          slot="trigger"
          class="px-4 ml-2 has-text-weight-bold"
          size="is-small"
          style="white-space: nowrap;"
          type="is-info"
          expanded
          @click="showFeedbackModal"
        >
          Rate part(s)
        </BButton>
      </template>
    </OrderFeedbackButton>
  </div>
  <div v-else />
</template>
<script>
import OrderFeedbackButton
  from '@/app-buyer/components/order-details-collapsable-card/OrderFeedbackButton.vue';
import InvoiceSummaryLayout from '@/app-buyer/views/orders/InvoiceSummaryLayout.vue';
import STATUS_SEQ_NUM from '@/shared/consts/status-sequence-numbers';

export default {
  name: 'OrderFeedbackCell',
  components: { InvoiceSummaryLayout, OrderFeedbackButton },
  props: {
    invoice: {
      type: Object,
      required: true,
    },
  },
  computed: {
    rating() {
      return this.invoice.feedback?.rating;
    },
    isReceived() {
      let received = false;

      this.invoice.last_5_rfqs.forEach((rfq) => rfq.order?.product_lifecycle?.statuses
        .forEach((status) => {
          if (status.sequence_number >= STATUS_SEQ_NUM.CUSTOMER_RECEIVED) received = true;
        }));

      return received;
    },
  },
};
</script>
