<template>
  <div class="revise-requote-body-wrapper">
    <div class="left-wrapper p-4">
      <div class="is-flex pb-4">
        <BCheckbox
          v-model="allReviseRequoteRfqsSelected"
          :indeterminate="someSelected"
          class="checkboxAll-checkbox"
        />
        <p class="number-selected-text">
          {{ REVISE_REQUOTE_SELECTED_RFQS.length }} selected
        </p>
      </div>
      <ReviseRequoteRfqCard
        v-for="rfq in rfqsOriginalOrder"
        :key="rfq.id"
        :part="rfq"
        :selected-rfqs="REVISE_REQUOTE_SELECTED_RFQS"
      />
    </div>
    <div class="has-background-g-light-3 part-form-wrapper p-4">
      <PartFormDraftConfigurator
        v-if="REVISE_REQUOTE_SELECTED_RFQS.length"
        :parts="REVISE_REQUOTE_SELECTED_RFQS"
        :is-revise-requote="true"
      />
      <div
        v-else
        class=""
      >
        <p class="text">
          <em>Your configuration options will show here after you've selected a part.</em>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex';
import PartFormDraftConfigurator
from '@/app-buyer/components/project/PartFormDraftConfigurator.vue';
import ReviseRequoteRfqCard from '@/app-buyer/components/revise-requote/ReviseRequoteRfqCard.vue';
import {
  RFQ_MODULE, REVISE_REQUOTE_SELECTED_RFQS, REVISE_REQUOTE_MODAL_RFQS,
  SET_REVISE_REQUOTE_MODAL_RFQS,
} from '@/app-buyer/store/modules/rfq/types';

export default {
  name: 'ReviseRequoteBody',
  components: {
    PartFormDraftConfigurator,
    ReviseRequoteRfqCard,
  },

  props: {
    reviseRequoteRfqs: {
      type: Object,
      required: true,
    },
  },

  computed: {
    ...mapGetters(RFQ_MODULE, {
      REVISE_REQUOTE_SELECTED_RFQS,
    }),

    ...mapState(RFQ_MODULE, {
      REVISE_REQUOTE_MODAL_RFQS,
    }),

    rfqsOriginalOrder() {
      const { rfqs } = this.reviseRequoteRfqs;
      return rfqs.sort((a, b) => a.order - b.order);
    },

    someSelected() {
      return !this.allReviseRequoteRfqsSelected
        && this[REVISE_REQUOTE_MODAL_RFQS].rfqs.some((d) => this[REVISE_REQUOTE_SELECTED_RFQS].some((s) => s.hash === d.hash));
    },
    allReviseRequoteRfqsSelected: {
      get() {
        return this[REVISE_REQUOTE_MODAL_RFQS].rfqs.every((d) => this[REVISE_REQUOTE_SELECTED_RFQS].some((s) => s.hash === d.hash));
      },
      set(value) {
        const newHashes = value ? this[REVISE_REQUOTE_MODAL_RFQS].rfqs.map((d) => d.hash) : [];
        this.selectReviseRequoteHashes(newHashes);
      },
    },
  },

  methods: {
    ...mapMutations(RFQ_MODULE, {
      SET_REVISE_REQUOTE_MODAL_RFQS,
    }),

    selectReviseRequoteHashes(hashes) {
      this[SET_REVISE_REQUOTE_MODAL_RFQS]({
        ...this[REVISE_REQUOTE_MODAL_RFQS],
        selectedRfqs: hashes,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.revise-requote-body-wrapper {
  display: grid;
  grid-template-columns: 2fr 1fr;
  height: 100%;

  .left-wrapper {
    overflow-y: auto;
    max-height: 100%;
  }

  .number-selected-text {
    font-weight: 700;
    font-size: 13px;
  }

  .part-form-wrapper {
    box-shadow: inset 15px 0 20px -20px rgba(0,0,0,0.2);

    .text {
      font-size: 13px;
    }
  }
}

</style>
