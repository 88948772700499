<template>
  <GmButton
    id="create-project-button"
    fullwidth
    type="white"
    outlined
    data-testid="add-project"
    class="px-6"
    size="small"
    v-on="$listeners"
  >
    Create a new project
  </GmButton>
</template>

<script>
import GmButton from '@/shared/components/gm-button/gm-button.vue';

export default {
  name: 'ProjectSidebarCreate',
  components: { GmButton },
};
</script>

<style lang="scss" scoped>

  #create-project-button {
    background-color: transparent;
    border: 2px solid rgba(255, 255, 255, 0.5);
    color: white;
    font-size: 0.8rem;
    height: 35px;
    margin: 0.5rem 0;
    width: 100%;
    white-space: nowrap;

    &:hover {
      background-color: white;
      color: var(--text-dark);
    }

    .project-creation__content {
        align-items: center;
        justify-content: center;
        display: flex;
      }
  }

  .hover-effect-color:hover {
    color: variables.$v2-supporting-7 !important;
    border-color: variables.$v2-supporting-7 !important;
  }
</style>
