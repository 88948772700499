<script>
export default {
  name: 'TOC',
  functional: true,
  props: {
    data: {
      type: Object,
      required: true,
    },
    path: {
      type: String,
      required: true,
    },
  },
  render(_, { props, scopedSlots }) {
    if (!props.path || !props.data) {
      console.error('For the TOC component to work a path and data prop is required');
      return scopedSlots.default({ value: null });
    }
    const keys = props.path.split('.');
    const value = keys.reduce((res, cur) => (res ? res[cur] : undefined), props.data);
    return scopedSlots.default({ value });
  },
};
</script>
