export const FORM_MODES = {
  CONFIGURATOR: 'CONFIGURATOR',
  G_SERVE: 'G_SERVE',
};
export const ORDER_STATUS_LISTED = [
  'accepted',
  'project_started',
  'production_completed',
  'quality_control',
  'shipped',
  'delivered',
  'job_complete',
];

export const MESSAGE_TYPES = {
  ORDER: 'order',
  QUOTE: 'quote',
};

export const INVOICE_TYPES = {
  PAID: 'paid',
  PROFORMA: 'proforma',
};

export const CART_TRANSFER_TYPES = {
  INCOMING: 'incoming',
  OUTGOING: 'outgoing',
};

export const CART_TRANSFER_ACTIONS = {
  ACCEPT: 'accept',
  REVERT: 'revert',
};

export const SERVICE_FILES = {
  'CNC Machining': '.STEP, .STP, .IGES, .IGS, .PDF',
  'Injection Moulding': '.STEP, .STP, .IGES, .IGS, .PDF',
  'Sheet Metal': '.STEP, .STP, .IGES, .IGS, .PDF, .DXF, .DWG',
  '3D Printing': '.STL',
};

export const ORDER_TYPES = {
  ACTIVE: 'active',
  COMPLETED: 'completed',
  TOTAL: 'all',
};
