import { REFERENCE_DATA } from './types';
import { SET } from '../types';
import { LOADING, SET_LOADING } from '@/app-buyer/store/modules/rfq/types';

let loadingPromise;

export default {
  /**
     * Sets data in the state
     *
     * @param state
     * @param data
     * */
  [SET](state, data) {
    const idMap = data?.reduce((r, c) => ({
      ...r,
      [c.id]: c,
    }), {});
    state[REFERENCE_DATA] = data.map((refData) => ({
      ...refData,
      parents: refData.parent_ids.map((p) => idMap[p]).filter(Boolean),
    }));
    setTimeout(() => {
      state[LOADING] = false;
    });
  },
  [SET_LOADING](state, isLoading) {
    if (isLoading) {
      state[LOADING] = new Promise((resolve) => {
        loadingPromise = resolve;
      });
    } else {
      loadingPromise();
      loadingPromise = null;
    }
  },
};
