import { DATA, GET_SPECIFICATION } from './types';
import Api from '../../../api/api';
import ENDPOINTS from '../../../api/endpoints';
import { SET } from '../types';

export default {
  /**
     * Get specification for a selected uuid
     *
     * @param {Object} context
     * @param {Object} payload
     * @param {Object} payload.urn
     * @param {Object} payload.params
     * @returns {Promise<Object>} data
     */
  async [GET_SPECIFICATION]({ state, commit }, {
    urn, hash, params, force = false,
  }) {
    if (!force && state[DATA][hash]) {
      commit(SET, { model: hash, ...state[DATA][hash] });
      return state[DATA][hash];
    }
    const { data } = await Api.get(ENDPOINTS.G_SERVE.SPECIFICATION, {
      params,
      __pathParams: { urn },
    })
      .catch((e) => e.response);
    if (data?.features) {
      commit(SET, {
        model: { hash },
        holes: data.features.holes,
        bosses: data.features.bosses,
        threadInputs: data.features.thread_inputs,
        tolerances: data.features.tolerances,
      });
    }
    return data;
  },
};
