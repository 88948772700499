import {
  ADDONS,
  REFERENCE_DATA, REFERENCE_DATA_NOTE_REQUIRED,
  REFERENCE_DATA_OBJECT_BY_ID,
  REFERENCE_DATA_OBJECT_BY_SLUG, REFERENCE_DATA_PDF_REQUIRED,
} from '@/app-buyer/store/modules/reference-data/types';
import { ADD_ON } from '@/shared/consts/slugs';

export default {
  [ADDONS]: (state) => state[REFERENCE_DATA].filter((e) => e.entity_slug === ADD_ON),
  [REFERENCE_DATA_OBJECT_BY_SLUG]: (state) => state[REFERENCE_DATA]?.reduce((r, c) => ({
    ...r,
    [c.slug]: c,
  }), {}),
  [REFERENCE_DATA_OBJECT_BY_ID]: (state) => state[REFERENCE_DATA]?.reduce((r, c) => ({
    ...r,
    [c.id]: c,
  }), {}),
  [REFERENCE_DATA_PDF_REQUIRED]: (state) => state[REFERENCE_DATA]?.reduce((r, c) => ({
    ...r,
    [c.slug]: c.metadata?.requires_pdf,
  }), {}),
  [REFERENCE_DATA_NOTE_REQUIRED]: (state) => state[REFERENCE_DATA]?.reduce((r, c) => ({
    ...r,
    [c.slug]: c.requires_notes,
  }), {}),
};
