<template>
  <BDropdown
    :value="value"
    expanded
    @input="handleInput"
  >
    <template #trigger="{ active }">
      <BButton
        data-testid="quote-status-btn"
        class="is-justify-content-space-between"
        expanded
        :icon-right="active ? 'chevron-up' : 'chevron-right'"
      >
        {{ selectedStatus }}
      </BButton>
    </template>
    <BDropdownItem
      v-for="status in $options.statuses"
      :key="status.slug"
      data-testid="quote-status-item"
      :value="status.slug"
    >
      {{ status.name }}
    </BDropdownItem>
  </BDropdown>
</template>
<script>
export default {
  name: 'StatusPicker',
  props: {
    value: {
      type: String,
      default: null,
    },
  },
  statuses: [
    {
      slug: null,
      name: 'Any status',
    },
    {
      slug: 'quote-sent',
      name: 'Received quotes',
    },
    {
      slug: 'quote-requested',
      name: 'Awaiting quotes',
    },
    // TODO decide on keeping this one
    // {
    //   slug: 'cancelled',
    //   name: 'Cancelled quotes',
    // },
  ],
  computed: {
    selectedStatus() {
      return this.$options.statuses.find((s) => s.slug === this.value)?.name || 'Select status';
    },
  },
  methods: {
    handleInput(val) {
      this.$emit('input', val);
    },
  },
};
</script>
