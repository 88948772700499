/* eslint-disable no-undef */
import getEnvironmentVariable from './env-variable';

export default class StripePayment {
  constructor(style = null) {
    this.stripe = Stripe(_env_.VUE_APP_STRIPE_API || process.env.VUE_APP_STRIPE_API);
    this.stripe = Stripe(getEnvironmentVariable('VUE_APP_STRIPE_API'));
    this.elements = this.stripe.elements();
    this.card = this.elements.create('card', {
      style,
      hidePostalCode: true,
    });
  }

  mount(element) {
    this.card.mount(element);
  }

  createCardPaymentMethod() {
    return this.stripe.createPaymentMethod('card', this.card);
  }

  handleCardAction(paymentIntentClientSecret) {
    return this.stripe.handleCardAction(paymentIntentClientSecret);
  }
}
