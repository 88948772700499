<template>
  <div>
    <PartTableGroupRowStatus :issues="issuesToPass" />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import PartTableGroupRowStatus from '@/app-buyer/components/project/PartTableGroupRowStatus.vue';
import { RFQ_ISSUES, RFQ_MODULE } from '@/app-buyer/store/modules/rfq/types';
import { WARNING } from '@/shared/consts/slugs';
import QuoteConfirmPrompt from '@/app-buyer/components/grouped-quotes/QuoteConfirmPrompt.vue';

export default {
  name: 'GroupedQuotesStatus',

  components: { PartTableGroupRowStatus },

  props: {
    isPending: Boolean,

    rfq: {
      type: Object,
      required: true,
    },

    activeQuote: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      customIssues: [],
    };
  },

  computed: {
    ...mapGetters(RFQ_MODULE, {
      RFQ_ISSUES,
    }),

    issuesToPass() {
      if (this.hasIssues) {
        return [
          ...(this.issues || []),
          ...this.customIssues,
        ];
      }
      if (this.isPending) return null;
      return [
        ...(this.issues || []),
        ...this.customIssues,
      ];
    },

    issues() {
      const base = this[RFQ_ISSUES]?.[this.rfq.hash];
      return base?.map((issue) => {
        if (typeof issue === 'object' && issue) {
          return {
            ...issue,
            on: {
              loading: (isLoading) => {
                this.$emit('loading', isLoading);
              },
              update: (rfqData) => {
                this.$emit('update', rfqData);
              },
            },
          };
        }
        return issue;
      });
    },

    hasIssues() {
      if (!this.issues) return false;
      return Object.values(this.issues).some(Boolean);
    },
  },

  watch: {
    activeQuote: {
      handler(val) {
        if (!val) return;
        this.checkConfirmables(val);
      },
      immediate: true,
    },
  },

  methods: {
    checkConfirmables(activeQuote) {
      const confirmed = localStorage.getItem(`${this.quoteId}_confirmed`);
      const clientNotes = activeQuote?.notes?.map((e) => e.content).join(' | ');
      if (!confirmed && clientNotes) {
        this.customIssues = [
          {
            message: 'There are changes to confirm.',
            type: WARNING,
            component: QuoteConfirmPrompt,
            props: {
              clientNotes,
              quoteId: activeQuote?.id,
            },
            on: {
              confirm: () => {
                this.customIssues = [];
              },
            },
          },
        ];
        return;
      }
      this.customIssues = [];
    },
  },
};
</script>
