<template>
  <form @submit.prevent="submit">
    <div class="modal-card feedback">
      <header class="has-background-white modal-card-head px-6">
        <div class="is-width-100">
          <h2 class="title is-5 mt-4">
            Rate your orders to improve your recommended suppliers
          </h2>
          <BMessage
            type="is-info"
            size="is-small"
            class="has-text-weight-bold"
          >
            The more orders you rate, the better we can match you to your favourite suppliers for future quotes
          </BMessage>
        </div>
      </header>
      <div class="feedback-items-wrapper px-6 has-background-white">
        <FeedbackOrderSingle
          v-for="(invoice, index) in invoices"
          :key="invoice.id"
          v-model="feedbacks[index]"
          :invoice="invoice"
          :is-last="index === invoices.length - 1"
        />
      </div>
      <footer
        class="modal-card-foot is-justify-content-space-between has-background-white px-6"
        style="border-top: 1px solid #efefef"
      >
        <GmButton
          v-if="canDelay"
          is-link
          class="has-text-info is-size-7 p-0 has-text-decoration-underline"
          :disabled="loading"
          @click="postpone"
        >
          Not sure yet, ask me again in 1 week
        </GmButton>
        <GmButton
          type="v2-supporting-1"
          native-type="submit"
          class="px-6 has-text-gm-grey-blue mb-2 has-text-weight-bold"
          :disabled="!isReady || loading"
          :loading="loading"
        >
          Submit
        </GmButton>
      </footer>
    </div>
  </form>
</template>

<script>
import { mapState } from 'vuex';
import FeedbackOrderSingle from '@/app-buyer/components/feedback-modal/FeedbackOrderSingle.vue';
import GmButton from '@/shared/components/gm-button/gm-button.vue';
import Api from '@/app-buyer/api/api';
import ENDPOINTS from '@/app-buyer/api/endpoints';
import { AUTH_MODULE, MASQUERADING } from '@/app-buyer/store/modules/auth/types';

export default {
  name: 'FeedbackOrder',
  components: { GmButton, FeedbackOrderSingle },
  props: {
    invoices: {
      type: Array,
      default: () => [],
    },
    canDelay: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      loading: false,
      feedbacks: this.invoices
        .map((invoice) => ({ rating: null, comment: null, invoice_id: invoice.id })),
    };
  },
  computed: {
    ...mapState(AUTH_MODULE, {
      MASQUERADING,
    }),
    isReady() {
      return this.feedbacks
        .every((feedback) => feedback.rating != null);
    },
  },
  methods: {
    async submit() {
      this.loading = true;
      const responses = await Promise.all(this.feedbacks
        .map((feedback) => Api.post(ENDPOINTS.ORDERS.FEEDBACK, feedback).catch((e) => e.response)));
      this.loading = false;
      if (responses.every(({ status }) => status < 300)) {
        this.$emit('submitted');
      } else {
        this.$buefy.toast.open({
          type: 'is-danger',
          message: 'Something went wrong, your feedback was not submitted!',
        });
        this.$emit('close');
      }
    },
    postpone() {
      this.$emit('close');
      this.feedbacks
        .forEach((fb) => this.$cookies.set(`dont_ask_about.${fb.invoice_id}`, 1, '7d'));
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-card.feedback {
  width: 800px;
  max-width: 90vw;
  max-height: 90vh;
  margin: auto !important;

  .feedback-items-wrapper {
    overflow: auto;
  }

  .modal-card-foot {
    position: sticky;
    bottom: 0;
  }
}
</style>
