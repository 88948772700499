<template>
  <div v-show="isActive">
    <slot />
  </div>
</template>

<script>

export default {
  name: 'BigTabItem',

  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      default: null,
    },
    icon: {
      type: String,
      default: null,
    },
    tooltip: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isActive: false,
    };
  },

  watch: {
    isActive(value) {
      if (value) {
        this.$emit('active');
      }
    },
  },

};
</script>

<style
  lang="scss"
  scoped
>

</style>
