<template>
  <div
    class="modal-card"
    style="overflow: visible;"
  >
    <div
      class="modal-card-body p-6"
      style="border-top-left-radius: 4px; border-top-right-radius: 4px; overflow: visible;"
    >
      <h1 class="is-size-5 mb-2 has-text-weight-bold">
        Confirm your configurations
      </h1>
      <p class="is-size-7 mb-4">
        By requesting these quotes, you confirm you have clarified any discrepancies between
        selected configurations and attached engineering drawings in the notes, for each part.
      </p>
      <b-field
        custom-class="is-size-7"
        label="Please choose the country of delivery *"
      >
        <b-dropdown
          v-model="deliveryCountry"
          max-height="300px"
          scrollable
        >
          <template v-slot:trigger="{ active }">
            <b-button
              :icon-right="active ? 'chevron-up' : 'chevron-down'"
              class="is-justify-content-space-between"
              size="is-small"
              style="min-width: 40ch;"
            >
              {{ deliveryCountry }}
            </b-button>
          </template>
          <b-dropdown-item
            custom
            paddingless
          >
            <b-input
              v-model="countrySearch"
              expanded
              icon="search"
              size="is-small"
            />
          </b-dropdown-item>
          <b-dropdown-item
            v-for="country in countries"
            :key="country"
            :value="country"
          >
            {{ country }}
          </b-dropdown-item>
        </b-dropdown>
      </b-field>
      <div
        v-if="hasSelectedPartsParsingCount || hasSelectedPartsWithErrors"
        class="issues-wrapper"
      >
        <b-message
          v-if="hasSelectedPartsParsingCount"
          class="is-size-7"
          type="is-danger"
        >
          <span class="message-title">{{ selectedPartsParsingCount }}/{{ selectedPartsCount }}
            selected parts are still being analysed</span>
          Very complex files can take up to 5 mins
        </b-message>
        <b-message
          v-if="hasSelectedPartsWithErrors"
          class="is-size-7"
          type="is-danger"
        >
          <span class="message-title">{{ selectedPartsWithErrorsCount }}/{{ selectedPartsCount }}
            parts have warnings</span>
          Please resolve the warnings to request the selected quotes
        </b-message>
      </div>
      <div class="button-wrapper mt-4 is-flex is-justify-space-between">
        <gm-button
          class="has-text-weight-bold px-5"
          has-shadow
          type="white"
          @click="$emit('close')"
        >
          Cancel
        </gm-button>
        <gm-button
          :disabled="hasSelectedPartsWithErrors || hasSelectedPartsParsingCount"
          class="has-text-weight-bold"
          data-testid="submit-delivery-country-btn"
          has-shadow
          type="v2-supporting-1"
          @click="submit"
        >
          Get {{ selectedPartsCount }} {{ selectedPartsCount >= 2 ? 'quotes' : 'quote' }} now
        </gm-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import COUNTRIES from '@/app-buyer/consts/countries';
import {
  DRAFT_RFQ_ISSUES,
  DRAFT_RFQS,
  GET_ALL_SELECTED_PARTS,
  RFQ_MODULE,
} from '@/app-buyer/store/modules/rfq/types';

export default {
  name: 'DeliveryCountryForm',
  props: {
    selected: {
      type: Boolean,
    },
  },
  data() {
    return {
      countrySearch: null,
      deliveryCountry: COUNTRIES[0],
    };
  },
  computed: {
    ...mapState(RFQ_MODULE, {
      DRAFT_RFQS,
    }),
    ...mapGetters(RFQ_MODULE, {
      DRAFT_RFQ_ISSUES,
      GET_ALL_SELECTED_PARTS,
    }),
    countries() {
      if (this.countrySearch) {
        return COUNTRIES.filter((e) => e.toLowerCase().includes(this.countrySearch.toLowerCase()));
      }
      return COUNTRIES;
    },
    selectedPartsCount() {
      return this.selected ? this[GET_ALL_SELECTED_PARTS].length : this[DRAFT_RFQS].length;
    },
    selectedPartsWithErrorsCount() {
      return this.partsWithIssues('error');
    },
    selectedPartsParsingCount() {
      return this.partsWithIssues('parsing');
    },
    hasSelectedPartsWithErrors() {
      return this.selectedPartsWithErrorsCount > 0;
    },
    hasSelectedPartsParsingCount() {
      return this.selectedPartsParsingCount > 0;
    },
  },
  methods: {
    submit() {
      this.$emit('submit', this.deliveryCountry);
      this.$emit('close');
    },

    partsWithIssues(errorType) {
      if (!this.selected) {
        return Object.values(this[DRAFT_RFQ_ISSUES])
          .filter((issues) => {
            let errors = false;
            if (!issues.length) return errors;
            issues.forEach((issue) => {
              if (typeof issue === 'object' && issue.type === errorType) {
                errors = true;
              }
            });
            return errors;
          }).length;
      }

      return this[GET_ALL_SELECTED_PARTS].filter((d) => {
        const issues = this[DRAFT_RFQ_ISSUES][d.hash];
        if (issues?.length) {
          let errors = false;
          issues.forEach((issue) => {
            if (typeof issue === 'object' && issue.type === errorType) {
              errors = true;
            }
          });
          return errors;
        }
        return issues;
      }).length;
    },
  },
};
</script>
<style lang="scss" scoped
>
.modal-card-body {
  border-radius: 4px !important;

  .issues-wrapper {
    margin-top: 3.5rem;
  }
}
::v-deep {
  .message-title {
    font-weight: 700;
    margin-right: 0.5rem;
  }
  .message-body {
    padding: 0.7rem;
  }
  .message:not(:last-child) {
    margin-bottom: 0.5rem;
  }
}
</style>
