<template>
  <div :style="!isReviseRequote && 'padding-bottom: 10rem;'">
    <template v-for="elementDefinition in ELEMENTS">
      <b-skeleton
        v-if="Array.isArray(elementDefinition) && (!leadHash || !REFERENCE_DATA_OBJECT_BY_ID)"
        :animated="animated"
      />
      <div
        v-if="Array.isArray(elementDefinition) && leadHash && REFERENCE_DATA_OBJECT_BY_ID"
        :key="elementDefinition.entity"
        class="is-flex is-align-items-center is-justify-space-between has-multiple-inputs"
      >
        <DraftConfiguratorElement
          v-for="(subElementDefinition, index) in elementDefinition"
          :key="subElementDefinition.entity"
          class="is-flex-grow-1"
          :class="{
            'mr-2': index < elementDefinition.length - 1,
            'ml-2': index > 0
          }"
          :def="subElementDefinition"
          :configurations="configurations"
          :locked-configurations="lockedConfigurations"
          :lead-hash="leadHash"
          @update-config="updateConfiguration(subElementDefinition.entity, $event)"
          @effect="handleEffect"
          @set-note="handleNote(subElementDefinition.entity, $event)"
        />
      </div>
      <b-skeleton
        v-if="!Array.isArray(elementDefinition) && (!REFERENCE_DATA || !leadHash)"
        :animated="animated"
      />
      <DraftConfiguratorElement
        v-if="!Array.isArray(elementDefinition) && REFERENCE_DATA && leadHash"
        :key="elementDefinition.entity"
        :def="elementDefinition"
        :configurations="configurations"
        :locked-configurations="lockedConfigurations"
        :lead-hash="leadHash"
        @update-config="updateConfiguration(elementDefinition.entity, $event)"
        @effect="handleEffect"
        @set-note="handleNote(elementDefinition.entity, $event)"
      />
    </template>
    <ProductionRequirements
      :parts="parts"
      :lead-hash="leadHash"
      :configurations="configurations"
      :locked-configurations="lockedConfigurations"
      :is-revise-requote="isReviseRequote"
      @update-config="(({entitySlug, canApply}) => updateConfiguration(entitySlug, canApply))"
    />
    <p class="is-size-7">
      Extras
    </p>
    <PartFormDraftConfiguratorExtras
      :parts="parts"
      :lead-hash="leadHash"
      :configurations="configurations"
      :locked-configurations="lockedConfigurations"
      @effect="handleEffect"
      @update-config="(({entitySlug, canApply}) => updateConfiguration(entitySlug, canApply))"
    />
    <PartFormNoteComponent
      v-if="showNotes"
      :parts="parts"
      :lead-hash="leadHash"
      :configurations="configurations"
      :locked-configurations="lockedConfigurations"
      @update-config="(({entitySlug, canApply}) => updateConfiguration(entitySlug, canApply))"
    />
    <div>
      <GmButton
        class="p-0"
        is-link
        type="info"
        size="small"
        @click="showNotes = !showNotes"
      >
        {{ notesMessage }}
      </GmButton>
    </div>
  </div>
</template>

<script>
/* eslint-disable camelcase */

import {
  mapActions, mapMutations, mapGetters, mapState,
} from 'vuex';
import { cloneDeep } from 'lodash/lang';
import ELEMENTS
  from '@/app-buyer/components/configurator/configurator-body/configurator-drafts/configurator-element/configurator-elements';
import DraftConfiguratorElement from '@/app-buyer/components/project/DraftConfiguratorElement.vue';
import {
  RFQ_MODULE,
  UPDATE_DRAFT,
  SET_REVISE_REQUOTE_CONFIG, REVISE_REQUOTE_CONFIG,
} from '@/app-buyer/store/modules/rfq/types';
import PartFormDraftConfiguratorExtras
  from '@/app-buyer/components/project/PartFormDraftConfiguratorExtras.vue';
import PartFormNoteComponent from '@/app-buyer/components/project/PartFormNoteComponent.vue';
import presetHandler from '@/app-buyer/mixins/preset-handler';
import {
  REFERENCE_DATA_OBJECT_BY_SLUG,
  REFERENCE_MODULE,
  REFERENCE_DATA_OBJECT_BY_ID,
} from '@/app-buyer/store/modules/reference-data/types';
import ProductionRequirements from '@/app-buyer/components/project/ProductionRequirements.vue';
import getEnvironmentVariable from '@/shared/misc/env-variable';
import { MASQUERADING } from '@/app-buyer/store/modules/auth/types';
import { ACTIVE_PROJECT_HASH } from '@/app-buyer/store/modules/projects/types';
import createConfigurationObject from '@/app-buyer/mixins/create-configuration-object';

const getConfigurationForPart = (part) => {
  const {
    configuration, quantity_initial, quantity_production, notes,
  } = part;
  return {
    ...configuration,
    quantity_initial,
    quantity_production,
    notes,
  };
};

const getConfigurationsFromParts = (parts) => parts.reduce((result, part) => ({
  ...result,
  [part.hash]: getConfigurationForPart(part),
}), {});

const nullOutConfig = (config) => {
  const keep = ['quantity_initial', 'quantity_production', 'file-type', 'notes'];
  return Object.entries(config).reduce((cleared, [key, value]) => ({
    [key]: keep.includes(key) ? value : null,
    ...cleared,
  }), {});
};

export default {
  name: 'PartFormDraftConfigurator',

  components: {
    ProductionRequirements,
    PartFormNoteComponent,
    PartFormDraftConfiguratorExtras,
    DraftConfiguratorElement,
  },

  mixins: [presetHandler, createConfigurationObject],

  props: {
    parts: {
      type: Array,
      required: true,
    },
    isReviseRequote: {
      type: Boolean,
      default: false,
    },
    selectedHash: {
      type: String,
      required: false,
      default: '',
    },
    isMobile: Boolean,
  },

  data() {
    return {
      ELEMENTS,
      showNotes: false,
      configurations: {},
      originalConfigurations: {},
      configurationChanges: {},
      lockedConfigurations: {},
      animated: true,
    };
  },

  computed: {
    ...mapGetters(REFERENCE_MODULE, {
      REFERENCE_DATA_OBJECT_BY_SLUG,
      REFERENCE_DATA_OBJECT_BY_ID,
    }),

    ...mapState(RFQ_MODULE, {
      REVISE_REQUOTE_CONFIG,
    }),

    leadHash() {
      return this.parts[0]?.hash;
    },

    notesMessage() {
      if (this.showNotes) {
        return 'Hide notes';
      }
      if (this.parts?.[0].notes) {
        return 'Edit notes';
      }
      return 'Add notes';
    },
  },

  watch: {
    parts: {
      handler(parts) {
        this.configurations = getConfigurationsFromParts(parts);
        this.originalConfigurations = getConfigurationsFromParts(parts);
      },
      immediate: true,
      deep: true,
    },
  },

  methods: {
    ...mapActions(RFQ_MODULE, {
      UPDATE_DRAFT,
    }),
    ...mapMutations(RFQ_MODULE, {
      SET_REVISE_REQUOTE_CONFIG,
    }),

    /*
     Compare the original configurations with the updated ones and send only the changes
     to update
    */
    calculateChanges() {
      const alwaysInclude = ['development-stage',
        'tool-life',
        'tooling-configuration',
        't1-sample-date',
        'im-production-notes'];
      this.configurationChanges = Object.entries(this.configurations)
        .reduce((res, [hash, configuration]) => ({
          ...res,
          [hash]: Object.entries(configuration).reduce((changes, [entity, value]) => {
            const hasDifference = this.originalConfigurations[hash]
              && value !== this.originalConfigurations[hash][entity];
            const mustInclude = alwaysInclude.includes(entity);
            if (hasDifference || mustInclude) {
              return {
                ...changes,
                [entity]: value,
              };
            }
            return changes;
          }, {}),
        }), {});
    },

    updateConfiguration(entitySlug, newValues) {
      // deep copy the existing configurations
      const copy = cloneDeep({ ...this.configurations });
      Object.entries(newValues).forEach(([hash, value]) => {
        // If the change is a service we apply the preset to the configuration
        if (entitySlug === 'service' && copy[hash].service !== value) {
          this._storeAsPreset(copy[hash], hash);
          copy[hash].service = value;
          const preset = this._findPreset(copy[hash], hash);
          copy[hash] = {
            ...nullOutConfig(copy[hash]),
            ...preset,
          };
        } else if (
          this[REFERENCE_DATA_OBJECT_BY_SLUG]['3d-printing']?.id === copy[hash].service
          && entitySlug === 'technology'
          && copy[hash].technology !== value
        ) {
          this._storeAsPreset(copy[hash], hash);
          copy[hash].technology = value;
          const preset = this._findPreset(copy[hash], hash);
          copy[hash] = {
            ...nullOutConfig(copy[hash]),
            ...preset,
          };
        } else {
          copy[hash] = {
            ...copy[hash],
            [entitySlug]: value,
          };
        }

        // SEGMENT TRACKING
        let modifiedField = {};

        if (entitySlug === 'production_requirements') {
          modifiedField = {
            production_requirements: newValues[this.leadHash],
          };
        } else {
          modifiedField = {
            [entitySlug]: this[REFERENCE_DATA_OBJECT_BY_ID]?.[newValues[this.leadHash]]?.slug,
          };
        }

        if (getEnvironmentVariable('VUE_APP_SEGMENT_ENABLED')) {
          window.analytics.track('Configurator field modified', {
            isMasquerading: !!this[MASQUERADING],
            projectHash: this[ACTIVE_PROJECT_HASH],
            draftHash: this.leadHash,
            modifiedField,
          });
        }
        // SEGMENT TRACKING
      });
      this.configurations = copy;
      this.calculateChanges();
      this.updateParts();
    },

    updateParts() {
      Object.entries(this.configurationChanges).forEach(([hash, config]) => {
        const hasChanges = !!Object.keys(config).length;
        const part = this.parts.find((p) => p.hash === hash);
        // Only start update if the part is still in the configurations object and has change
        if (!part || !hasChanges) return;
        let properties = {
          project_hash: part.project_hash,
          'file-type': part.configuration['file-type'],
          ...config,
        };
        const draft = {
          ...part,
          configuration: {
            ...part.configuration,
            ...config,
            'file-type': part.configuration['file-type'],
          },
        };
        if (this.isReviseRequote) {
          const existingConfig = this[REVISE_REQUOTE_CONFIG].filter((c) => c.hash === draft.hash);

          let configProperties = {
            hash: draft.hash,
            properties,
          };

          if (existingConfig.length) {
            configProperties = {
              ...existingConfig[0],
              properties: {
                ...existingConfig[0].properties,
                ...properties,
              },
            };
          }

          const configObject = this.createConfig({ hash: draft.hash, properties });

          configProperties = {
            ...configProperties,
            configObject,
            configuration: draft.configuration,
            quantity_initial: properties.quantity_initial ?? draft.quantity_initial,
            quantity_production: properties.quantity_production ?? draft.quantity_production,
          };

          if (properties.production_requirements) {
            configProperties = {
              ...configProperties,
              production_requirements: properties.production_requirements,
            };
          }

          if (properties.notes) {
            configProperties = {
              ...configProperties,
              notes: properties.notes,
            };
          }

          this[SET_REVISE_REQUOTE_CONFIG](configProperties);
        } else {
          properties = {
            ...properties,
            name: part.name,
          };

          this[UPDATE_DRAFT]({
            draft,
            properties,
          });
        }
      });
    },

    /*
     Custom notes (eg. from the input of other materials) are stored in localstorage until
     the part is submitted so it can be appended to the existing notes
    */
    handleNote(entitySlug, note) {
      this.parts.forEach((draft) => {
        const notes = localStorage.getItem(`notes_${draft.hash}`);
        const notesObject = notes ? JSON.parse(notes) : {};
        if (note) {
          notesObject[entitySlug] = note;
        } else if (Object.prototype.hasOwnProperty.call(notesObject, entitySlug)) {
          delete notesObject[entitySlug];
        }
        localStorage.setItem(`notes_${draft.hash}`, JSON.stringify(notesObject));
      });
    },

    /*
      After setting the locked values a new update is required so the BE is in sync
     */
    handleEffect({
      hash, entitySlug, value, lockedBy, properties,
    }) {
      const currentConfig = this.lockedConfigurations[hash] ? this.lockedConfigurations[hash] : {};
      this.lockedConfigurations = {
        ...this.lockedConfigurations,
        [hash]: {
          ...currentConfig,
          [entitySlug]: lockedBy?.length ? {
            lockedBy,
            properties,
          } : null,
        },
      };
      if (value !== undefined) {
        this.updateConfiguration(entitySlug, { [hash]: value });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.has-multiple-inputs {
  margin-bottom: 0.5rem;

  ::v-deep {
    .field {
      flex-grow: 1;

      &:not(:last-child) {
        margin-bottom: 0 !important;
        margin-right: 1rem !important;
      }
    }
  }
}

::v-deep {
  label.b-radio.button {
    @media all and (max-width: 1500px) {
      padding: 0.25rem !important;
    }
  }
}
</style>
