import {
  GET_LOGIN_MODAL_MODE,
  IS_LOGIN_MODAL_VISIBLE,
  LOGIN_MODAL_MODE,
  LOGIN_MODAL_VISIBLE,
} from './types';

export default {
  // Returns true if the login modal is visible
  [IS_LOGIN_MODAL_VISIBLE]: (state) => state[LOGIN_MODAL_VISIBLE],
  // Returns the mode the login modal is set to
  [GET_LOGIN_MODAL_MODE]: (state) => state[LOGIN_MODAL_MODE] || 'login',
};
