/* eslint-disable no-param-reassign */
import { mapState } from 'vuex';
import { PRESETS, RFQ_MODULE } from '../store/modules/rfq/types';
import {
  REFERENCE_DATA,
  REFERENCE_MODULE,
} from '../store/modules/reference-data/types';

const newPresets = {};

const presetHandler = {
  computed: {
    ...mapState(RFQ_MODULE, {
      PRESETS,
    }),
    ...mapState(REFERENCE_MODULE, {
      REFERENCE_DATA,
    }),
  },
  methods: {
    _preSelectServiceByFileType(extension, supportingExtension) {
      const fileType = extension?.toLowerCase();
      const supportingFileType = supportingExtension?.toLowerCase();
      if (fileType === 'stl') {
        return this[REFERENCE_DATA]?.find((e) => e.entity_slug === 'service' && e.slug === '3d-printing')?.id;
      } if (fileType === 'dwg' || fileType === 'dxf' || supportingFileType === 'dwg' || supportingFileType === 'dxf') {
        return this[REFERENCE_DATA]?.find((e) => e.entity_slug === 'service' && e.slug === 'sheet-metal')?.id;
      }
      return this[REFERENCE_DATA]?.find((e) => e.entity_slug === 'service' && e.slug === 'cnc')?.id;
    },
    _findBEPresetObject(properties) {
      return this[PRESETS].find((preset) => {
        const service = preset.configuration_properties.find((e) => e.entity_slug === 'service');
        const technology = preset.configuration_properties.find((e) => e.entity_slug === 'technology');
        if (service.id === properties.service) {
          if (properties.technology && technology && properties.technology === technology.id) {
            return technology && properties.technology === technology.id;
          }
        }
        if (!technology || !properties.technology) {
          return service.id === properties.service;
        }
        return false;
      });
    },
    _findPreset(properties, hash) {
      const foundPreset = this._findBEPresetObject(properties);
      if (!foundPreset) return properties;
      const formatPreset = (preset) => preset.configuration_properties.reduce((res, property) => ({
        ...res,
        [property.entity_slug]: property.id,
      }), {});
      const presetObject = newPresets[hash]?.[foundPreset?.slug] || formatPreset(foundPreset);
      if (properties['file-type']) {
        presetObject['file-type'] = properties['file-type'];
      }
      presetObject.quantity_initial = properties.quantity_initial || 1;
      presetObject.quantity_production = properties.quantity_production || 0;
      return presetObject;
    },
    _storeAsPreset(properties, hash) {
      const foundPreset = this._findBEPresetObject(properties);
      if (!newPresets[hash]) newPresets[hash] = {};
      newPresets[hash][foundPreset.slug] = { ...properties };
    },
  },
};

export default presetHandler;
