import {
  ACCESS_TOKEN, FORCED, LOGGED_IN, MASQUERADING, UPLOAD_BEFORE_AUTH,
} from './types';

export default function getState() {
  return {
    [LOGGED_IN]: false,
    [ACCESS_TOKEN]: null,
    [MASQUERADING]: false,
    [FORCED]: null,
    [UPLOAD_BEFORE_AUTH]: false,
  };
}
