<template>
  <BDropdown
    :value="value"
    class="project-picker"
    @input="$emit('input', $event)"
    @active-change="handleGetAllProjects"
  >
    <template #trigger="{ active }">
      <BButton
        class="is-justify-content-space-between"
        :icon-right="`chevron-${active ? 'down' : 'right'}`"
        data-testid="project-list-btn"
      >
        {{ buttonLabel }}
      </BButton>
    </template>
    <BDropdownItem
      :value="null"
      paddingless
    >
      <div
        class="py-1 px-4 is-flex is-align-items-center is-justify-content-space-between"
        style="white-space: nowrap"
      >
        All projects
      </div>
    </BDropdownItem>
    <BDropdownItem
      v-for="project in PROJECTS"
      :key="project.hash"
      paddingless
      :value="project.hash"
      data-testid="project-list-item"
    >
      <div
        class="py-1 px-4 is-flex is-align-items-center is-justify-content-space-between"
        style="white-space: nowrap"
      >
        {{ project.name }}
        <div class="is-flex is-align-items-center is-justify-content-space-between">
          <BTooltip
            type="is-white"
            position="is-left"
            :label="`${project.unordered_rfqs_count} rfq${project.unordered_rfqs_count > 1 ? 's' : ''} in project`"
          >
            <BTag
              v-if="project.unordered_rfqs_count"
              rounded
              size="is-small"
            >
              {{ project.unordered_rfqs_count }}
            </BTag>
          </BTooltip>
        </div>
      </div>
    </BDropdownItem>
    <div
      v-if="loading"
      class="loading-container"
    >
      <BLoading
        :active="loading"
        :is-full-page="false"
      />
    </div>
  </BDropdown>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import { PROJECT_MODULE, PROJECTS, PAGINATION_PAGES } from '@/app-buyer/store/modules/projects/types';
import { PAGINATION, GET } from '@/app-buyer/store/modules/types';

export default {
  name: 'ProjectPicker',
  props: {
    value: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapState(PROJECT_MODULE, {
      PROJECTS,
      PAGINATION,
      PAGINATION_PAGES,
    }),
    buttonLabel() {
      const project = this[PROJECTS].find((p) => p.hash === this.value);
      return project?.name || 'All projects';
    },
  },
  methods: {
    ...mapActions(PROJECT_MODULE, {
      GET,
    }),
    handleNextPage() {
      this.loading = true;
      this[GET]({ clear: false, page: this[PAGINATION].current_page + 1 }).then(() => this.loading = false);
    },
    async handleGetAllProjects(isDropdownActive) {
      if (isDropdownActive && this.PROJECTS.length < this.PAGINATION.total) {
        this.loading = true;
        await this.GET({ limit: this.PAGINATION.total, clear: true });
        this.loading = false;
      }
    },
  },
};
</script>

<style
  scoped
  lang="scss"
>
::v-deep {
  .dropdown-menu {
    min-width: 250px;
  }
}
</style>
<style lang="scss">
.project-picker.dropdown {
    width: 100%;
  .dropdown-trigger,
  .button {
    width: 100%;
  }
  .dropdown-content {
    height: 325px;
    overflow: auto;
    .loading-container {
      position: absolute;
      height: 100%;
      inset: 0;
    }
  }
}
</style>
