import { ACTIVE, RFQ_MODULE } from '../store/modules/rfq/types';

const accessAllViewed = {
  computed: {
    _viewedModels() {
      return this.$store.getters[`${RFQ_MODULE}/${ACTIVE}`].length
                && this.$store.getters[`${RFQ_MODULE}/${ACTIVE}`];
    },
  },
};

export default accessAllViewed;
