<template>
  <div
    class="px-4"
    style="border-bottom: 1px solid #dbdbdb;"
  >
    <div
      style="width: 100%;"
    >
      <div class="is-flex is-align-items-center is-justify-content-space-between mb-2">
        <GroupedQuotesCellRef :is-open="open">
          {{ rfq.ref }}
        </GroupedQuotesCellRef>
        <PartThumbnail :part="rfq" />
      </div>
      <div class="is-flex-grow-1">
        <table
          cellpadding="0"
          cellspacing="0"
          class="inner-table is-width-100"
        >
          <tr>
            <td class="has-text-weight-bold">
              Part name:
            </td>
            <td>
              {{ rfq.name }}
            </td>
          </tr>
          <tr>
            <td class="has-text-weight-bold">
              Service:
            </td>
            <td>
              <TOC
                v-slot="{ value }"
                :data="rfq"
                path="configuration_object.service.string_value"
              >
                {{ value }}
              </TOC>
            </td>
          </tr>
          <tr>
            <td class="has-text-weight-bold">
              Qty
            </td>
            <td>
              {{ rfq.quantity_initial }}
            </td>
          </tr>
          <tr>
            <td class="has-text-weight-bold">
              Material
            </td>
            <td>
              <TOC
                v-slot="{ value }"
                :data="rfq"
                path="configuration_object.material.string_value"
              >
                {{ value }}
              </TOC>
            </td>
          </tr>
          <tr>
            <td class="has-text-weight-bold">
              Manufacturing time
            </td>
            <td>
              <TOC
                v-slot="{ value }"
                :data="rfq"
                path="configuration_object.manufacturing-time.string_value"
              >
                {{ value }}
              </TOC>
            </td>
          </tr>
          <tr>
            <td class="has-text-weight-bold">
              Subtotal
            </td>
            <td>
              {{ subtotal | formatWithCurrency }}
            </td>
          </tr>
          <tr>
            <td class="has-text-weight-bold">
              Status
            </td>
            <td>
              <GroupedQuotesStatus
                :is-pending="!subtotal"
                :rfq="rfq"
              />
            </td>
          </tr>
        </table>
      </div>
    </div>
    <div class="my-2 is-flex is-align-items-center is-justify-content-space-between">
      <GroupedQuotesOptions
        :quote="quote"
        :hidden="hasQuotesInCartFromOtherGroup ? ['add-cart', 'remove-cart'] : []"
        :rfq="rfq"
      />
      <GroupedQuotesCartButtons
        v-if="quote && !hasQuotesInCartFromOtherGroup"
        :quote="quote"
        :rfq="rfq"
        :quote-group-ids-in-cart-from-other-groups="quoteGroupIdsInCartFromOtherGroups"
      />
    </div>
  </div>
</template>
<script>
import GroupedQuotesCartButtons
  from '@/app-buyer/components/grouped-quotes/GroupedQuotesCartButtons.vue';
import GroupedQuotesOptions from '@/app-buyer/components/grouped-quotes/GroupedQuotesOptions.vue';
import TOC from '@/shared/components/template-optional-chaining/TemplateOptionalChaining.vue';
import GroupedQuotesCellRef from '@/app-buyer/components/grouped-quotes/GroupedQuotesCellRef.vue';
import PartThumbnail from '@/app-buyer/components/project/PartThumbnail.vue';
import GroupedQuotesStatus from '@/app-buyer/components/grouped-quotes/GroupedQuotesStatus.vue';

export default {
  name: 'GroupedQuoteSummaryMobile',
  components: {
    GroupedQuotesStatus,
    PartThumbnail,
    GroupedQuotesCellRef,
    TOC,
    GroupedQuotesOptions,
    GroupedQuotesCartButtons,
  },
  props: {
    subtotal: {
      type: Number,
      default: () => null,
    },
    open: Boolean,
    rfq: {
      type: Object,
      required: true,
    },
    quote: {
      type: Object,
      default: () => null,
    },
    quoteGroupIdsInCartFromOtherGroups: {
      type: Array,
      required: true,
    },
  },
  computed: {
    hasQuotesInCartFromOtherGroup() {
      return this.quoteGroupIdsInCartFromOtherGroups.length;
    },
  },
};
</script>
<style
  lang="scss"
  scoped
>
::v-deep {
  @media all and (max-width: variables.$tablet) {
  }

  @media all and (max-width: variables.$tablet) {
    .th-wrap span {
      flex-grow: 1 !important;
    }
  }

}

.inner-table {
  border-collapse: collapse;

  td {
    padding: 0.2rem 0.2rem;
    border: none;
    font-size: 13px;
  }
}
</style>
