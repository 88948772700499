var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"modal-card",staticStyle:{"width":"761px"},attrs:{"id":"inspection-modal"}},[_c('div',{staticClass:"modal-card-head has-background-white py-5 px-6"},[_c('p',{staticClass:"modal-card-title has-text-weight-bold"},[_vm._v("\n      Inspections\n    ")]),_c('a',{staticClass:"delete",on:{"click":function($event){return _vm.$emit('close')}}})]),_c('div',{staticClass:"modal-card-body py-5 px-6"},[_c('BTable',{attrs:{"data":_vm.inspections,"opened-detailed":_vm.openedDetailed,"show-detail-icon":false,"detail-key":"id","detailed":""},scopedSlots:_vm._u([{key:"detail",fn:function(ref){
var row = ref.row;
return [_c('div',[_c('p',{staticClass:"title is-7"},[_vm._v("\n            What's included\n          ")]),_c('div',{staticClass:"markdown-html",domProps:{"innerHTML":_vm._s(_vm.$options.markdownConverter.makeHtml(row.metadata.description))}})])]}}])},[_c('BTableColumn',{attrs:{"width":"3rem"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('GmButton',{staticClass:"p-1",attrs:{"size":"small","type":"g-light-1"},on:{"click":function($event){_vm.updateDetailed(row.id, !_vm.openedDetailed.includes(row.id))}}},[_c('BIcon',{attrs:{"icon":_vm.openedDetailed.includes(row.id) ? 'chevron-down' : 'chevron-right'}})],1)]}}])}),_c('BTableColumn',{attrs:{"header-class":"is-size-7 py-5 pl-0 pr-2","label":"Name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',{staticClass:"is-size-6 has-text-weight-bold pr-2"},[_vm._v("\n          "+_vm._s(row.string_value)+"\n        ")])]}}])}),_c('BTableColumn',{attrs:{"header-class":"is-size-7 py-5 pl-0 pr-2","label":"Typical cost"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.slug === 'custom-inspection')?[_vm._v("\n          Variable\n        ")]:(row.quote_addon_price)?[_vm._v("\n          "+_vm._s(_vm._f("formatSimpleCost")(row.quote_addon_price))+"\n        ")]:[_vm._v("\n          No cost\n        ")]]}}])}),_c('BTableColumn',{attrs:{"numeric":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('BButton',{staticClass:"is-toggle",style:({
            'font-size': (_vm.isSelected(row) ? '0.85rem' : '1rem'),
            'min-width': '165px',
            'min-height': '40px',
          }),attrs:{"disabled":_vm.isLocked(row),"icon-left":_vm.isSelected(row) ? 'check' : null,"outlined":_vm.isSelected(row),"icon-pack":"fal","type":"is-info"},on:{"click":function($event){return _vm.handleSelected(row)}}},[_vm._v("\n          "+_vm._s(_vm.isSelected(row) ? 'Inspection selected' : 'Select inspection')+"\n        ")])]}}])})],1),_vm._m(0)],1),_c('div',{staticClass:"modal-card-foot has-background-white is-justify-space-between py-5 px-6"},[_c('GmButton',{attrs:{"is-link":""},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v("\n      Cancel\n    ")]),_c('GmButton',{attrs:{"type":"g-primary"},on:{"click":_vm.handleConfirm}},[_vm._v("\n      Confirm Inspection\n    ")])],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-4"},[_c('p',{staticClass:"is-size-7"},[_vm._v("\n        * Lot size sampling as Geomiq Inspection Sampling Table\n        (based on ISO 2859-1:1999, AQL 2.5%, with zero defects)\n      ")]),_c('p',{staticClass:"is-size-7"},[_vm._v("\n        + A customer-supplied dimensional print is required for this inspection\n      ")])])}]

export { render, staticRenderFns }