const checkFileType = {
  methods: {
    checkType(file) {
      if (!this.accept) {
        return true;
      }
      const types = this.accept.split(',');
      if (types.length === 0) {
        return true;
      }
      let valid = false;
      for (let i = 0; i < types.length && !valid; i++) {
        const type = types[i].trim();
        if (type) {
          if (type.substring(0, 1) === '.') {
            const extIndex = file.name.lastIndexOf('.');
            const extension = extIndex >= 0
              ? file.name.substring(extIndex) : '';
            if (extension.toLowerCase() === type.toLowerCase()) {
              valid = true;
            }
          } else if (file.type.match(type)) {
            valid = true;
          }
        }
      }
      return valid;
    },
  },
};

export default checkFileType;
