<template>
  <div>
    <BDropdown
      aria-role="list"
    >
      <template #trigger>
        <BButton
          size="is-small"
          icon-left="ellipsis-h"
          class="option-button"
        />
      </template>
      <BDropdownItem
        v-for="(item, index) in options"
        :key="cleanString(item.label)"
      >
        <Component
          :is="item.component || 'div'"
          class="is-flex is-flex-align-centered py-2"
          v-bind="item.bind"
          :class="cleanString(item.label)"
          v-on="item.on"
        >
          <p
            v-if="index < 2"
            class="has-text-left is-flex-grow-1"
          >
            {{ item.label }} ({{ allPartsNumber }})
          </p>
          <p
            v-else
            class="has-text-left is-flex-grow-1"
          >
            {{ item.label }} ({{ selectedPartsNumber }})
          </p>
        </Component>
      </BDropdownItem>
    </BDropdown>
    <b-modal
      v-model="modalActive"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      aria-role="dialog"
      close-button-aria-label="Close"
      aria-modal
      width="410px"
      class="dialog"
    >
      <PartTableBulkOperationOption
        :selected-parts="selectedParts"
        @selected-option="optionSelected = $event"
        @close-modal="modalActive = $event"
        @moveRfqsToProject="moveRfqsToProject"
      />
    </b-modal>
  </div>
</template>

<script>
import {
  mapActions, mapMutations, mapState,
} from 'vuex';
import {
  DRAFT_RFQS, MOVE_RFQ,
  RFQ_MODULE, SELECTED_PART_HASHES,
  UPDATE_DRAFT,
  BATCH_DELETE,
} from '@/app-buyer/store/modules/rfq/types';
import { GET } from '@/app-buyer/store/modules/types';
import { partSelectMixin } from '@/app-buyer/components/project/mixins';
import PartTableBulkOperationOption from '@/app-buyer/components/project/PartTableBulkOperationOption.vue';
import {
  GET_MRFQ_PROJECTS,
  GET_MRFQ_PROJECTS_M,
  MRFQS_PAGINATION_PAGES,
  PROJECT_MODULE, PROJECTS,
  PROJECTS_MRFQ, RESET_PROJECTS_MRFQ,
  ACTIVE_PROJECT_HASH, GET_UPDATED_PROJECTS_MRFQ,
} from '@/app-buyer/store/modules/projects/types';

export default {
  name: 'PartTableBulkOperationAll',
  components: {
    PartTableBulkOperationOption,
  },
  mixins: [partSelectMixin],
  data() {
    return {
      modalActive: false,
      allPartsSelected: false,
      optionSelected: '',
      options: [
        {
          label: 'Select all',
          on: {
            click: () => {
              this.selectAllParts();
            },
          },
        },
        {
          label: 'Deselect all',
          on: {
            click: () => {
              this.deselectAllParts();
            },
          },
        },
        {
          label: 'Delete selected',
          on: {
            click: () => {
              this.deleteSelected();
            },
          },
        },
        {
          label: 'Move selected',
          on: {
            click: () => {
              this.moveSelected();
            },
          },
        },
      ],
    };
  },

  computed: {
    ...mapState(RFQ_MODULE, {
      DRAFT_RFQS,
      SELECTED_PART_HASHES,
    }),

    allPartsNumber() {
      return this[DRAFT_RFQS]?.length;
    },

    selectedPartsNumber() {
      return this[SELECTED_PART_HASHES]?.length;
    },
    selectedParts() {
      return this[DRAFT_RFQS].filter((part) => this[SELECTED_PART_HASHES].includes(part.hash));
    },
  },
  methods: {
    ...mapActions(RFQ_MODULE, {
      UPDATE_DRAFT,
      BATCH_DELETE,
      MOVE_RFQ,
      GET_UPDATED_PROJECTS_MRFQ,
    }),
    ...mapActions(PROJECT_MODULE, {
      GET_MRFQ_PROJECTS,
      GET_MRFQ_PROJECTS_M,
    }),
    ...mapState(PROJECT_MODULE, {
      MRFQS_PAGINATION_PAGES,
      PROJECTS_MRFQ,
      PROJECTS,
      ACTIVE_PROJECT_HASH,
    }),
    ...mapMutations(PROJECT_MODULE, {
      RESET_PROJECTS_MRFQ,
    }),
    ...mapActions(PROJECT_MODULE, {
      GET,
    }),

    selectAllParts() {
      this.allPartsSelected = true;
      this.selectParts(...this[DRAFT_RFQS].map((part) => part.hash));
    },

    deselectAllParts() {
      this.allPartsSelected = false;
      this.selectParts(...[]);
    },

    deleteSelected() {
      if (this[SELECTED_PART_HASHES].length > 0) {
        this.$buefy.dialog.confirm({
          message: `Are you sure you want to <b>remove</b> ${this[SELECTED_PART_HASHES].length === 1
            ? `this ${this[SELECTED_PART_HASHES].length} part?`
            : `these ${this[SELECTED_PART_HASHES].length} parts?`} `,
          confirmText: 'Remove',
          onConfirm: () => {
            this[BATCH_DELETE](this.selectedParts);
          },
          type: 'is-danger',
        });
      }
    },

    async moveSelected() {
      if (this[SELECTED_PART_HASHES].length > 0) {
        this[RESET_PROJECTS_MRFQ]();
        await this[GET_MRFQ_PROJECTS]({});
        this.modalActive = true;
      }
    },

    moveRfqsToProject() {
      const toHash = this.optionSelected?.hash;
      this.selectedParts.forEach((part) => {
        const { hash } = part;
        this[MOVE_RFQ]({
          hash, to: toHash, isDraft: true,
        });
      });
      this[GET_UPDATED_PROJECTS_MRFQ]({ to: toHash });
      this.modalActive = false;
    },

    cleanString(str) {
      return str.trim().toLowerCase().replaceAll(' ', '-');
    },
  },
};
</script>

<style scoped>
.option-button {
  height: 1.25em;
  width: 1.25em;
  color: black !important;
  border: 0;
  min-height: 11px!important;
}
</style>
