import { render, staticRenderFns } from "./ProjectSidebar.vue?vue&type=template&id=34baa9e4&scoped=true&"
import script from "./ProjectSidebar.vue?vue&type=script&lang=js&"
export * from "./ProjectSidebar.vue?vue&type=script&lang=js&"
import style0 from "./ProjectSidebar.vue?vue&type=style&index=0&id=34baa9e4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "34baa9e4",
  null
  
)

export default component.exports