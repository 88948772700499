<template>
  <section
    class="card box p-sm-important m-y-sm-important"
    :class="backgroundType"
  >
    <div class="card-content p-sm-important">
      <div class="media">
        <div
          class="media-left"
        >
          <b-icon
            :icon="icon"
            :type="invertedColor"
          />
        </div>
        <div
          class="media-content"
        >
          <div
            class="content"
          >
            <PortalTarget
              v-if="notification.portalName"
              :name="notification.portalName"
            />
            <p
              data-testid="notification-message"
              class="notification-message"
              style="font-size: 0.8rem;"
              :class="textType"
              v-html="sanitizedMessage"
            />
          </div>
        </div>
        <div class="media-right">
          <a
            class="delete is-small"
            @click="close"
          />
        </div>
      </div>
    </div>
    <footer
      v-if="notification.confirmText || notification.cancelText"
      class="is-flex"
    >
      <div
        v-if="notification.confirmText"
        class="p-sm is-flex-grow-1"
        style="flex-basis: 0;"
      >
        <gm-button
          type="white"
          fullwidth
          size="small"
          class="text-transform-capitalize"
          @click="confirmClick"
        >
          <b>{{ notification.confirmText }}</b>
        </gm-button>
      </div>
      <div
        v-if="notification.cancelText"
        class="p-sm is-flex-grow-1"
        style="flex-basis: 0;"
      >
        <gm-button
          type="white"
          fullwidth
          outlined
          size="small"
          class="text-transform-capitalize"
          @click="cancelClick"
        >
          <b>{{ notification.cancelText }}</b>
        </gm-button>
      </div>
    </footer>
  </section>
</template>

<script>
import notificationInjection from './notification-injection-mixin';

export default {
  name: 'NotificationComponent',
  mixins: [notificationInjection],
  props: {
    notification: {
      type: Object,
      required: true,
    },
    component: {
      type: Object,
      default: () => null,
    },
    componentProps: {
      type: Object,
      default: () => null,
    },
  },
  computed: {
    defaultIcon() {
      switch (this.notification.type) {
        case 'is-success':
          return 'check-circle';
        case 'is-danger':
          return 'exclamation-circle';
        case 'is-info':
          return 'info-circle';
        case 'is-warning':
          return 'exclamation-triangle';
        default:
          return 'info-circle';
      }
    },
    icon() {
      return this.notification.icon || this.defaultIcon;
    },
    sanitizedMessage() {
      return this.$sanitize(this.notification.message);
    },
    invertedColor() {
      return `${this.notification.type}invert`;
    },
    backgroundType() {
      return ['has', 'background'].concat(this.notification.type.split('-').filter((e) => e !== 'is')).join('-');
    },
    textType() {
      return `${['has', 'text'].concat(this.notification.type.split('-').filter((e) => e !== 'is')).join('-')}invert`;
    },
  },
  created() {
    if (!this.notification.indefinite) {
      setTimeout(this.close, this.notification.duration || 6000);
    }
  },
  methods: {
    confirmClick() {
      if (this.notification.onConfirm) {
        this.notification.onConfirm();
      }
      this.close();
    },
    cancelClick() {
      if (this.notification.onCancel) {
        this.notification.onCancel();
      }
      this.close();
    },
    close() {
      this._removeNotification(this.notification.id);
    },
  },
};
</script>

<style lang="scss" scoped>
  section.card.box {
    width: 20rem;
  }

  .notification-message {
    word-break: break-word;

    :first-letter {
      text-transform: capitalize;
    }
  }
</style>
