/* eslint-disable class-methods-use-this */
import Model from './Model';

export default class Rfq extends Model {
  resource() {
    return 'rfqs';
  }

  primaryKey() {
    return 'hash';
  }
}
