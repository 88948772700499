<template>
  <div
    class="wrapper"
    style="--size: 0.8rem;"
  >
    <slot />
    <b-tooltip
      animated
      multilined
      :position="pos"
      :label="message"
    >
      <div class="icon-container">
        <b-icon
          v-if="icon"
          :icon="icon"
        />
        <p
          v-else
          class="info-icon"
        >
          <span>i</span>
        </p>
      </div>
    </b-tooltip>
  </div>
</template>

<script>
export default {
  name: 'InfoButton',
  props: {
    icon: {
      type: String,
      default: '',
    },
    message: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      pos: 'is-top',
    };
  },
  mounted() {
    if (this.$el.offsetTop < 200) {
      this.pos = 'is-bottom';
    }
  },
};
</script>

<style scoped>
  .wrapper {
    display: flex;
  }

  .icon-container {
    flex-grow: 0;
    cursor: pointer;
  }

  .info-icon {
    font-size: calc(var(--size) * 0.9);
    font-weight: bold;
    background-color: var(--geomiq-blue);
    color: white;
    border-radius: 50%;
    width: var(--size);
    height: var(--size);
    display: flex;
    justify-content: center;
    line-height: var(--size);
    margin: calc(var(--size) * 0.3);
  }
</style>
