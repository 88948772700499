<template>
  <b-modal
    :active="isModalActive"
    has-modal-card
    trap-focus
    custom-class="animation-content-full"
    @close="handleCloseModal"
  >
    <div
      class="modal-card"
      style="width: 75vw; height: 90vh"
    >
      <header class="modal-card-head if-flex">
        <p class="modal-card-head-title has-text-weight-bold">
          Revise your quotes
        </p>
        <p class="head-desc-text">
          <em>Making changes to 'received quotes' will remove any existing prices until a new quote
            is displayed</em>
        </p>
      </header>
      <div class="modal-card-body">
        <ReviseRequoteBody
          v-if="isModalActive"
          :revise-requote-rfqs="REVISE_REQUOTE_MODAL_RFQS"
        />
      </div>
      <footer class="modal-card-foot">
        <GmButton
          class="p-0"
          is-link
          :is-black="true"
          type="info"
          size="small"
          @click="handleCloseModal"
        >
          Cancel
        </GmButton>
        <div class="is-flex">
          <div class="is-flex is-align-items-center radio-text pr-2">
            <b-checkbox
              v-model="isRequestNewQuote"
              name="request-new-quotes"
            >
              Request as new quote(s)
            </b-checkbox>
          </div>
          <b-tooltip
            :active="buttonDisabled"
            :label="errorTooltipText"
            position="is-left"
            type="is-light-danger"
            multilined
          >
            <b-button
              :disabled="buttonDisabled"
              :label="requestBtnText"
              type="is-v2-supporting-1"
              @click="handleOpenModalOrRevise"
            />
          </b-tooltip>
        </div>
      </footer>
    </div>
    <b-modal
      v-model="confirmReviseModal"
      has-modal-card
      trap-focus
    >
      <div
        class="modal-card"
        style="overflow: visible;"
      >
        <div
          class="modal-card-body p-6"
          style="border-radius: 4px; overflow: visible;"
        >
          <h1 class="is-size-5 mb-2 has-text-weight-bold">
            Makes changes to your existing quotes?
          </h1>
          <p class="is-size-7 mb-6">
            Your current prices will be removed, we'll update the configuration and send you a new
            quoted price once ready. If you'd prefer to keep your existing prices too, please cancel
            and select 'Request as new quote(s)'.
          </p>
          <div class="button-wrapper mt-4 is-flex is-justify-space-between">
            <gm-button
              class="has-text-weight-bold px-5"
              has-shadow
              type="white"
              @click="handleCloseConfirmReviseModal"
            >
              Cancel
            </gm-button>
            <gm-button
              class="has-text-weight-bold"
              data-testid="submit-delivery-country-btn"
              has-shadow
              type="v2-supporting-1"
              @click="handleRevisions"
            >
              {{ requestBtnText }}
            </gm-button>
          </div>
        </div>
      </div>
    </b-modal>
  </b-modal>
</template>

<script>
import {
  mapActions, mapGetters, mapMutations, mapState,
} from 'vuex';
import ReviseRequoteBody from '@/app-buyer/components/revise-requote/ReviseRequoteBody.vue';
import RfqPendingModalContent from '@/app-buyer/components/project/RfqPendingModalContent.vue';
import {
  CREATE_RFQS,
  REVISE_REQUOTE_CONFIG,
  REVISE_REQUOTE_ISSUES,
  REVISE_REQUOTE_MODAL_RFQS,
  REVISE_REQUOTE_SELECTED_RFQS,
  REVISE_RFQS,
  RFQ_MODULE,
  SET_REVISE_REQUOTE_CONFIG,
  SET_REVISE_REQUOTE_MODAL_RFQS,
} from '@/app-buyer/store/modules/rfq/types';
import {
  GET_QUOTES,
  QUOTES_MODULE,
  SET_QUOTES,
  SET_QUOTES_LOADING,
} from '@/app-buyer/store/modules/quotes/types';

export default {
  name: 'ReviseRequoteModal',
  components: { ReviseRequoteBody },
  data() {
    return {
      isReviseRequoteActive: true,
      isRequestNewQuote: false,
      buefyModal: null,
      confirmReviseModal: false,
    };
  },

  computed: {
    ...mapState(RFQ_MODULE, {
      REVISE_REQUOTE_MODAL_RFQS,
      REVISE_REQUOTE_CONFIG,
    }),
    ...mapGetters(RFQ_MODULE, {
      REVISE_REQUOTE_ISSUES,
      REVISE_REQUOTE_SELECTED_RFQS,
    }),

    buttonDisabled() {
      return this.hasSelectedPartsWithErrors
        || !(this.selectedPartsHaveChanges === this[REVISE_REQUOTE_SELECTED_RFQS]?.length);
    },

    isModalActive() {
      return !!this[REVISE_REQUOTE_MODAL_RFQS]?.rfqs?.length;
    },

    selectedPartsWithErrorsCount() {
      return this.partsWithIssues();
    },

    hasSelectedPartsWithErrors() {
      return this.selectedPartsWithErrorsCount > 0;
    },
    selectedPartsHaveChanges() {
      let selectedReviseRequoteConfigs = [];

      this[REVISE_REQUOTE_CONFIG].forEach((c) => {
        let hasMatch = false;
        // eslint-disable-next-line no-unused-expressions
        this[REVISE_REQUOTE_MODAL_RFQS]?.selectedRfqs?.forEach((selected) => {
          if (c.hash === selected) hasMatch = true;
        });
        if (hasMatch) selectedReviseRequoteConfigs = [...selectedReviseRequoteConfigs, c];
      });

      return selectedReviseRequoteConfigs.length;
    },
    errorTooltipText() {
      if (this.hasSelectedPartsWithErrors) return 'Please resolve the warnings to request the selected revisions';
      return 'Please make changes to your selected parts';
    },
    requestBtnText() {
      return `Request ${this.selectedPartsHaveChanges ? `${this.selectedPartsHaveChanges}` : ''} revision${this.selectedPartsHaveChanges !== 1 ? 's' : ''}`;
    },
  },

  methods: {
    ...mapActions(RFQ_MODULE, {
      REVISE_RFQS,
      CREATE_RFQS,
    }),
    ...mapMutations(RFQ_MODULE, {
      SET_REVISE_REQUOTE_MODAL_RFQS,
      SET_REVISE_REQUOTE_CONFIG,
    }),

    ...mapMutations(QUOTES_MODULE, {
      SET_QUOTES,
      SET_QUOTES_LOADING,
    }),
    ...mapActions(QUOTES_MODULE, {
      GET_QUOTES,
    }),

    handleCloseModal() {
      this[SET_REVISE_REQUOTE_MODAL_RFQS]({});
      this[SET_REVISE_REQUOTE_CONFIG]({ reset: true });
    },

    handleCloseConfirmReviseModal() {
      this.confirmReviseModal = false;
    },

    handleOpenModalOrRevise() {
      if (this.isRequestNewQuote) this.handleRevisions();
      else this.confirmReviseModal = true;
    },

    async handleRevisions() {
      this.confirmReviseModal = false;
      this.buefyModal = this.$buefy.modal.open({
        parent: this,
        component: RfqPendingModalContent,
        props: {
          quoteNumber: this[REVISE_REQUOTE_CONFIG].length,
        },
        canCancel: false,
        width: '700px',
        hasModalCard: true,
        trapFocus: true,
      });

      let selectedReviseRequoteConfigs = [];

      for (let i = 0; i < this[REVISE_REQUOTE_SELECTED_RFQS]?.length; i++) {
        const changedConfig = this[REVISE_REQUOTE_CONFIG].filter(
          (c) => c.hash === this[REVISE_REQUOTE_SELECTED_RFQS][i].hash,
        );

        let createRfq = this[REVISE_REQUOTE_SELECTED_RFQS][i];
        createRfq = {
          ...createRfq,
          listing_hash: this[REVISE_REQUOTE_MODAL_RFQS]?.listingHash,
        };

        createRfq.configuration = {
          ...createRfq.configuration,
          ...changedConfig[0]?.properties,
          ...changedConfig[0]?.files,
        };

        selectedReviseRequoteConfigs = [...selectedReviseRequoteConfigs, createRfq];
      }

      if (!this.isRequestNewQuote) {
        try {
          const jobListings = {
            id: selectedReviseRequoteConfigs[0]?.listing_hash,
            rfqs_count: selectedReviseRequoteConfigs?.length,
          };
          await this[REVISE_RFQS]({ revisedRfqs: selectedReviseRequoteConfigs, jobListings });

          this[SET_REVISE_REQUOTE_CONFIG]({ reset: true });
        } catch (err) {
          this.$buefy.toast.open({
            message: `Something went wrong! ${err.message}`,
            type: 'is-danger',
          });
        } finally {
          this.buefyModal.close();
        }
      } else {
        try {
          await this[CREATE_RFQS]({
            draftOrRfq: selectedReviseRequoteConfigs,
            isReviseRequote: true,
          });
          this[SET_QUOTES]([]);

          this[SET_QUOTES_LOADING](true);
          this[SET_REVISE_REQUOTE_CONFIG]({ reset: true });
        } catch (err) {
          this.$buefy.toast.open({
            message: `Something went wrong! ${err.message}`,
            type: 'is-danger',
          });
        } finally {
          this.buefyModal.close();
        }
      }

      this.isRequestNewQuote = false;
      this[SET_REVISE_REQUOTE_MODAL_RFQS]({});
    },

    partsWithIssues() {
      return this[REVISE_REQUOTE_MODAL_RFQS]?.selectedRfqs?.filter((hash) => {
        const issues = this[REVISE_REQUOTE_ISSUES][hash];
        if (issues?.length) {
          let errors = false;
          issues.forEach((issue) => {
            if (typeof issue === 'object' && issue.type === 'error') {
              errors = true;
            }
          });
          return errors;
        }
        return issues;
      }).length;
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
.modal-card-head, .modal-card-foot {
  background-color: white;
}

.modal-card-head {
  flex-direction: column;
  align-items: start;
  border-bottom: 1px solid #dbdbdb;

  .head-desc-text {
    font-size: 13px;
  }
}

.modal-card-body {
  padding: 0;
}

.modal-card-foot {
  display: flex;
  justify-content: space-between;

  .radio-text {
    font-size: 13px;
  }
}
</style>
