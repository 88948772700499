<template>
  <p v-if="ACTIVE_PROJECT">
    <b class="is-size-5 mr-2">
      {{ ACTIVE_PROJECT.name }}
    </b>
    <small>
      ({{
        DRAFT_RFQS.length
      }} parts)
    </small>
  </p>
</template>
<script>
import { mapGetters, mapState } from 'vuex';
import { ACTIVE_PROJECT, PROJECT_MODULE } from '@/app-buyer/store/modules/projects/types';
import {
  DRAFT_RFQS,
  RFQ_MODULE,
} from '@/app-buyer/store/modules/rfq/types';

export default {
  name: 'ProjectContentHeaderTitle',
  computed: {
    ...mapGetters(PROJECT_MODULE, {
      ACTIVE_PROJECT,
    }),
    ...mapState(RFQ_MODULE, {
      DRAFT_RFQS,
    }),
  },
};
</script>
<style scoped>
header {
  height: 110px;
}
</style>
