const URL_PARAMS = {
  PROJECT_HASH: 'PROJECT_HASH',
  DRAFT_HASH: 'DRAFT_HASH',
  RFQ_ID: 'RFQ_ID',
  ORDER_ID: 'ORDER_ID',
  MESSAGE_ID: 'MESSAGE_ID',
  CHAT_ID: 'CHAT_ID',
  INVOICE_ID: 'INVOICE_ID',
  INVOICE_TYPE: 'INVOICE_TYPE',
  CART_TRANSFER_DIRECTION: 'CART_TRANSFER_DIRECTION',
};

export default URL_PARAMS;
