import Vue from 'vue';
import moment from 'moment';

const displayNames = Intl.DisplayNames
  ? new Intl.DisplayNames(['en'], { type: 'region' })
  : null;

Vue.filter('formatMoney', (value, currency) => {
  if (value !== 0 && !value) {
    return 'N/A';
    // eslint-disable-next-line no-restricted-globals
  } if (isNaN(value)) {
    if (value.match(/\d{1,3}(,\d{3})*(\.\d\d)?|\.\d\d/)) {
      return `${currency || '£'}${value}`;
    }
    return value;
  }

  const num = parseFloat(value)
    .toFixed(2)
    .replace(/\d(?=(\d{3})+\.)/g, '$&,');
  return `${currency || '£'}${num}`;
});

export const formatDate = (value, format) => {
  if (moment(String(value)).isValid()) {
    return moment(String(value)).format(format || 'DD/MM/YYYY');
  }
  return value;
};

export const formatWithCurrency = (value, isPenny = true, currency = 'GBP') => {
  const formatter = new Intl.NumberFormat('en-UK', {
    style: 'currency',
    currency,
  });
  const formatted = formatter.format(isPenny ? value * 0.01 : value);
  return (formatted.includes('NaN') || value == null) ? '' : formatted;
};

export const formatSimpleCost = (value, baseUnit = true) => {
  if (!value) return '';
  let pounds = value;
  if (baseUnit) {
    pounds *= 0.01;
  }
  let string = '';
  const bands = [1, 150, 250, 1000];
  for (let i = 0; i < bands.length; i++) {
    if (pounds >= bands[i]) {
      string += '£';
    } else {
      break;
    }
  }
  return string;
};

export const countryNameFromCode = (value) => (value && displayNames
  ? displayNames.of(value)
  : value);

Vue.filter('formatDate', formatDate);
Vue.filter('formatSimpleCost', formatSimpleCost);
Vue.filter('formatWithCurrency', formatWithCurrency);
Vue.filter('countryNameFromCode', countryNameFromCode);
