<template>
  <span class="is-relative">
    <template v-if="loading">
      Calculating...
      <BLoading
        :is-full-page="false"
      />
    </template>
    <template v-else>
      {{ selectedSum | formatWithCurrency }}
    </template>
  </span>
</template>
<script>
import { debounce } from 'lodash/function';
import Api from '@/app-buyer/api/api';
import ENDPOINTS from '@/app-buyer/api/endpoints';

export default {
  name: 'InvoiceTableReorderTotals',

  props: {
    selectedForReorder: {
      type: Array,
      default: () => [],
    },

    newQuantities: {
      type: Object,
      default: () => ({}),
    },

    originalTotal: {
      type: Number,
      default: null,
    },
  },

  data() {
    return {
      selectedSum: this.originalTotal,
      loading: false,
    };
  },

  watch: {
    newQuantities: {
      handler() {
        this.getCalculatedValue();
      },
      deep: true,
    },

    selectedForReorder: {
      handler() {
        this.getCalculatedValue();
      },
      deep: true,
    },
  },

  methods: {
    getCalculatedValue: debounce(async function getSum() {
      this.loading = true;
      const ids = [];
      const quantities = [];
      this.selectedForReorder
        .forEach((rfq) => {
          ids.push(rfq.order.id);
          quantities.push(this.newQuantities[rfq.id] || rfq.quantity_initial);
        });
      const { data, status } = await Api
        .get(ENDPOINTS.ORDERS.REORDER_COST, {
          params: {
            orderIds: ids.join(','),
            orderQuantities: quantities.join(','),
          },
        }).catch((e) => e.response);
      if (status < 300) {
        this.selectedSum = data.total_inc_vat;
      } else {
        this.selectedSum = null;
      }
      this.loading = false;
    }, 500),
  },
};
</script>
