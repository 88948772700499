/**
 * File for bootstrapping the Application
 */

/**
 * Here we check whether the global _env_ variable has been set previously and if not
 * we instantiate it to ensure its existence. This can be overridden via a script
 * declared in the html to allow run-time reconfiguring of dynamic app variables.
 */

if (window._env_ === undefined) {
  window._env_ = {};
}
