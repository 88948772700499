import {
  INJECTION_MOULDING,
  PRIMARY_FINISH,
  PRIMARY_FINISH_POLISHED,
  SHEET_METAL,
  SLS,
  THREE_D_PRINTING,
  CNC,
} from '@/shared/consts/slugs';
import {
  REFERENCE_DATA,
  REFERENCE_MODULE,
} from '@/app-buyer/store/modules/reference-data/types';
import { MIXED_SORT } from '@/app-buyer/mixins/sorting';
import store from '../../../../../store';
import { VALUE_TYPES, INPUT_TYPES } from './configurator-types';

const ELEMENTS = [
  {
    type: INPUT_TYPES.RADIO_BUTTON,
    entity: 'service',
    valueType: VALUE_TYPES.STRING_VALUE,
    visibleWithoutDependents: true,
    dependencies: ['file-type'],
  }, [
    {
      type: INPUT_TYPES.NUMBER,
      entity: 'quantity_initial',
      label: 'Initial quantity',
      min: 1,
      visibleInList: true,
    }, {
      type: INPUT_TYPES.NUMBER,
      entity: 'quantity_production',
      label: 'Production quantity',
    },
  ],
  {
    type: INPUT_TYPES.SELECT,
    entity: 'technology',
    valueType: VALUE_TYPES.STRING_VALUE,
    visibleWithoutDependents: false,
    dependencies: ['service'],
  },
  {
    type: INPUT_TYPES.AUTOCOMPLETE,
    entity: 'material',
    dependencies: {
      get(form) {
        if (store.state[REFERENCE_MODULE][REFERENCE_DATA]
          ?.find((e) => e.id === form.service)
          ?.slug === THREE_D_PRINTING) {
          return ['service', 'technology'];
        }
        return ['service'];
      },
    },
    visibleInList: true,
  },
  {
    type: INPUT_TYPES.SELECT,
    entity: 'colour',
    valueType: VALUE_TYPES.STRING_VALUE,
    visibleWithoutDependents: false,
    dependencies: ['material'],
  },
  {
    type: INPUT_TYPES.SELECT,
    entity: 'primary-finish',
    valueType: VALUE_TYPES.STRING_VALUE,
    visibleWithoutDependents: false,
    dependencies: {
      get(form) {
        if (store.state[REFERENCE_MODULE][REFERENCE_DATA]
          ?.find((e) => e.id === form.service)
          ?.slug === THREE_D_PRINTING) {
          return ['technology'];
        }
        return ['service', 'material'];
      },
    },
  },
  {
    type: INPUT_TYPES.SELECT,
    entity: 'polishing-type',
    valueType: VALUE_TYPES.STRING_VALUE,
    visibleWithoutDependents: false,
    blockTransformation: true,
    dependencies: ['service', 'material', 'primary-finish'],
  },
  {
    type: INPUT_TYPES.CHECKBOX,
    entity: 'vibro-polishing',
    dependencies: ['service', 'technology'],
  },
  {
    type: INPUT_TYPES.COLLAPSABLE_INPUT,
    entity: 'secondary-finish',
    valueType: VALUE_TYPES.STRING_VALUE,
    visibleWithoutDependents: false,
    blockTransformation: true,
    sortMethod: MIXED_SORT,
    dependencies: {
      get(form) {
        if (store.state[REFERENCE_MODULE][REFERENCE_DATA]
          ?.find((e) => e.id === form.service)
          ?.slug === SHEET_METAL) {
          return ['service', 'material'];
        }
        if (store.state[REFERENCE_MODULE][REFERENCE_DATA]
          ?.find((e) => e.id === form.service)
          ?.slug === THREE_D_PRINTING && store.state[REFERENCE_MODULE][REFERENCE_DATA]
            ?.find((e) => e.id === form.technology)
            ?.slug === SLS) {
          return ['service', 'material'];
        }
        return ['service', 'material'];
      },
    },
  },
  {
    type: INPUT_TYPES.MIN_MAX,
    entity: 'hrc',
    valueType: VALUE_TYPES.OBJECT_VALUE,
    visibleWithoutDependents: false,
    dependencies: ['secondary-finish'],
  },
  {
    type: INPUT_TYPES.SELECT,
    entity: 'a-side-finish',
    valueType: VALUE_TYPES.STRING_VALUE,
    visibleWithoutDependents: false,
    dependencies: ['service', 'material'],
  }, {
    type: INPUT_TYPES.SELECT,
    entity: 'b-side-finish',
    valueType: VALUE_TYPES.STRING_VALUE,
    visibleWithoutDependents: false,
    dependencies: ['service', 'material'],
  }, [
    {
      type: INPUT_TYPES.CHECKBOX,
      entity: 'hole-threading',
      dependencies: ['service'],
    }, {
      type: INPUT_TYPES.CHECKBOX,
      entity: 'hole-countersinking',
      dependencies: ['service'],
    }, {
      type: INPUT_TYPES.CHECKBOX,
      entity: 'bending',
      dependencies: ['service'],
    },
  ], {
    type: INPUT_TYPES.SELECT,
    entity: 'tolerance',
    valueType: VALUE_TYPES.STRING_VALUE,
    visibleWithoutDependents: false,
    dependencies: ['service'],
    tooltip: {
      get(form) {
        if (store.state[REFERENCE_MODULE][REFERENCE_DATA]
          ?.find((e) => e.id === form.service)
          ?.slug === CNC) {
          return 'Unless otherwise specified, tolerances will be held to (+/-0.127 mm) in accordance with ISO 2768. The selected level of tolerance will be held where possible, so long as the deformation and material choices allow it.';
        }
        if (store.state[REFERENCE_MODULE][REFERENCE_DATA]
          ?.find((e) => e.id === form.service)
          ?.slug === INJECTION_MOULDING) {
          return '<div class="tooltip-message"><p class="has-text-white is-size-7">Select Standard or Precision Injection Moulding.</p></div>';
        }
        return '<div class="tooltip-message"><p class="has-text-white is-size-7">Select the tightest tolerance your part has, we will only produce the part to this minimum.</p></div>';
      },
      type(form) {
        if (store.state[REFERENCE_MODULE][REFERENCE_DATA]
          ?.find((e) => e.id === form.service)
          ?.slug === CNC) {
          return 'html';
        }
        return 'tooltip';
      },
      secondary(form) {
        // if (store.state[REFERENCE_MODULE][REFERENCE_DATA]
        //   ?.find((e) => e.id === form.service)
        //   ?.slug === CNC) {
        //   return 'Tolerances will be held to (+/-0.127 mm) in accordance with ISO 2768 unless otherwise specified';
        // }
        return null;
      },
    },
  }, {
    type: INPUT_TYPES.SELECT,
    entity: 'surface-roughness',
    valueType: VALUE_TYPES.STRING_VALUE,
    visibleWithoutDependents: false,
    dependencies: {
      get(form) {
        return store.state[REFERENCE_MODULE][REFERENCE_DATA]?.find((e) => e.id === form[PRIMARY_FINISH])?.slug === PRIMARY_FINISH_POLISHED ? false : ['service'];
      },
    },
  }, {
    type: INPUT_TYPES.SELECT,
    entity: 'thickness',
    valueType: VALUE_TYPES.STRING_VALUE,
    visibleWithoutDependents: false,
    blockTransformation: true,
    sortMethod: MIXED_SORT,
    dependencies: ['service', 'material'],
  },
  {
    type: INPUT_TYPES.SELECT,
    entity: 'layer-height',
    valueType: VALUE_TYPES.STRING_VALUE,
    visibleWithoutDependents: false,
    dependencies: ['technology'],
  }, {
    type: INPUT_TYPES.SELECT,
    entity: 'infill',
    valueType: VALUE_TYPES.STRING_VALUE,
    visibleWithoutDependents: false,
    dependencies: ['technology'],
  }, {
    type: INPUT_TYPES.SELECT,
    entity: 'manufacturing-time',
    valueType: VALUE_TYPES.STRING_VALUE,
    visibleWithoutDependents: false,
    visibleInList: true,
    blockTransformation: true,
    dependencies: ['service'],
  },
];

export default ELEMENTS;
