<template>
  <section class="is-flex is-flex-direction-column">
    <RegistrationLayout class="is-flex-grow-1">
      <BInputWithValidator
        v-if="(!hadContactNumber && isModalEdit) || isProfileEdit"
        v-model="contact_number"
        :custom-class="`
        is-size-7-touch has-text-weight-bold mt-6
        `"
        :field-type="hasSubmitted && !contact_number ? 'is-danger' : ''"
        label="Contact number"
        name="phone"
        placeholder="Enter contact number..."
        rules="required"
        vid="contact-number"
        data-testid="register-contact-number"
      />
      <template v-if="(!hadServices && isModalEdit) || isProfileEdit">
        <p class="has-text-weight-bold mt-4">
          Your primary service of interest
        </p>
        <BField
          v-for="(service, i) in hardcodedServices"
          :key="i"
          class="mt-4"
          data-testid="register-details-services"
        >
          <BCheckbox
            v-model="services"
            type="is-v2-supporting-1"
            :native-value="service.value"
          >
            <span>{{ service.string }}</span>
          </BCheckbox>
        </BField>
      </template>
      <template v-if="(!hadIndustry && isModalEdit) || isProfileEdit">
        <BField
          label="Your industry"
          class="mt-4"
        >
          <BSelect
            v-model="industry"
            required
            expanded
            data-testid="register-details-industry"
            placeholder="Please select an industry segment"
          >
            <option
              v-for="(industrySegment, slug) in INDUSTRY_SEGMENTS"
              :key="slug"
              :value="slug"
            >
              {{ industrySegment }}
            </option>
          </BSelect>
        </BField>
      </template>
      <template v-if="(!hadJobTitle && isModalEdit) || isProfileEdit">
        <BField
          label="Your job title"
          class="mt-4"
        >
          <BSelect
            v-model="jobTitle"
            placeholder="Please select a job title"
            data-testid="register-details-job"
            required
            expanded
          >
            <option
              v-for="(title, slug) in JOB_TITLES"
              :key="slug"
              :value="slug"
            >
              {{ title }}
            </option>
          </BSelect>
        </BField>
      </template>
      <div class="mt-6">
        <BButton
          type="is-v2-supporting-1"
          class="px-6 has-text-bold"
          :loading="loading"
          :disabled="disableSubmit"
          data-testid="register-details-submit"
          @click="submit"
        >
          {{ isProfileEdit ? 'Confirm my details' : 'Submit' }}
        </BButton>
      </div>
    </RegistrationLayout>
  </section>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import RegistrationLayout from '@/app-buyer/components/registration/RegistrationLayout.vue';
import { USER_DATA, USER_MODULE } from '@/app-buyer/store/modules/user/types';
import { UPDATE } from '@/app-buyer/store/modules/types';
import JOB_TITLES from './job-titles.json';
import INDUSTRY_SEGMENTS from './industry-segments.json';

export default {
  name: 'RegistrationDetails',
  components:
    {
      RegistrationLayout,
    },
  props: {
    isProfileEdit: {
      type: Boolean,
      default: false,
    },
    isModalEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      hardcodedServices: [
        {
          string: 'CNC',
          value: 'cnc',
        },
        {
          string: 'Injection Moulding',
          value: 'injection',
        },
        {
          string: 'Sheet Metal',
          value: 'sheet_metal',
        },
        {
          string: '3D Printing',
          value: 'three_d',
        },
      ],
      services: [],
      jobTitle: null,
      intent: null,
      industry: null,
      JOB_TITLES,
      INDUSTRY_SEGMENTS,
      loading: false,
      name: '',
      formattedContactNumber: '',
      hasSubmitted: false,
      hadServices: false,
      hadJobTitle: false,
      hadIndustry: false,
      hadContactNumber: false,
    };
  },
  computed: {
    ...mapState(USER_MODULE, {
      USER_DATA,
    }),
    contact_number: {
      get() {
        return this.formattedContactNumber;
      },
      set(value) {
        this.formattedContactNumber = value;
        this.$nextTick(() => {
          this.formattedContactNumber = this.formattedContactNumber.replace(/[^\-+0-9]/g, '');
        });
      },
    },
    disableSubmit() {
      if ((this.isModalEdit || this.isProfileEdit)
      && !(this.jobTitle && this.services.length && this.contact_number && this.industry)) {
        return true;
      }
      return false;
    },
  },
  mounted() {
    const profileFields = this[USER_DATA]?.buyer_profile_fields;
    if (profileFields) {
      this.services = profileFields.find((pf) => pf.field === 'demand_specific_services')?.values || [];
      this.jobTitle = profileFields.find((pf) => pf.field === 'title')?.values?.[0];
      this.industry = profileFields.find((pf) => pf.field === 'industry_segments')?.values?.[0];

      // as we are using v-model and this component is used in two places, we
      // are checking to see if the value was pre-populated to handle
      // if the field should be shown in the modal form.
      this.hadServices = !!this.services.length;
      this.hadJobTitle = !!this.jobTitle;
      this.hadIndustry = !!this.industry;
    }
    this.formattedContactNumber = this[USER_DATA]?.user?.contact_number ?? '';
    this.hadContactNumber = !!this.formattedContactNumber;
  },
  methods: {
    ...mapActions(USER_MODULE, {
      UPDATE,
    }),
    async submit() {
      try {
        this.hasSubmitted = true;
        this.loading = true;
        const copy = JSON.parse(JSON.stringify(this[USER_DATA]));
        copy.profile.title = this.jobTitle;
        copy.user.contact_number = this.contact_number;
        copy.profile.industry_segments = [this.industry];
        copy.profile.demand_specific_services = this.services;
        await this[UPDATE](copy);
        this.$emit('close');
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
option {
  color: black;
}
</style>
