<template>
  <div
    style="width: 100%; border-top: 1px solid #dbdbdb; margin-top: -3px;"
    class="px-4 py-4 pt-2 is-hidden-tablet"
  >
    <GroupedQuoteHeaderBody
      :data="data"
      :index="index"
      :show-flair="false"
    />
  </div>
</template>
<script>
import GroupedQuoteHeaderBody from '@/app-buyer/components/grouped-quotes/GroupedQuoteHeaderBody.vue';

export default {
  name: 'GroupedQuoteHeaderMobile',
  components: {
    GroupedQuoteHeaderBody,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    quotesThatCanBeAddedToCart: {
      type: Array,
      default: () => null,
    },
    index: {
      type: Number,
      default: () => null,
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
