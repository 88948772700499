import { mapMutations, mapState } from 'vuex';
import { UPDATE } from '../store/modules/types';
import {
  METADATA,
  SET_METADATA,
  USER_MODULE,
} from '../store/modules/user/types';
import { CHATS, CHATS_MODULE } from '../store/modules/chats/types';

const newMessageSubscription = {
  data() {
    return {
      liveMessageCount: [],
    };
  },
  computed: {
    ...mapState(USER_MODULE, {
      METADATA,
    }),
    ...mapState(CHATS_MODULE, {
      CHATS,
    }),
  },
  mounted() {
    const unscubscribe = this.$store.subscribe(({ type, payload }) => {
      if (type === `${CHATS_MODULE}/${UPDATE}` && this.$route.name !== 'messages') {
        if (payload?.data?.id
          && !this.liveMessageCount.includes(payload.data.id)
          && !this[CHATS].find((e) => e.id === payload.data.id)?.resolved_at) {
          if (payload?.data?.last_message
            && payload.data.last_message.author_id !== this.userId) {
            this.liveMessageCount.push(payload.data.id);
            if (this[METADATA]?.new_messages_count) {
              const copy = JSON.parse(JSON.stringify(this[METADATA]));
              copy.new_messages_count++;
              this[SET_METADATA](copy);
            }
          }
        }
      }
    });
    this.$once('hook:beforeDestroy', () => {
      unscubscribe();
    });
  },
  methods: {
    ...mapMutations(USER_MODULE, {
      SET_METADATA,
    }),
  },
};

export default newMessageSubscription;
