<template>
  <section class="is-flex is-flex-direction-column">
    <RegistrationLayout class="is-flex-grow-1">
      <template #title>
        Thanks! You have been successfully signed up.
      </template>
      <template #subtitle>
        Here's a video with a quick overview of how our platform can help deliver your manufacturing
        projects cheaper, faster and with industry leading quality.
      </template>
      <template>
        <p class="title is-5 has-text-white my-5">
          What happens next?
        </p>
        <p class="mb-5">
          We've sent you an email outlining all the benefits of the Geomiq platform.
        </p>
        <p class="mb-5">
          One of our account managers will also be in touch soon,
          to help you get used to the platform.
        </p>
        <p class="mb-5">
          Or if you're ready to upload your first quote, just click the button below to get started.
        </p>
      </template>
      <div>
        <button
          class="button is-v2-supporting-1 px-6 has-text-weight-bold"
          data-testid="register-success-btn"
          @click="$emit('progress', 'final')"
        >
          Get a quote now
        </button>
      </div>
    </RegistrationLayout>
  </section>
</template>

<script>
import RegistrationLayout from '@/app-buyer/components/registration/RegistrationLayout.vue';
import RegistrationVideoLink from '@/app-buyer/components/registration/RegistrationVideoLink.vue';

export default {
  name: 'RegistrationSuccess',
  components: { RegistrationVideoLink, RegistrationLayout },
};
</script>

<style scoped>

</style>
