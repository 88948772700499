export default function getEnvironmentVariable(key) {
  // eslint-disable-next-line no-prototype-builtins
  const value = window._env_?.hasOwnProperty(key)
    ? window._env_[key]
    : process.env[key];
  switch (value) {
    case 'false':
      return false;
    case 'true':
      return true;
    default:
      return value;
  }
}
