<template>
  <component
    :is="htmlType"
    :class="[
      type,
      size,
      {
        outlined,
        fullwidth,
        loading,
        rounded,
        'has-shadow' : hasShadow,
        'is-3d': is3d,
        'link': isLink,
        'is-black': isBlack
      },
      {'is-inline-flex is-align-items-center': !!(iconLeft || iconRight) }
    ]"
    class="gm-button gm-theme"
    v-bind="$attrs"
    :type="nativeType"
    v-on="$listeners"
  >
    <b-icon
      v-if="iconLeft"
      :pack="iconPack"
      :icon="iconLeft"
      style="font-size: 15px; height: 15px;"
    />
    <slot />
    <b-icon
      v-if="iconRight"
      :pack="iconPack"
      :icon="iconRight"
      style="font-size: 15px;"
    />
  </component>
</template>

<script>
import { gmThemeProps } from '../../mixins';

export default {
  name: 'GmButton',
  mixins: [gmThemeProps],
  props: {
    nativeType: {
      type: String,
      default: 'button',
      validator: (value) => [
        'button',
        'submit',
        'reset',
      ].indexOf(value) >= 0,
    },
    iconLeft: {
      type: String,
      default: null,
    },
    iconRight: {
      type: String,
      default: null,
    },
    iconPack: {
      type: String,
      default: null,
    },
    iconSize: {
      type: String,
      default: null,
    },
    isLink: {
      type: Boolean,
      default: false,
    },
    htmlType: {
      type: String,
      default: 'button',
    },
    isBlack: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
