<template>
  <div>
    <input
      v-show="false"
      ref="supporting-file-input"
      type="file"
      @input="uploadFile"
    >
    <GmButton
      class="py-1 is-justify-content-center"
      icon-left="file-pdf"
      icon-pack="fal"
      outlined
      rounded
      size="small"
      type="info"
      fullwidth
      style="font-size: 13px; white-space: nowrap;"
      :loading="loading"
      @click="$refs['supporting-file-input'].click()"
    >
      Update model file
    </GmButton>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { getAcceptableUploads } from '@/app-buyer/components/project/mixins';
import { RFQ_MODULE, UPDATE_RFQ } from '@/app-buyer/store/modules/rfq/types';

export default {
  name: 'RfqSupportingFileUpload',

  mixins: [getAcceptableUploads],

  props: {
    part: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
    };
  },

  methods: {
    ...mapActions(RFQ_MODULE, {
      UPDATE_RFQ,
    }),

    async uploadFile(event) {
      this.loading = true;
      this.$emit('loading', true);
      const file = event.target.files[0];
      try {
        const updatedData = await this[UPDATE_RFQ](
          { rfq: this.part, files: { modelFile: file }, properties: {} },
        );
        this.$emit('update', updatedData);
      } finally {
        this.loading = false;
        this.$emit('loading', false);
      }
    },
  },
};
</script>

<style scoped>

</style>
