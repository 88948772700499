import { mapGetters } from 'vuex';
import {
  REFERENCE_DATA_OBJECT_BY_ID,
  REFERENCE_MODULE,
} from '@/app-buyer/store/modules/reference-data/types';

const findSecondaryFinishParentMixin = {
  computed: {
    ...mapGetters(REFERENCE_MODULE, {
      REFERENCE_DATA_OBJECT_BY_ID,
    }),
  },
  methods: {
    findSecondaryFinishParent(property) {
      return this[REFERENCE_DATA_OBJECT_BY_ID][property?.id]?.parents
        ?.find((p) => p.entity_slug === 'secondary-finish-type');
    },
  },
};

export default findSecondaryFinishParentMixin;
