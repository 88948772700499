<template>
  <section data-testid="login-form">
    <validation-observer v-slot="{ passes }">
      <form
        @submit.prevent.stop
      >
        <transition-group
          class="input-wrapper"
          mode="out-in"
          name="slide-left"
          tag="ul"
        >
          <li
            key="1"
            class="input-wrapper"
          >
            <b-input-with-validator
              v-model="email"
              label="Email"
              placeholder="Enter email..."
              rules="required|email"
              name="email"
              vid="email"
              trim
              :field-type="fieldType"
            />
          </li>
          <li
            key="2"
            class="input-wrapper"
          >
            <b-input-with-validator
              v-model="password"
              label="Password"
              placeholder="Enter password..."
              rules="required"
              type="password"
              name="password"
              vid="password"
              :field-type="fieldType"
              :message="message"
            />
          </li>
          <li
            key="4"
            class="input-wrapper"
          >
            <b-field>
              <b-checkbox
                v-model="remember"
              >
                Remember my details
              </b-checkbox>
            </b-field>
            <b-field>
              <a
                class="is-emphasised"
                style="color: var(--geomiq-blue);"
                data-testid="reset-password-link"
                @click="showPasswordReset = !showPasswordReset"
              >
                Forgot password?
              </a>
            </b-field>
            <b-field
              key="9"
            >
              <gm-button
                type="v2-supporting-1"
                fullwidth
                native-type="submit"
                has-shadow
                data-testid="sign-in-button"
                :loading="submitted"
                :disabled="submitted"
                @click="passes(startLogin)"
              >
                <b>Login</b>
              </gm-button>
            </b-field>
          </li>
        </transition-group>
      </form>
    </validation-observer>
    <b-modal
      :active.sync="showPasswordReset"
      has-modal-card
    >
      <EmailPasswordResetForm />
    </b-modal>
  </section>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
import { ValidationObserver } from 'vee-validate';
import { FORCE_AUTH, AUTH_MODULE, LOGIN } from '@/app-buyer/store/modules/auth/types';

import notificationInjection from '../../../shared/components/notification/notification-injection-mixin';
import EmailPasswordResetForm from '../password-reset/EmailPasswordResetForm.vue';

export default {
  name: 'LoginForm',
  components: {
    EmailPasswordResetForm,
    ValidationObserver,
  },
  mixins: [notificationInjection],
  data() {
    return {
      email: '',
      password: '',
      remember: false,
      showPasswordReset: false,
      submitted: false,
      fieldType: null,
      message: null,
    };
  },
  methods: {
    ...mapActions(AUTH_MODULE, {
      login: LOGIN,
    }),
    ...mapMutations(AUTH_MODULE, {
      FORCE_AUTH,
    }),
    async startLogin() {
      this.submitted = true;
      const response = await this.login({
        email: this.email,
        password: this.password,
        remember: this.remember,
      });
      if (response.status < 300) {
        this[FORCE_AUTH](null);
        this._addNotification({
          type: 'is-success',
          message: 'Successfully logged in!',
        });
      } else {
        this.fieldType = 'is-danger';
        this.message = 'Invalid email or password!';
      }
      this.submitted = false;
    },
  },

};
</script>

<style lang="scss" scoped>
  .input-wrapper {
    margin: 1rem 0;

    &:last-child {
      margin-bottom: 0;
    }
  }
</style>
