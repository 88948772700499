<template>
  <div class="is-flex is-align-items-center">
    <div
      v-if="rating"
      class="is-flex"
    >
      <tippy
        arrow
        placement="top"
      >
        <template #trigger>
          <p class="rating has-text-info">
            {{ formattedRating }} / 5
          </p>
        </template>
        <div class="m-1">
          <div class="is-flex is-justify-space-between">
            <p class="mr-5">
              Avg. customer rating
            </p>
            <p>{{ formattedRating }} / 5</p>
          </div>
          <div class="is-flex is-justify-space-between">
            <p>Total orders</p>
            <p>{{ totalJobs }}</p>
          </div>
          <div class="is-flex is-justify-space-between">
            <p>Your rating</p>
            <p>{{ customerRating || 'Not rated yet' }}</p>
          </div>
          <div class="is-flex is-justify-space-between">
            <p>Your orders</p>
            <p>{{ customerOrders }}</p>
          </div>
        </div>
      </tippy>
      <p class="ml-1">
        from {{ totalJobs }} orders
      </p>
    </div>
    <tippy
      v-else
      arrow
      placement="top"
      type="is-white"
      content="This supplier has no rating yet."
    >
      <template #trigger>
        <p>Awaiting reviews</p>
      </template>
    </tippy>
  </div>
</template>
<script>
export default {
  name: 'GroupedQuoteRating',
  props: {
    rating: [Number, String],
    customerRating: [Number, String],
    totalJobs: {
      type: Number,
      default: 0,
    },
    customerOrders: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    formattedRating() {
      const ratingNumber = Number(this.rating);
      return ratingNumber.toFixed(1);
    },
  },
};
</script>
<style lang="scss" scoped>
.rating {
  text-decoration: underline;
}
::v-deep {
  p {
    font-size: 0.75rem;
  }
}
</style>
