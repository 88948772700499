<template>
  <tr class="is-hidden-desktop">
    <td colspan="4">
      <PartFormDraftConfigurator
        v-if="opened"
        is-mobile
        :parts="[part]"
      />
    </td>
  </tr>
</template>

<script>
import PartFormDraftConfigurator from '@/app-buyer/components/project/PartFormDraftConfigurator.vue';

export default {
  name: 'PartTableGroupRowForm',
  components: { PartFormDraftConfigurator },
  props: {
    part: {
      type: Object,
      required: true,
    },
    isOpen: Boolean,
  },
  data() {
    return {
      opened: false,
    };
  },
  watch: {
    isOpen: {
      handler(val) {
        if (val) this.opened = true;
      },
      immediate: true,
    },
  },
};
</script>
