export const RESET_STATE = 'RESET_STATE';
export const CACHE = 'CACHE';
export const PAGINATION = 'PAGINATION';
export const UPDATE = 'UPDATE';
export const AGREE_TO_TERMS = 'AGREE_TO_TERMS';
export const TERMS_AND_CONDITIONS_MODAL = 'TERMS_AND_CONDITIONS_MODAL';
export const GET = 'GET';
export const SET = 'SET';
export const ADD = 'ADD';
export const DETAIL = 'DETAIL';
export const CREATE = 'CREATE';
export const DELETE = 'DELETE';
export const SET_ALL = 'SET_ALL';
export const CHANNEL_SETUP = 'CHANNEL_SETUP';
export const CHANNEL_TEARDOWN = 'CHANNEL_TEARDOWN';
export const SET_CHANNEL = 'SET_CHANNEL';
export const CHANNELS = 'CHANNELS';
export const INITIAL_LOAD = 'INITIAL_LOAD';
export const SET_PAGINATION = 'SET_PAGINATION';
export const UNSUBSCRIBE = 'UNSUBSCRIBE';
