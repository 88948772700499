<template>
  <div style="cursor: pointer;">
    <slot :open="open">
      <div
        class="is-flex is-flex-align-centered"
        tabindex="0"
        @click="open"
      >
        <div
          style="flex: none; width: 1rem; margin-bottom: -1px;"
          class="mr-3"
        >
          <BIcon
            icon="trash"
            pack="fal"
          />
        </div>
        <p class="has-text-left is-flex-grow-1">
          Cancel
        </p>
      </div>
    </slot>
    <Portal to="modal">
      <BModal
        :active.sync="showModal"
        has-modal-card
      >
        <CancelModal
          :cancel-label="rfq.ref"
          :loading="cancelling"
          :show-modal="showModal"
          @cancel="cancel"
          @close="showModal = false"
        />
      </BModal>
    </Portal>
  </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
import Api from '@/app-buyer/api/api';
import ENDPOINTS from '@/app-buyer/api/endpoints';
import { DELETE, SET_ALL } from '@/app-buyer/store/modules/types';
import notificationInjection from '@/shared/components/notification/notification-injection-mixin';
import { CART_MODULE } from '@/app-buyer/store/modules/cart/types';
import { RFQ_MODULE } from '@/app-buyer/store/modules/rfq/types';
import CancelModal from '@/app-buyer/components/rfq-list/CancelModal.vue';

export default {
  name: 'QuoteListOperationsCancel',
  components: { CancelModal },
  mixins: [notificationInjection],
  props: {
    rfq: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showModal: false,
      cancelling: false,
    };
  },
  methods: {
    ...mapActions(CART_MODULE, {
      removeFromCart: DELETE,
    }),
    ...mapMutations(RFQ_MODULE, {
      SET_ALL,
    }),
    async cancel(cancelReason, explanation) {
      this.cancelling = true;
      const { status } = await Api.post(ENDPOINTS.RFQS.CANCEL, {
        reason_for_cancel: `${cancelReason.val}${explanation ? `. ${explanation}` : ''}`,
      }, {
        __pathParams: { hash: this.rfq.hash },
      }).catch((e) => e.response);
      if (status < 300) {
        this.showModal = false;
        if (this.rfq.is_in_current_user_cart) {
          this.removeFromCart(this.rfq);
        }
        this.$set(this.rfq, 'status', 'Request Cancelled');
        this._addNotification({
          type: 'is-info',
          message: `${this.rfq.ref} cancelled`,
        });
        this.$emit('cancelled');
      }
      this.cancelling = false;
    },
    open() {
      this.showModal = true;
    },
  },
};
</script>

<style
    lang="scss"
    scoped
>
.list-item-element {
  padding: 0.375rem 3rem 0.375rem 1rem;

  &:hover {
    background-color: whitesmoke;
  }
}
</style>
