<template>
  <div
    class="is-relative is-height-100"
  >
    <div
      id="part-table"
      class="part-table"
    >
      <PartTableHead />
      <span v-if="FILTERED_PARTS && !FILTERED_PARTS.length">
        <p class="has-text-centered mt-3">
          No parts found.
        </p>
      </span>
      <PartTableGroup
        v-if="FILTERED_PARTS"
        :group="{
          name: 'filtered',
          parts: FILTERED_PARTS
        }"
        @shift-select="handleShiftSelect"
      />
      <template v-else>
        <PartTableGroup
          v-for="group in groups"
          :key="group.name"
          :group="group"
          @shift-select="handleShiftSelect"
        />
        <PartTableUploadingParts />
      </template>
    </div>
    <PartTableDropArea />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import {
  DWG_DRAFTS,
  DXF_DRAFTS,
  FILTERED_PARTS,
  IGS_DRAFTS,
  PDF_DRAFTS,
  RFQ_MODULE,
  RFQS,
  SELECTED_PART_HASHES,
  STEP_DRAFTS,
  STL_DRAFTS,
  DRAFT_RFQS,
} from '@/app-buyer/store/modules/rfq/types';
import PartTableGroup from '@/app-buyer/components/project/PartTableGroup.vue';
import PartTableHead from '@/app-buyer/components/project/PartTableHead.vue';
import { partSelectMixin } from '@/app-buyer/components/project/mixins';
import PartTableUploadingParts from '@/app-buyer/components/project/PartTableUploadingParts.vue';
import PartTableDropArea from '@/app-buyer/components/project/PartTableDropArea.vue';

export default {
  name: 'PartTable',
  components: {
    PartTableDropArea, PartTableUploadingParts, PartTableHead, PartTableGroup,
  },
  mixins: [partSelectMixin],
  computed: {
    ...mapState(RFQ_MODULE, {
      SELECTED_PART_HASHES,
      RFQS,
      FILTERED_PARTS,
      DRAFT_RFQS,
    }),
    ...mapGetters(RFQ_MODULE, {
      STEP_DRAFTS,
      PDF_DRAFTS,
      IGS_DRAFTS,
      DWG_DRAFTS,
      DXF_DRAFTS,
      STL_DRAFTS,
    }),
    groups() {
      return [
        {
          name: 'step',
          parts: this[STEP_DRAFTS].slice(),
        },
        {
          name: 'pdf',
          draggable: true,
          parts: this[PDF_DRAFTS].slice(),
        },
        {
          name: 'igs',
          parts: this[IGS_DRAFTS].slice(),
        },
        {
          name: 'dwg',
          parts: this[DWG_DRAFTS].slice(),
        },
        {
          name: 'dxf',
          parts: this[DXF_DRAFTS].slice(),
        },
        {
          name: 'stl',
          parts: this[STL_DRAFTS].slice(),
        },
      ]
        .filter((e) => e.parts.length);
    },
  },
  mounted() {
    if (this[DRAFT_RFQS].length && !this[SELECTED_PART_HASHES]?.length) {
      this.selectParts(this[DRAFT_RFQS][0]?.hash);
    }
  },
  methods: {
    handleShiftSelect(part) {
      const flattenedParts = this.groups
        .reduce((flattened, current) => flattened.concat(current.parts), []);
      const indexStart = flattenedParts
        .findIndex((e) => this[SELECTED_PART_HASHES].includes(e.hash));
      const indexEnd = flattenedParts
        .findIndex((e) => e.hash === part.hash);
      let newHashes = [];
      if (indexStart < indexEnd) {
        newHashes = flattenedParts.slice(indexStart, indexEnd + 1).map((p) => p.hash);
      } else {
        newHashes = flattenedParts.slice(indexEnd, indexStart + 1).map((p) => p.hash);
      }
      this.selectParts(...newHashes);
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
.part-table {
  width: 100%;
  user-select: none;
  table-layout: fixed;

  ::v-deep {
    .header-cell,
    td {
      vertical-align: middle;
      font-size: 13px;
      padding: 10px;

      @media all and (min-width: 1230px) and (max-width: 1500px) {
        padding: 5px;
      }

      @media all and (min-width: 1150px) and (max-width: 1229px) {
        padding: 1px;
      }
    }

    .header-cell {
      white-space: nowrap;
      padding-top: 0;
      padding-bottom: 0;
    }

    tbody .row:first-child td {
      padding-top: .5rem !important;
    }
  }
}

</style>
