var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',_vm._l((_vm.standardsAndMarkings),function(segment){return _c('div',{key:segment.title},[_c('p',[_c('span',{staticClass:"has-text-weight-bold"},[_vm._v(_vm._s(segment.title))]),_vm._m(0,true)]),_c('BTable',{attrs:{"data":segment.data,"opened-detailed":_vm.openedDetailed,"show-detail-icon":false,"detail-key":"id","detailed":""},scopedSlots:_vm._u([{key:"detail",fn:function(ref){
var row = ref.row;
return [_c('div',[_c('p',{staticClass:"title is-7"},[_vm._v("\n            What's included\n          ")]),(row.metadata)?_c('div',{staticClass:"markdown-html",domProps:{"innerHTML":_vm._s(_vm.$options.markdownConverter.makeHtml(row.metadata.description))}}):_vm._e()])]}}],null,true)},[_c('BTableColumn',{attrs:{"width":"2rem"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('GmButton',{directives:[{name:"show",rawName:"v-show",value:(row.metadata && row.metadata.description),expression:"row.metadata && row.metadata.description"}],staticClass:"p-1",attrs:{"size":"small","type":"g-light-1"},on:{"click":function($event){_vm.$emit(
            'update-detailed',
            {
              id: row.id,
              isOpen: !_vm.openedDetailed.includes(row.id)
            }
          )}}},[_c('BIcon',{attrs:{"icon":_vm.openedDetailed.includes(row.id) ? 'chevron-down' : 'chevron-right'}})],1)]}}],null,true)}),_c('BTableColumn',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var row = ref.row;
return [_c('span',{staticClass:"is-size-6 has-text-weight-bold"},[_vm._v("\n          "+_vm._s(row.string_value)+"\n        ")])]}}],null,true)}),_c('BTableColumn',{attrs:{"width":"10rem"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var row = ref.row;
return [(row.quote_addon_price)?[_vm._v("\n          "+_vm._s(_vm._f("formatSimpleCost")(row.quote_addon_price))+"\n        ")]:_vm._e()]}}],null,true)}),_c('BTableColumn',{attrs:{"numeric":"","width":"10rem"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var row = ref.row;
return [_c('GmButton',{attrs:{"icon-left":_vm.selected.includes(row.id) ? 'check' : null,"outlined":_vm.selected.includes(row.id),"icon-pack":"fal","type":"info"},on:{"click":function($event){return _vm.$emit('add-or-remove', row.id)}}},[_vm._v("\n          "+_vm._s(_vm.selected.includes(row.id) ? 'Added' : 'Add')+"\n        ")])]}}],null,true)})],1)],1)}),0)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('small',[_c('i',[_vm._v(" (you can select multiple)")])])}]

export { render, staticRenderFns }