import Api from '../../../api/api';
import ENDPOINTS from '../../../api/endpoints';
import {
  CHANNEL_SETUP,
  CHANNEL_TEARDOWN,
  CHANNELS,
  CREATE,
  DETAIL,
  GET,
  SET,
  SET_ALL,
  SET_CHANNEL,
  DELETE,
  UPDATE,
} from '../types';
import { USER_DATA, USER_MODULE } from '../user/types';
import { CHATS, GET_SUBSCRIPTIONS, SET_SUBSCRIPTIONS } from './types';

export default {
  /**
     * Get the access token to use for autodesk calls
     *
     * @param {Object} context
     * @param {Object} payload
     * @param {Object} payload.params
     * @returns {Promise<Object>} data
     */
  async [GET]({ commit }, { params, clear = false }) {
    const { data, status } = await Api.get(ENDPOINTS.CHAT.INDEX, { params })
      .catch((e) => e.response);
    if (status < 300) {
      commit(SET_ALL, {
        data: data.data,
        clear,
      });
    }
    return data;
  },
  async [CREATE]({ commit }, { id, type = 'rfq_id' }) {
    const { data, status } = await Api.post(ENDPOINTS.CHAT.INDEX, { [type]: id })
      .catch((e) => e.response);
    if (status < 300) {
      commit(SET_ALL, { data: [data.data] });
      commit(SET, {
        data: data.data?.messages?.length ? data.data.messages : [],
        id,
        clear: true,
      });
      return data.data;
    }
    return null;
  },
  async [DETAIL]({ commit }, { id, params, clear = false }) {
    const { data, status } = await Api.get(ENDPOINTS.CHAT.MESSAGES, {
      params,
      __pathParams: { id },
    })
      .catch((e) => e.response);
    if (status < 300) {
      commit(SET, {
        data: data.data,
        id,
        clear,
        unshift: true,
      });
      return data;
    }
    return null;
  },
  async [DELETE]({ commit }, { id }) {
    await Api.delete(ENDPOINTS.CHAT.SHOW, { __pathParams: { id } });
    commit(DELETE, { id });
  },
  async [SET]({ commit, state }, { id, data }) {
    const { status, data: responseData } = await Api.post(
      ENDPOINTS.CHAT.MESSAGES,
      { body: data.body, upload_ids: data.upload_ids },
      { __pathParams: { id } },
    )
      .catch((e) => e.response);
    if (status < 300) {
      commit(SET, {
        data: [responseData.data],
        id,
      });
      const chat = state[CHATS].find((e) => e.id === id);
      if (chat) {
        const copy = JSON.parse(JSON.stringify(chat));
        copy.last_message = responseData.data;
        commit(UPDATE, { data: copy });
      }
    }
    return { status, responseData };
  },
  [CHANNEL_SETUP]({ commit, rootState }, { id, isMonitor }) {
    const name = isMonitor ? `chat-subscriber.${id}.monitor` : `chat.${id}.messages`;
    // eslint-disable-next-line no-undef
    const channel = Echo.private(name);
    if (isMonitor) {
      channel
        .listen('v2.Chat.ChatUpdated', ({ data }) => {
          const userId = rootState[USER_MODULE][USER_DATA]?.profile?.user_id;
          if (userId !== data?.last_message?.author_id) {
            commit(UPDATE, { data });
          }
        });
    } else {
      channel.listen('v2.Chat.ChatMessageCreated', ({ data }) => {
        const userId = rootState[USER_MODULE][USER_DATA]?.profile?.user_id;
        if (userId !== data?.author_id) {
          commit(SET, {
            data: [data],
            id,
          });
        }
      });
    }
    commit(SET_CHANNEL, {
      id,
      name,
      channel,
    });
    return channel;
  },
  async [GET_SUBSCRIPTIONS]({ commit }) {
    const { data } = await Api.get(ENDPOINTS.CHAT.SUBSCRIPTIONS);
    commit(SET_SUBSCRIPTIONS, { data: data.data, clear: true });
    return data;
  },
  [CHANNEL_TEARDOWN]({ commit, state }, { id }) {
    // eslint-disable-next-line no-undef
    Echo.leave(state[CHANNELS][id].name);
    commit(SET_CHANNEL, { id });
  },
};
