<template>
  <div>
    <header v-if="showHeader || $scopedSlots.header">
      <slot
        :print="print"
        name="header"
      >
        <div class="default-header-content">
          <button
            class="btn btn-info"
            @click="print"
          >
            Print
          </button>
        </div>
      </slot>
    </header>
    <div ref="print-content">
      <slot />
    </div>
    <footer v-if="showFooter || $scopedSlots.footer">
      <slot
        :print="print"
        name="footer"
      />
    </footer>
  </div>
</template>

<script>
export default {
  name: 'PrintContent',
  props: {
    showHeader: {
      type: Boolean,
      default: () => true,
    },
    showFooter: Boolean,
    closeAfterPrint: {
      type: Boolean,
      default: () => true,
    },
    title: {
      type: String,
      default: () => '',
    },
  },
  methods: {
    print() {
      const printContent = this.$refs['print-content'].innerHTML;
      const styles = [...document.querySelectorAll('link[rel="stylesheet"], style')].reduce((res, node) => res + node.outerHTML, '');
      const printerWindow = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');
      printerWindow.document.write(
        `<!DOCTYPE html>
         <html>
           <head>
             <title>${this.title}</title>
             ${styles}
             <style>
              html, body {
                overflow: visible !important;
                background-color: white;
              }
              @media print {
                 * {
                    -webkit-print-color-adjust: exact;
                    print-color-adjust: exact;
                 }
                 .print-only {
                    visibility: visible !important;
                    max-height: unset !important;
                 }
              }
              @page {
                margin: 0;
                padding: 0;
              }
             </style>
           </head>
           <body>
             ${printContent}
           </body>
         </html>`,
      );
      printerWindow.document.close();
      printerWindow.focus();
      printerWindow.print();
      if (this.closeAfterPrint) {
        printerWindow.onafterprint = function onAfterPrint() {
          printerWindow.close();
        };
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.default-header-content {
  display: flex;
  justify-content: flex-end;
  padding: 0.5rem;
  border-bottom: 1px solid #efefef;
}

::v-deep {
  .print-only {
    visibility: hidden;
    max-height: 0;
  }
}
</style>
