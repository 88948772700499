<template>
  <svg
    :class="{'appeared' : appeared}"
    :style="`
         width: ${246.25 * scale}px;
         height: ${32 * scale}px;
         --scale: ${scale};
         --stroke: ${stroke};
         --stroke-width: ${strokeWidth};
         --fill: ${fill};
         --duration: ${duration}s`"
    class="animated-geomiq-text"
  >
    <title>geomiq</title>
    <path
      :d="path"
    />
  </svg>
</template>

<script>
export default {
  name: 'AnimatedGeomiqText',
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    scale: {
      type: Number,
      default: 0.55,
    },
    duration: {
      type: Number,
      default: 3,
    },
    strokeWidth: {
      type: Number,
      default: 0.5,
    },
    stroke: {
      type: String,
      default: '#fff',
    },
    fill: {
      type: String,
      default: '#fff',
    },
  },
  data() {
    return {
      appeared: false,
      path: 'M40.958 7.393v-4.003c0-1.862-1.509-3.371-3.371-3.371v0h-34.216c-1.862 0-3.371 1.509-3.371 3.371v0\n'
        + '        22.839c0 1.862 1.509 3.371 3.371 3.371v0h34.216c1.862 0 3.371-1.509\n'
        + '        3.371-3.371v0-10.661c0-1.862-1.509-3.371-3.371-3.371v0h-13.063v5.183h11.251v7.037h-30.592v-19.215h30.592v2.191zM93.672\n'
        + '        0.019c-1.862 0-3.371 1.509-3.371 3.371v0 22.839c0 1.862 1.509 3.371 3.371 3.371v0h34.174c1.862 0 3.371-1.509\n'
        + '        3.371-3.371v0-22.839c0-1.862-1.509-3.371-3.371-3.371v0zM126.034 24.417h-30.592v-19.215h30.575v19.215zM139.653\n'
        + '        3.348v26.252h5.183v-24.398h12.683v24.398h5.183v-24.398h12.726v24.398h5.183v-26.458c0-0.001 0-0.003 0-0.004\n'
        + '        0-1.726-1.397-3.126-3.122-3.131h-34.423c-0.024-0.001-0.052-0.001-0.080-0.001-1.841 0-3.333 1.492-3.333\n'
        + '        3.333 0 0.003 0 0.007 0 0.010v-0.001zM246.253 28.336l-2.697-2.697v-22.249c0-1.862-1.509-3.371-3.371-3.371v0h-34.174c-1.862\n'
        + '        0-3.371 1.509-3.371 3.371v0 22.839c0 1.862 1.509 3.371 3.371 3.371v0h34.199l2.402\n'
        + '        2.402zM207.781 17.38v-12.178h30.592v19.215h-30.609zM49.428 0.019v5.183h32.488l-5.347-5.183h-27.141zM49.428\n'
        + '        24.417v5.183h32.488l-5.347-5.183h-27.141zM49.432 12.239v5.141h32.488l-5.352-5.141h-27.137zM194.192\n'
        + '        4.874l-5.183-4.875v29.602h5.183v-24.726z',
    };
  },
  mounted() {
    this.appeared = true;
  },
};
</script>

<style lang="scss" scoped>
  .animated-geomiq-text {
    align-items: center;
    display: flex;
    fill: var(--fill);
    justify-content: center;
    margin: auto;
    stroke: var(--stroke);
    stroke-dasharray: 270px;
    stroke-width: var(--strokeWidth);
    text-align: center;

    &.appeared {
      animation: logo-appear var(--duration) reverse cubic-bezier(0.52, -0.02, 0.03, 1);
    }

    &.hovered {
      animation: logo-appear calc(var(--duration) / 4)
      alternate 2 cubic-bezier(0.52, -0.02, 0.03, 1);
    }

    path {
      transform: scale(var(--scale));
    }
  }

  @keyframes logo-appear {
    100% {
      stroke-dashoffset: 270px;
      stroke-dasharray: 150;
      fill: transparent;
      opacity: 0;
    }

    80% {
      opacity: 0;
    }

    50% {
      opacity: 100%;
    }

    35% {
      fill: transparent;
    }
  }
</style>
