<template>
  <div class="modal-card">
    <header class="modal-card-head has-background-white p-1" />
    <div class="modal-card-body px-6 ">
      <font-awesome-icon
        icon="spinner"
        spin
      />
      <p class="is-size-4 mt-1">
        We are processing your quote{{ quoteNumber > 1 ? 's' : '' }}
      </p>
      <p class="is-size-7">
        Please do not refresh or close your browser
      </p>
    </div>
    <footer class="modal-card-foot is-justify-content-space-between" />
  </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

export default {
  name: 'RfqSuccessModalContent',
  components: {
    FontAwesomeIcon,
  },
  props: {
    quoteNumber: {
      type: Number,
      default: 1,
    },
  },
  methods: {
    goToQuotes() {
      this.$router.push({ name: 'quote-list' });
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-card-body {
  text-align: center;
}
::v-deep svg {
  height: 1.6em !important;
  width: 1.6em !important;
}
</style>
