<template>
  <div>
    <GmButton
      class="py-1 is-justify-content-center"
      fullwidth
      outlined
      rounded
      size="small"
      style="font-size: 13px; white-space: nowrap;"
      type="danger"
      @click="handleClick"
    >
      {{ btnText }}
    </GmButton>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import { partSelectMixin } from '@/app-buyer/components/project/mixins';
import { RFQ_MODULE, SET_PRODUCTION_REQUIREMENTS_MODAL } from '@/app-buyer/store/modules/rfq/types';

export default {
  name: 'ProductionRequirementsButton',
  mixins: [partSelectMixin],
  props: {
    part: {
      type: Object,
      required: true,
    },
    btnText: {
      type: String,
      required: true,
    },
  },
  methods: {
    ...mapMutations(RFQ_MODULE, {
      SET_PRODUCTION_REQUIREMENTS_MODAL,
    }),
    async handleClick() {
      await this.selectParts(this.part.hash);
      this[SET_PRODUCTION_REQUIREMENTS_MODAL](true);
      setTimeout(() => {
        this[SET_PRODUCTION_REQUIREMENTS_MODAL](false);
      }, 300);
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>

</style>
