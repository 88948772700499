<template>
  <validation-observer
    v-slot="{passes}"
  >
    <form
      class="modal-card p-md"
      @submit.prevent="passes(changePassword)"
    >
      <header class="modal-card-head">
        <p class="modal-card-head-title">
          <strong>
            Change password
          </strong>
        </p>
      </header>
      <section
        class="modal-card-body"
        data-testid="change_password_form"
      >
        <p
          v-if="message"
          class="is-size-6"
        >
          {{ message }}
        </p>
        <BInputWithValidator
          v-if="withCurrent"
          v-model="current_password"
          custom-class="mb-2"
          label="Current Password"
          type="password"
          placeholder="Enter current password"
          rules="required"
          :label-position="null"
          data-testid="current_password"
        />
        <BInputWithValidator
          v-model="password"
          custom-class="mb-2 mt-4"
          label="New Password"
          type="password"
          placeholder="Enter new password"
          rules="required"
          vid="password"
          :label-position="null"
          data-testid="password"
        />
        <BInputWithValidator
          v-model="password_confirmation"
          custom-class="mb-2 mt-4"
          label="Password Confirmation"
          type="password"
          placeholder="Confirm new password"
          rules="required|confirmed:password"
          :label-position="null"
          data-testid="password_confirmation"
        />
      </section>
      <footer class="modal-card-foot">
        <BButton
          type="is-v2-supporting-1"
          native-type="submit"
          expanded
          :loading="loading"
          :disabled="loading"
          data-testid="change_password_save_button"
        >
          <strong>Change password</strong>
        </BButton>
      </footer>
    </form>
  </validation-observer>
</template>

<script>
import { ValidationObserver } from 'vee-validate';
import BInputWithValidator from '@/shared/components/inputs/BInputWithValidator.vue';
import Api from '../../api/api';
import ENDPOINTS from '../../api/endpoints';
import notificationInjection from '../../../shared/components/notification/notification-injection-mixin';

export default {
  name: 'PasswordChangeForm',
  components: {
    BInputWithValidator,
    ValidationObserver,
  },
  mixins: [notificationInjection],
  props: {
    withCurrent: Boolean,
    message: {
      type: String,
      default: () => null,
    },
  },
  data() {
    return {
      current_password: '',
      password: '',
      password_confirmation: '',
      disabled: false,
    };
  },
  methods: {
    async changePassword() {
      try {
        this.loading = true;

        const data = {
          password: this.password,
          password_confirmation: this.password_confirmation,
        };

        if (this.withCurrent) {
          data.current_password = this.current_password;
        }

        await Api.post(ENDPOINTS.AUTH.CHANGE_PASSWORD, data);

        this._addNotification({
          message: 'Password Successfully changed',
          type: 'is-success',
        });

        this.$emit('success');
      } catch (error) {
        let message = 'Something went wrong!';

        if (error.response.status === 422) {
          message = error.response.data.errors.password;
        }

        this._addNotification({
          message,
          type: 'is-warning',
        });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
