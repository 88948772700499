<template>
  <div class="is-flex is-align-items-center">
    <ProjectMemberAvatars
      :max="max"
      :project="currentProject"
    />
    <ProjectMemberInvite
      v-if="withInvite"
      class="ml-2"
      :project="currentProject"
      @update:project="$emit('update:project', $event)"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { ACTIVE_PROJECT, PROJECT_MODULE } from '@/app-buyer/store/modules/projects/types';
import ProjectMemberAvatars from '@/app-buyer/components/project/ProjectMemberAvatars.vue';
import ProjectMemberInvite from '@/app-buyer/components/project/ProjectMemberInvite.vue';

export default {
  name: 'ProjectMembers',
  components: { ProjectMemberInvite, ProjectMemberAvatars },
  props: {
    withInvite: Boolean,
    project: {
      type: Object,
      default: null,
    },
    max: {
      type: Number,
      default: null,
    },
  },
  computed: {
    ...mapGetters(PROJECT_MODULE, {
      ACTIVE_PROJECT,
    }),
    currentProject() {
      return this.project || this[ACTIVE_PROJECT] || null;
    },
  },
};
</script>
