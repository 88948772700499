import {
  PENDING_REVISIONS,
  QUOTES,
  QUOTES_FILTERS,
  QUOTES_LOADING,
  QUOTES_META,
  REQUESTED_QUOTE_INFO,
} from './types';

export default function getState() {
  return {
    [QUOTES]: null,
    [QUOTES_META]: null,
    [QUOTES_FILTERS]: null,
    [REQUESTED_QUOTE_INFO]: null,
    [PENDING_REVISIONS]: null,
    [QUOTES_LOADING]: false,
  };
}
