<template>
  <div>
    <div
      v-for="(segment) in standardsAndMarkings"
      :key="segment.title"
    >
      <p>
        <span class="has-text-weight-bold">{{ segment.title }}</span>
        <small>
          <i> (you can select multiple)</i>
        </small>
      </p>
      <BTable
        :data="segment.data"
        :opened-detailed="openedDetailed"
        :show-detail-icon="false"
        detail-key="id"
        detailed
      >
        <BTableColumn
          #default="{ row }"
          width="2rem"
        >
          <GmButton
            v-show="row.metadata && row.metadata.description"
            class="p-1"
            size="small"
            type="g-light-1"
            @click="$emit(
              'update-detailed',
              {
                id: row.id,
                isOpen: !openedDetailed.includes(row.id)
              }
            )"
          >
            <BIcon :icon="openedDetailed.includes(row.id) ? 'chevron-down' : 'chevron-right'" />
          </GmButton>
        </BTableColumn>
        <BTableColumn
          #default="{ row }"
        >
          <span class="is-size-6 has-text-weight-bold">
            {{ row.string_value }}
          </span>
        </BTableColumn>
        <BTableColumn
          #default="{ row }"
          width="10rem"
        >
          <template v-if="row.quote_addon_price">
            {{ row.quote_addon_price | formatSimpleCost }}
          </template>
        </BTableColumn>
        <BTableColumn
          #default="{ row }"
          numeric
          width="10rem"
        >
          <GmButton
            :icon-left="selected.includes(row.id) ? 'check' : null"
            :outlined="selected.includes(row.id)"
            icon-pack="fal"
            type="info"
            @click="$emit('add-or-remove', row.id)"
          >
            {{ selected.includes(row.id) ? 'Added' : 'Add' }}
          </GmButton>
        </BTableColumn>
        <template #detail="{ row }">
          <div>
            <p class="title is-7">
              What's included
            </p>
            <div
              v-if="row.metadata"
              class="markdown-html"
              v-html="$options.markdownConverter.makeHtml(row.metadata.description)"
            />
          </div>
        </template>
      </BTable>
    </div>
  </div>
</template>
<script>
import showdown from 'showdown';

export default {
  name: 'StandardsAndMarkingsTables',
  markdownConverter: new showdown.Converter(),
  props: {
    openedDetailed: { type: Array, default: () => [] },
    selected: { type: Array, default: () => [] },
    standardsAndMarkings: { type: Array, default: () => [] },
  },
};
</script>
