<template>
  <BButton
    size="is-small"
    :icon-left="`chevron-${isOpen ? 'down' : 'right'}`"
    :type="`is-${isOpen ? 'info' : 'g-light-1'}`"
    :class="isOpen && 'has-background-white has-text-info'"
    :outlined="isOpen"
  >
    <slot />
  </BButton>
</template>

<script>
export default {
  name: 'InvoiceRefCell',
  props: {
    isOpen: Boolean,
  },
};
</script>
