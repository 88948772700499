<template>
  <div class="is-flex is-flex-direction-column is-align-items-center text-align-left">
    <div
      v-if="hasOtherVendorQuotes"
      class="is-width-100"
    >
      <p class="is-size-7 mb-2">
        For each group of quotes, you can only choose quotes from one supplier at a time.
      </p>
      <div
        class="mb-1 has-text-left"
      >
        <p class="is-size-7">
          <i>
            Confirm if you would like to add this quote to your cart and remove the quotes
            from the other suppliers?
          </i>
        </p>
      </div>
    </div>

    <div
      v-if="rfqHasAnotherQuote"
      class="is-width-100"
    >
      <p class="is-size-7 mb-2">
        For each part, you can only choose a quote from one supplier at a time.
      </p>
      <div
        class="mb-1 has-text-left"
      >
        <p class="is-size-7">
          <i>
            Confirm if you would like to add this quote to your cart and remove the quote
            from the other supplier?
          </i>
        </p>
      </div>
    </div>
    <div
      v-if="clientNotes"
      class="is-width-100"
    >
      <p class="is-size-7 mb-2">
        Have you reviewed the Geomiq notes related to {{
          Array.isArray(quoteId) ? 'these' : 'this'
        }} job{{
          Array.isArray(quoteId) ? 's' : ''
        }}?
      </p>
      <div
        v-if="clientNotes"
        class="mb-1 has-text-left"
      >
        <p class="is-size-7 has-text-weight-bold">
          Geomiq Notes:
        </p>
        <p class="is-size-7">
          <i v-html="clientNotes" />
        </p>
      </div>
    </div>
    <div
      v-if="deliveryDelay"
      class="is-width-100"
    >
      <p class="is-size-7 mb-2">
        Have you reviewed the new expected delivery date for this job?
      </p>
      <div class="mb-1 has-text-left">
        <p class="is-size-7">
          <span class="has-text-weight-bold">
            Delivery Delay:
          </span>
          +{{ deliveryDelay }} day{{
            deliveryDelay === 1
              ? ''
              : 's'
          }}
        </p>
      </div>
    </div>
    <div class="is-width-100">
      <GmButton
        :disabled="loading"
        class="px-4"
        fullwidth
        size="small"
        type="info"
        rounded
        outlined
        @click="confirm"
      >
        {{ confirmText }}
      </GmButton>
    </div>
  </div>
</template>
<script>
import GmButton from '@/shared/components/gm-button/gm-button.vue';

export default {
  name: 'QuoteConfirmPrompt',
  components: { GmButton },
  props: {
    quoteId: {
      type: [String, Number, Array],
    },
    clientNotes: {
      type: String,
      default: null,
    },
    deliveryDelay: {
      type: [String, Number],
      default: null,
    },
    hasOtherVendorQuotes: {
      type: Boolean,
      default: false,
    },
    rfqHasAnotherQuote: {
      type: Boolean,
      default: false,
    },
    confirmText: {
      type: String,
      default: 'Confirm',
    },
    loading: {
      type: Boolean,
    },
  },

  methods: {
    saveConfirmation(id) {
      localStorage.setItem(`${id}_confirmed`, Date.now().toString());
    },
    confirm() {
      if (!this.hasOtherVendorQuotes && !this.rfqHasAnotherQuote) {
        if (Array.isArray(this.quoteId)) {
          this.quoteId.forEach((id) => this.saveConfirmation(id));
        } else {
          this.saveConfirmation(this.quoteId);
        }
      }
      this.$emit('confirm');
    },
  },
};
</script>
