<template>
  <div class="row">
    <div
      class="header-cell"
      style="width:88px;"
    >
      <div class="is-flex">
        <BCheckbox
          :key="renderKey"
          v-model="allDraftsSelected"
          :indeterminate="someSelected"
          class="checkboxAll-checkbox"
        />
        <PartTableBulkOperationAll />
      </div>
    </div>
    <div
      class="header-cell"
      style="min-width: 155px; flex: 1"
    >
      <button
        class="button-no-style is-flex is-align-items-center"
        @click="sortBy('name')"
      >
        Name
        <BIcon
          :icon="sortIcon['name']"
          :type="sortIcon['name'] === 'sort' ? 'is-black' : 'is-info'"
        />
      </button>
    </div>
    <div
      class="header-cell is-hidden-touch"
      style="width: 155px;"
    >
      <button
        class="button-no-style is-flex is-align-items-center"
        @click="sortBy('configuration_object.service.string_value')"
      >
        Service
        <BIcon
          :icon="sortIcon['configuration_object.service.string_value']"
          :type="sortIcon['configuration_object.service.string_value'] === 'sort'
            ? 'is-black'
            : 'is-info'"
        />
      </button>
    </div>
    <div
      class="header-cell is-hidden-touch"
      style="width: 80px;"
    >
      Qty.
    </div>
    <div
      class="header-cell is-hidden-touch"
      style="width: 155px;"
    >
      <button
        class="button-no-style is-flex is-align-items-center"
        @click="sortBy('configuration_object.material.string_value')"
      >
        Material
        <BIcon
          :icon="sortIcon['configuration_object.material.string_value']"
          :type="sortIcon['configuration_object.material.string_value'] === 'sort'
            ? 'is-black' :
              'is-info'"
        />
      </button>
    </div>
    <div
      class="header-cell is-hidden-touch"
      style="width: 155px;"
    >
      Shipping in
    </div>
    <div
      class="header-cell is-hidden-touch"
      style="width: 210px;"
    >
      Supporting file (if required)
    </div>
    <div
      class="header-cell is-hidden-touch"
      style="width: 60px;"
    >
      Quotes
    </div>
    <div
      class="header-cell"
      style="width: 53px;"
    />
  </div>
</template>
<script>
import { mapMutations, mapState } from 'vuex';
import {
  DRAFT_RFQS,
  RFQ_MODULE,
  SELECTED_PART_HASHES,
  SET_SORT_BY,
  SORT_BY,
} from '@/app-buyer/store/modules/rfq/types';
import { partSelectMixin } from '@/app-buyer/components/project/mixins';
import PartTableBulkOperationAll
  from '@/app-buyer/components/project/PartTableBulkOperationAll.vue';

export default {
  name: 'PartTableHead',
  components: {
    PartTableBulkOperationAll,
  },
  mixins: [partSelectMixin],
  data() {
    return {
      renderKey: 0,
    };
  },
  computed: {
    ...mapState(RFQ_MODULE, {
      DRAFT_RFQS,
      SELECTED_PART_HASHES,
      SORT_BY,
    }),
    someSelected() {
      return !this.allDraftsSelected
        && this[DRAFT_RFQS].some((d) => this[SELECTED_PART_HASHES].includes(d.hash));
    },
    allDraftsSelected: {
      get() {
        return this[DRAFT_RFQS].every((d) => this[SELECTED_PART_HASHES].includes(d.hash));
      },
      set(value) {
        const { someSelected } = this;
        const newHashes = (value && !someSelected) ? this[DRAFT_RFQS].map((d) => d.hash) : [];
        this.selectParts(...newHashes);
        if (someSelected) {
          this.$nextTick(() => this.renderKey++);
        }
      },
    },
    sortIcon() {
      const { field, direction } = this[SORT_BY];
      return {
        // eslint-disable-next-line no-nested-ternary
        name: field === 'name' ? direction === 1 ? 'sort-up' : 'sort-down' : 'sort',
        // eslint-disable-next-line no-nested-ternary
        'configuration_object.service.string_value': field === 'configuration_object.service.string_value' ? direction === 1 ? 'sort-up' : 'sort-down' : 'sort',
        // eslint-disable-next-line no-nested-ternary
        'configuration_object.material.string_value': field === 'configuration_object.material.string_value' ? direction === 1 ? 'sort-up' : 'sort-down' : 'sort',
      };
    },
  },
  methods: {
    ...mapMutations(RFQ_MODULE, {
      SET_SORT_BY,
    }),
    sortBy(field) {
      if (this[SORT_BY].field === field) {
        if (this[SORT_BY].direction === 1) {
          this[SET_SORT_BY]({
            ...this[SORT_BY],
            direction: -1,
          });
        } else {
          this.SET_SORT_BY(null);
        }
      } else {
        this[SET_SORT_BY]({
          field,
          type: String,
          direction: 1,
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.row {
  display: flex;
  font-weight: 700;

  .header-cell,
  .header-cell * {
    overflow: visible;
    text-overflow: ellipsis;
  }

  .checkboxAll {
    width: 86px;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    font-weight: 400;
  }

  .checkboxAll-checkbox {
    margin-right: 0 !important;
  }
}
</style>
