<template>
  <div class="modal-card">
    <div class="modal-card-head has-background-white py-5 px-6">
      <p class="modal-card-title has-text-weight-bold">
        Standards, Part marking and Extras
      </p>
      <a
        class="delete"
        @click="$emit('close')"
      />
    </div>
    <div class="modal-card-body py-5 px-6">
      <StandardsAndMarkingsTables
        :opened-detailed="openedDetailed"
        :selected="selected"
        :standards-and-markings="standardsAndMarkings"
        @add-or-remove="addOrRemove"
        @update-detailed="updateDetailed"
      />
      <ScrewsTable
        :opened-detailed="openedDetailed"
        :screws="screws"
        :selected="selected"
        @update-detailed="updateDetailed"
        @add-or-remove="addOrRemove"
      />
    </div>
    <div class="modal-card-foot has-background-white is-justify-space-between py-5 px-6">
      <GmButton
        is-link
        @click="$emit('close')"
      >
        Cancel
      </GmButton>
      <GmButton
        type="g-primary"
        @click="setupUpdate"
      >
        Confirm Extras
      </GmButton>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import { safeMultipleUpdate } from '@/app-buyer/components/project/mixins';
import {
  ADDONS,
  REFERENCE_DATA,
  REFERENCE_MODULE,
} from '@/app-buyer/store/modules/reference-data/types';
import { CERTIFICATE_STANDARD, PART_MARKING, SCREWS } from '@/shared/consts/slugs';
import StandardsAndMarkingsTables
  from '@/app-buyer/components/project/StandardsAndMarkingsTables.vue';
import ScrewsTable from '@/app-buyer/components/project/ScrewsTable.vue';

export default {
  name: 'OtherExtrasModal',
  components: { ScrewsTable, StandardsAndMarkingsTables },
  mixins: [safeMultipleUpdate],
  props: {
    leadHash: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      openedDetailed: [],
      initialSelected: [],
      selected: [],
    };
  },

  computed: {
    ...mapState(REFERENCE_MODULE, {
      REFERENCE_DATA,
    }),
    filteredAddons() {
      return this[ADDONS]?.map((a) => {
        const children = this[REFERENCE_DATA]?.filter((p) => p.parent_ids.includes(a.id));
        return {
          ...a,
          children: children.filter((c) => this.checkParents(c.id, this.leadConfig)),
        };
      });
    },
    partMarkings() {
      return this.filteredAddons?.find((e) => e.slug === PART_MARKING)?.children || [];
    },
    certificateStandards() {
      return this.filteredAddons?.find((e) => e.slug === CERTIFICATE_STANDARD)?.children || [];
    },
    standardsAndMarkings() {
      return [
        {
          title: 'Standards',
          data: this.certificateStandards,
        },
        {
          title: 'Part marking',
          data: this.partMarkings,
        },
      ];
    },
    screws() {
      return this.filteredAddons?.find((e) => e.slug === SCREWS)?.children || [];
    },
    allAddonOptions() {
      return [
        ...this.partMarkings,
        ...this.certificateStandards,
        ...this.screws,
      ];
    },
    leadConfig() {
      return this.configurations?.[this.leadHash];
    },
  },
  watch: {
    leadConfig: {
      handler(val) {
        const addonIds = [...this.certificateStandards, ...this.partMarkings, ...this.screws].map((e) => e.id);
        this.initialSelected = Object.values(val).filter((v) => addonIds.includes(v));
        this.selected = [...this.initialSelected];
      },
      immediate: true,
    },
  },
  methods: {
    setupUpdate() {
      this.initialSelected.filter((id) => !this.selected.includes(id)).forEach((id) => {
        const entitySlug = this.allAddonOptions.find((e) => e.id === id).entity_slug;
        this.startUpdate(null, { entitySlug, values: [null] });
      });
      this.selected.forEach((id) => {
        const entitySlug = this.allAddonOptions.find((e) => e.id === id).entity_slug;
        const values = Array.from(new Set(
          Object.values(this.configurations).map((v) => v[entitySlug]).filter(Boolean),
        ));
        this.startUpdate(id, { entitySlug, values });
      });
      this.$emit('close');
    },
    updateDetailed({ id, isOpen }) {
      if (isOpen) {
        this.openedDetailed.push(id);
      } else {
        this.openedDetailed = this.openedDetailed.filter((e) => e !== id);
      }
    },
    addOrRemove(id) {
      if (this.selected.includes(id)) {
        this.selected = this.selected.filter((e) => e !== id);
      } else {
        this.selected.push(id);
      }
    },
    onUpdateFinish() {
      this.$emit('close');
    },
  },
};
</script>
