// TODO this could be better

export function mapUserData(userdata) {
  const mappedData = {};
  mappedData.name = userdata.user ? userdata.user.name : '';
  mappedData.phone = userdata.user ? userdata.user.contact_number : '';
  mappedData.email = userdata.user ? userdata.user.email : '';
  mappedData.email_verified = userdata.user ? userdata.user.email_verified_at : '';
  mappedData.company_name = userdata.profile ? userdata.profile.company_name : '';
  mappedData.business_role = ''; // TODO
  mappedData.phone_number = userdata.profile ? userdata.profile.phone_number : '';
  mappedData.company_number = ''; // TODO
  mappedData.vat_number = userdata.profile ? userdata.profile.vat_number : '';
  mappedData.eori_number = userdata?.profile?.eori_number;
  mappedData.number_of_employees = userdata.profile ? userdata.profile.number_of_employees : '';
  mappedData.company_description = userdata.profile ? userdata.profile.company_description : '';
  mappedData.post_code = userdata.contact ? userdata.contact.post_code || '' : '';
  mappedData.street_address = userdata.contact ? userdata.contact.street_address || '' : '';
  mappedData.town = userdata.contact ? userdata.contact.town || '' : '';
  mappedData.county = userdata.contact ? userdata.contact.county || '' : '';
  mappedData.country = userdata.contact ? userdata.contact.country || '' : '';
  mappedData.delivery_post_code = userdata.delivery_contact ? userdata.delivery_contact.post_code || '' : '';
  mappedData.delivery_street_address = userdata.delivery_contact ? userdata.delivery_contact.street_address || '' : '';
  mappedData.delivery_town = userdata.delivery_contact ? userdata.delivery_contact.town || '' : '';
  mappedData.delivery_county = userdata.delivery_contact ? userdata.delivery_contact.county || '' : '';
  mappedData.delivery_country = userdata.delivery_contact ? userdata.delivery_contact.country || '' : '';
  mappedData.billing_post_code = userdata.billing_contact ? userdata.billing_contact.post_code || '' : '';
  mappedData.billing_street_address = userdata.billing_contact ? userdata.billing_contact.street_address || '' : '';
  mappedData.billing_town = userdata.billing_contact ? userdata.billing_contact.town || '' : '';
  mappedData.billing_county = userdata.billing_contact ? userdata.billing_contact.county || '' : '';
  mappedData.billing_country = userdata.billing_contact ? userdata.billing_contact.country || '' : '';
  mappedData.subscribed = userdata.profile.subscribed_at;
  mappedData.industry_segments = userdata.profile?.industry_segments;
  mappedData.title = userdata.profile?.title;
  mappedData.demand_specific_services = userdata.profile?.demand_specific_services;
  mappedData.intent = userdata.profile?.intent;
  return mappedData;
}

export function mapUserDataToBEFormat(rawUserData) {
  const userData = mapUserData(rawUserData);
  const mappedData = {
    address: {},
    profile: {},
    buyer_profile: rawUserData.buyer_profile_fields
      .reduce((res, curr) => ({
        ...res,
        [curr.field]: [
          'title',
          'organization_size',
          'engineering_team_size',
          'headcount',
          'required_lead_times',
          'volume_of_production',
          'number_of_rfqs',
          'order_per_month',
          'intent',
        ].includes(curr.field)
          ? curr.values[0]
          : curr.values,
      }), {}),
  };
  if (Object.prototype.hasOwnProperty.call(rawUserData, 'subscribe')) {
    mappedData.subscribe = rawUserData.subscribe;
    mappedData.profile.subscribed = rawUserData.subscribe;
  }
  mappedData.name = userData.name || '';
  mappedData.contact_number = userData.phone || '';
  mappedData.email = userData.email || '';
  mappedData.profile.company_name = userData.company_name || '';
  mappedData.profile.business_role = '' || ''; // TODO
  mappedData.profile.phone_number = userData.phone_number || '';
  mappedData.profile.company_number = '' || ''; // TODO
  mappedData.profile.vat_number = userData.vat_number || '';
  mappedData.profile.eori_number = userData.eori_number || '';
  mappedData.profile.number_of_employees = userData.number_of_employees || '';
  mappedData.profile.company_description = userData.company_description || '';

  if (userData.industry_segments) {
    mappedData.buyer_profile.industry_segments = userData.industry_segments;
  }
  if (userData.title) {
    mappedData.buyer_profile.title = userData.title;
  }
  if (userData.demand_specific_services) {
    mappedData.buyer_profile.demand_specific_services = userData.demand_specific_services;
  }
  if (userData.intent) {
    mappedData.buyer_profile.intent = Array.isArray(userData.intent)
      ? userData.intent[0]
      : userData.intent;
  }

  if (userData.post_code) {
    mappedData.address.user = {};
    mappedData.address.user.post_code = userData.post_code || '';
    mappedData.address.user.street_address = userData.street_address || '';
    mappedData.address.user.town = userData.town || '';
    mappedData.address.user.county = userData.county || '';
    mappedData.address.user.country = userData.country || '';
  }
  if (userData.delivery_post_code) {
    mappedData.address.delivery = {};
    mappedData.address.delivery.post_code = userData.delivery_post_code || '';
    mappedData.address.delivery.street_address = userData.delivery_street_address || '';
    mappedData.address.delivery.town = userData.delivery_town || '';
    mappedData.address.delivery.county = userData.delivery_county || '';
    mappedData.address.delivery.country = userData.delivery_country || '';
  }
  if (userData.billing_post_code) {
    mappedData.address.billing = {};
    mappedData.address.billing.post_code = userData.billing_post_code || '';
    mappedData.address.billing.street_address = userData.billing_street_address || '';
    mappedData.address.billing.town = userData.billing_town || '';
    mappedData.address.billing.county = userData.billing_county || '';
    mappedData.address.billing.country = userData.billing_country || '';
  }

  return mappedData;
}

export function getProfileStatus(mappedData) {
  const profileStatus = {
    all: -7,
    complete: 0,
  };

  Object.keys(mappedData).forEach((key) => {
    profileStatus.all++;
    if (mappedData[key]) {
      profileStatus.complete++;
    }
  });

  return Math.round((profileStatus.complete / profileStatus.all) * 100);
}
