<template>
  <div
    class="container m-b-md-important"
    data-testid="empty-list-indicator"
  >
    <div class="hero">
      <div class="icon-wrapper">
        <font-awesome-icon
          icon="folder"
          size="6x"
        />
      </div>
      <h2 class="title is-6 is-emphasised">
        {{ message }}
      </h2>
    </div>
  </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

export default {
  name: 'EmptyListIndicator',
  components: {
    FontAwesomeIcon,
  },
  props: {
    message: {
      type: String,
      default: () => 'No items found.',
    },
  },
};
</script>

<style scoped>
  .hero {
    align-items: center;
    justify-content: center;
    opacity: 0.2;
  }

  .icon-wrapper {
    align-items: center;
    background-color: black;
    border-radius: 50%;
    color: white;
    display: flex;
    height: 15rem;
    justify-content: center;
    margin: 2rem;
    mix-blend-mode: multiply;
    width: 15rem;
  }

  h2 {
    color: #000;
  }
</style>
