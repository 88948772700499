<template>
  <section>
    <div class="columns">
      <div
        v-for="(tab, index) in tabs"
        :key="tab.componentInstance.title"
        class="column is-4-widescreen"
        @click="selectTab(index)"
      >
        <a
          class="big-tab is-flex is-align-items-center"
          :class="{
            selected: (index === selectedTabIndex) ,
            disabled: tab.componentInstance.disabled,
          }"
          style="display: block;"
        >
          <div class="columns is-align-items-center">
            <div
              v-if="tab.componentInstance.icon"
              class="column is-1 is-hidden-mobile"
              style="margin-right: 10px;"
            >
              <b-icon
                :icon="tab.componentInstance.icon"
                size="is-medium"
              />
            </div>
            <div class="column">
              <div
                v-if="tab.componentInstance.tooltip"
                class="tooltip-content"
              >
                <b-tooltip
                  :label="tab.componentInstance.tooltip"
                  position="is-top"
                  size="is-small"
                  type="is-dark"
                  class="pull-right"
                  multilined
                >
                  <b-icon
                    icon="info-circle"
                    size="is-small"
                    class="has-text-info"
                  />
                </b-tooltip>
              </div>
              <p>{{ tab.componentInstance.title }}</p>
              <p
                class="description"
                v-html="tab.componentInstance.description"
              />
            </div>
          </div>
        </a>
      </div>
    </div>
    <slot />
  </section>
</template>

<script>

export default {
  name: 'BigTabs',

  data() {
    return {
      selectedTabIndex: 0,
      tabs: [],
    };
  },
  mounted() {
    this.tabs = this.$slots.default;
    this.selectTab(0);
  },
  methods: {
    selectTab(i) {
      if (this.tabs[i].componentInstance.disabled) {
        return;
      }

      this.selectedTabIndex = i;
      this.tabs.forEach((tab, index) => {
        tab.componentInstance.isActive = (index === i);
      });
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
.big-tab {
  border: 2px solid #dbdbdb;
  border-radius: 5px;
  padding: 15px;
  width: 100%;
  font-size: 13px;
  font-weight: 600;
  color: #363636;
  min-height: 70px;
  line-height: 1.2;
}

.big-tab.selected {
  color: #338dbc;
  border: 2px solid #338dbc;
}

.big-tab.disabled {
  font-weight: 300 !important;
  color: #7e7e7e !important;
  cursor: not-allowed !important;
}

.description {
  font-weight: 400 !important;
  margin-top: 2px !important;
}

.tooltip-content {
  float: right;
  background-color: white !important;
}
</style>
