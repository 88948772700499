<template>
  <div>
    <div
      v-for="(memberGroup, index) in activeAndPendingUsers"
      :key="index"
      :class="!index && 'mb-4'"
    >
      <p class="is-size-5 has-text-weight-bold">
        {{ index === 0 ? projectName : 'Pending' }} members
      </p>
      <BTable
        v-if="memberGroup.length"
        :data="memberGroup"
        :mobile-cards="false"
      >
        <BTableColumn
          #default="{row}"
          width="2rem"
        >
          <MemberAvatar :user="row.user" />
        </BTableColumn>
        <BTableColumn #default="{row}">
          <span :class="row.user.pending && 'has-text-dark'">
            {{ row.user.name || row.user.email }}
          </span>
        </BTableColumn>
        <BTableColumn
          #default="{row}"
          width="1rem"
        >
          <button
            v-if="row.role === 'owner'"
            class="button-no-style cursor-pointer"
          >
            <BIcon
              icon="crown"
              type="is-g-primary"
            />
          </button>
          <button
            v-else-if="viewedByOwner"
            class="button-no-style cursor-pointer"
            data-testid="remove-team-member-btn"
            @click="$emit('remove', row.user)"
          >
            <BIcon
              icon="trash"
              type="is-black"
            />
          </button>
        </BTableColumn>
      </BTable>
    </div>
  </div>
</template>

<script>
import MemberAvatar from '@/app-buyer/components/teams/MemberAvatar.vue';

export default {
  name: 'ProjectMemberManager',
  components: { MemberAvatar },
  props: {
    members: {
      type: Array,
      default: () => [],
    },
    projectName: {
      type: String,
      default: null,
    },
    viewedByOwner: Boolean,
  },
  computed: {
    activeAndPendingUsers() {
      return this.members.reduce(([active, pending], member) => {
        (member.user.pending ? pending : active).push(member);
        return [active, pending];
      }, [[], []]);
    },
  },
};
</script>
