<template>
  <div @click.stop>
    <BField style="min-width: 6ch;">
      <BNumberinput
        v-model="quantity"
        size="is-small"
        :controls="false"
        :min="1"
      />
    </BField>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import { debounce } from 'lodash/function';
import { RFQ_MODULE, UPDATE_DRAFT } from '@/app-buyer/store/modules/rfq/types';

export default {
  name: 'PartTableGroupRowQuantity',
  props: {
    part: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      quantityInitial: 0,
    };
  },
  computed: {
    quantity: {
      get() {
        return this.part?.quantity_initial;
      },
      set(val) {
        if (!val) return;
        this.updateQuantity(val);
      },
    },
  },
  methods: {
    ...mapActions(RFQ_MODULE, {
      UPDATE_DRAFT,
    }),
    // eslint-disable-next-line camelcase
    updateQuantity: debounce(function debounceUpdateQuantity(quantity_initial) {
      const properties = {
        name: this.part.name,
        project_hash: this.part.project_hash,
        'file-type': this.part.configuration['file-type'],
        quantity_initial,
      };
      this[UPDATE_DRAFT]({
        draft: this.part,
        properties,
      });
    }),
  },
};
</script>
