import Vue from 'vue';
/**
 * Set all components in this folder as global components.
 */

const components = require.context('./', true, /\.vue$/);

components.keys().forEach((fileName) => {
  const component = components(fileName);
  const componentName = fileName.split('/').pop().replace('.vue', '');
  const capitalised = componentName
    .split('-')
    .map((p) => p.charAt(0).toUpperCase() + p.slice(1))
    .join('');
  Vue.component(capitalised, component.default || component);
});
