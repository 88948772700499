<template>
  <div
    class="modal-card"
    style="overflow: unset;"
    data-testid="add-team-member-modal"
  >
    <header class="modal-card-head has-background-white is-align-items-flex-start px-5 pt-5">
      <div class="modal-card-title">
        <p class="title is-4">
          Team management
        </p>
        <p class="subtitle is-6">
          Invite and manage team members for your projects
        </p>
      </div>
      <button
        class="delete"
        @click="$emit('close')"
      />
    </header>
    <section class="modal-card-body px-5">
      <ProjectMemberInviteModalPromo />
      <BMessage
        v-if="errors"
        type="is-danger"
      >
        <ul>
          <li
            v-for="(error, errorKey) in errors"
            :key="errorKey"
          >
            <ul>
              <li
                v-for="errorMessage in error"
                :key="errorMessage"
              >
                <BIcon
                  icon="exclamation-circle"
                  type="is-danger"
                />
                {{ errorMessage }}
              </li>
            </ul>
          </li>
        </ul>
      </BMessage>
      <ProjectMemberManager
        :members="projectMembers"
        :project-name="projectName"
        :viewed-by-owner="isViewedByProjectOwner"
        @remove="remove"
      />
    </section>
    <footer class="modal-card-foot has-background-white px-5 pb-5">
      <div class="is-width-100 is-flex">
        <ProjectMemberInviteInput
          v-model="invitees"
          :members="projectMembers"
          :disabled="isLoading"
        />
        <BButton
          :disabled="!invitees.length || isLoading"
          type="is-g-primary"
          class="has-text-weight-bold ml-2"
          :loading="isLoading"
          data-testid="add-team-member-submit"
          @click="invite"
        >
          Invite
        </BButton>
      </div>
    </footer>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import ProjectMemberInviteModalPromo
  from '@/app-buyer/components/project/ProjectMemberInviteModalPromo.vue';
import ProjectMemberManager from '@/app-buyer/components/project/ProjectMemberManager.vue';
import ProjectMemberInviteInput from '@/app-buyer/components/project/ProjectMemberInviteInput.vue';
import { PROJECT_MODULE } from '@/app-buyer/store/modules/projects/types';
import { USER_DATA, USER_MODULE } from '@/app-buyer/store/modules/user/types';
import Api from '@/app-buyer/api/api';
import { UPDATE } from '@/app-buyer/store/modules/types';
import { AUTH_MODULE, MASQUERADING } from '@/app-buyer/store/modules/auth/types';
import getEnvironmentVariable from '@/shared/misc/env-variable';

export default {
  name: 'ProjectMemberInviteModal',

  components: { ProjectMemberInviteInput, ProjectMemberManager, ProjectMemberInviteModalPromo },

  props: {
    project: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      invitees: [],
      errors: null,
      isLoading: false,
    };
  },

  computed: {
    ...mapState(USER_MODULE, {
      USER_DATA,
    }),
    ...mapState(AUTH_MODULE, {
      MASQUERADING,
    }),

    projectMembers() {
      return this.project?.members;
    },

    projectOwner() {
      return this.projectMembers?.find((m) => m.role === 'owner')?.user;
    },

    projectName() {
      return this.project?.name;
    },

    userId() {
      return this[USER_DATA]?.user?.id;
    },

    isViewedByProjectOwner() {
      return this.projectOwner?.id === this.userId;
    },
  },

  methods: {
    ...mapMutations(PROJECT_MODULE, {
      UPDATE,
    }),

    async invite() {
      this.errors = null;
      this.isLoading = true;
      try {
        const { data } = await Api.post(
          `v2/projects/${this.project.hash}/members`,
          Array.isArray(this.invitees)
            ? { emails: this.invitees.map((e) => e.email) }
            : { email: this.invitees.email },
        );

        // SEGMENT TRACKING
        if (getEnvironmentVariable('VUE_APP_SEGMENT_ENABLED')) {
          window.analytics.track('Members created', {
            isMasquerading: !!this[MASQUERADING],
            projectHash: this.project?.hash,
            memberLength: data.data?.members?.length,
          });
        }
        this[UPDATE]({
          hash: this.project.hash,
          data: data.data,
        });
        this.$emit('update:project', data.data);
        this.invitees = [];
      } catch (e) {
        this.errors = e.response?.data?.errors;
      } finally {
        this.isLoading = false;
      }
    },

    remove(user) {
      this.$buefy.dialog.confirm({
        message: `Are you sure you want to remove <b>${user.name || user.email}</b> from <b>${this.project.name}</b>?`,
        onConfirm: () => this.finaliseRemove(user),
        confirmText: 'Remove',
        type: 'is-danger',
        hasIcon: true,
      });
    },

    async finaliseRemove(user) {
      this.errors = null;
      this.isLoading = true;
      try {
        const { data } = await Api.delete(`v2/projects/${this.project.hash}/members/${user.id}`);

        // SEGMENT TRACKING
        if (getEnvironmentVariable('VUE_APP_SEGMENT_ENABLED')) {
          window.analytics.track('Members deleted', {
            isMasquerading: !!this[MASQUERADING],
            projectHash: this.project?.hash,
            memberLength: data.data?.members?.length,
          });
        }

        this[UPDATE]({
          hash: this.project.hash,
          data: data.data,
        });
        this.$emit('update:project', data.data);
      } catch (e) {
        this.errors = e?.response?.data?.errors;
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
