<template>
  <div>
    <section
      :class="isLast && 'mb-4'"
      class="is-flex is-justify-content-space-between is-flex-wrap-wrap"
    >
      <FeedbackOrderSingleSummary
        class="mb-4"
        :invoice="invoice"
      />
      <div
        class="is-flex is-flex-direction-column is-justify-content-space-between mb-4"
        style="width: 370px;"
      >
        <p class="has-text-weight-bold is-size-7">
          How would you rate this order?
        </p>
        <FeedbackStars
          v-model="rating"
          @input="$emit('input', {...value, rating: $event})"
        />
        <BInput
          v-model="comment"
          size="is-small"
          type="textarea"
          maxlength="500"
          has-counter
          :placeholder="placeholder"
          @input="$emit('input', {...value, comment: $event})"
        />
      </div>
      <slot name="footer" />
    </section>
    <hr
      v-if="!isLast"
      class="mt-0"
    >
  </div>
</template>

<script>
import FeedbackOrderSingleSummary
  from '@/app-buyer/components/feedback-modal/FeedbackOrderSingleSummary.vue';
import FeedbackStars from '@/app-buyer/components/feedback-modal/FeedbackStars.vue';

export default {
  name: 'FeedbackOrderSingle',
  components: { FeedbackStars, FeedbackOrderSingleSummary },
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    invoice: {
      type: Object,
      required: true,
    },
    isLast: Boolean,
  },
  data() {
    return {
      rating: null,
      comment: null,
      placeholder: 'You can provide any further details for the Geomiq Quality Engineers here.',
    };
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .help.counter {
    position: absolute;
  }
}

</style>
