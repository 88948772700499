<template>
  <BCheckbox
    v-bind="$attrs"
    :value="leadValue"
    :true-value="trueValue"
    :false-value="falseValue"
    v-on="$listeners"
    @input="update"
  >
    <slot />
  </BCheckbox>
</template>
<script>
import { formInput } from '@/app-buyer/mixins/form-input';

export default {
  name: 'ConfiguratorElementCheckbox',
  mixins: [formInput],
  computed: {
    trueValue() {
      return this.properties.find((e) => e.boolean_value)?.id
        || (this.properties.length === 1 && this.properties[0]?.id);
    },
    falseValue() {
      return this.properties.find((e) => e.boolean_value === false)?.id;
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
::v-deep .label {
  display: inline;
}
</style>
