<template>
  <div
    class="modal-card box has-background-info"
    style="min-width: 80vw; max-height: 80vh;"
  >
    <section class="is-flex is-justify-space-between m-t-sm m-b-sm">
      <div class="wrapper">
        <p class="title-is-6 has-text-white">
          Parts Summary
        </p>
        <p class="subtitle-is-6 has-text-white is-flex">
          <b class="m-r-sm">Uploaded files</b>
          <portal-target name="summary" />
        </p>
      </div>
      <gm-button
        v-show="false"
        class="is-hidden-in-print"
        outlined
        style="color: white; font-size: 2rem;"
        type="link"
        @click="downloadSummary"
      >
        <icon-font
          :paths="4"
          icon="download-pdf"
        />
        <p class="is-size-7">
          Download
        </p>
      </gm-button>
    </section>
    <section
      v-if="selectedDrafts && selectedDrafts.length"
      ref="printable"
      class="card box"
      style="overflow-y: auto;"
    >
      <article
        v-for="draft in selectedDrafts"
        :key="draft.hash"
        class="card m-b-lg p-lg"
      >
        <configurator-drafts-summary-element :draft="draft" />
      </article>
    </section>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { DRAFT_RFQS, RFQ_MODULE, SELECTED_PART_HASHES } from '../../../../store/modules/rfq/types';
import ConfiguratorDraftsSummaryElement from './configurator-drafts-summary-element.vue';

export default {
  name: 'ConfiguratorDraftsSummary',
  components: { ConfiguratorDraftsSummaryElement },

  props: {
    requestMode: {
      type: String,
      default: 'selected',
    },
  },
  computed: {
    ...mapState(RFQ_MODULE, {
      DRAFT_RFQS,
      SELECTED_PART_HASHES,
    }),

    selectedDrafts() {
      if (this.requestMode === 'all') return this[DRAFT_RFQS];

      let selected = [];
      this[SELECTED_PART_HASHES].forEach((selectedHash) => {
        const selectedRfq = this[DRAFT_RFQS].filter((draft) => draft.hash === selectedHash);
        selected = [...selected, ...selectedRfq];
      });
      return selected;
    },
  },
  methods: {
    downloadSummary() {
      window.print();
    },
  },
};
</script>
