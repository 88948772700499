<template>
  <div
    ref="loader"
    :class="status"
    :style="`--loader-size: ${size}rem;
    --success-color: ${successColor};
    --danger-color: ${dangerColor}`"
    class="circle-loader"
  >
    <div
      v-show="status === STATUSES.COMPLETE"
      class="circle-loader-checkmark"
    />
    <div
      v-show="status === STATUSES.FAILED"
      class="circle-loader-failed"
    />
  </div>
</template>

<script>
export const STATUS_LOADER_STATUSES = {
  LOOPING: 'looping',
  COMPLETE: 'complete',
  FAILED: 'failed',
};
export default {
  name: 'StatusLoader',
  props: {
    status: {
      type: String,
      default: STATUS_LOADER_STATUSES.LOOPING,
    },
    size: {
      type: Number,
      default: 5,
    },
    successColor: {
      type: String,
      default: '#04ac00',
    },
    dangerColor: {
      type: String,
      default: 'var(--button-warning)',
    },
  },
  data() {
    return {
      STATUSES: STATUS_LOADER_STATUSES,
      timedStatus: STATUS_LOADER_STATUSES.LOOPING,
    };
  },
};
</script>

<style lang="scss" scoped>
  $check-height: calc(var(--loader-size) / 2);
  $check-width: calc(var(--loader-size) / 4);
  $check-left: calc(var(--loader-size) / 6 + var(--loader-size) / 12.7
  - calc(var(--loader-size) / 40 + 1px));
  $check-thickness: calc(var(--loader-size) / 30 + 1px);

  .circle-loader {
    align-items: center;
    animation: loader-spin 1.2s infinite linear;
    border: calc(var(--loader-size) / 40 + 1px) solid rgba(0, 0, 0, 0.2);
    border-left-color: var(--success-color);
    border-radius: 50%;
    display: flex;
    height: var(--loader-size);
    justify-content: center;
    position: relative;
    vertical-align: top;
    width: var(--loader-size);
  }

  .complete {
    -webkit-animation: none;
    animation: none;
    border-color: var(--success-color);
    transition: border 500ms ease-out;
  }

  .failed {
    -webkit-animation: none;
    animation: none;
    border-color: var(--danger-color);
    transition: border 500ms ease-out;
  }

  .circle-loader-checkmark {
    &:after {
      animation-duration: 800ms;
      animation-name: checkmark;
      animation-timing-function: ease;
      transform: scaleX(-1) rotate(135deg);
    }

    &:after {
      border-right: $check-thickness solid var(--success-color);
      border-top: $check-thickness solid var(--success-color);
      content: '';
      height: $check-height;
      left: $check-left;
      opacity: 1;
      position: absolute;
      top: $check-height;
      transform-origin: left top;
      width: $check-width;
    }
  }

  .circle-loader-failed {
    height: $check-height;
    position: relative;
    width: $check-thickness;

    &:before {
      animation-name: growBefore;
      transform: rotate(45deg);
    }

    &:after {
      animation-name: growAfter;
      transform: rotate(135deg);
    }

    &:after,
    &:before {
      animation-duration: 200ms;
      animation-timing-function: ease;
      background-color: var(--danger-color);
      color: var(--danger-color);
      content: "";
      height: 100%;
      position: absolute;
      width: 100%;
    }

  }

  @keyframes loader-spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes growBefore {
    from {
      transform: scaleY(0) rotate(30deg);
    }
    to {
      transform: scaleY(1) rotate(45deg);
    }
  }

  @keyframes growAfter {
    from {
      transform: scaleY(0) rotate(150deg);
    }
    to {
      transform: scaleY(1) rotate(135deg);
    }
  }

  @keyframes checkmark {
    0% {
      height: 0;
      width: 0;
      opacity: 1;
    }
    20% {
      height: 0;
      width: $check-width;
      opacity: 1;
    }
    40% {
      height: $check-height;
      width: $check-width;
      opacity: 1;
    }
    100% {
      height: $check-height;
      width: $check-width;
      opacity: 1;
    }
  }
</style>
