/* eslint-disable camelcase */
import { mapMutations, mapState } from 'vuex';
import CartItem from '@/app-buyer/models/CartItem';
import Api from '@/app-buyer/api/api';
import {
  METADATA,
  SET_METADATA,
  USER_MODULE,
} from '@/app-buyer/store/modules/user/types';
import Quote from '@/app-buyer/models/Quote';

const addRemoveQuoteCart = {
  computed: {
    ...mapState(USER_MODULE, {
      METADATA,
    }),
  },
  methods: {
    ...mapMutations(USER_MODULE, {
      SET_METADATA,
    }),
    addQuoteToCart(quote_id) {
      const query = new CartItem({ quote_id });
      const request = query.save();
      this.updateMetadataIfSuccessful(request, 'add');
      return request;
    },
    async removeQuotesFromCartByQuoteGroupIds(ids) {
      const queries = ids.map((id) => new Quote().custom(`quote-groups/${id}/quotes`).get());
      const quotes = await Promise.all(queries);
      const quoteIds = quotes.flatMap((res) => res.data).map((quote) => quote.id);
      const request = new Api.delete(`v2/quotes/${quoteIds.join(',')}/cart-items/bulk-destroy`);
      this.updateMetadataIfSuccessful(request, 'removeMultiple', quoteIds.length);
      return quoteIds;
    },
    removeQuoteFromCartByCartItemId(id) {
      const query = new CartItem({ id });
      const request = query.delete();
      this.updateMetadataIfSuccessful(request, 'remove');
      return request;
    },
    addMultipleQuotesToCart(quote_ids) {
      const request = Api.post('v2/cart-items/bulk-store', { quote_ids });
      this.updateMetadataIfSuccessful(request, 'addMultiple', quote_ids.length);
      return request;
    },
    async updateMetadataIfSuccessful(request, type, num) {
      if (!this[METADATA]) return;
      try {
        const { data } = await request;
        switch (type) {
          case 'remove':
            this[SET_METADATA]({
              ...this[METADATA],
              cart_count: this[METADATA].cart_count - 1,
            });
            break;
          case 'add':
            this[SET_METADATA]({
              ...this[METADATA],
              cart_count: this[METADATA].cart_count + 1,
            });
            break;
          case 'addMultiple':
            this[SET_METADATA]({
              ...this[METADATA],
              cart_count: this[METADATA].cart_count + num,
            });
            break;
          case 'removeMultiple':
            this[SET_METADATA]({
              ...this[METADATA],
              cart_count: this[METADATA].cart_count - (data?.delete_count || 0),
            });
            break;
          default:
            break;
        }
      } catch (e) {
        console.debug('Update failed');
      }
    },
  },
};

export default addRemoveQuoteCart;
