<template>
  <BDropdown
    ref="dropdown"
    data-testid="quote_operations_dropdown"
    position="is-bottom-left"
    type="is-dark"
  >
    <div
      slot="trigger"
      class="is-relative"
    >
      <Tippy
        slot="trigger"
        :enabled="hasError"
        arrow
        theme="is-warning"
      >
        <BButton
          slot="trigger"
          :icon-left="hasError ? 'exclamation-triangle' : 'ellipsis-h'"
          :type="hasError ? 'is-warning' : ''"
          class="option-button"
          data-testid="quote_operations_button"
          size="is-small"
        />
        <p v-if="needsSupportingFile">
          Please upload a supporting file!
        </p>
        <p v-if="fileIssue">
          <strong>{{ fileIssue.messageTitle }}:</strong> {{ fileIssue.message }}
        </p>
      </Tippy>
      <BTag
        v-show="rfq.unread_messages_count"
        class="is-absolute"
        rounded
        size="is-small"
        style="top: -0.5rem; right: -0.5rem;"
        type="is-info"
      >
        {{ rfq.unread_messages_count }}
      </BTag>
    </div>
    <BDropdownItem v-show="needsSupportingFile">
      <BUpload
        accept=".pdf"
        multiple
        native
        @input="uploadSupportingFile"
      >
        <div class="is-flex is-align-items-center">
          <BIcon
            icon="exclamation-triangle"
            type="is-warning"
          />
          Upload supporting file
        </div>
      </BUpload>
    </BDropdownItem>
    <BDropdownItem
      v-show="fileIssue"
      custom
      @click.native="$refs.upload.$el.click()"
    >
      <BButton
        expanded
        icon-left="exclamation-triangle"
        type="is-warning"
      >
        Upload CAD file
      </BButton>
      <BUpload
        ref="upload"
        expanded
        native
        @input="uploadModelFile"
      />
    </BDropdownItem>
    <BDropdownItem
      v-for="item in dropdownItems"
      :key="item.slug"
      :custom="!!item.component"
      :data-testid="item.testId ? item.testId : null"
      :has-link="item.hasLink"
      @click="item.onClick"
    >
      <Component
        :is="item.component"
        v-if="item.component"
        :rfq="rfq"
        @cancelled="$emit('cancelled')"
      />
      <Component
        :is="item.hasLink ? 'RouterLink' : 'div'"
        v-else
        :to="item.to"
        class="is-flex is-flex-align-centered py-2"
      >
        <div
          class="mr-3"
          style="flex: none; width: 1rem; margin-bottom: -1px;"
        >
          <BIcon
            :icon="item.icon"
            pack="fal"
          />
        </div>
        <p class="has-text-left is-flex-grow-1">
          {{ item.label }}
        </p>
        <BTag
          v-if="item.tag"
          rounded
          size="is-small"
          type="is-info"
        >
          {{ item.tag }}
        </BTag>
      </Component>
    </BDropdownItem>
  </BDropdown>
</template>

<script>
import { mapActions } from 'vuex';
import modifyMixin from '@/app-buyer/mixins/modify-mixin';
import QuoteListOperationsCancel from '@/app-buyer/components/rfq-list/RfqListOperationsCancel.vue';
import addRemoveQuoteCart from '@/app-buyer/mixins/add-remove-quote-cart';
import openQuoteChat from '@/app-buyer/mixins/open-quote-chat';
import {
  CNC, RFQ_MODEL, RFQ_SUPPORTING_FILE, STEP, STP,
} from '@/shared/consts/slugs';
import Api from '@/app-buyer/api/api';
import { getParserErrorMessage } from '@/app-buyer/mixins/check-uploads';
import { RFQ_MODULE, UPDATE_RFQ } from '@/app-buyer/store/modules/rfq/types';

export default {
  name: 'GroupedQuotesOptions',
  components: {
    QuoteListOperationsCancel,
  },
  mixins: [
    modifyMixin,
    openQuoteChat,
    addRemoveQuoteCart,
  ],
  props: {
    rfq: {
      type: Object,
      required: true,
    },
    listing: {
      type: Object,
      default: () => {},
    },
    quote: {
      type: Object,
      default: () => null,
    },
    enabled: {
      type: Array,
      default: () => [
        'view',
        'duplicate',
        'modify',
        'chat',
        'cancel',
        'add-cart',
        'remove-cart',
      ],
    },
    hidden: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    dropdownItems() {
      return [
        {
          slug: 'view',
          to: `/project/${this.rfq.project_hash}`,
          icon: 'eye',
          label: 'View part in project',
          testId: 'rfq-operation-view',
          onClick: () => {
          },
          hasLink: true,
        },
        {
          slug: 'modify',
          icon: 'cog',
          label: 'Revise & Re-quote',
          testId: 'rfq-operation-modify',
          onClick: () => this.handleReviseRequote({ selectedRfqs: [this.rfq.hash], rfqs: this.listing?.rfqs, listingHash: this.listing.id }),
          hide: !this.rfq.draft_hash,
        },
        {
          slug: 'chat',
          icon: 'comment-dots',
          label: 'Chat about this quote',
          testId: 'rfq-open-chat',
          tag: this.rfq.unread_messages_count,
          onClick: () => Intercom('showNewMessage'),
        },
        {
          slug: 'cancel',
          component: QuoteListOperationsCancel,
          testId: 'rfq-remove-from-cart',
          onClick: () => {
          },
        },
        {
          slug: 'add-cart',
          icon: 'cart-plus',
          label: 'Add to cart',
          onClick: () => {
            this.addQuoteToCart(this.quote.id).then((cartItem) => {
              this.$set(this.quote, 'cart_item', cartItem);
            });
          },
          hide: !this.quote || this.rfq.status !== 'Quote Sent' || this.quote.cart_item,
        },
        {
          slug: 'remove-cart',
          icon: 'minus-circle',
          label: 'Remove from cart',
          onClick: () => {
            this.removeQuoteFromCartByCartItemId(this.quote.cart_item.id).then(() => {
              this.quote.cart_item = null;
            });
          },
          hide: !this.quote || this.rfq.status !== 'Quote Sent' || !this.quote.cart_item,
        },
      ].filter((item) => !item.hide
        && this.enabled.includes(item.slug)
        && !this.hidden.includes(item.slug));
    },
    hasError() {
      const errors = [
        this.needsSupportingFile,
        this.fileIssue,
      ];
      return errors.some(Boolean);
    },
    modelFile() {
      return this.rfq?.uploads?.find((e) => e.type.slug === RFQ_MODEL);
    },
    fileIssue() {
      if (!this.modelFile || this.modelFile.extension.toLowerCase() === 'pdf') return '';
      return this.modelFile?.parser_metadata?.failed_at !== null
        && getParserErrorMessage(this.modelFile?.parser_metadata?.status);
    },
    needsSupportingFile() {
      const supportingFile = this.rfq?.uploads?.find((e) => e.type.slug === RFQ_SUPPORTING_FILE);
      const isStep = [STEP, STP].includes(this.modelFile?.extension);
      const isCnc = this.rfq?.configuration_object?.service?.slug === CNC;
      const isAutodeskParsed = this.modelFile?.parser_metadata?.parser_slug === 'autodesk';
      return !supportingFile && isStep && isCnc && isAutodeskParsed;
    },
  },
  methods: {
    ...mapActions(RFQ_MODULE, {
      UPDATE_RFQ,
    }),
    async uploadSupportingFile(files) {
      this.uploadingSupportingFiles = true;
      try {
        const calls = files.map((file) => {
          const data = new FormData();
          data.append('type', RFQ_SUPPORTING_FILE);
          data.append('file', file);

          return Api.post(
            `/v2/rfqs/${this.rfq.id}/uploads`,
            data,
          );
        });
        const responses = await Promise.all(calls);
        responses.forEach(({ data }) => {
          this.rfq.uploads.push(data);
        });
      } catch (e) {
        console.log(e);
      } finally {
        this.uploadingSupportingFiles = false;
      }
    },
    async uploadModelFile(file) {
      this[UPDATE_RFQ]({ rfq: this.rfq, files: { modelFile: file }, properties: {} });
      if (this.$refs.dropdown) this.$refs.dropdown.isActive = false;
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
::v-deep {

  .icon-wrapper {
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    display: flex;
    height: 2rem;
    justify-content: center;
    margin-right: 0.5rem;
    width: 2rem;
  }

  a.dropdown-item {
    padding-right: 1rem !important;
  }

}
</style>
