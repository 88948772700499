<template>
  <div class="wrapper">
    <svg
      :height="diameter + strokeWidth"
      :width="diameter + strokeWidth"
      class="circular"
    >
      <path
        :d="`M${diameter/2} ${strokeWidth / 2}
                    a ${radius} ${radius} 0 0 1 0 ${diameter}
                    a ${radius} ${radius} 0 0 1 0 -${diameter}`"
        :stroke-dasharray="`${circumference * percentage / 100}, ${circumference}`"
        :stroke-width="strokeWidth"
        :style="`stroke: ${stroke}`"
        class="circle"
        fill="none"
      />
    </svg>
    <span
      :style="`font-size: ${diameter / 3}px`"
      class="percentage"
    >{{ percentage }}%</span>
  </div>
</template>

<script>
export default {
  name: 'CircularPercentage',
  props: {
    diameter: {
      type: Number,
      default: 25,
    },
    strokeWidth: {
      type: Number,
      default: 2,
    },
    percentage: {
      type: Number,
      default: 0,
    },
    stroke: {
      type: String,
      default: 'var(--geomiq-blue)',
    },
    showPercentage: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    radius() {
      return this.diameter / 2;
    },
    circumference() {
      return this.diameter * Math.PI;
    },
  },
};
</script>

<style scoped>
  .wrapper {
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 0.2rem;
    position: relative;
  }

  .circular {
    display: block;
  }

  .circle {
    animation: progress 1s ease-out forwards;
    fill: none;
    stroke-linecap: round;
  }

  .percentage {
    font-weight: bold;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  @keyframes progress {
    0% {
      stroke-dasharray: 0 100;
    }
  }
</style>
