<template>
  <div
    v-show="!isSelfDragged"
    class="is-absolute supporting-drop-area"
    :class="{'visible-on-dragover' : !isDraggingValid, dragover}"
    @drop.prevent="handleDrop"
    @dragenter.prevent="handleDragOver"
    @dragover.prevent="handleDragOver"
    @dragleave.prevent="handleDragEnd"
    @dragexit.prevent="handleDragEnd"
    data-testid="configurator-attach-files"
  >
    Drop supporting files here
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import { DRAFT_RFQ_MODEL, DRAFT_RFQ_SUPPORTING_FILE } from '@/shared/consts/slugs';
import accessDragData from '@/app-buyer/mixins/drag-drop-access';
import {
  APPEND_FILE,
  RFQ_MODULE,
  SWAP_SUPPORTING_FILES,
} from '@/app-buyer/store/modules/rfq/types';
import rfqService from '@/app-buyer/api/services/rfq';
import { DELETE } from '@/app-buyer/store/modules/types';

export default {
  name: 'PartSupportingDropArea',

  mixins: [accessDragData],

  props: {
    part: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      dragover: false,
      dragoverFile: null,
    };
  },

  computed: {
    isSelfDragged() {
      return this._dragData?.ownerHash === this.part.hash
        || this._dragData?.hash === this.part.hash;
    },

    isDraggingSupportingFile() {
      return this._dragType === DRAFT_RFQ_SUPPORTING_FILE;
    },

    isDraggingModel() {
      return this._dragType === DRAFT_RFQ_MODEL;
    },

    isDraggingValid() {
      return !this.isSelfDragged && (this.isDraggingModel || this.isDraggingSupportingFile);
    },
  },

  methods: {
    ...mapActions(RFQ_MODULE, {
      APPEND_FILE,
      SWAP_SUPPORTING_FILES,
      DELETE,
    }),

    handleDragOver() {
      this.dragover = true;
      if (this._dragData?.file) {
        this.dragoverFile = this._dragData.file;
      }
    },

    handleDragEnd() {
      this.dragover = false;
      this.dragoverFile = null;
    },

    async handleDrop(event) {
      const dragData = this._dragData;
      if (this.isDraggingSupportingFile && this.dragoverFile) {
        event.stopPropagation();
        this[SWAP_SUPPORTING_FILES]({
          file: this.dragoverFile,
          originalOwner: dragData.ownerHash,
          newOwner: this.part.hash,
        });
        this._removeDragData();
        this.dragoverFile = null;
      } else if (this.isDraggingModel) {
        event.stopPropagation();
        const modelFile = dragData.uploads.find((e) => e.type?.slug === DRAFT_RFQ_MODEL);
        try {
          await rfqService.attachModelFileAsSupportingFile(modelFile, this.part.hash);
          this[DELETE](dragData);
          modelFile.type.slug = DRAFT_RFQ_SUPPORTING_FILE;
          this.part.uploads.push(modelFile);
          this._removeDragData();
        } catch {
          this.$buefy.toast.open({
            message: 'Couldn\'t attach file as supporting file!',
            type: 'is-danger',
          });
        }
      } else if (event.dataTransfer.files.length && !event._handledByComponent) {
        event.stopPropagation();
        this.$emit('upload', event.dataTransfer.files);
        event.dataTransfer.clearData();
        // eslint-disable-next-line no-param-reassign
        event._handledByComponent = true;
      }
    },
  },
};
</script>
<style
  lang="scss"
  scoped
>
.supporting-drop-area {
  height: calc(100% + 1.5rem);
  top: -0.75rem;
  left: 0;
  width: 170px;
  border: 2px dashed #dbdbdb;
  border-top-right-radius: 5px;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: bold;
  z-index: 2;

  &.dragover {
    border-color: white;
    background-color: rgba(0, 0, 0, 0.5);
  }
}

::v-deep {

}
</style>
