<template>
  <tippy>
    <template #trigger>
      <div
        class="feedback-image"
        :style="topImages.length === 2 && 'height: 52px'"
      >
        <figure
          v-for="(image, index) in topImages"
          :key="image"
          class="image"
          :style="topImages.length === 1
            ? 'width: 100%; height: 100%;'
            : 'width: 48px; height: 48px; margin: 1px;'"
        >
          <img
            :src="image"
            alt=""
          >
          <div
            v-if="count && index === topImages.length - 1"
            class="is-absolute is-size-7"
          >
            +{{ count }}
          </div>
        </figure>
      </div>
    </template>
    <div class="is-flex is-flex-wrap-wrap p-3 is-justify-content-space-between">
      <figure
        v-for="image in allImages"
        :key="image.hash"
        class="image is-64x64 m-1 mb-4"
      >
        <img
          :src="image.path"
          :alt="`Image of ${image.name}`"
        >
        <figcaption>{{ image.name }}</figcaption>
      </figure>
    </div>
  </tippy>
</template>

<script>
export default {
  name: 'FeedbackOrderImage',
  props: {
    rfqs: {
      type: Array,
      required: true,
    },
  },
  computed: {
    topImages() {
      return this.rfqs.slice(0, 4).map((rfq) => rfq.uploads.find((e) => e.type?.slug === 'rfq-model-preview')?.url);
    },
    allImages() {
      return this.rfqs.map((rfq) => ({
        hash: rfq.hash,
        name: rfq.name,
        path: rfq.uploads.find((e) => e.type?.slug === 'rfq-model-preview')?.url,
      }));
    },
    count() {
      return this.rfqs.length - this.topImages.length;
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
$border: #ffc906;

.feedback-image {
  border-radius: 5px;
  height: 100px;
  position: relative;
  width: 100px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  figure.image {
    border-radius: inherit;
    overflow: hidden;
    flex-grow: 1;
    position: relative;

    .is-absolute {
      align-items: center;
      display: flex;
      justify-content: center;
      background-color: rgba(255, 255, 255, .5);
      inset: 0;
    }
  }
}
</style>
