<template>
  <b-modal
    :active="active"
    has-modal-card
    @update:active="$emit('update:active', $event)"
  >
    <div
      class="card has-background-v2-neutral-4 p-5"
      style="max-width: 400px;"
    >
      <header class="is-flex is-justify-content-flex-end">
        <button
          class="button-no-style"
          @click="$emit('update:active', false)"
        >
          <b-icon icon="times" />
        </button>
      </header>
      <form @submit.prevent="submit">
        <p
          v-if="addressType || data && data.address_type"
          class="title is-6"
        >
          Add your {{ addressType || data && data.address_type }} address
        </p>
        <b-field label="Postcode *">
          <b-autocomplete
            v-model="editableObject.post_code"
            data-testid="post_code-input"
            :data="suggestions"
            placeholder="Postcode"
            required
            @input="search"
            @select="applySuggestion"
          />
        </b-field>
        <b-field label="Address line *">
          <b-autocomplete
            v-model="editableObject.street_address"
            data-testid="street_address-input"
            :data="suggestions"
            placeholder="Street address"
            required
            @input="search"
            @select="applySuggestion"
          />
        </b-field>
        <b-field label="Town *">
          <b-input
            v-model="editableObject.town"
            data-testid="town-input"
            placeholder="Town"
            required
          />
        </b-field>
        <div class="is-flex">
          <b-field
            class="mr-2"
            label="County *"
          >
            <b-input
              v-model="editableObject.county"
              data-testid="county-input"
              placeholder="County"
              required
            />
          </b-field>
          <b-field
            class="ml-2"
            label="Country *"
          >
            <b-input
              v-model="editableObject.country"
              data-testid="country-input"
              placeholder="Country"
              required
            />
          </b-field>
        </div>
        <div class="is-flex is-justify-content-center mt-4">
          <gm-button
            :loading="loading"
            has-shadow
            native-type="submit"
            type="v2-supporting-1 px-6"
            data-testid="address-submit"
          >
            <b>
              Add
            </b>
          </gm-button>
        </div>
      </form>
    </div>
  </b-modal>
</template>

<script>
/* eslint-disable camelcase */

import debounce from 'lodash/debounce';
import Api from '@/app-buyer/api/api';
import ENDPOINTS from '@/app-buyer/api/endpoints';

export default {
  name: 'UserAddressModal',
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    active: Boolean,
    addressType: {
      type: String,
      default: () => '',
    },
  },
  data() {
    return {
      loading: false,
      editableObject: null,
      rawSuggestions: [],
    };
  },
  computed: {
    suggestions() {
      return this.rawSuggestions.map((e) => e.title);
    },
  },
  watch: {
    data: {
      handler(val) {
        const {
          street_address, town, post_code, country, county,
        } = (val || {});
        this.editableObject = {
          street_address, town, post_code, country, county,
        };
      },
      immediate: true,
    },
  },
  methods: {
    async submit() {
      this.loading = true;
      const asyncUpdate = new Promise((resolve, reject) => {
        this.$emit('update', { data: this.editableObject, resolve, reject });
      });
      await asyncUpdate;
      this.loading = false;
      this.$emit('update:active', false);
    },
    search(search) {
      if (search.length > 2) {
        this.getSuggestions(search, this);
      }
    },
    getSuggestions: debounce((search, vm) => {
      if (search) {
        const params = { q: search };
        Api.get(ENDPOINTS.PROFILE.SUGGEST, { params })
          .then((result) => {
            // eslint-disable-next-line no-param-reassign
            vm.rawSuggestions = result.data.values;
          })
          .catch(() => {
            // eslint-disable-next-line no-param-reassign
            vm.rawSuggestions = [];
          });
      } else {
        // eslint-disable-next-line no-param-reassign
        vm.rawSuggestions = [];
      }
    }, 500),
    async applySuggestion(selected) {
      const idx = this.suggestions.indexOf(selected);
      if (idx === -1) return;
      const { id } = this.rawSuggestions[idx];
      const { data, status } = await Api.get(ENDPOINTS.PROFILE.DETAIL, { params: { id } })
        .catch((e) => e);
      if (status < 300) {
        const street_address = `${data.values.street_number ? `${data.values.street_number.long} ` : ''}${data.values.route ? data.values.route.long : ''}`;
        let town;
        if (Object.prototype.hasOwnProperty.call(data.values, 'postal_town')) {
          town = data.values.postal_town.long;
        } else {
          town = Object.prototype.hasOwnProperty.call(data.values, 'locality')
            ? data.values.locality.long : '';
        }
        let post_code;
        if (Object.prototype.hasOwnProperty.call(data.values, 'postal_code')) {
          post_code = data.values.postal_code.long;
        } else {
          post_code = Object.prototype.hasOwnProperty.call(data.values, 'postal_code_prefix')
            ? data.values.postal_code_prefix.long : null;
        }
        let county;
        if (Object.prototype.hasOwnProperty.call(data.values, 'administrative_area_level_2')) {
          county = data.values.administrative_area_level_2.long;
        } else {
          county = Object.prototype.hasOwnProperty.call(data.values, 'administrative_area_level_1')
            ? data.values.administrative_area_level_1.long
            : null;
        }
        const country = Object.prototype.hasOwnProperty.call(data.values, 'country') ? data.values.country.short : null;
        this.editableObject = {
          street_address,
          town,
          post_code,
          county,
          country,
        };
      }
    },
  },
};
</script>

<style scoped>

</style>
