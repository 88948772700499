<template>
  <section class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-head-title">
        <strong>Cancel {{ cancelLabel }}</strong>
      </p>
    </header>
    <section class="modal-card-body">
      <div class="mb-4 is-size-7/6">
        <p>
          Please select an option below as to why you would like to cancel {{
            cancelLabel === 'group' ? 'this group' : cancelLabel }}:
        </p>
      </div>
      <b-field
        v-for="option in cancelOptions"
        :key="option.val"
        class="text-align-left"
      >
        <b-radio
          v-model="cancelReason"
          data-testid="quote-cancel-request-reason"
          :native-value="option"
          size="is-small"
        >
          {{ option.val }}
        </b-radio>
      </b-field>
      <b-field
        v-show="cancelReason && cancelReason.hasExplanation"
        label="Please tell us what could be better"
      >
        <b-input
          v-model="explanation"
          size="is-small"
          has-counter
          placeholder="Please tell us what could be better"
          :maxlength="200"
          type="textarea"
        />
      </b-field>
    </section>
    <footer class="modal-card-foot is-flex is-justify-space-between">
      <gm-button
        class="m-r-xs"
        fullwidth
        type="white"
        @click="$emit('close')"
      >
        Back
      </gm-button>
      <gm-button
        :disabled="!cancelReason
          || cancelReason.hasExplanation
          && cancelReason.explanationRequired
          && !explanation"
        fullwidth
        type="v2-supporting-1"
        :loading="loading"
        data-testid="rfq-cancel-request-btn"
        @click="$emit('cancel', cancelReason, explanation)"
      >
        Cancel request
      </gm-button>
    </footer>
  </section>
</template>
<script>
import GmButton from '@/shared/components/gm-button/gm-button.vue';

export default {
  name: 'CancelModal',

  components: { GmButton },

  props: {
    loading: Boolean,
    cancelLabel: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      cancelReason: null,
      explanation: '',
      cancelling: false,
      showTextArea: false,
      cancelOptions: [
        {
          val: "I've ordered elsewhere",
        },
        {
          val: "I'm revising my design",
        },
        {
          val: 'The platform was confusing',
          hasExplanation: true,
          explanationRequired: true,
        },
        {
          val: 'Other',
          hasExplanation: true,
        },
      ],
    };
  },
};
</script>
