<template>
  <tippy
    :key="user.id"
    :content="hoverText"
    placement="right"
    arrow
    boundary="window"
  >
    <template #trigger>
      <div
        :style="`background-color: ${avatarColour}`"
        :class="{'pending': user.pending}"
        class="user-avatar"
        data-testid="user-avatar"
      >
        <span class="initials">{{ initials }}</span>
      </div>
    </template>
  </tippy>
</template>

<script>

import { avatarHueMixin, initialsMixin } from '@/app-buyer/components/teams/team-mixins';

export default {
  name: 'MemberAvatar',
  mixins: [avatarHueMixin, initialsMixin],
  props: {
    user: {
      required: true,
      type: Object,
    },
  },
  computed: {
    avatarColour() {
      const saturation = this.user.pending
        ? '0%'
        : '50%';
      const lightness = this.user.pending
        ? '70%'
        : '50%';
      return `hsl(${this.hue}, ${saturation}, ${lightness})`;
    },
    hoverText() {
      return this.user.pending ? `${this.user.email} (Pending)` : this.user.name;
    },
  },
};
</script>

<style lang="scss" scoped>
@use 'sass:math';

$avatar-size: 25px;

.user-avatar {
  border-radius: $avatar-size;
  height: $avatar-size;
  width: $avatar-size;
  color: white;
  position: relative;
  transform: translate(-1px, -1px);
  overflow: visible;
  margin-left: - math.div($avatar-size, 5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.initials {
  font-size: 10px;
  font-weight: 500;
}

.pending {
  z-index: 0;
}

.user-avatar {
  z-index: 1;
}
</style>
