<template>
  <div>
    <div class="is-flex is-justify-space-between is-align-items-center">
      <p class="has-text-weight-bold mt-1">
        Supplier {{ index + 1 }}
      </p>
      <span
        v-if="data.flair && showFlair"
        class="flair tag has-font-weight-medium"
        style="background-color: rgb(51, 141, 188); color: white; text-transform: capitalize;"
      >{{ data.flair === 'best-price' ? 'Best price' : data.flair }}</span>
    </div>
    <GroupedQuoteRating
      :rating="data.overall_rating"
      :total-jobs="data.completed_jobs_count"
      :customer-rating="data.customer_rating"
      :customer-orders="data.customer_jobs_count"
      class="mb-2"
    />
    <div class="is-flex is-justify-space-between">
      <p>
        Total price<template v-if="allRfqsLength > data.quotes.length">
          ({{ data.quotes.length }} / {{ allRfqsLength }})
        </template>:
      </p>
      <p class="has-text-weight-bold">
        {{ data.subtotal | formatWithCurrency }}
      </p>
    </div>
    <div class="is-flex is-justify-space-between">
      <p>Shipped in:</p>
      <p class="has-text-weight-bold">
        {{ data.manufacturing_time }} days
      </p>
    </div>
    <div
      v-if="isGQuote"
      class="is-flex is-align-items-center mt-1"
      style="height: 1.1rem;"
    >
      <span class="is-flex is-align-items-center is-size-7 has-text-weight-bold">
        Who is this supplier
      </span>
      <tippy
        arrow
        placement="bottom"
      >
        <template #trigger>
          <BIcon
            class="is-size-7"
            icon="question-circle"
          />
        </template>
        Our system has quoted this based on data of jobs like this.
        We will endeavour to fill this this order with the best suited
        supplier for the job.
      </tippy>
    </div>
  </div>
</template>

<script>
import GroupedQuoteRating from '@/app-buyer/components/grouped-quotes/GroupedQuoteRating.vue';

export default {
  name: 'GroupedQuoteHeaderBody',
  components: {
    GroupedQuoteRating,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    allRfqsLength: {
      type: Number,
      default: 0,
    },
    showFlair: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    isGQuote() {
      return this.data.vendorReference === 'gquote';
    },
  },
};
</script>

<style lang="scss" scoped>
.flair {
  position: absolute;
  top: -1rem;
  right: -5px;
  border-bottom-right-radius: 0;
  padding: .5rem .75rem;

  &::after {
    content: "";
    width: 0;
    height: 0;
    position: absolute;
    bottom: -5px;
    right: 0;
    border: 2.5px solid variables.$black;
    border-bottom-color: transparent;
    border-right-color: transparent;
  }
}
</style>
