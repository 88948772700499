<template>
  <div>
    <input
      v-show="false"
      ref="supporting-file-input"
      type="file"
      @input="uploadFile"
    >
    <GmButton
      :loading="loading"
      class="py-1 is-justify-content-center"
      fullwidth
      outlined
      rounded
      size="small"
      style="font-size: 13px; white-space: nowrap;"
      type="danger"
      @click="$refs['supporting-file-input'].click()"
    >
      {{ btnText }}
    </GmButton>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { RFQ_MODULE, UPDATE_DRAFT } from '@/app-buyer/store/modules/rfq/types';

export default {
  name: 'PartFileUpload',

  props: {
    part: {
      type: Object,
      required: true,
    },
    btnText: {
      type: String,
      default: 'Update file',
    },
  },

  data() {
    return {
      loading: false,
    };
  },

  methods: {
    ...mapActions(RFQ_MODULE, {
      UPDATE_DRAFT,
    }),

    uploadFile(event) {
      const modelFile = event?.target?.files?.[0];
      if (!modelFile) return;
      const properties = { ...this.part.configuration };
      properties['file-type'] = this.part?.configuration?.['file-type'];
      if (properties['file-type'] === 9) {
        properties.project_hash = this.part.project_hash;
        properties.name = modelFile.name;
        this[UPDATE_DRAFT]({
          draft: this.part,
          properties,
          files: {
            modelFile,
            supportingFiles: this.part?.uploads,
          },
          immediate: true,
        });
      } else if (properties['file-type']) {
        properties.project_hash = this.part.project_hash;
        properties.name = modelFile.name;
        this[UPDATE_DRAFT]({
          draft: this.part,
          properties,
          files: {
            modelFile,
          },
          immediate: true,
        });
      }
    },
  },
};
</script>

<style scoped>

</style>
