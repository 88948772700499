<template>
  <div>
    <validation-observer
      ref="observer"
      v-slot="{ passes }"
    >
      <div
        class="editable-card card"
        data-testid="personal-information"
        @click.prevent.stop
      >
        <section
          v-show="!editing"
          class="card-content is-flex is-flex-direction-column"
        >
          <p class="subtitle is-7">
            <span>Name</span><span data-testid="name">{{ personalData.name }}</span>
          </p>
          <p class="subtitle is-7">
            <span>Phone</span><span data-testid="phone">{{ personalData.contact_number }}</span>
          </p>
          <p class="subtitle is-7">
            <span>Email</span><span data-testid="email">{{ personalData.email }}</span>
          </p>
          <div class="is-flex-grow-1 is-flex is-flex-direction-column is-justify-flex-end">
            <b-button
              expanded
              type="is-info"
              size="is-small"
              data-testid="change_password_button"
              @click="showPasswordReset = true"
            >
              Change password
            </b-button>
          </div>
        </section>
        <section
          v-show="editing"
          class="card-content"
          data-testid="personal-information-edit"
        >
          <b-input-with-validator
            v-model="editableData.name"
            label-position="on-border"
            rules="required"
            size="is-small"
            vid="name"
            type="text"
            label="Name"
            placeholder="Name"
            data-testid="name_edit"
          />
          <b-input-with-validator
            v-model="editableData.contact_number"
            label-position="on-border"
            rules="required"
            size="is-small"
            vid="phone"
            type="text"
            label="phone"
            placeholder="Phone"
            data-testid="phone_edit"
          />
          <b-input-with-validator
            v-model="editableData.email"
            label-position="on-border"
            rules="required|email"
            size="is-small"
            vid="email"
            type="text"
            label="email"
            placeholder="Email"
            data-testid="email_edit"
          />
        </section>
        <footer class="card-footer">
          <a
            v-show="!editing"
            class="card-footer-item"
            data-testid="personal-edit-button"
            @click.stop.prevent="startEdit"
          >Edit</a>
          <a
            v-show="editing"
            class="card-footer-item"
            data-testid="personal-save-button"
            @click.stop.prevent="passes(save)"
          >Save</a>
          <a
            v-show="editing"
            class="card-footer-item"
            @click.stop.prevent="stopEdit"
          >Cancel</a>
        </footer>
      </div>
    </validation-observer>
    <b-modal
      :active.sync="showPasswordReset"
      has-modal-card
    >
      <password-change-form
        with-current
        @success="showPasswordReset = false"
      />
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { ValidationObserver } from 'vee-validate';
import { GET_PERSONAL_DATA, USER_DATA, USER_MODULE } from '../../store/modules/user/types';
import { UPDATE } from '../../store/modules/types';
import PasswordChangeForm from '../password-reset/PasswordChangeForm.vue';

export default {
  name: 'UserProfilePersonalInformation',
  components: {
    PasswordChangeForm,
    ValidationObserver,
  },
  data() {
    return {
      editing: false,
      editableData: {},
      showPasswordReset: false,
    };
  },
  computed: {
    ...mapGetters(USER_MODULE, {
      personalData: GET_PERSONAL_DATA,
    }),
    ...mapState(USER_MODULE, {
      userData: USER_DATA,
    }),
  },
  methods: {
    ...mapActions(USER_MODULE, {
      updateUserData: UPDATE,
    }),
    startEdit() {
      this.editableData = JSON.parse(JSON.stringify(this.personalData));
      this.editing = true;
    },
    stopEdit() {
      this.editing = false;
    },
    async save() {
      try {
        const copy = JSON.parse(JSON.stringify(this.userData));
        copy.user = { ...copy.user, ...this.editableData };
        this.updateUserData(copy)
          .then(() => {
            this.editing = false;
          })
          .catch((e) => console.error(e));
      } catch (e) {
        console.error('invalid user data');
      }
    },
  },
};
</script>

<style scoped>

</style>
