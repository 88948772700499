export const getProperty = (object, keys) => {
  if (!object || !keys) return null;
  const keyArray = Array.isArray(keys) ? keys : keys.split('.');
  return keyArray.reduce((res, curr) => {
    if (!res) return null;
    if (res[curr]) {
      return res[curr];
    }
    return null;
  }, object);
};

const getPropertyMixin = {
  methods: {
    getProperty,
  },
};

export default getPropertyMixin;
