import Api from '@/app-buyer/api/api';
import ENDPOINTS from '@/app-buyer/api/endpoints';
import {
  DRAFT_RFQ_MODEL,
  DRAFT_RFQ_SUPPORTING_FILE,
} from '@/shared/consts/slugs';

const attachModelFileAsSupportingFile = (modelFile, attachToHash) => Api.put(
  ENDPOINTS.UPLOADS.DETAIL,
  { type: DRAFT_RFQ_SUPPORTING_FILE },
  { __pathParams: { id: modelFile.id } },
).then(() => {
  Api.put(ENDPOINTS.DRAFT_RFQS.MANAGE_UPLOADED,
    { attach: modelFile.id },
    {
      __pathParams: {
        hash: attachToHash,
      },
    });
});

const attachSupportingFileAsModelFile = (supportingFile, attachToHash) => Api.put(
  ENDPOINTS.UPLOADS.DETAIL,
  { type: DRAFT_RFQ_MODEL },
  { __pathParams: { id: supportingFile.id } },
).then(() => Api.put(ENDPOINTS.DRAFT_RFQS.MANAGE_UPLOADED,
  { attach: supportingFile.id },
  {
    __pathParams: {
      hash: attachToHash,
    },
  }));

const rfqService = {
  attachModelFileAsSupportingFile,
  attachSupportingFileAsModelFile,
};

export default rfqService;
