export const CART = 'CART';
export const PAYMENT_PROGRESS = 'PAYMENT_PROGRESS';
export const PAYMENT_PROGRESS_PAID = 'PAYMENT_PROGRESS_PAID';
export const GENERATE_PROFORMA = 'GENERATE_PROFORMA';
export const TOTAL_AMOUNT = 'TOTAL_AMOUNT';
export const TAX_AMOUNT = 'TAX_AMOUNT';
export const SUBTOTAL = 'SUBTOTAL';
export const SHIPPING = 'SHIPPING';
export const ITEMS_TOTAL = 'ITEMS_TOTAL';
export const CREDIT = 'CREDIT';
export const SETUP_STRIPE = 'SETUP_STRIPE';
export const PAY = 'PAY';
export const DISCOUNT_VALUE = 'DISCOUNT_VALUE';
export const DISCOUNT_CODE_NAME = 'DISCOUNT_CODE_NAME';
export const PAY_VIA_CREDIT = 'PAY_VIA_CREDIT';
export const CONFIRM_PAYMENT = 'CONFIRM_PAYMENT';
export const SET_PAYMENT_PROGRESS = 'SET_PAYMENT_PROGRESS';
export const FETCH_PAYMENT_METHODS = 'FETCH_PAYMENT_METHODS';
export const REMOVE_PAYMENT_METHOD = 'REMOVE_PAYMENT_METHOD';
export const SET_SAVE_CARD = 'SET_SAVE_CARD';
export const SET_PAYMENT_METHOD = 'SET_PAYMENT_METHOD';
export const SET_ORDER_REFERENCE_NUMBER = 'SET_ORDER_REFERENCE_NUMBER';
export const SET_ORDER_REFERENCE_FILE = 'SET_ORDER_REFERENCE_FILE';
export const SET_PAYMENT_METHODS = 'SET_PAYMENT_METHODS';
export const PAYMENT_METHOD = 'PAYMENT_METHOD';
export const ORDER_REFERENCE_NUMBER = 'ORDER_REFERENCE_NUMBER';
export const ORDER_REFERENCE_FILE = 'ORDER_REFERENCE_FILE';
export const PAYMENT_METHODS = 'PAYMENT_METHODS';
export const STRIPE = 'STRIPE';
export const SAVE_CARD = 'SAVE_CARD';
export const SET_STRIPE = 'SET_STRIPE';
export const GENERATE_FORMAL_QUOTE = 'GENERATE_FORMAL_QUOTE';
export const CART_DATA = 'CART_DATA';
export const CART_MODULE = 'CART_MODULE';
