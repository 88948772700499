<template>
  <div
    class="visible-on-dragover flex is-align-items-center
    is-justify-content-center part-table-drop-area"
    @drop.prevent="handleDrop"
    @dragenter.prevent
    @dragleave.prevent
    @dragover.prevent
  >
    <p class="has-text-weight-bold has-text-white is-size-5 has-text-centered">
      Drop your <br> model files here
    </p>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import baseUpload from '@/app-buyer/mixins/base-upload';
import accessDragData from '@/app-buyer/mixins/drag-drop-access';
import { DRAFT_RFQ_SUPPORTING_FILE } from '@/shared/consts/slugs';
import {
  REFERENCE_DATA_OBJECT_BY_SLUG,
  REFERENCE_MODULE,
} from '@/app-buyer/store/modules/reference-data/types';
import { PROJECT_MODULE, ACTIVE_PROJECT } from '@/app-buyer/store/modules/projects/types';
import { CREATE_DRAFT_RFQ, RFQ_MODULE, DRAFT_RFQS } from '@/app-buyer/store/modules/rfq/types';
import rfqService from '@/app-buyer/api/services/rfq';

export default {
  name: 'PartTableDropArea',

  mixins: [baseUpload, accessDragData],

  computed: {
    ...mapState(RFQ_MODULE, {
      DRAFT_RFQS,
    }),

    ...mapGetters(PROJECT_MODULE, {
      ACTIVE_PROJECT,
    }),

    ...mapGetters(REFERENCE_MODULE, {
      REFERENCE_DATA_OBJECT_BY_SLUG,
    }),
  },

  methods: {
    ...mapActions(RFQ_MODULE, {
      CREATE_DRAFT_RFQ,
    }),

    async createDraftFromSupporting({ file, ownerHash }) {
      const fileTypeId = this[REFERENCE_DATA_OBJECT_BY_SLUG][file.extension]?.id;
      const service = this._preSelectServiceByFileType(file.extension);
      const preset = this._findPreset({ service }, {});

      const originalDraft = this[DRAFT_RFQS].find((d) => d.hash === ownerHash);
      if (originalDraft) {
        originalDraft.uploads = originalDraft.uploads.filter((u) => u.id !== file.id);
      }

      const properties = {
        ...preset,
        ...{
          'file-type': fileTypeId,
          project_hash: this[ACTIVE_PROJECT]?.hash,
          name: file.client_original_name,
        },
      };

      const { model } = await this[CREATE_DRAFT_RFQ]({ properties, isDragged: true });

      if (model) {
        try {
          const { data } = await rfqService.attachSupportingFileAsModelFile(file, model.hash);
          data.forEach((newFile) => {
            model.uploads.push(newFile);
          });
        } catch {
          originalDraft.uploads.push(file);
        }
      }
    },

    handleDrop(event) {
      if (event._handledByComponent) return;
      if (this._dragType === DRAFT_RFQ_SUPPORTING_FILE) {
        this.createDraftFromSupporting(this._dragData);
      } else {
        const files = [].slice.apply(event.dataTransfer.files);
        if (files && files.length) {
          this.createQuotes(files, true);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.part-table-drop-area {
  position: fixed;
  z-index: 10;
  top: 4rem;
  left: 6rem;
  bottom: 0;
  max-height: 92%;
  background-color: rgba(0,0,0,0.5);
  width: 88%;
  border: 2px dashed white;
  border-radius: 5px;
}

@media (min-width: 1150px) {
  .part-table-drop-area {
    width: 35%;
  }
}
</style>
