import { ACCESS_TOKEN, SET_ACCESS_TOKEN } from './types';
import { RESET_STATE } from '../types';
import getState from './state';

export default {
  /**
     * Preserves the access token in the state
     *
     * @param state
     * @param token
     * */
  [SET_ACCESS_TOKEN](state, token) {
    state[ACCESS_TOKEN] = token;
  },
  // eslint-disable-next-line no-unused-vars
  [RESET_STATE](state) {
    // eslint-disable-next-line no-param-reassign
    state = Object.assign(state, getState());
  },
};
