<template>
  <div
    :class="{'is-selected': isSelected }"
    class="revise-requote-card is-flex is-flex-direction-column p-4 has-cursor-pointer "
    @click="handleSelectSingle"
    @mouseenter="isHovered = true"
    @mouseleave="isHovered = false"
  >
    <!--    <ReviseRequoteUploadFile-->
    <!--      :rfq-hash="part.hash"-->
    <!--      class="upload-btn"-->
    <!--      :part="part"-->
    <!--    />-->
    <div class="is-flex is-align-items-flex-start">
      <PartHandler
        :is-column="true"
        :is-selected.sync="isSelected"
        :part="part"
      />
      <div class="desc-wrapper pl-3">
        <div class="file-name-wrapper is-flex pb-3">
          <p class="file-name-text">
            {{ modelFileName }}
          </p>
          <div class="" />
        </div>
        <div class="grid-row">
          <p class="bold">
            Service:
          </p>
          <p>{{ part.configuration_object.service.string_value }}</p>
        </div>
        <div class="grid-row">
          <p class="bold">
            Quantity:
          </p>
          <p>{{ quantity }}</p>
        </div>
        <div class="grid-row">
          <p class="bold">
            Material:
          </p>
          <p>{{ part.configuration_object.material.string_value }}</p>
        </div>
        <div class="grid-row">
          <p class="bold">
            Shipping in:
          </p>
          <p>{{ part.configuration_object['manufacturing-time'].string_value }}</p>
        </div>
        <div class="grid-row">
          <p class="bold">
            Supporting files:
          </p>
          <div class="is-flex supporting-file-column">
            <ReviseRequoteUploadFile
              v-show="!hasSupportingFiles"
              ref="requoteUpload"
              :is-model-file="false"
              :part="part"
              :rfq-hash="part.hash"
              class="no-select"
            />
            <PartSupportingFiles
              v-if="hasSupportingFiles"
              :is-draggable="false"
              :is-project-page="false"
              :part="part"
              :show-hover="false"
              :supporting-files="supportingFiles"
              @add-more-files="$refs['supporting-file-input'].click()"
            />
            <input
              v-show="false"
              ref="supporting-file-input"
              :accept="acceptableSupportingFiles"
              multiple
              type="file"
              @input="uploadSupportingFiles($event.target.files)"
            >
          </div>
        </div>
      </div>
    </div>
    <PartErrorMessage
      v-for="(issue, i) in issueMessages"
      :key="i"
      :issue="issue"
      :part="part"
      :show-cta="false"
    />
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex';
import PartHandler from '@/app-buyer/components/project/PartHandler.vue';
import ReviseRequoteUploadFile
  from '@/app-buyer/components/revise-requote/ReviseRequoteUploadFile.vue';
import PartErrorMessage from '@/app-buyer/components/project/PartErrorMessage.vue';
import PartSupportingFiles from '@/app-buyer/components/project/PartSupportingFiles.vue';
import {
  REVISE_REQUOTE_CONFIG, REVISE_REQUOTE_ISSUES, REVISE_REQUOTE_MODAL_RFQS, RFQ_MODULE,
  SET_REVISE_REQUOTE_MODAL_RFQS,
} from '@/app-buyer/store/modules/rfq/types';
import {
  REFERENCE_DATA_OBJECT_BY_ID, REFERENCE_MODULE,
} from '@/app-buyer/store/modules/reference-data/types';
import { getAcceptableUploads } from '@/app-buyer/components/project/mixins';

export default {
  name: 'ReviseRequoteRfqCard',
  components: {
    ReviseRequoteUploadFile,
    PartHandler,
    PartErrorMessage,
    PartSupportingFiles,
  },

  mixins: [getAcceptableUploads],

  props: {
    part: {
      type: Object,
      required: true,
    },
    selectedRfqs: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      isHovered: false,
    };
  },

  computed: {
    ...mapState(RFQ_MODULE, {
      REVISE_REQUOTE_CONFIG,
      REVISE_REQUOTE_MODAL_RFQS,
    }),

    ...mapGetters(REFERENCE_MODULE, {
      REFERENCE_DATA_OBJECT_BY_ID,
    }),

    ...mapGetters(RFQ_MODULE, {
      REVISE_REQUOTE_ISSUES,
    }),

    hasSupportingFiles() {
      const reviseRequoteConfigFiles = this[REVISE_REQUOTE_CONFIG]?.find((c) => c.hash === this.part.hash)?.files;
      return reviseRequoteConfigFiles?.hasOwnProperty('supportingFiles');
    },

    supportingFiles() {
      const reviseRequoteConfigFiles = this[REVISE_REQUOTE_CONFIG]?.find((c) => c.hash === this.part.hash)?.files;
      return reviseRequoteConfigFiles?.supportingFiles ?? [];
    },

    existingConfig() {
      const config = this[REVISE_REQUOTE_CONFIG].filter((c) => c.hash === this.part.hash);

      if (config.length) return config[0];
      return null;
    },

    quantity() {
      if (this.existingConfig) {
        const string = this.existingConfig?.properties?.quantity_initial;

        if (string) return string;
      }
      return this.part.quantity_initial;
    },

    modelFileName() {
      if (this.existingConfig?.files?.modelFile) return this.existingConfig?.properties?.name;
      return this.part.name;
    },

    issueMessages() {
      if (this[REVISE_REQUOTE_ISSUES][this.part.hash]?.length && Object.keys(this.part).length) {
        const issueMessages = this[REVISE_REQUOTE_ISSUES][this.part?.hash].filter((issue) => issue.type !== 'parsing');
        if (issueMessages.length) return issueMessages;
      }
      return [];
    },

    isSelected: {
      get() {
        return this.selectedRfqs.some((sRfq) => sRfq.hash === this.part.hash);
      },
      set(value) {
        this.handleIsSelected(value);
        return value;
      },
    },
  },

  methods: {
    ...mapMutations(RFQ_MODULE, {
      SET_REVISE_REQUOTE_MODAL_RFQS,
    }),

    handleIsSelected(v) {
      if (v) {
        const selected = [
          ...this[REVISE_REQUOTE_MODAL_RFQS]?.selectedRfqs,
          this.part.hash,
        ];

        this[SET_REVISE_REQUOTE_MODAL_RFQS]({
          ...this[REVISE_REQUOTE_MODAL_RFQS],
          selectedRfqs: selected,
        });
      } else {
        const selected = this[REVISE_REQUOTE_MODAL_RFQS]?.selectedRfqs.filter(
          (sHash) => sHash !== this.part.hash,
        );

        this[SET_REVISE_REQUOTE_MODAL_RFQS]({
          ...this[REVISE_REQUOTE_MODAL_RFQS],
          selectedRfqs: selected,
        });
      }
    },

    handleSelectSingle(e) {
      if (e?.target?.classList.contains('check')
        || e?.target?.classList.contains('checkbox-wrapper')
        || e?.target?.classList.contains('hidden-input')
        || e?.target?.classList.contains('supporting-file-btn')) return;

      this[SET_REVISE_REQUOTE_MODAL_RFQS]({
        ...this[REVISE_REQUOTE_MODAL_RFQS],
        selectedRfqs: [this.part.hash],
      });
    },

    uploadSupportingFiles(e) {
      const files = Array.from(e);
      this.$refs.requoteUpload.handleUpload(files);
    },
  },
};

</script>

<style
  lang="scss"
  scoped
>
.revise-requote-card {
  border-color: variables.$g-light-2;
  border-style: solid;
  border-width: 1px 0 0;

  overflow-x: hidden;
  position: relative;
  transition: opacity .3s ease-in-out;

  &.is-selected {
    background-color: rgba(variables.$info, .2);
    border-color: variables.$info;
  }

  &:hover:not(.is-selected) {
    background-color: variables.$g-light-3;
  }

  .upload-btn {
    position: absolute;
    right: 1rem;
    top: 1rem;
  }

  .desc-wrapper {
    display: flex;
    flex-direction: column;
    font-size: 13px;
    width: 100%;

    .file-name-wrapper {
      pointer-events: none;
      position: relative;
      right: 1.25rem;

      .file-name-text {
        font-size: initial;
      }
    }

    .supporting-file-column {
      flex-direction: column;
    }

    .bold {
      font-weight: 700;
    }

    .grid-row {
      display: grid;
      grid-template-columns: 1fr 3fr;
    }
  }
}
</style>
