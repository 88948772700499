const getValue = (el, keys) => {
  let val = el;
  // eslint-disable-next-line no-restricted-syntax
  for (const key of keys) {
    val = val[key];
    if (val === undefined) {
      break;
    }
  }
  return val || null;
};

export const NUMERIC_SORT = 'NUMERIC_SORT';
export const ALPHABETIC_SORT = 'ALPHABETIC_SORT';
export const MIXED_SORT = 'MIXED_SORT';

export function mixedSort(arr, ...keys) {
  const collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' });
  return arr.slice().sort((a, b) => {
    const aVal = keys?.length ? getValue(a, keys) : a;
    const bVal = keys?.length ? getValue(b, keys) : b;
    return collator.compare(aVal, bVal) * -1;
  });
}

export function numericSort(arr, ...keys) {
  return arr.slice().sort((a, b) => {
    const aVal = keys?.length ? getValue(a, keys) : a;
    const bVal = keys?.length ? getValue(b, keys) : b;
    return +aVal - +bVal;
  });
}

export function alphabeticSort(arr, ...keys) {
  return arr.slice().sort((a, b) => {
    const aVal = keys?.length ? getValue(a, keys) : a;
    const bVal = keys?.length ? getValue(b, keys) : b;
    if (aVal > bVal) {
      return 1;
    }
    return aVal < bVal ? -1 : 0;
  });
}

const sorting = {
  methods: {
    [NUMERIC_SORT]: numericSort,
    [ALPHABETIC_SORT]: alphabeticSort,
    [MIXED_SORT]: mixedSort,
  },
};

export default sorting;
