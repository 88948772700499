<template>
  <BDropdown
    ref="dropdown"
    expanded
  >
    <template v-slot:trigger="{ open }">
      <button
        ref="trigger-button"
        slot="trigger"
        class="button is-small is-fullwidth"
      >
        <p
          :style="!selected && 'opacity: 0.5'"
          class="subtitle is-7 is-flex is-justify-content-space-between is-width-100"
          data-testid="configurator-preselected"
        >
          {{ label }}
          <icon-font
            :style="!open && 'transform: rotate(-90deg)'"
            icon="chevron-down"
            style="font-size: 0.4rem; color: var(--text-light); font-weight: lighter;"
          />
        </p>
      </button>
    </template>
    <BDropdownItem
      v-for="(data, key) in filteredOptions.parents"
      :key="key"
      class="p-0"
      focusable
      custom
    >
      <MultiLayerCollapsableInput
        :data="data"
        :open="false"
        :open-top-level="false"
        :title="key"
        :values="values"
        @input="updateAndClose"
        @set-note="$emit('set-note', $event)"
      />
    </BDropdownItem>
    <BDropdownItem
      v-for="(data, key) in filteredOptions.singles"
      :key="key"
      :value="data.id"
      focusable
      paddingless
      @click="updateAndClose(data.id)"
    >
      <div
        :class="values && values.includes(data.id) ? 'has-background-v2-supporting-7 has-text-white' : 'has-background-white has-text-black-bis'"
        class="px-3 py-1"
        style="border-bottom: 1px solid #dbdbdb"
      >
        <span class="is-size-7">
          {{ data.string_value }}
        </span>
      </div>
    </BDropdownItem>
  </BDropdown>
</template>

<script>
import { formInput } from '@/app-buyer/mixins/form-input';
import MultiLayerCollapsableInput
  from '@/app-buyer/components/configurator/configurator-body/configurator-drafts/configurator-element/multi-layer-collapsable-input.vue';

function setProperty(data, keys, value) {
  if (keys.length > 1) {
    // eslint-disable-next-line no-param-reassign
    if (!data[keys[0]]) data[keys[0]] = { options: [] };
    setProperty(data[keys[0]], keys.slice(1), value);
    return;
  }
  if (data[keys[0]]) {
    data[keys[0]].options.push(value);
  } else {
    // eslint-disable-next-line no-param-reassign
    data[keys[0]] = { options: [value] };
  }
}

export default {
  name: 'ConfiguratorElementCollapsableInput',
  components: { MultiLayerCollapsableInput },
  mixins: [formInput],
  props: {
    placeholder: {
      type: String,
      default: () => null,
    },
    showParent: {
      type: Boolean,
      default: () => true,
    },
  },
  data() {
    return {
      searchInput: '',
      highlightedIndex: 0,
      group: null,
    };
  },
  computed: {
    filteredOptions() {
      const secondaryFinishesToMap = this.properties.filter((prop) => prop.entity_slug === 'secondary-finish')
        .sort((a, b) => a.sequence_number - b.sequence_number);
      if (!secondaryFinishesToMap) {
        return {};
      }
      const secondaryFinishGroups = secondaryFinishesToMap.reduce((mappedProperties, secondaryFinish) => {
        const keys = [];
        const type = secondaryFinish.parents.find((parent) => parent.entity_slug === 'secondary-finish-type');
        if (type) {
          keys.push(type.string_value);
          setProperty(mappedProperties.parents, keys, secondaryFinish);
        } else {
          mappedProperties.singles.push(secondaryFinish);
        }
        return mappedProperties;
      }, { parents: {}, singles: [] });
      // Sort Parents before returning
      const parents = Object.entries(secondaryFinishGroups.parents).sort(([, a], [, b]) => {
        const first = a.options[0].parents.find((parent) => parent.entity_slug === 'secondary-finish-type').sequence_number;
        const second = b.options[0].parents.find((parent) => parent.entity_slug === 'secondary-finish-type').sequence_number;
        return first - second;
      }).reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
      return { ...secondaryFinishGroups, parents };
    },
    prefix() {
      if (!this.selected || typeof this.selected === 'string') return null;
      if (this.filteredOptions?.singles?.find((option) => option.id === this.selected.id)) {
        return null;
      }
      return Object.entries(this.filteredOptions.parents).reduce((res, [key, value]) => {
        if (value?.options?.find((option) => option.id === this.selected.id)) {
          return key;
        }
        return res;
      }, null);
    },
    selected() {
      if (this.values.length > 1) {
        return `Multiple values selected (${this.values.length})`;
      }
      return this.properties.find((prop) => prop.id === this.values[0]);
    },
    label() {
      if (this.selected) {
        if (typeof this.selected === 'string') return this.selected;
        if (this.prefix && this.showParent) {
          return `${this.prefix}: ${this.selected.string_value}`;
        }
        return this.selected.string_value;
      }
      return this.placeholder;
    },
  },
  methods: {
    updateAndClose(event) {
      if (event === this.filteredOptions.singles.find((single) => single.slug === 'secondary-not-required')?.id) {
        this.$nextTick(() => {
          this.$emit('input', event);
        });
        return;
      }
      this.$refs.dropdown.toggle();
      this.$emit('input', event);
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
::v-deep {
  .outside-wrapper {
    background-color: white;
    bottom: 0;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.25);
    left: 0;
    max-height: 20rem;
    overflow-y: auto;
    position: absolute;
    right: 0;
    transform: translateY(100%);
    z-index: 1;
  }

  .title,
  .subtitle {
    margin: 0;
  }

  .title {
    padding: 0.5rem;
  }

  .is-result:active,
  .is-result:focus {
    border-color: #fbad39 !important;
    box-shadow: none !important;
  }

  .is-result p {
    align-items: center;
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }

  .is-highlighted {
    background-color: var(--bg-gray3);
  }

  .is-dropdown-trigger {
    border-bottom-color: transparent !important;
    border-left-color: transparent !important;
    border-radius: 0 !important;
    border-right-color: transparent !important;
    font-weight: bold;
  }
}

</style>
