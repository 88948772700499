<template>
  <FileUpload
    :accept="acceptedFileTypes"
    multiple
    @upload="handleUpload"
  >
    <GmButton
      v-if="isModelFile"
      class="p-0"
      html-type="a"
      is-link
      size="small"
      type="info"
    >
      Change File
    </GmButton>
    <b-button
      v-else
      class="supporting-file-btn"
      icon-left="file-pdf"
      icon-pack="fal"
      outlined
      rounded
      size="small"
      style="font-size: 13px; white-space: nowrap;"
      tag="a"
      type="is-primary"
    >
      Change supporting files
    </b-button>
  </FileUpload>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import FileUpload from '@/app-buyer/components/file-upload/file-upload.vue';
import {
  REVISE_REQUOTE_CONFIG, RFQ_MODULE, SET_REVISE_REQUOTE_CONFIG,
} from '@/app-buyer/store/modules/rfq/types';
import { getAcceptableUploads } from '@/app-buyer/components/project/mixins';

export default {
  name: 'ReviseRequoteUploadFile',
  components: {
    FileUpload,
  },

  mixins: [getAcceptableUploads],

  props: {
    isModelFile: {
      type: Boolean,
      default: true,
    },
    rfqHash: {
      type: String,
      required: true,
    },
  },

  computed: {
    ...mapState(RFQ_MODULE, {
      REVISE_REQUOTE_CONFIG,
    }),

    acceptedFileTypes() {
      if (!this.isModelFile) return this.acceptableSupportingFiles;
      return this.acceptableModelFiles;
    },
  },

  methods: {
    ...mapMutations(RFQ_MODULE, {
      SET_REVISE_REQUOTE_CONFIG,
    }),

    handleUpload(uploadedFile) {
      const uFile = this.isModelFile ? uploadedFile[0] : uploadedFile;
      const existingConfig = this[REVISE_REQUOTE_CONFIG].filter((c) => c.hash === this.rfqHash);
      const files = { [this.isModelFile ? 'modelFile' : 'supportingFiles']: uFile };
      let properties = {};

      if (existingConfig.length) {
        properties = {
          ...existingConfig[0].properties,
        };
      }

      if (this.isModelFile) {
        properties = {
          ...properties,
          name: uFile.name,
          hash: this.rfqHash,
        };
      } else {
        const config = this[REVISE_REQUOTE_CONFIG].filter((c) => c.hash === this.rfqHash);
        if (config[0]?.files?.supportingFiles) files.supportingFiles = [...new Set([...config[0]?.files.supportingFiles, ...files.supportingFiles])];
      }

      this[SET_REVISE_REQUOTE_CONFIG]({
        hash: this.rfqHash,
        files,
        properties,
      });
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
.supporting-file-btn {
  border-color: variables.$v2-supporting-2;
  color: variables.$v2-supporting-2;
  transition: 0.3s ease;

  &:hover {
    background-color: variables.$v2-supporting-2;
    color: variables.$white;
  }
}

::v-deep {
  span {
    pointer-events: none;
  }
}

</style>
