<template>
  <div
    class="mr-2 main-wrapper"
  >
    <button
      class="header-button button-no-style has-text-left is-flex is-flex-direction-column
      group-header has-background-white is-relative is-hidden-mobile"
      :class="{'has-background-hover-g-light-3': active}"
      :style=" active
        ? {
          borderTopLeftRadius: '4px',
          borderTopRightRadius: '4px',
          borderBottomWidth: 0,
          marginBottom: 0,
          height: '100%',
        }
        : {
          borderRadius: '4px',
          marginBottom: '0.5rem',
        }"
      style="border: 1px solid #dbdbdb;"
      @click="$emit('click')"
    >
      <div
        class="p-2"
        style="min-width:240px"
      >
        <GroupedQuoteHeaderBody
          :data="data"
          :index="index"
          :all-rfqs-length="allRfqsLength"
        />
      </div>
    </button>
    <button
      class="button is-expanded mobile-header-button is-hidden-tablet"
      :class="{ active }"
      @click="$emit('click')"
    >
      <div
        class="header-button-content-mobile is-flex is-align-items-center
        is-justify-content-space-around is-flex-direction-column"
      >
        <p
          style="text-transform: capitalize;"
          class="is-size-7 has-text-weight-medium"
        >
          Supplier {{ index + 1 }}
        </p>
        <p class="mobile-flair-text has-text-info text-transform-capitalize">
          {{ data.flair === 'cheapest' ? 'Best price' : data.flair }}
        </p>
      </div>
    </button>
  </div>
</template>

<script>
import GroupedQuoteHeaderBody from '@/app-buyer/components/grouped-quotes/GroupedQuoteHeaderBody.vue';

export default {
  name: 'GroupedQuoteHeaderButton',
  components: {
    GroupedQuoteHeaderBody,
  },
  props: {
    active: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    allRfqsLength: {
      type: Number,
      required: true,
    },
  },
  computed: {
    rating() {
      return this.data.customer_rating || this.data.overall_rating;
    },
    isGQuote() {
      return this.data.reference === 'gquote';
    },
    iconData() {
      return toSvg(this.data.reference, 20);
    },
  },
};
</script>

<style lang="scss" scoped>
@media all and (max-width: variables.$tablet) {
  .main-wrapper.mr-2 {
    margin-right: 0.25rem !important;
  }

  .main-wrapper {
    padding-top: 0.5rem;
    padding-bottom: 0.25rem;
    flex-grow: 1;
    align-items: stretch;

    .mobile-header-button {
      width: 100%;
    }

    .mobile-header-button.active {
      margin-bottom: calc(-0.18rem) !important;
      padding-bottom: 1.18rem !important;
      height: unset !important;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
      border-bottom-width: 0;
    }
    .mobile-flair-text {
      font-size: 0.75rem;
      min-height: 1.2rem;
    }
    .mobile-header-button {
      &.button {
        min-height: 3.4rem;
        height: unset !important;
      }
      &.active {
        margin-bottom: -0.5rem;
      }
    }
  }
}

.grouped-active-mobile-button {
  box-shadow: 0 4px 0 0 #ffc906 inset,
  0 0.5em 1em -0.125em rgb(10 10 10 / 10%),
  0 0px 0 1px rgb(10 10 10 / 2%);
  height: calc(100% + 2px);
  margin-bottom: -2px;
}

@media all and (max-width: 1440px) {
  .header-button {
    .columns {
      display: block;

      .column.is-relative {
        position: unset !important;
        background-color: transparent !important;
      }
    }
  }
}

@media all and (max-width: 600px) {
  .header-button-content-mobile {
    display: block !important;
  }
}
</style>
