<template>
  <nav
    v-click-outside="() => menuOpen = false"
    class="navbar is-relative"
    role="navigation"
    aria-label="main navigation"
    style="z-index: 15;"
  >
    <div class="navbar-brand">
      <a
        href="https://geomiq.com/"
        class="navbar-item is-hidden-touch no-box-shadow"
      >
        <div
          class="large-brand-holder"
        >
          <div
            style="width: var(--main-aside-width);"
            class="is-flex is-justify-center"
          >
            <icon-font
              icon="geomiq-up"
              style="font-size: 1.8rem; color: white; font-weight: 100;"
            />
          </div>
          <icon-font
            icon="geomiq-text"
            style="font-size: 1.5rem; color: white; font-weight: 100; margin-top: 3px;"
          />
        </div>
      </a>
      <a
        role="button"
        class="navbar-burger burger"
        :class="{'is-active' : menuOpen}"
        aria-label="menu"
        aria-expanded="false"
        @click="menuOpen = !menuOpen"
      >
        <span aria-hidden="true" />
        <span aria-hidden="true" />
        <span aria-hidden="true" />
        <gm-tag
          v-if="metadata
            && metadata['cart_transfer_count']"
        >
          {{ metadata['cart_transfer_count'] }}
        </gm-tag>
      </a>
      <span class="is-hidden-desktop is-flex-grow-1 is-flex is-flex-align-centered">
        <span class="is-flex-grow-1 is-flex is-flex-align-centered is-justify-center">
          <icon-font
            icon="geomiq-text"
            style="font-size: 1rem; color: white;"
          />
        </span>
        <span
          class="is-flex-grow-0"
          style="min-width: 3rem;"
        >
          <component
            :is="item.tag || 'a'"
            v-for="item in navbarItems.end"
            v-show="!item.requiresAuth || loggedIn"
            :key="item.name"
            :href="item.link || '#'"
            :target="(!item.tag || item.tag === 'a') && '_blank'"
            :to="item.tag === 'router-link' && item.link"
            class="navbar-item"
            style="background-color: transparent !important;"
          >
            {{ item.name }}
            <gm-tag
              v-if="item.hasCount && metadata && metadata[item.hasCount]"
              style="min-width: 1.3rem;"
              class="count"
              rounded
              type="is-v2-supporting-1"
            >
              {{ " " + metadata[item.hasCount] }}
            </gm-tag>
          </component>
        </span>
      </span>
    </div>
    <div
      class="navbar-menu"
      :class="{'is-active' : menuOpen}"
    >
      <div class="navbar-end">
        <a
          v-if="loggedIn && roles.includes('vendor') && !MASQUERADING"
          :href="apiUrl + `/switch/vendor`"
          class="navbar-item is-hidden-touch is-flex is-flex-align-centered is-justify-center"
        >
          <strong>
            Switch to Vendor
          </strong>
        </a>
        <router-link
          v-if="loggedIn"
          v-show="$route.name !== 'rfq-form'
            && $route.name !== 'rfq-form-no-selected'
            && $route.path !== '/'"
          :to="'/project' + (ACTIVE_PROJECT ? '/' + ACTIVE_PROJECT.hash : '')"
          class="navbar-item is-hidden-touch"
        >
          <gm-button
            type="v2-supporting-1"
            size="small"
            class="is-flex is-flex-align-centered is-justify-center"
          >
            <strong>Get a Quote</strong>
          </gm-button>
        </router-link>
        <navbar-notifications v-if="loggedIn" />
        <component
          :is="item.tag || 'a'"
          v-for="item in navbarItems.end"
          v-show="!item.requiresAuth || loggedIn"
          :key="item.name"
          :href="item.link || '#'"
          :target="(!item.tag || item.tag === 'a') && '_blank'"
          :to="item.tag === 'router-link' && item.link"
          class="navbar-item is-hidden-touch"
          style="background-color: transparent !important;"
        >
          <b-icon
            v-if="item.icon"
            :type="item.hasCount && metadata && metadata[item.hasCount] ? 'is-white' : 'is-light'"
            :icon="item.icon"
          />
          <span v-else>{{ item.name }}</span>
          <gm-tag
            v-if="item.hasCount && metadata && metadata[item.hasCount]"
            style="width: 1.3rem; height: 1.3rem; position: absolute;
            left: 1.5rem; top: 0.7rem; border: 2px solid #131A22;"
            class="count"
            rounded
            type="is-v2-supporting-1"
          >
            {{ " " + metadata[item.hasCount] }}
          </gm-tag>
        </component>
        <div
          v-show="loggedIn"
          class="navbar-item has-dropdown is-hoverable"
          data-testid="menu-dropdown"
        >
          <a class="navbar-link">
            <div
              v-if="MASQUERADING"
              class="column is-narrow"
            >
              <b-icon icon="user-secret" />
            </div>
            <div class="column">
              <span>
                {{ username || "" }}
              </span>
            </div>
          </a>
          <div
            class="navbar-dropdown"
            data-testid="navbar-dropdown"
            @click="menuOpen = false"
          >
            <component
              :is="item.tag || 'a'"
              v-for="item in navbarItems.dropdown"
              :key="item.name"
              class="navbar-item no-box-shadow"
              :href="item.link || '#'"
              :target="(!item.tag || item.tag === 'a') && '_blank'"
              :to="item.tag === 'router-link' && item.link"
              :data-testid="`${item.name}-nav-dropdown-link`"
            >
              <b-icon
                v-if="item.icon"
                :type="item.hasCount
                  && metadata
                  && metadata[item.hasCount]
                  ? 'is-white'
                  : 'is-light'"
                :icon="item.icon"
              />
              <span v-else>{{ item.name }}</span>
              <gm-tag
                v-if="item.hasCount && metadata && metadata[item.hasCount]"
                style="min-width: 0.8rem;"
                class="count"
                rounded
                type="is-danger"
              >
                {{ " " + metadata[item.hasCount] }}
              </gm-tag>
            </component>
            <b-navbar-item
              class="no-box-shadow"
              @click="logOut"
            >
              {{ MASQUERADING ? "Back to admin" : "Logout" }}
              <b-icon
                v-show="MASQUERADING"
                icon="user-secret"
              />
            </b-navbar-item>
          </div>
        </div>
        <div
          v-if="!loggedIn"
          class="navbar-item"
        >
          <div class="buttons">
            <RouterLink
              class="gm-theme gm-button small link"
              data-testid="register-button"
              to="/register"
            >
              Sign up
            </RouterLink>
          </div>
        </div>
        <div
          v-if="!loggedIn"
          class="navbar-item"
        >
          <div class="buttons">
            <RouterLink
              class="gm-theme gm-button small link"
              data-testid="login-button"
              to="/login"
            >
              Log in
            </RouterLink>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import {
  mapActions, mapGetters, mapMutations, mapState,
} from 'vuex';
import clickOutside from 'buefy/src/directives/clickOutside';
import {
  NAVIGATION_MODULE,
  RFQ_ENGINEER_VIEW,
  SET_LOGIN_MODAL,
  SET_RFQ_ENGINEER_MODE,
} from '../../store/modules/navigation/types';
import {
  AUTH_MODULE, LOGGED_IN, LOGOUT, MASQUERADING,
} from '../../store/modules/auth/types';
import {
  GET_METADATA, GET_USER_NAME, GET_USER_ROLES, METADATA, USER_MODULE,
} from '../../store/modules/user/types';
import ProfileDataWidget from '../user-profile/profile-data-widget.vue';
import StepIndicator from '../step-indicator/step-indicator.vue';
import NavbarNotifications from './navbar-notifications.vue';
import { ACTIVE_PROJECT, PROJECT_MODULE } from '../../store/modules/projects/types';
import getEnvironmentVariable from '../../../shared/misc/env-variable';

const navbarItems = {
  end: [
    {
      name: 'messages',
      link: '/messages',
      icon: 'inbox',
      tag: 'router-link',
      hasCount: 'new_messages_count',
      requiresAuth: true,
    },
    {
      name: 'cart',
      link: '/cart',
      icon: 'shopping-cart',
      tag: 'router-link',
      hasCount: 'cart_count',
      requiresAuth: true,
    },
  ],
  dropdown: [
    {
      name: 'invoices',
      link: '/invoices',
      tag: 'router-link',
      requiresAuth: true,
    },
    {
      name: 'cart transfer',
      link: '/cart-transfer',
      tag: 'router-link',
      hasCount: 'cart_transfer_count',
      requiresAuth: true,
    },
    {
      name: 'resources',
      link: 'https://geomiq.com/knowledgebase/',
      tag: 'a',
    },
    {
      name: 'contact',
      link: 'https://geomiq.com/contact-us/',
      tag: 'a',
    },
    {
      name: 'profile',
      link: '/profile',
      tag: 'router-link',
    },
  ],
};

export default {
  name: 'Navbar',
  components: { NavbarNotifications, StepIndicator, ProfileDataWidget },
  directives: {
    clickOutside,
  },
  data() {
    return {
      navbarItems,
      menuOpen: false,
      apiUrl: getEnvironmentVariable('VUE_APP_API_URL'),
    };
  },

  computed: {
    ...mapState(AUTH_MODULE, {
      loggedIn: LOGGED_IN,
      MASQUERADING,
    }),
    ...mapGetters(PROJECT_MODULE, {
      ACTIVE_PROJECT,
    }),
    ...mapState(NAVIGATION_MODULE, {
      RFQ_ENGINEER_VIEW,
    }),
    ...mapState(USER_MODULE, {
      metadata: METADATA,
    }),
    ...mapGetters(USER_MODULE, {
      username: GET_USER_NAME,
      roles: GET_USER_ROLES,
    }),
    activeStep() {
      switch (this.$route.name) {
        case 'rfq-form':
          return 1;
        case 'rfq-form-no-selected':
          return 1;
        case 'quote-list':
          return 2;
        case 'cart':
          return 3;
        default:
          return null;
      }
    },
  },
  methods: {
    ...mapMutations(NAVIGATION_MODULE, {
      setLoginModal: SET_LOGIN_MODAL,
      setEngineerMode: SET_RFQ_ENGINEER_MODE,
    }),
    ...mapActions(AUTH_MODULE, {
      logOut: LOGOUT,
    }),
    ...mapActions(USER_MODULE, {
      getMetaData: GET_METADATA,
    }),
    openRegistration() {
      this.setLoginModal({ visible: true, mode: 'register' });
    },
    showLoginModal() {
      this.setLoginModal({ visible: true, mode: 'login' });
    },
  },
};
</script>

<style lang="scss" scoped>

  .large-brand-holder {
    display: flex;
    align-items: center;
    margin: -0.75rem;
  }

  .navbar {
    background-color: variables.$v2-primary;
  }
</style>
