import Vue from 'vue';
import {
  SET_ALL, SET, RESET_STATE, SET_CHANNEL, CHANNELS, DELETE, UPDATE,
} from '../types';
import {
  CHAT_MESSAGES, CHATS, SET_SUBSCRIPTIONS, SUBSCRIPTIONS,
} from './types';
import getState from './state';

export default {
  /**
     * Sets data in the state
     *
     * @param state
     * @param data
     * @param clear
     * */
  [SET_ALL](state, { data, clear = false }) {
    if (clear) {
      state[CHATS] = [];
    }
    state[CHATS] = state[CHATS].concat(data);
  },
  [UPDATE](state, { data }) {
    const idx = state[CHATS].findIndex((e) => e.id === data.id);
    if (idx !== -1) {
      state[CHATS].splice(idx, 1);
    }
    state[CHATS].unshift(data);
  },
  [SET](state, {
    data, id, clear = false, unshift = false,
  }) {
    if (clear || !state[CHAT_MESSAGES][id]) {
      Vue.set(state[CHAT_MESSAGES], id, unshift ? data.reverse() : data);
    } else if (unshift) {
      state[CHAT_MESSAGES][id] = data.concat(state[CHAT_MESSAGES][id]);
    } else {
      state[CHAT_MESSAGES][id] = state[CHAT_MESSAGES][id].concat(data);
    }
  },
  [SET_CHANNEL](state, { id, channel = null, name = null }) {
    Vue.set(state[CHANNELS], id, { name, channel });
  },
  [DELETE](state, { id }) {
    state[CHATS].splice(state[CHATS].findIndex((e) => +e.id === +id), 1);
  },
  [SET_SUBSCRIPTIONS](state, { data, clear = false }) {
    if (clear) {
      state[SUBSCRIPTIONS] = {};
    }
    data.forEach((e) => {
      if (state[SUBSCRIPTIONS][e.chat_id]) {
        state[SUBSCRIPTIONS][e.chat_id] = e.last_viewed_chat_at;
      } else {
        Vue.set(state[SUBSCRIPTIONS], e.chat_id, e.last_viewed_chat_at);
      }
    });
  },
  // eslint-disable-next-line no-unused-vars
  [RESET_STATE](state) {
    // eslint-disable-next-line no-param-reassign
    state = getState();
  },
};
