export const QUOTES = 'QUOTES';
export const EXAMPLE_GETTER = 'EXAMPLE_GETTER';
export const GET_QUOTES = 'GET_QUOTES';
export const GET_ALL_QUOTE_RFQS = 'GET_ALL_QUOTE_RFQS';
export const SET_QUOTES = 'SET_QUOTES';
export const QUOTES_MODULE = 'QUOTES_MODULE';
export const SET_QUOTES_FILTERS = 'SET_QUOTES_FILTERS';
export const QUOTES_FILTERS = 'QUOTES_FILTERS';
export const SET_QUOTES_META = 'SET_QUOTES_META';
export const QUOTES_META = 'QUOTES_META';
export const SET_REQUESTED_QUOTE_INFO = 'SET_REQUESTED_QUOTE_INFO';
export const REQUESTED_QUOTE_INFO = 'REQUESTED_QUOTE_INFO';
export const PENDING_REVISIONS = 'PENDING_REVISIONS';
export const SET_PENDING_REVISIONS = 'SET_PENDING_REVISIONS';
export const SET_QUOTES_LOADING = 'SET_QUOTES_LOADING';
export const QUOTES_LOADING = 'QUOTES_LOADING';
export const SET_QUOTE_CARD_ITEM_TO_NULL = 'SET_QUOTE_CARD_ITEM_TO_NULL';
