<template>
  <BField expanded>
    <BRadioButton
      v-for="(property, key) in allProperties"
      :key="key + currentValue"
      v-tippy="{
        placement: 'top-start',
        animation: 'normal',
        duration: 0,
        delay: [0, 0],
        arrow: true,
        content: (!availableProperties[property.id] && isServicePicker) &&
          makeTooltipContent(property.id),
        theme: 'is-info',
        trigger: !availableProperties[property.id] && isServicePicker
          ? 'mouseenter focus'
          : 'manual',
        onShow: () => triggerTooltipIfServicePicker(true, property.id),
        onHide: () => triggerTooltipIfServicePicker(false, property.id)
      }"
      :data-testid="`configurator-` + property[valueType].replace(' ', '-').toLowerCase()"
      :disabled="!availableProperties[property.id]"
      :native-value="property.id"
      :title="property[valueType]"
      :value="currentValue"
      class="is-flex-grow-1 service-select"
      type="is-info"
      v-bind="$attrs"
      @input="update"
    >
      <p style="overflow: hidden;">
        {{ property[valueType] }}
      </p>
    </BRadioButton>
  </BField>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import {
  REFERENCE_DATA,
  REFERENCE_MODULE,
} from '@/app-buyer/store/modules/reference-data/types';
import { formInput } from '@/app-buyer/mixins/form-input';
import {
  NAVIGATION_MODULE,
  SET_SHOW_FILE_SERVICE_TOOLTIP,
} from '@/app-buyer/store/modules/navigation/types';

export default {
  name: 'ConfiguratorElementRadioButton',
  mixins: [formInput],
  props: {
    entity: {
      type: String,
      default: () => null,
    },
    isServicePicker: {
      type: Boolean,
      default: () => true,
    },
  },
  data() {
    return {
      currentValue: null,
    };
  },
  computed: {
    ...mapState(REFERENCE_MODULE, {
      REFERENCE_DATA,
    }),
    allProperties() {
      return this[REFERENCE_DATA]?.filter((e) => e.entity_slug === this.entity) || [];
    },
    availableProperties() {
      return this.allProperties.reduce((result, current) => ({
        ...result,
        [current.id]: !!this.properties.find((e) => e.id === current.id),
      }), {});
    },
  },
  watch: {
    leadValue: {
      handler(val) {
        this.$nextTick(() => {
          this.currentValue = val;
        });
      },
      immediate: true,
    },
  },
  methods: {
    ...mapMutations(NAVIGATION_MODULE, {
      SET_SHOW_FILE_SERVICE_TOOLTIP,
    }),
    triggerTooltipIfServicePicker(bool, id) {
      if (!this.availableProperties[id]) {
        this[SET_SHOW_FILE_SERVICE_TOOLTIP](bool);
      }
    },
    makeTooltipContent(id) {
      if (id === 1) return 'Accepted file types: STEP, STP, IGES, IGS, PDF';
      if (id === 2) return 'Accepted file types: STEP, STP, STL';
      if (id === 3) return 'Accepted file types: STEP, STP, IGES, IGS, PDF';
      if (id === 4) return 'Accepted file types: STEP, STP, DWG, DXF, IGES, IGS, PDF';
      return '';
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
::v-deep {
  .service-select .b-radio.radio.button {
    &.is-info {
      background-color: rgb(51 141 188 / 25%);
      border: 1px solid #338dbc !important;
      color: rgb(54, 54, 54);
    }

    &:active {
      border: 1px solid #338dbc !important
    }
  }

  @media all and (max-width: 1500px) {
    .field.has-addons {
      display: flex;
      flex-wrap: wrap;

      .control.is-flex-grow-1 {
        flex: none;
        width: 50%;

        &:first-child .button {
          border-radius: 5px 0 0 0;
        }

        &:nth-child(2) .button {
          border-radius: 0 5px 0 0;
        }

        &:nth-last-child(2) .button {
          border-radius: 0 0 0 5px;
        }

        &:last-child .button {
          border-radius: 0 0 5px 0;
        }
      }
    }
  }
}
</style>
