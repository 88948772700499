import { SET_METADATA, USER_DATA, METADATA } from './types';
import { RESET_STATE, SET } from '../types';
import getState from './state';

export default {
  [SET](state, data) {
    state[USER_DATA] = data;
  },
  [SET_METADATA](state, data) {
    state[METADATA] = data;
  },
  // eslint-disable-next-line no-unused-vars
  [RESET_STATE](state) {
    // eslint-disable-next-line no-param-reassign
    state = Object.assign(state, getState());
  },
};
