/* eslint-disable class-methods-use-this */
import Model from './Model';

export default class Project extends Model {
  resource() {
    return 'projects';
  }

  primaryKey() {
    return 'hash';
  }
}
