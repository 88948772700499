<template>
  <div
    class="is-flex is-flex-align-stretch summary-element"
  >
    <div
      class="m-r-sm is-flex is-flex-direction-column is-flex-align-centered is-justify-center"
      style="width: 256px; flex: none;"
    >
      <figure
        v-if="imagePath"
        class="image is-relative"
      >
        <img
          :src="imagePath"
          alt="image"
        >
      </figure>
      <span
        v-else-if="isPdf"
        :data-filetype="'PDF'"
        class="document-placeholder"
        style="margin: auto;"
      />
      <p
        :title="draft.name"
        class="title is-7 text-align-center m-t-xs"
      >
        {{ draft.name }}
      </p>
    </div>
    <div class="is-flex-grow-1 is-flex is-justify-space-around is-flex-direction-column">
      <header class="data-grid">
        <div
          v-for="(data, key) in properties"
          :key="key"
          class="m-xs data-holder"
        >
          <p
            class="subtitle is-7 m-b-xs"
          >
            {{ data.entity_name }}
          </p>
          <p
            class="title is-7"
          >
            {{ data.string_value || data.name }}
          </p>
        </div>
        <div class="data-holder m-xs">
          <p class="subtitle is-7 m-b-xs">
            Initial quantity
          </p>
          <p class="title is-7">
            {{ draft.quantity_initial }}
          </p>
        </div>
        <div class="data-holder m-xs">
          <p class="subtitle is-7 m-b-xs">
            Production quantity
          </p>
          <p class="title is-7">
            {{ draft.quantity_production }}
          </p>
        </div>
      </header>
      <section
        class="p-sm m-y-sm"
        style="border-top: 1px solid var(--gray6); border-bottom: 1px solid var(--gray6);"
      >
        <p class="is-size-7">
          Supporting files
        </p>
        <ul>
          <li v-if="properties.service.slug === '3d-printing'">
            <p class="is-size-7">
              <strong>Not required</strong>
            </p>
          </li>
          <template v-else>
            <li
              v-for="supportingFile in supportingFiles"
              :key="supportingFile.hash_name"
            >
              <p class="is-size-7">
                <strong>{{ supportingFile.client_original_name }}</strong>
              </p>
            </li>
            <li v-show="!supportingFiles.length">
              <p class="is-size-7">
                <strong>No supporting files uploaded</strong>
              </p>
            </li>
          </template>
        </ul>
      </section>
      <footer class="p-sm">
        <p class="is-size-7">
          Add ons
        </p>
        <p
          v-if="addons"
          class="subtitle is-7"
        >
          <strong>{{ addons }}</strong>
        </p>
        <p v-else>
          No addons selected
        </p>
      </footer>
    </div>
  </div>
</template>

<script>
/* eslint-disable camelcase */

import {
  DRAFT_RFQ_MODEL_PREVIEW,
  DRAFT_RFQ_SUPPORTING_FILE,
  PDF,
  RFQ_MODEL_PREVIEW,
  RFQ_SUPPORTING_FILE,
} from '@/shared/consts/slugs';
import ELEMENTS from './configurator-element/configurator-elements';
import findSecondaryFinishParentMixin from '@/app-buyer/mixins/findSecondaryFinishParent';
import { findModelFile } from '@/app-buyer/components/project/helpers';

export default {
  name: 'ConfiguratorDraftsSummaryElement',
  mixins: [findSecondaryFinishParentMixin],
  props: {
    draft: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      // TODO get these items dynamically!!
      // Currently when looking at the configuration_object you
      // are unable to dynamically see what is an addon.
      // Below is a list of hardcoded addons to
      // get this working without b/e changes
      manualAddons: [
        'mill-certificate',
        'xrf-analysis',
        'ppap-package',
        'screws',
        'engraving',
        'laser-marking',
        'screen-printing',
      ],
    };
  },
  computed: {
    isPdf() {
      return this.draft.uploads && this.draft.configuration_object['file-type']?.slug === PDF && (findModelFile(this.draft));
    },
    imagePath() {
      const hasPreview = this.draft.uploads?.find((e) => [RFQ_MODEL_PREVIEW, DRAFT_RFQ_MODEL_PREVIEW].includes(e.type?.slug));
      return this.isPdf ? null : hasPreview?.url || null;
    },
    supportingFiles() {
      return this.draft?.uploads?.filter((e) => [RFQ_SUPPORTING_FILE, DRAFT_RFQ_SUPPORTING_FILE].includes(e.type?.slug)) || [];
    },
    properties() {
      return ELEMENTS.flat().reduce((result, element) => {
        const newResult = { ...result };
        const entity_slug = element.entity;
        const baseConfig = this.draft?.configuration_object?.[entity_slug];
        const configurationValue = baseConfig ? JSON.parse(JSON.stringify(baseConfig)) : null;
        if (configurationValue && entity_slug !== 'file-type') {
          if (entity_slug === 'secondary-finish') {
            const parent = this.findSecondaryFinishParent(configurationValue);
            if (parent) {
              configurationValue.string_value = `${parent.string_value}: ${configurationValue.string_value}`;
            }
          }
          newResult[entity_slug] = configurationValue;
        }
        return newResult;
      }, {});
    },
    addons() {
      return this.manualAddons?.filter((a) => this.draft?.configuration_object?.[a]).map((a) => this.draft?.configuration_object[a]?.name).concat(
        this.draft?.configuration_object?.inspection
          ? [this.draft?.configuration_object?.inspection.string_value]
          : [],
      ).join(', ');
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>

@media all and (max-width: variables.$desktop) {
  .summary-element {
    flex-direction: column;
  }
}

figure.image {
  height: 256px;
  width: 256px;

  @media all and (max-width: variables.$desktop) {
    height: 64px;
    width: 64px;
  }
}

.data-grid {
  display: grid;
  grid-gap: 0.5rem;
  grid-template-columns: repeat(5, auto);

  @media all and (max-width: variables.$desktop) {
    grid-template-columns: repeat(2, 45%);
    margin-top: 1rem;
  }
}

</style>
