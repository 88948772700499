<template>
  <div class="has-text-centered my-2">
    <div class="is-flex icon-container">
      <button
        v-for="n in 5"
        :key="n"
        type="button"
        class="button-no-style"
        @mouseenter="hovered = n"
        @mouseleave="hovered = null"
        @click="$emit('input', n)"
      >
        <BIcon
          icon="star"
          :style="{
            color: (hovered ? hovered : selected) >= n ? '#ffc906' : '#ddd'
          }"
        />
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FeedbackStars',
  props: {
    value: {
      type: Number,
      default: () => null,
    },
  },
  data() {
    return {
      hovered: null,
    };
  },
  computed: {
    selected() {
      return this.value ? this.value : null;
    },
    selectedText() {
      switch (this.hovered || this.selected) {
        case 1:
          return ' Non-Conforming parts. Submit an NCR <a href="https://share.hsforms.com/1t2LBN3k2Ro6-g4ynlS4TKg5h3ex" target="_blank">here</a> to get this resolved.';
        case 2:
          return 'Lower than expected part quality.<br>Please leave more detail and we will take appropriate action with our partner.';
        case 3:
          return 'Satisfied. These are to spec.';
        case 4:
          return 'Great Job! We will notify this Partner for your future relevant projects.';
        case 5:
          return 'Exceeded all expectations! We will add this supplier to your preferred network.';
        default:
          return null;
      }
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
::v-deep {
  .icon {
    margin-right: 10px;

    [data-icon="star"] {
      height: 28px;
      width: 28px;
    }
  }
}
</style>
