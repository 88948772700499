import Vue from 'vue';
import Vuex from 'vuex';

/*
* Module imports
* */
import { AUTH_MODULE } from '@/app-buyer/store/modules/auth/types';
import { AUTODESK_MODULE } from '@/app-buyer/store/modules/autodesk/types';
import { CART_MODULE } from '@/app-buyer/store/modules/cart/types';
import {
  CART_TRANSFER_MODULE,
} from '@/app-buyer/store/modules/cart-transfer/types';
import { CHATS_MODULE } from '@/app-buyer/store/modules/chats/types';
import {
  DRAG_DROP_MODULE,
} from '@/app-buyer/store/modules/drag-drop-helper/types';
import { G_SERVE_MODULE } from '@/app-buyer/store/modules/g-serve/types';
import { NAVIGATION_MODULE } from '@/app-buyer/store/modules/navigation/types';
import { PROJECT_MODULE } from '@/app-buyer/store/modules/projects/types';
import {
  REFERENCE_MODULE,
} from '@/app-buyer/store/modules/reference-data/types';
import { RFQ_MODULE } from '@/app-buyer/store/modules/rfq/types';
import { USER_MODULE } from '@/app-buyer/store/modules/user/types';
import { VIEWER_MODULE } from '@/app-buyer/store/modules/viewer/types';
import { WEBSOCKET_MODULE } from '@/app-buyer/store/modules/web-sockets/types';
import { QUOTES_MODULE } from '@/app-buyer/store/modules/quotes/types';
import { ORDERS_MODULE } from '@/app-buyer/store/modules/orders/types';
import auth from './modules/auth';
import projects from './modules/projects';
import navigation from './modules/navigation';
import rfq from './modules/rfq';
import autodesk from './modules/autodesk';
import user from './modules/user';
import cart from './modules/cart';
import dragDrop from './modules/drag-drop-helper';
import viewer from './modules/viewer';
import websocket from './modules/web-sockets';
import gServe from './modules/g-serve';
import cartTransfer from './modules/cart-transfer';
import reference from './modules/reference-data';
import chats from './modules/chats';
import quotes from './modules/quotes';
import orders from './modules/orders';

Vue.use(Vuex);

// const persistedProjects = createPersistedState({
//   paths: [
//     `${PROJECT_MODULE}.${PROJECTS}`,
//     `${RFQ_MODULE}.${RFQS}`,
//     `${RFQ_MODULE}.${DRAFT_RFQS}`,
//   ],
//   storage: window.sessionStorage,
// });

const store = new Vuex.Store({
  modules: {
    [AUTH_MODULE]: auth,
    [PROJECT_MODULE]: projects,
    [NAVIGATION_MODULE]: navigation,
    [RFQ_MODULE]: rfq,
    [AUTODESK_MODULE]: autodesk,
    [USER_MODULE]: user,
    [CART_MODULE]: cart,
    [DRAG_DROP_MODULE]: dragDrop,
    [VIEWER_MODULE]: viewer,
    [WEBSOCKET_MODULE]: websocket,
    [G_SERVE_MODULE]: gServe,
    [CART_TRANSFER_MODULE]: cartTransfer,
    [REFERENCE_MODULE]: reference,
    [CHATS_MODULE]: chats,
    [QUOTES_MODULE]: quotes,
    [ORDERS_MODULE]: orders,
  },
  // plugins: [persistedProjects],
});

export default store;
