<template>
  <tr
    :class="{
      'is-selected' : isSelected,
      'is-transparent': isTransparent,
      'has-bottom-progress': part.uploadPercent < 100 && part.uploadPercent > 0,
      'is-dragged': isDragged,
    }"
    :draggable="isDraggable"
    :style="part.uploadPercent < 100 && `background-size: ${part.uploadPercent}% 5px;`"
    class="part-row has-cursor-pointer is-flex"
    data-testid="single-part-row"
    @click="selectSingle"
    @dragend="onDragEnd"
    @dragstart="onDragStart(part, DRAFT_RFQ_MODEL)"
    @mouseenter="isHovered = true"
    @mouseleave="isHovered = false"
  >
    <td
      colspan="9"
      style="padding: 0; background-color: transparent; display: contents;"
    >
      <table style="width: 100%; overflow: hidden">
        <tr class="part-wrap">
          <td style="width:88px; padding-right: 0;">
            <PartHandler
              :is-open="isOpen"
              :is-selected.sync="isSelected"
              :part="part"
              :show-hovered="isHovered"
              @update:is-open="$emit('update:is-open', $event)"
            />
          </td>
          <td
            :data-testid="partName"
            :title="partName"
            class="text-field"
            style="min-width: 155px;"
          >
            {{ partName }}
          </td>
          <td
            :title="service"
            class="is-hidden-touch text-field"
            style="width: 155px;"
          >
            {{ service }}
          </td>
          <td
            style="width: 80px;"
            class="is-hidden-touch"
          >
            <PartTableGroupRowQuantity
              :part="part"
            />
          </td>
          <td
            :title="material"
            class="is-hidden-touch text-field"
            style="width: 155px;"
          >
            {{ material }}
          </td>
          <td
            :title="shippingIn"
            class="is-hidden-touch text-field"
            style="width: 155px;"
          >
            {{ shippingIn }}
          </td>
          <td
            style="width: 210px; padding: 0;"
            class="is-hidden-touch"
          >
            <PartSupporting
              :part="part"
              :service="service"
              is-on-table
              @shift-select="selectSingle"
            />
          </td>
          <td
            style="width: 60px;"
            class="is-hidden-touch has-text-centered"
          >
            <RouterLink
              v-if="part.pending_rfqs_count"
              :to="{ name: 'quote-list', query: { origin: part.hash } }"
            >
              {{ part.pending_rfqs_count }}
            </RouterLink>
          </td>
          <td
            class="part-options-wrapper"
            style="width: 50px;"
            @click.stop
          >
            <PartOptions :part="part" />
          </td>
        </tr>
        <tr v-if="showErrors">
          <td
            colspan="9"
            style="padding-top: 0"
          >
            <div class="error-message-left-spacing">
              <PartErrorMessage
                v-for="(issue, i) in issueMessages"
                :key="i"
                :part="part"
                :issue="issue"
              />
            </div>
          </td>
        </tr>
      </table>
    </td>
  </tr>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import {
  DRAFT_RFQ_MODEL,
  DRAFT_RFQ_MODEL_PREVIEW,
  DRAFT_RFQ_SUPPORTING_FILE,
  IGES,
  IGS,
  RFQ_MODEL_PREVIEW,
  RFQ_SUPPORTING_FILE,
  STEP,
  STP,
} from '@/shared/consts/slugs';
import PartOptions from '@/app-buyer/components/project/PartOptions.vue';
import {
  DRAFT_RFQ_ISSUES,
  RFQ_MODULE,
  SELECTED_PART_HASHES,
} from '@/app-buyer/store/modules/rfq/types';
import PartSupporting from '@/app-buyer/components/project/PartSupporting.vue';
import PartHandler from '@/app-buyer/components/project/PartHandler.vue';
import PartErrorMessage from '@/app-buyer/components/project/PartErrorMessage.vue';
import { partSelectMixin } from '@/app-buyer/components/project/mixins';
import { G_SERVE_MODULE, GET_SPECIFICATION } from '@/app-buyer/store/modules/g-serve/types';
import { isComponentVisible } from '@/shared/mixins';
import PartTableGroupRowQuantity
from '@/app-buyer/components/project/PartTableGroupRowQuantity.vue';
import { findDraftModelFile } from '@/app-buyer/components/project/helpers';
import draggableElement from '@/app-buyer/mixins/draggable-element';

export default {
  name: 'PartTableGroupRow',

  components: {
    PartTableGroupRowQuantity,
    PartHandler,
    PartSupporting,
    PartErrorMessage,
    PartOptions,
  },

  mixins: [partSelectMixin, isComponentVisible, draggableElement],

  inject: {
    projectContext: {
      default: null,
    },
  },

  props: {
    part: {
      type: Object,
      required: true,
    },
    isOpen: Boolean,
  },

  data() {
    return {
      isHovered: false,
      nonDraggableFileTypes: [IGS, IGES, STP, STEP],
      DRAFT_RFQ_MODEL,
    };
  },

  computed: {
    ...mapState(RFQ_MODULE, {
      SELECTED_PART_HASHES,
    }),

    ...mapGetters(RFQ_MODULE, {
      DRAFT_RFQ_ISSUES,
    }),

    issueMessages() {
      if (this[DRAFT_RFQ_ISSUES][this.part.hash]?.length && Object.keys(this.part).length) {
        const issueMessages = this[DRAFT_RFQ_ISSUES][this.part?.hash].filter((issue) => issue.type !== 'parsing');
        if (issueMessages.length) return issueMessages;
      }
      return [];
    },

    showErrors() {
      return !!this.issueMessages?.length;
    },

    imagePath() {
      return this.part?.uploads
        ?.find((upload) => [RFQ_MODEL_PREVIEW, DRAFT_RFQ_MODEL_PREVIEW]
          .includes(upload?.type?.slug))?.url;
    },

    modelFile() {
      return findDraftModelFile(this.part);
    },

    supportingFiles() {
      return this.part?.uploads
        ?.filter((u) => [RFQ_SUPPORTING_FILE, DRAFT_RFQ_SUPPORTING_FILE].includes(u.type.slug))
        || [];
    },

    fileType() {
      return this.modelFile?.extension;
    },

    isDraggable() {
      return !this.nonDraggableFileTypes.includes(this.fileType) && !this.supportingFiles.length;
    },

    partName() {
      return this.part?.name;
    },

    service() {
      return this.part?.configuration_object?.service?.string_value;
    },

    material() {
      return this.part?.configuration_object?.material?.string_value;
    },

    shippingIn() {
      return this.part?.configuration_object?.['manufacturing-time']?.string_value;
    },

    isTransparent() {
      return this.projectContext?.highlightedParts?.length
        && !this.projectContext?.highlightedParts.includes(this.part?.hash);
    },

    isSelected: {
      get() {
        return this[SELECTED_PART_HASHES].includes(this.part.hash);
      },
      set(value) {
        this.addRemoveFromSelection(!value);
      },
    },

    uploadingStatus() {
      if (this.part?.uploadPercent) {
        return {
          message: `File is uploading - ${this.part?.uploadPercent}%`,
        };
      }
      return null;
    },
  },

  watch: {
    isComponentVisible: {
      handler(isVisible) {
        if (isVisible) {
          this.loadGServeData();
        }
      },
      immediate: true,
    },
  },

  methods: {
    ...mapActions(G_SERVE_MODULE, {
      GET_SPECIFICATION,
    }),

    addRemoveFromSelection(isSelected) {
      const newHashes = isSelected
        ? this[SELECTED_PART_HASHES].filter((h) => h !== this.part.hash)
        : [...this[SELECTED_PART_HASHES], this.part.hash];
      this.selectParts(...newHashes);
    },

    selectSingle(event) {
      if (event?.shiftKey) {
        this.$emit('shift-select');
        return;
      }
      // if the click source is the checkbox further actions are prevented
      if (event?.target?.classList.contains('check') || event?.target?.classList.contains('checkbox-wrapper')) return;
      if (event?.ctrlKey) {
        this.addRemoveFromSelection(this[SELECTED_PART_HASHES].includes(this.part.hash));
      } else {
        this.selectParts(this.part.hash);
      }
    },

    loadGServeData() {
      const isGServeFile = this.modelFile?.parser_metadata?.parser_slug === 'g-serve';
      const urn = this.modelFile?.parser_metadata?.parser_uuid;
      if (isGServeFile && urn) {
        this[GET_SPECIFICATION]({
          urn,
          hash: this.part.hash,
        });
      }
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
.part-row {
  min-height: 65px;
  overflow-x: hidden;
  position: relative;
  transition: opacity .3s ease-in-out;

  .part-wrap {
    border-color: variables.$g-light-2;
    border-style: solid;
    border-width: 1px 0 0;
  }

  td {
    padding: 10px 0;

    &.text-field {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .part-options-wrapper {
    overflow: initial;
  }

  &:last-child,
  &.is-selected {
    border-bottom-width: 1px;
  }

  &:hover:not(.is-selected) {
    background-color: variables.$g-light-3;
  }

  &.is-selected {
    border-color: variables.$info;

    td {
      background-color: rgba(variables.$info, .2);
    }
  }

  &.is-transparent {
    opacity: .1;
  }

  &.is-dragged {
    opacity: .25;
  }
  .error-message-left-spacing {
    margin-left: 2rem;
    ::v-deep {
      .message:not(:last-child) {
        margin-bottom: 0.5rem;
      }
    }
  }
}
</style>
