<template>
  <li
    class="is-flex is-justify-content-center"
    data-testid="loadMoreProjects"
  >
    <GmButton
      :loading="loading"
      :type="color"
      is-link
      size="small"
      :disabled="loading"
      @click="$emit(`${nextPage ? 'load-next-page' : 'load-previous-page'}`)"
    >
      Load more projects
    </GmButton>
  </li>
</template>
<script>
import { isComponentVisible } from '@/shared/mixins';
import GmButton from '@/shared/components/gm-button/gm-button.vue';

export default {
  name: 'ProjectSidebarBottom',
  components: { GmButton },
  mixins: [isComponentVisible],
  props: {
    loading: Boolean,
    color: {
      type: String,
      default: 'white',
    },
    nextPage: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    isComponentVisible: {
      handler(isVisible) {
        if (isVisible && !this.loading && this.nextPage) {
          this.$emit(`${this.nextPage ? 'load-next-page' : 'load-previous-page'}`);
        }
      },
    },
  },
};
</script>
<style lang="scss" scoped>
$archive-color: #4b5a6b;
</style>
