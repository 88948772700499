<template>
  <tr
    :key="rfq.id"
    :class="{ 'is-loading-file': loadingRfqs[rfq.id] }"
    class="quote-table-row is-size-7 has-background-white is-clickable"
    draggable="false"
    @click="handleDetailClick(rfq.hash)"
  >
    <!-- Ref -->
    <td class="is-hidden-touch">
      <GroupedQuotesCellRef
        :is-open="openDetailed.indexOf(rfq.hash) > -1"
        style="min-height: 30px; height: 30px"
      >
        {{ rfq.ref }}
      </GroupedQuotesCellRef>
    </td>
    <td class="is-hidden-touch">
      <PartThumbnail
        :part="rfq"
        :size="44"
      />
    </td>
    <!-- Part name-->
    <td class="is-hidden-touch name">
      {{ rfq.name }}
    </td>
    <!-- Service -->
    <td class="is-hidden-touch">
      <TOC
        v-slot="{ value }"
        :data="rfq"
        path="configuration_object.service.string_value"
      >
        {{ value }}
      </TOC>
    </td>
    <!-- Qty. -->
    <td class="is-hidden-touch">
      {{ rfq.quantity_initial }}
    </td>
    <!-- Material -->
    <td class="is-hidden-touch">
      <TOC
        v-slot="{ value }"
        :data="rfq"
        path="configuration_object.material.string_value"
      >
        {{ value }}
      </TOC>
    </td>
    <!-- Manufacturing time -->
    <td class="is-hidden-touch">
      {{ manufacturingTimeForRfq }}
    </td>
    <!-- Status -->
    <td class="is-hidden-touch">
      <BTag
        :type="!!subtotalForRfq ? 'is-success' : 'is-g-light-1'"
        class="is-half-transparent"
        rounded
        style="width: 175px"
      >
        {{ !!subtotalForRfq ? 'Received quotes' : 'Awaiting quotes' }}
      </BTag>
    </td>
    <!-- Unit price -->
    <td class="is-hidden-touch has-text-right ">
      <div v-if="unitPrice">
        {{ unitPrice | formatWithCurrency }}
      </div>
      <div
        v-if="toolingPrice"
        style="min-width: max-content; font-size: 11px;"
      >
        Tool: {{ toolingPrice | formatWithCurrency }}
      </div>
    </td>
    <!-- Subtotal -->
    <td class="is-hidden-touch has-text-right has-text-weight-bold">
      <span>{{ subtotalForRfq | formatWithCurrency }}</span>
    </td>
    <!-- Add to cart -->
    <td class="is-hidden-touch has-text-right">
      <GroupedQuotesCartButtons
        :disabled="!activeQuote"
        :quote="activeQuote"
        :inactive-quote-in-the-cart="inactiveQuoteInTheCart"
        :quote-group-ids-in-cart-from-other-groups="quoteGroupIdsInCartFromOtherGroups"
        :rfq="rfq"
        :rfqs-and-their-active-quotes-ids="rfqsAndTheirActiveQuotesIds"
        :has-multiple-quotes="hasMultipleQuotes"
        @removeAllCartItems="removeAllCartItems"
      />
    </td>
    <!-- Options -->
    <td class="is-hidden-touch has-text-right">
      <GroupedQuotesOptions
        :hidden="hasQuotesInCartFromOtherGroup ? ['add-cart', 'remove-cart'] : []"
        :quote="activeQuote"
        :listing="listing"
        :rfq="rfq"
        @cancelled="handleCancelled"
        @duplicated="$emit('duplicated')"
        @click.native.stop
      />
    </td>
    <!-- Mobile responsive view -->
    <td class="is-hidden-desktop">
      <GroupedQuoteSummaryMobile
        :open="openDetailed.indexOf(rfq.hash) !== -1"
        :quote="activeQuote"
        :quote-group-ids-in-cart-from-other-groups="quoteGroupIdsInCartFromOtherGroups"
        :rfq="rfq"
        :subtotal="subtotalForRfq"
      />
    </td>
  </tr>
</template>

<script>
import GroupedQuoteSummaryMobile
from '@/app-buyer/components/grouped-quotes/GroupedQuoteSummaryMobile.vue';
import GroupedQuotesCartButtons
from '@/app-buyer/components/grouped-quotes/GroupedQuotesCartButtons.vue';
import GroupedQuotesCellRef from '@/app-buyer/components/grouped-quotes/GroupedQuotesCellRef.vue';
import GroupedQuotesOptions from '@/app-buyer/components/grouped-quotes/GroupedQuotesOptions.vue';
import PartThumbnail from '@/app-buyer/components/project/PartThumbnail.vue';
import TOC from '@/shared/components/template-optional-chaining/TemplateOptionalChaining.vue';

export default {
  /* eslint-disable no-unused-expressions */

  components: {
    GroupedQuoteSummaryMobile,
    GroupedQuotesCartButtons,
    GroupedQuotesCellRef,
    GroupedQuotesOptions,
    PartThumbnail,
    TOC,
  },

  props: {
    rfq: {
      type: Object,
      required: true,
    },
    listing: {
      type: Object,
      required: true,
    },
    activeQuoteGroups: {
      type: Array,
      required: true,
    },
    tableId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      openDetailed: [],
      addingAllToCart: false,
      selected: null,
      showOtherVendorQuotesModal: false,
      promptForNoteConfirmation: null,
      loadingRfqs: {},
      refreshKey: 0,
    };
  },

  computed: {
    active() {
      return this.activeQuoteGroups?.find(
        (q) => q.id === this.listing.id,
      ).index || 0;
    },
    activeGroup() {
      if (!this.hasMultipleQuotes) {
        return null;
      }
      let activeGroupIndex = 0;

      if (this.activeQuoteGroups) {
        activeGroupIndex = this.activeQuoteGroups?.find(
          (q) => q.id === this.listing.id,
        ).index;
      }

      return this.quoteGroups[activeGroupIndex];
    },

    /**
     * Whether any single RFQ has quotes from more than one vendor.
     * @returns {boolean}
     */
    hasMultipleQuotes() {
      return this.listing.rfqs.some((rfq) => rfq.quotes.length > 1);
    },

    hasQuotesInCartFromOtherGroup() {
      return this.quoteGroups.some(
        (group, index) => index !== this.active
          && group.quotes.some((quote) => !!quote.cart_item),
      );
    },

    hasNotesOnPartFromGroup() {
      return this.activeGroup?.quotes.some((quote) => quote?.notes?.length);
    },

    quoteGroupIdsInCartFromOtherGroups() {
      return (
        this.quoteGroups.filter(
          (group, index) => index !== this.active
            && group.quotes.some((quote) => !!quote.cart_item),
        )?.map((group) => group.id) || []
      );
    },
    rfqsAndTheirActiveQuotesIds() {
      const map = {};
      this.quoteGroups.forEach((group) => {
        group.quotes.forEach((quote) => {
          if (quote.cart_item) {
            map[quote.cart_item.rfq_id] = quote.id;
          }
        });
      });
      return map;
    },
    quoteGroups() {
      let quoteGroups = this.listing?.quoteGroups.map((group) => ({ ...group, quotes: [] }));
      // Nest each RFQ quote under their respective quote group
      this.listing.rfqs.forEach((rfq) => {
        rfq?.quotes?.forEach((quote) => {
          const group = quoteGroups.find((e) => e.id === quote.quote_group_id);
          if (group) {
            group.quotes?.push(quote);
          }
        });
      });
      quoteGroups = quoteGroups?.filter((g) => g?.quotes?.length);
      // If recommended flairs are present, set them as the first items in the quoteGroup
      const recommendedQuotes = [];
      const remainingQuotes = [];

      quoteGroups.forEach((rec) => {
        if (rec.flair === 'recommended') {
          recommendedQuotes.push(rec);
        } else {
          remainingQuotes.push(rec);
        }
      });

      quoteGroups = [...recommendedQuotes, ...remainingQuotes];
      return quoteGroups;
    },

    unitPrice() {
      return this.activeQuote?.entries?.find((e) => e.type?.slug === 'part')?.unit_cost;
    },

    toolingPrice() {
      return this.activeQuote?.entries?.find((e) => e.type?.slug === 'tooling')?.unit_cost;
    },

    activeQuote() {
      if (!this.hasMultipleQuotes) return this.rfq.quotes[0];
      return this.activeGroup?.quotes?.find((x) => this.rfq.quotes.find((y) => x.id === y.id));
    },

    inactiveQuotes() {
      return this.rfq?.quotes.filter((quote) => quote.id !== this.activeQuote?.id);
    },

    inactiveQuoteInTheCart() {
      return this.inactiveQuotes.find((quote) => quote.id === this.rfqsAndTheirActiveQuotesIds[this.rfq.id]);
    },
    subtotalForRfq() {
      return this.activeQuote ? this.activeQuote.subtotal : null;
    },

    manufacturingTimeForRfq() {
      if (this.activeQuote) return `${this.activeQuote?.delivery?.range} business days`;
      return this.rfq.configuration_object['manufacturing-time'].string_value;
    },
  },

  methods: {
    removeAllCartItems(quoteIds) {
      this.listing.rfqs.forEach((rfq) => {
        rfq.quotes.forEach((quote) => {
          if (quoteIds.includes(quote.id)) {
            this.$set(quote, 'cart_item', null);
          }
        });
      });
    },
    handleDetailClick(hash) {
      const idx = this.openDetailed.indexOf(hash);
      if (idx === -1) {
        this.openDetailed.push(hash);
      } else {
        this.openDetailed.splice(idx, 1);
      }
      this.$emit('open-quote-details', {
        openDetailed: this.openDetailed,
        activeGroup: this.activeGroup,
      });
    },

    handleCancelled() {
      this.$emit('cancelled', { tableId: this.tableId, rfqHash: this.rfq.hash });
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
.quote-table-row {
  .name {
    word-break: break-all;
  }
}
</style>
