export const DRAG_DATA = 'DRAG_DATA';
export const DRAG_TYPE = 'DRAG_TYPE';
export const DRAGGING = 'DRAGGING';
export const SET_DRAG_DATA = 'ADD_DRAG_DATA';
export const REMOVE_DRAG_DATA = 'REMOVE_DRAG_DATA';
export const APP_DRAGOVER = 'APP_DRAGOVER';
export const SET_APP_DRAGOVER = 'SET_APP_DRAGOVER';

export const DRAG_TYPES = {
  MODEL: 'MODEL',
  SUPPORTING_FILE: 'SUPPORTING_FILE',
};
export const DRAG_DROP_MODULE = 'DRAG_DROP_MODULE';
