<script>
import Form from 'form-backend-validation';
import getEnvironmentVariable from '@/shared/misc/env-variable';

export default {
  name: 'FormWithBackendValidation',
  props: {
    fields: {
      type: Array,
      required: true,
    },
    url: {
      type: String,
      default: () => null,
    },
    method: {
      type: String,
      default: () => 'post',
      validator(val) {
        return ['post', 'put', 'delete'].includes(val);
      },
    },
  },
  data() {
    return {
      form: null,
      errors: {},
      submitting: false,
    };
  },
  computed: {
    formObject() {
      return this.fields.reduce((result, current) => {
        if (typeof current === 'string') {
          return {
            ...result,
            [current]: null,
          };
        }
        if (typeof current === 'object' && current != null && current.field) {
          return {
            ...result,
            [current.field]: current.initialValue,
          };
        }
        return result;
      }, {});
    },
  },
  watch: {
    formObject: {
      handler(value) {
        if (value) {
          if (!this.form) {
            this.form = new Form(value);
          } else {
            this.form.populate(value);
          }
        } else {
          this.form = new Form({});
        }
      },
      immediate: true,
    },
  },
  methods: {
    collectErrors() {
      this.errors = this.fields.reduce((result, field) => ({
        ...result,
        [field]: this.form?.errors?.get(field),
      }), {});
    },
    clear(key = null) {
      this.form.errors.clear(key);
      this.collectErrors();
    },
    async submit() {
      if (this.submitting) {
        return;
      }
      if (!this.url) {
        console.warn('Form was not submitted because no url was specified!');
        return;
      }
      this.submitting = true;
      try {
        const url = `${getEnvironmentVariable('VUE_APP_API_URL')}/api${this.url}`;
        const responseData = await this.form[this.method](url);
        this.$emit('submit', responseData);
      } catch (error) {
        this.$emit('error', error.response);
      } finally {
        this.collectErrors();
        this.submitting = false;
      }
    },
  },
  render() {
    return this.$scopedSlots.default({
      submit: this.submit,
      clear: this.clear,
      errors: this.errors,
      form: this.form,
      submitting: this.submitting,
    });
  },
};
</script>

<style scoped>

</style>
