<template>
  <b-dropdown
    ref="dropdown"
    v-bind="$attrs"
    :value="values"
    class="gm-dropdown"
    expanded
    multiple
    @active-change="setListener"
  >
    <template v-slot:trigger="{ open }">
      <button
        slot="trigger"
        ref="trigger-button"
        v-bind="$attrs"
        :entity="entity"
        class="button is-small is-fullwidth is-result select-target"
      >
        <p
          class="subtitle is-7 is-width-100 is-flex-align-centered
          is-flex is-justify-space-between select-target"
          style="overflow: hidden; text-overflow: ellipsis;"
          :style="!selected && 'opacity: 0.5'"
          data-testid="configurator-preselected"
        >
          {{ (customNote && `${selected}: ${customNote}`) || selected || placeholder }}
          <icon-font
            :style="!open && 'transform: rotate(-90deg)'"
            icon="chevron-down"
            style="font-size: 0.4rem; color: var(--text-light); font-weight: lighter;"
          />
        </p>
      </button>
    </template>
    <b-dropdown-item
      v-for="(property, index) in properties"
      :key="property.id"
      :value="property.id"
      :class="{'is-highlighted' : index === highlightedIndex}"
      :custom="property.metadata && property.metadata.requires_notes"
      :data-testid="`configurator-${formatString(property.string_value)}`"
      @click="updateWithNote(property.id)"
    >
      <b-field
        v-if="property.metadata && property.metadata.requires_notes"
        custom-class="is-size-7"
        :label="property.string_value"
        @click.stop
      >
        <b-input
          v-model="customNote"
          expanded
          size="is-small"
          :placeholder="`Please specify the ${property.entity_name}`"
          @keyup.native.stop
        />
        <b-button
          :disabled="!customNote"
          size="is-small"
          @click="updateWithNote(property.id, true)"
        >
          Save
        </b-button>
      </b-field>
      <p
        v-else
        class="is-size-7"
      >
        {{ property[valueType] }}
      </p>
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
import { formInput } from '@/app-buyer/mixins/form-input';
import customNotes from '@/app-buyer/mixins/custom_notes';

export default {
  name: 'ConfiguratorElementSelect',
  mixins: [formInput, customNotes],
  props: {
    placeholder: {
      type: String,
      default: () => '',
    },
    entity: {
      type: String,
      default: () => null,
    },
  },
  data() {
    return {
      highlightedIndex: -1,
    };
  },
  computed: {
    selected() {
      if (this.values.length > 1) {
        return `Multiple selected (${this.values.length})`;
      }
      const selectedProperty = this.properties?.find((e) => e.id === this.values[0]);
      return selectedProperty?.[this.valueType] || null;
    },
  },
  watch: {
    leadValue: {
      handler(val) {
        if (val && this.entity && this._singleViewedModel && this._singleViewedModel.hash) {
          this.getNote(this.entity);
        }
      },
      immediate: true,
    },
  },
  methods: {
    select(value) {
      this.searchInput = '';
      this.highlightedIndex = -1;
      this.$emit('input', value);
      this.$nextTick(() => {
        if (this.$refs.dropdown?.isActive) {
          this.$refs.dropdown.toggle();
        }
      });
    },
    setListener(activated) {
      if (activated) {
        setTimeout(() => {
          this.$el.addEventListener('keyup', this.navigate);
          this.$refs['trigger-button'].addEventListener('keydown', this.preventEnter);
        }, 500);
      } else {
        this.$nextTick(() => {
          this.$el.removeEventListener('keyup', this.navigate);
          this.$refs['trigger-button'].removeEventListener('keydown', this.preventEnter);
        });
      }
    },
    navigate(event) {
      if (event.key === 'ArrowDown' && this.highlightedIndex < this.properties.length) {
        event.stopPropagation();
        this.highlightedIndex++;
        this.scrollToHighlighted();
      } else if (event.key === 'ArrowUp' && this.highlightedIndex > 0) {
        event.stopPropagation();
        this.highlightedIndex--;
        this.scrollToHighlighted();
      } else if (event.key === 'Enter' || event.key === 'Spacebar' || event.key === ' ') {
        event.stopPropagation();
        this.select(this.properties?.[this.highlightedIndex]?.id || null);
      }
    },
    scrollToHighlighted() {
      this.$nextTick(() => {
        const highlighted = this.$el.getElementsByClassName('is-highlighted')[0];
        if (highlighted) {
          highlighted.scrollIntoView({
            block: 'nearest',
          });
        }
      });
    },
    preventEnter(event) {
      if (event.key === 'Enter') {
        event.preventDefault();
      }
    },
    updateWithNote(id, withNote) {
      this.update(id);
      this.$emit('set-note', withNote ? this.customNote : null);
      if (this.$refs.dropdown?.isActive) {
        this.$refs.dropdown.toggle();
      }
    },
    formatString(str) {
      return str.replace(/\s+/g, '-').toLowerCase();
    },
  },
};
</script>

<style scoped>
  .is-highlighted {
    background-color: var(--bg-gray3);
  }
</style>
