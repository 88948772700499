<template>
  <button
    :class="{
      'is-active' : isActive && $route.name === 'rfq-form',
      dragover,
      'drop-available': canDrop
    }"
    class="project-list-element is-relative hover-parent"
    data-testid="project-pill"
    @click.stop="$emit('select')"
    @dragenter="handleDragOver"
    @dragleave="dragover = false"
    @dragover="handleDragOver"
    @drop="handlePartDrop"
    @mouseenter="loadDetails"
  >
    <div
      class="no-outline is-width-100"
    >
      <div
        class="is-flex is-flex-align-centered"
        style="height: 1.5rem;"
      >
        <div class="is-flex-grow-1 fill-fix-width">
          <p
            class="project-title text-align-left"
            :class="darkText && 'has-text-dark'"
            data-testid="project-title"
          >
            <span
              class="is-size-6"
              :content="project.name || 'Untitled project'"
            >
              {{ project.name || 'Untitled project' }}
            </span>
          </p>
        </div>
        <div
          v-if="isProjectOwner && !project.archived_at"
          class="is-flex-grow-0 visible-on-hover"
        >
          <a
            class="has-text-black m-r-sm"
            data-testid="rename-project-btn"
            @click.stop="$emit('prompt-rename')"
          >
            <BIcon
              icon="edit"
              pack="fal"
              style="font-size: 1rem; color: white;"
            />
          </a>
        </div>
        <div
          v-if="isProjectOwner"
          class="is-flex-grow-0 visible-on-hover"
        >
          <a
            v-if="project.archived_at"
            data-testid="unarchive-project-btn"
            class="has-text-black"
            @click.stop="$emit('confirm-action', 'unarchive')"
          >
            <BIcon
              icon="box-open"
              pack="fal"
              style="font-size: 1rem; color: white;"
            />
          </a>
          <a
            v-else
            class="has-text-black visible-on-hover"
            data-testid="archive-project-btn"
            @click.stop="$emit('confirm-action', 'archive')"
          >
            <BIcon
              icon="archive"
              pack="fal"
              style="font-size: 1rem; color: white;"
            />
          </a>
        </div>
      </div>
      <div class="mt-4">
        <ProjectMemberAvatars :project="project" />
      </div>
    </div>
  </button>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import { USER_DATA, USER_MODULE } from '@/app-buyer/store/modules/user/types';
import { PROJECT_MODULE, SET_PROJECT_INVITE_MODAL } from '@/app-buyer/store/modules/projects/types';
import ProjectMemberAvatars from '@/app-buyer/components/project/ProjectMemberAvatars.vue';

export default {
  name: 'ProjectSidebarElement',
  components: { ProjectMemberAvatars },
  props: {
    project: {
      type: Object,
      required: true,
    },
    darkText: {
      type: Boolean,
      default: () => false,
    },
    activeProject: {
      type: Object,
      default: () => null,
    },
    canDrop: Boolean,
  },
  data() {
    return {
      hovered: false,
      dragover: false,
    };
  },
  computed: {
    ...mapState(USER_MODULE, {
      userData: USER_DATA,
    }),
    isActive() {
      return this.activeProject && this.project.hash === this.activeProject.hash;
    },
    projectOwner() {
      return this.project.members?.find((member) => member.role === 'owner');
    },
    isProjectOwner() {
      return this.userData?.user.id === this.projectOwner?.user.id;
    },
  },
  methods: {
    ...mapMutations(PROJECT_MODULE, {
      SET_PROJECT_INVITE_MODAL,
    }),
    handleDragOver(ev) {
      ev.preventDefault();
      if (this.canDrop) {
        this.dragover = true;
      }
    },
    handlePartDrop(ev) {
      this.dragover = false;
      this.$emit('part-drop', ev);
    },
    handleShowInviteModal() {
      this[SET_PROJECT_INVITE_MODAL](true);
    },
    loadDetails() {
      if (!this.isActive) this.$emit('load-details');
    },
  },
};
</script>

<style lang="scss" scoped>

.project-list-element {
  align-items: center;
  background-color: transparent;
  border: 2px solid transparent;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  outline: none !important;
  overflow-x: hidden;
  padding: 1rem;
  min-height: 3.5rem;
  width: 100%;

  .wrapper {
    width: 100%;
  }

  p.project-title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: 1rem;
  }

  &.drop-available {
    border: 2px dashed rgba(variables.$v2-neutral-4, 0.5);
  }

  &.dragover {
    border: 2px dashed variables.$v2-neutral-4;
  }

  p.project-title,
  p.project-title.span {
    color: rgba(white, 0.75);
  }

  a {
    align-items: center;
    display: flex;
  }

  a {
    @media all and (max-width: variables.$desktop) {
      opacity: 1;
    }
  }

  &:hover {
    background-color: rgba(white, 0.1);

    p {
      color: white;
    }
  }

  &.is-active {
    background-color: rgba(white, 0.1);

    p {
      color: white;
    }
  }
}

.visible-on-hover {
  display: none;
}

.hover-parent:hover .visible-on-hover {
  display: block;
}
</style>
