<template>
  <BMessage
    size="is-small"
    :type="`is-${status}`"
  >
    <div class="is-flex is-justify-space-between">
      <div class="error-message-text is-flex is-align-items-center">
        <span
          v-if="issue.messageTitle"
          class="message-title"
        >{{ issue.messageTitle }}</span>
        {{ issue.message }}
      </div>
      <Component
        :is="issue.component"
        v-if="showCta"
        :part="part"
        :btn-text="issue.btnText"
        :btn-type="issue.btnType"
        :show-editor="issue.showEditor"
        :service="issue.service"
      />
    </div>
  </BMessage>
</template>

<script>

export default {
  name: 'PartErrorMessage',
  props: {
    issue: {
      type: Object,
      required: true,
    },
    part: {
      type: Object,
      required: true,
    },
    status: {
      type: String,
      default: 'danger',
    },
    showCta: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .error-message-text {
    margin-right: 0.5rem;
  }
  .message-title {
    font-weight: 700;
    margin-right: 0.5rem;
  }
  .message-body {
    padding: 0.7rem;
  }
}

</style>
