import {
  CART,
  CREDIT,
  ITEMS_TOTAL,
  PAYMENT_METHOD,
  PAYMENT_METHODS,
  PAYMENT_PROGRESS_PAID,
  PAYMENT_PROGRESS,
  SAVE_CARD,
  SHIPPING, STRIPE,
  SUBTOTAL,
  TAX_AMOUNT,
  TOTAL_AMOUNT,
  CART_DATA,
  ORDER_REFERENCE_NUMBER,
  ORDER_REFERENCE_FILE,
  DISCOUNT_VALUE,
  DISCOUNT_CODE_NAME,
} from './types';

export default function getState() {
  return {
    [CART]: [],
    [PAYMENT_PROGRESS]: null,
    [TOTAL_AMOUNT]: null,
    [TAX_AMOUNT]: null,
    [SUBTOTAL]: null,
    [SHIPPING]: {},
    [ITEMS_TOTAL]: null,
    [CREDIT]: null,
    [SAVE_CARD]: false,
    [STRIPE]: null,
    [PAYMENT_METHOD]: null,
    [ORDER_REFERENCE_NUMBER]: null,
    [ORDER_REFERENCE_FILE]: null,
    [PAYMENT_METHODS]: [],
    [PAYMENT_PROGRESS]: null,
    [PAYMENT_PROGRESS_PAID]: null,
    [CART_DATA]: null,
    [DISCOUNT_VALUE]: null,
    [DISCOUNT_CODE_NAME]: null,
  };
}
