const emailVerification = {
  methods: {
    emailVerificationToast() {
      if (this.$route.query.confirmEmail) {
        const status = this.$route.query.confirmEmail;
        let message; let type;
        switch (status) {
          case 'success':
            message = 'Email Verified Successfully';
            type = 'is-success';
            break;
          case 'already_verified':
            message = 'Your email address has already been verified';
            type = 'is-warning is-light';
            break;
          default:
            message = 'Email Verification Failed';
            type = 'is-danger';
            break;
        }
        this.$buefy.toast.open({
          message,
          type,
        });
      }
    },
  },
};

export default emailVerification;
