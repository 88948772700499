<template>
  <div class="flex align-items-baseline">
    <address
      v-if="addressObject && !hideString"
      class="mr-4 is-size-7/6"
      :data-testid="`${addressType}-contact-address`"
    >
      {{ addressObject.string }}
    </address>
    <button
      class="button-no-style has-text-info is-size-7/6"
      :data-testid="`add-${addressType}-contact-address`"
      @click="showModal = true"
    >
      {{ cta }}
    </button>
    <user-address-modal
      :active.sync="showModal"
      :data="data"
      @update="$emit('update', $event)"
    />
  </div>
</template>

<script>
/* eslint-disable camelcase */

import UserAddressModal from '@/app-buyer/components/user-profile/v2/user-address-modal.vue';

export default {
  name: 'UserAddress',
  components: { UserAddressModal },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    hideString: {
      type: Boolean,
      default: () => false,
    },
    addressType: {
      type: String,
      default: () => '',
    },
  },
  data() {
    return {
      showModal: false,
      STATUSES: {
        COMPLETE: 'COMPLETE',
        INCOMPLETE: 'INCOMPLETE',
        UNSET: 'UNSET',
      },
    };
  },
  computed: {
    addressObject() {
      if (this.data) {
        const {
          street_address, town, post_code, county, country,
        } = this.data;
        const segments = [street_address, town, post_code, county, country];
        // eslint-disable-next-line no-nested-ternary
        const status = segments.every((e) => e)
          ? this.STATUSES.COMPLETE
          : segments.every((e) => !e)
            ? this.STATUSES.INCOMPLETE
            : this.STATUSES.UNSET;
        const string = segments.join(', ');
        return {
          status,
          string,
        };
      }
      return null;
    },
    cta() {
      const addressType = this.addressType || this.data?.address_type;
      if (!this.addressObject || this.addressObject.status === 'UNSET' || this.hideString) {
        return `+ Add a new ${addressType} address`;
      }
      return 'Edit address';
    },
  },
};
</script>

<style
    lang="scss"
    scoped
>
address {
  font-style: normal;
}

::v-deep {
  label {
    font-size: 14px;
    font-style: normal;
    font-weight: normal;
    line-height: 16px;
    margin-bottom: 0.2rem !important;
  }
}
</style>
