// eslint-disable-next-line import/prefer-default-export
export const formInput = {
  props: {
    values: {
      type: Array,
      default: () => [],
    },
    leadValue: {
      type: [Number, String],
      default: () => null,
    },
    properties: {
      type: Array,
      default: () => [],
    },
    valueType: {
      type: String,
      default: () => 'string_value',
    },
    nativeValue: {
      type: [Number, String],
      default: () => null,
    },
  },
  methods: {
    update(value) {
      this.$emit('input', value);
    },
  },
};
