export const STORE = 'STORE';
export const PROJECTS = 'PROJECTS';
export const ACTIVE_PROJECT = 'ACTIVE_PROJECT';
export const ACTIVE_PROJECT_HASH = 'ACTIVE_PROJECT_HASH';
export const CURRENT_PROJECT = 'CURRENT_PROJECT';
export const SET_CURRENT_PROJECT_M = 'SET_CURRENT_PROJECT_M';
export const ARCHIVED_PROJECTS = 'ARCHIVED_PROJECTS';
export const SET_ACTIVE_PROJECT = 'SET_ACTIVE_PROJECT';
export const SET_ACTIVE_PROJECT_M = 'SET_ACTIVE_PROJECT_M';
export const ATTACH_PROJECTS = 'ATTACH_PROJECTS';
export const PROJECT_MODULE = 'PROJECT_MODULE';
export const LEAVE_PROJECT_CHANNEL = 'LEAVE_PROJECT_CHANNEL';
export const LISTEN_PROJECT_CHANNEL = 'LISTEN_PROJECT_CHANNEL';
export const ACTIVE_PROJECT_MEMBERS = 'ACTIVE_PROJECT_MEMBERS';
export const ACTIVE_PROJECT_MEMBER_COUNT = 'ACTIVE_PROJECT_MEMBER_COUNT';
export const PROJECT_INVITE_MODAL = 'PROJECT_INVITE_MODAL';
export const SET_PROJECT_INVITE_MODAL = 'SET_PROJECT_INVITE_MODAL';
export const REMOVE_FROM_PROJECT = 'REMOVE_FROM_PROJECT';
export const GET_ARCHIVED = 'GET_ARCHIVED';
export const SET_ARCHIVED_LOADED = 'SET_ARCHIVED_LOADED';
export const ARCHIVED_LOADED = 'ARCHIVED_LOADED';
export const ON_INITIAL_LOAD = 'ON_INITIAL_LOAD';
export const PAGINATION_PAGES = 'PAGINATION_PAGES';
export const SET_PAGINATION_PAGES = 'SET_PAGINATION_PAGES';
export const SET_PROJECT_SUPPORTING_FILE_MODAL = 'SET_PROJECT_SUPPORTING_FILE_MODAL';
export const PROJECT_SUPPORTING_FILE_MODAL = 'PROJECT_SUPPORTING_FILE_MODAL';
export const GET_MRFQ_PROJECTS = 'GET_MRFQ_PROJECTS';
export const GET_MRFQ_PROJECTS_M = 'GET_MRFQ_PROJECTS_M';
export const PROJECTS_MRFQ = 'PROJECTS_MRFQ';
export const SET_MRFQS_PAGINATION = 'SET_MRFQS_PAGINATION';
export const SET_MRFQS_PAGINATION_PAGES = 'SET_MRFQS_PAGINATION_PAGES';
export const MRFQS_PAGINATION = 'MRFQS_PAGINATION';
export const MRFQS_PAGINATION_PAGES = 'MRFQS_PAGINATION_PAGES';
export const RESET_PROJECTS_MRFQ = 'RESET_PROJECTS_MRFQ';
export const GET_UPDATED_PROJECTS_MRFQ = 'GET_UPDATED_PROJECTS_MRFQ';
