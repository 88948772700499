import {
  SET_DRAG_DATA,
  DRAG_DATA,
  REMOVE_DRAG_DATA,
  DRAGGING,
  DRAG_TYPE,
  APP_DRAGOVER,
} from './types';

export default {
  /**
     * Sets data in the state
     *
     * @param state
     * @param {Object} payload
     * @param {*} payload.data
     * @param {string} payload.type
     * */
  [SET_DRAG_DATA](state, { data, type }) {
    state[DRAG_DATA] = data;
    state[DRAGGING] = true;
    state[DRAG_TYPE] = type;
  },
  /**
     * Resets the drag state
     *
     * @param state
     * */
  [REMOVE_DRAG_DATA](state) {
    if (state[DRAG_DATA]?.node) {
      const parent = state[DRAG_DATA].node.parentNode;
      if (parent) {
        parent.removeChild(state[DRAG_DATA].node);
      }
    }
    if (state[DRAG_DATA]?.ghost) {
      const parent = state[DRAG_DATA].ghost.parentNode;
      if (parent) {
        parent.removeChild(state[DRAG_DATA].ghost);
      }
    }
    state[DRAG_DATA] = null;
    state[DRAGGING] = false;
    state[DRAG_TYPE] = null;
    state[APP_DRAGOVER] = false;
  },
};
