import {
  PENDING_REVISIONS,
  QUOTES,
  QUOTES_FILTERS,
  QUOTES_LOADING,
  QUOTES_META,
  REQUESTED_QUOTE_INFO,
  SET_PENDING_REVISIONS,
  SET_QUOTE_CARD_ITEM_TO_NULL,
  SET_QUOTES,
  SET_QUOTES_FILTERS,
  SET_QUOTES_LOADING,
  SET_QUOTES_META,
  SET_REQUESTED_QUOTE_INFO,
} from './types';

export default {
  /**
   * Sets data in the state
   *
   * @param state
   * @param data
   * */
  [SET_QUOTES](state, data) {
    state[QUOTES] = data;
  },
  [SET_QUOTES_META](state, data) {
    state[QUOTES_META] = data;
  },
  [SET_QUOTES_FILTERS](state, data) {
    state[QUOTES_FILTERS] = data;
  },
  [SET_REQUESTED_QUOTE_INFO](state, data) {
    state[REQUESTED_QUOTE_INFO] = data;
  },
  [SET_PENDING_REVISIONS](state, data) {
    state[PENDING_REVISIONS] = data;
  },
  [SET_QUOTES_LOADING](state, data) {
    state[QUOTES_LOADING] = data;
  },
  [SET_QUOTE_CARD_ITEM_TO_NULL](state, itemId) {
    const quoteItem = state.QUOTES.flatMap((quote) => quote.rfqs.flatMap((rfq) => rfq.quotes))
      .find((quoteProposal) => quoteProposal.id === itemId);
    if (quoteItem) {
      quoteItem.cart_item = null;
    }
  },
};
