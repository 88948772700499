import { MODEL } from '../store/modules/viewer/types';
import { findModelFile } from '@/app-buyer/components/project/helpers';

export const getParserErrorMessage = (status) => {
  switch (status) {
    case 'broken-file':
      return {
        messageTitle: 'This file has failed analysis',
        message: 'Please try again with another file. Please contact support if the issue continues.',
        btnText: 'Upload another file',
      };

    case 'multi-body':
      return {
        messageTitle: 'Multi-body part file',
        message: 'Unfortunately we cannot accept multi-body part files. Please ensure each file only contains one object.',
        btnText: 'Upload another file',
      };
    default:
      return {
        messageTitle: 'A problem occurred with the upload',
        message: 'Please try uploading this file again or try a different file.',
        btnText: 'Upload file',
      };
  }
};

// eslint-disable-next-line import/prefer-default-export
export const checkUploads = {
  computed: {
    _part() {
      return this.model || this.rfq || this.draftRfq || this[MODEL];
    },
    _messageInParserUUIDGServe() {
      try {
        // TODO makes this less hacky
        return JSON.parse(
          findModelFile(this._part)
            .parser_metadata.parser_uuid,
        ).message;
      } catch {
        return false;
      }
    },
    _missingModelFile() {
      try {
        return !this._part.uploadPercent && !findModelFile(this._part) && 'Missing model file, please upload a new one!';
      } catch {
        return false;
      }
    },
    _parserIssue() {
      const parserMetadata = findModelFile(this._part)?.parser_metadata;
      const hasIssue = parserMetadata?.failed_at != null;
      return hasIssue && getParserErrorMessage(parserMetadata?.status);
    },
    _hasInvalidModelFile() {
      const hasAnyIssue = this._missingModelFile
        || this._messageInParserUUIDGServe
        || this._parserIssue;
      return !!hasAnyIssue && `There is an issue with this file: ${hasAnyIssue}`;
    },
  },
  watch: {
    _hasInvalidModelFile: {
      handler(newVal) {
        if (this._part) {
          this.$set(this._part, '__fileError', newVal);
        }
      },
      immediate: true,
    },
  },
};
